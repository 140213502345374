import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Chrome from '../app/layout/Chrome';
import { fetchBespokeProducts } from '../features/bespoke/bespokeSlice';
import ConnectedOrderFormChrome from '../features/order_form/ConnectedOrderFormChrome';
import OrderAdForm from '../features/order_form/containers/OrderAdForm';
import {
  availableAdFormats,
  availableMercatoDevices,
  availableWebSpaces,
  orderPackages,
  orderUpdated,
  updateOrderFormOrganisations,
  clearOrder,
  selectOrder,
} from '../features/order_form/orderFormSlice';
import useSWR from 'swr';
import axios from '../axios';
import styled from 'styled-components';

const getOrder = async (order_id) => {
  return axios.get(`/order/${order_id}`);
};

// fetch order by id
const fetcher = (...args) => {
  //args is [[]]
  const params = args[0][0];
  return getOrder(params).then((res) => res);
};
const ViewOrder = () => {
  const dispatch = useDispatch();
  const { order_id } = useParams();

  const orderFromRedux = useSelector(selectOrder);
  const {
    data: order,
    isLoading,
    error,
  } = useSWR([order_id], fetcher, {
    keepPreviousData: true,
    revalidateOnMount: true,
  });

  useEffect(() => {
    if (order_id !== orderFromRedux.id) {
      dispatch(clearOrder()); //<- reset the order to the initialState if the in the url is different from what we have in redux.
    }
    if (!isLoading && !error) {
      dispatch(orderUpdated(order?.data?.data));
      dispatch(updateOrderFormOrganisations());
      dispatch(availableAdFormats());
      dispatch(availableMercatoDevices());
      dispatch(availableWebSpaces());
      dispatch(orderPackages());
      dispatch(fetchBespokeProducts());
    }
  }, [dispatch, isLoading, error, order_id]);

  if (isLoading)
    return (
      <Chrome>
        <Container>
          <Row>
            <Col>
              <StyledLoading>Loading order...</StyledLoading>
            </Col>
          </Row>
        </Container>
      </Chrome>
    );
  if (error) return <p>Error loading the order.</p>; //this can be improved

  return (
    <>
      <Helmet>
        <title>View Order - {process.env.REACT_APP_NAME}</title>
      </Helmet>

      <Chrome>
        <Container fluid className='pt-4'>
          <Row>
            <Col>
              <ConnectedOrderFormChrome>
                <OrderAdForm readOnly />
              </ConnectedOrderFormChrome>
            </Col>
          </Row>
        </Container>
      </Chrome>
    </>
  );
};

export default ViewOrder;

const StyledLoading = styled.div`
  display: grid;
  height: 90vh;
  place-items: center;
`;
