import { faCheck, faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import styles from './adbox.module.scss';

const placeholder = 'Enter a title for your order ex. Christmas Order etc.';

const Name = ({
  readOnly = false,
  required = false,
  value = '',
  onChange = () => {},
  errors = [],
  maxLength,
  maxLengthSoft,
}) => {
  const ref = useRef(null);
  const params = new URLSearchParams(useLocation().search);
  const [editing, setEditing] = useState((!readOnly && value.length === 0) || params.has('duplicate'));
  const [editValue, setEditValue] = useState(value);
  const handleChange = (e) => setEditValue(e.target.value);
  const errorMessage = errors.join('\n');

  useEffect(() => {
    if (errorMessage !== '') {
      toast.error(errorMessage);
    }
  }, [errorMessage]);

  useEffect(() => {
    if (params.has('duplicate') && ref?.current) {
      ref.current.select();
    }
  }, []);

  return (
    <Form.Group
      controlId='name'
      className='mb-0'
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          onChange(editValue);
          setEditing(false);
        }
      }}
    >
      <div className={`d-flex align-items-center ${styles.title_height}`}>
        {editing ? (
          <>
            <Form.Control
              ref={ref}
              type='text'
              readOnly={readOnly}
              value={editValue}
              isInvalid={errors.length > 0}
              onChange={handleChange}
              required={required}
              maxLength={maxLength}
              className={styles.adbox_input}
              placeholder={placeholder}
            />
            <span className='ml-2'>
              <Button
                className='order-name-btn'
                variant='success'
                disabled={editValue === ''}
                onClick={(e) => {
                  e.preventDefault();
                  onChange(editValue);
                  setEditing(false);
                }}
              >
                <FontAwesomeIcon icon={faCheck} />
              </Button>
            </span>
            <span className='ml-2'>
              <Button
                className='order-name-btn'
                variant='danger'
                disabled={value === ''}
                onClick={(e) => {
                  e.preventDefault();
                  setEditing(false);
                }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </Button>
            </span>
          </>
        ) : (
          <>
            <h3 className='mb-0'>{value}</h3>{' '}
            {!readOnly && (
              <span
                onClick={(e) => {
                  setEditValue(value);
                  setEditing(true);
                }}
              >
                <FontAwesomeIcon icon={faEdit} className='ml-3' />
              </span>
            )}
          </>
        )}
      </div>
    </Form.Group>
  );
};

Name.propTypes = {
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  maxLength: PropTypes.number,
  maxLengthSoft: PropTypes.number.isRequired,
};

export default Name;
