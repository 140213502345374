export const FALLBACK_CURRENCY = 'AUD';

export function calculateRoas(conversions, spend) {
  if (spend === 0) {
    return 0;
  }
  return (conversions / spend).toFixed(2);
}

export function discountDisplay(ads, currency = '$') {
  let percent = 0;
  let flat = 0;
  ads.map((ad) => {
    return {
      percent: (percent += ad?.percentage_discount),
      falt: (flat += ad?.flat_discount),
    };
  });

  if (flat > 0 && percent > 0) {
    return 'Discounts Available';
  }
  if (flat > 0 && percent === 0) {
    return `Discount: up to ${currency}${flat}`;
  }
  if (percent > 0 && flat === 0) {
    return `Discount: up to ${Math.floor(percent / ads.length)}%`;
  }
}

export function discountText(packageInfo) {
  let discount = '';
  if (packageInfo?.flat_discount) {
    discount += `$${packageInfo.flat_discount} OFF – `;
  }
  if (packageInfo?.percentage_discount) {
    discount += `${packageInfo.percentage_discount}% OFF – `;
  }
  return discount;
}

export function formatToCurrency(currency, value) {
  return new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: currency,
    maximumFractionDigits: 0,
    currencyDisplay: 'narrowSymbol',
  }).format(value);
}

export function formattedNumber(value) {
  return new Intl.NumberFormat(undefined, { notation: 'compact', compactDisplay: 'short' }).format(value);
}

export function currencySymbol(currency) {
  return new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: currency,
    maximumFractionDigits: 0,
    currencyDisplay: 'narrowSymbol',
  })
    .format(0)
    .slice(0, 1);
}

export function atof(string) {
  const parsed = parseFloat(string);
  return !isNaN(parsed) ? parsed : undefined;
}
