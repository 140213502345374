import React from 'react';
import styled from 'styled-components';
import {Figure} from 'react-bootstrap';
import Check from "./Check";
import {formatToCurrency} from "../../../../lib/financial";


const Container = styled(Figure)`
  position: relative;
  width: 16.25rem;
  ${props => props.disabled && 'opacity: 0.5;'}
`;

const ImageContainer = styled(Figure.Image)`
  object-fit: contain;
  height: 10rem !important;
  width: 100%;

  padding-top: 1.9rem;
  padding-bottom: 0.1rem;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const ImageCaption = styled(Figure.Caption)`
  box-sizing: content-box;
  text-align: left;
`;

const NameContainer = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const PriceContainer = styled.div`
  text-align: center;
`;

type ProductSquareProps = {
  name: string,
  src: string,
  price: number,
  currency: string,
  checked?: boolean,
  disabled?: boolean
  onClick: (EventTarget) => void,
};


const ProductSquare = ({
  name,
  src,
  price,
  currency,
  checked,
  disabled = false,
  onClick = () => {}
}: ProductSquareProps) => (
  <Container className="rounded bg-light" disabled={disabled} onClick={e => !disabled && onClick(e)}>
      <Check checked={checked}/>
      <ImageContainer className="px-3 rounded d-flex mx-auto" src={src} alt={name}/>
      <ImageCaption className="p-2">
          <NameContainer className="mb-2">{name}</NameContainer>
          <PriceContainer className="mb-2">{formatToCurrency(currency, price)}</PriceContainer>
      </ImageCaption>
  </Container>
);

export default ProductSquare
