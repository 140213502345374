import React from 'react';
import TextThrobber from "./TextThrobber";

const prompts = [
    "Preparing your brand's captivating ads.",
    "Generating momentum for your advertising campaign.",
    "Fine-tuning your ads for maximum impact.",
    "Engaging your audience with compelling visuals.",
    "Polishing your brand's story for the world.",
    "Crafting ads that will leave a lasting impression.",
    "Optimizing your campaign to drive results.",
    "Building anticipation for your brand's reveal.",
    "Unleashing the power of creativity in your ads.",
    "Finalizing the perfect blend of strategy and design.",
    "Igniting the spark that will ignite your brand.",
    "Creating ads that captivate and inspire.",
    "Refining every detail to grab attention.",
    "Harnessing the power of emotion in your ads.",
    "Assembling the ingredients for brand success.",
    "Bringing your brand to life through captivating ads.",
    "Unveiling a masterpiece of advertising creativity.",
    "Activating your brand's potential through ads.",
    "Empowering your brand to stand out from the crowd.",
    "Preparing a visual feast for your audience.",
];


const AdsThrobber = () => <TextThrobber prompts={prompts}/>;

export default AdsThrobber;
