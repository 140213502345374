import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

const TotalCost = ({ formData }) => {
  return (
    <Form.Group className='mb-3' controlId='formTotalCost'>
      <Form.Label className='font-weight-bold mb-1'>Total Cost</Form.Label>
      <div>$ {formData.total_cost}</div>
      <Form.Text className='text-muted'>
        This will be invoiced upfront once the store setup has been approved.
      </Form.Text>
    </Form.Group>
  );
};

export default TotalCost;

TotalCost.propTypes = {
  formData: PropTypes.shape({
    total_cost: PropTypes.number.isRequired,
  }).isRequired,
};
