import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Container, Form, InputGroup, Modal, Spinner } from 'react-bootstrap';

const CommentModal = ({
  show = false,
  title,
  heading,
  submitButtonText,
  handleSubmit,
  handleClose,
  maxLength = 1000,
  isSubmitting = false,
  errors = [],
}) => {
  const [comment, setComment] = useState('');

  useEffect(() => {
    if (!show) {
      setComment('');
    }
  }, [show]);

  const handleCommentChange = useCallback(
    (e) => {
      const inputText = e.target.value;
      if (inputText.length <= maxLength) {
        setComment(inputText);
      }
    },
    [maxLength]
  );

  return (
    <Modal dialogClassName='' show={show} onHide={handleClose}>
      <Modal.Header closeButton className='pl-4'>
        <span className='pl-2'>{title}</span>
      </Modal.Header>
      <Modal.Body>
        <Container className='container-fluid'>
          <Form.Group>
            <Form.Label>{heading}</Form.Label>
            <InputGroup>
              <Form.Control
                as='textarea'
                rows={10}
                cols={48}
                onChange={handleCommentChange}
                required={true}
                maxLength={maxLength}
              />
            </InputGroup>
            <Form.Text className='text-muted mt-1'>
              {comment.length}/{maxLength}
            </Form.Text>
            <>{errors.length > 0 && <Form.Text className='text-danger'>{errors.toString()}</Form.Text>}</>
          </Form.Group>
        </Container>
      </Modal.Body>
      <Modal.Footer className='pr-4'>
        <Button
          disabled={isSubmitting}
          className='mr-1 w-25'
          style={{ minWidth: '200px' }}
          onClick={() => handleSubmit(comment)}
        >
          {isSubmitting ? (
            <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' className='mr-2' />
          ) : (
            submitButtonText
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

CommentModal.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  heading: PropTypes.string,
  buttonText: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleClose: PropTypes.func,
  maxLength: PropTypes.number,
};

export default CommentModal;
