import { PREVIEW_STATE } from '../constants';
import { BrandStorePreviewIFrame } from './BrandStorePreviewIFrame';
import React from 'react';

export const PreviewContents = ({ brandStorePreview, previewPageId, brandStorePreviewState, brandStorePages }) => {
  return (
    <>
      {brandStorePreviewState === PREVIEW_STATE.INITIAL && <div>No preview...</div>}
      {brandStorePreviewState === PREVIEW_STATE.ERROR && (
        <>
          <div>Error updating preview</div>
          <div>{brandStorePreview}</div>
        </>
      )}
      {(brandStorePreviewState === PREVIEW_STATE.UPDATED || brandStorePreviewState === PREVIEW_STATE.UPDATING) && (
        <>
          <BrandStorePreviewIFrame
            brandStorePreview={brandStorePreview}
            previewPageId={previewPageId}
            previewPageContent={brandStorePages.find((page) => page.id === previewPageId)?.json_schema.blocks}
          />
        </>
      )}
    </>
  );
};
