
import { connect } from 'react-redux'
import Currency from '../../components/form_elements/Currency'
import { selectPackageMinBudget, selectPackageMinBudgetErrors, updateMinBudget } from '../../packageFormSlice'

const mapStateToProps = state => ({
    controlId: "package-min-budget",
    label: "Minimum Total Budget",
    description: "The minimum total budget for all ads in this package.",
    required: true,
    value: selectPackageMinBudget(state),
    errors: selectPackageMinBudgetErrors(state),
})


const mapDispatchToProps = dispatch => ({
    onChange: budget => dispatch(updateMinBudget(budget)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Currency)
