import { connect } from 'react-redux'
import { selectErrors, selectOrder, orderFieldUpdated, selectReadOnly } from '../orderFormSlice'
import Name from '../components/Name'

const mapStateToProps = state => ({
    controlId: 'name',
    required: true,
    readOnly: selectReadOnly(state),
    value: selectOrder(state).name,
    maxLengthSoft: 25,
    errors: selectErrors(state).name,
})


const mapDispatchToProps = dispatch => ({
    onChange: name => dispatch(orderFieldUpdated({ field: 'name', value: name })),
})

export default connect(mapStateToProps, mapDispatchToProps)(Name)
