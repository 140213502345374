import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import WebAdsDynamicField from '../components/WebAdsDynamicField';
import styles from '../owned_space_section.module.scss';

export const AdDetailsTab = ({
  readOnly,
  templateFields,
  ad,
  controlId,
  adType,
  errors,
  orderAssets,
  handleChange,
  productsCustomFields,
  handleWebAdsDynamicFieldChange,
}) => (
  <div className={styles.form_section}>
    <Container>
      <Row>
        <Col>
          {templateFields
            .filter((field) => !['asset', 'feed'].includes(field.type))
            .map((field) => (
              <div key={field.id} style={{ marginTop: 0, marginBottom: '25px' }}>
                <WebAdsDynamicField
                  ad={ad}
                  key={field.id}
                  controlId={`${controlId}.template_data.web.${field.id}`}
                  placeholder={field?.placeholder}
                  platform={adType.platform}
                  field={field}
                  readOnly={readOnly}
                  errors={errors[`template_data.web.${field.id}`]}
                  value={ad?.template_data?.web?.[field.id]}
                  assets={orderAssets}
                  onChange={(val) => {
                    handleChange({
                      template_data: {
                        web: {
                          ...ad?.template_data?.web,
                          [field.id]: val,
                        },
                      },
                    });
                  }}
                />
              </div>
            ))}

          {productsCustomFields
            .filter((field) => !['asset', 'feed'].includes(field.type))
            .map((field) => (
              <div key={field.id} style={{ marginTop: 0, marginBottom: '25px' }}>
                <WebAdsDynamicField
                  ad={ad}
                  key={field.id}
                  controlId={`${controlId}.${field.id}`}
                  disabled={readOnly}
                  placeholder={field?.placeholder}
                  platform={adType.platform}
                  field={field}
                  readOnly={readOnly}
                  errors={errors?.[field.id]}
                  value={ad?.[field.id]}
                  assets={orderAssets}
                  onChange={(val) => !readOnly && handleWebAdsDynamicFieldChange(val, field)}
                />
              </div>
            ))}
        </Col>
      </Row>
    </Container>
  </div>
);
