import styles from './ad_selection_modal.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import PropTypes from 'prop-types';

export const Group = ({ icon, title, selectstate, keyGroup }) => {
  return (
    <div
      className={`${styles.platform_box} ${selectstate?.toString() === keyGroup?.toString() ? styles.selected : null}`}
    >
      <div className={styles.platform_icon}>
        <FontAwesomeIcon icon={icon} style={{ height: '40px', width: '40px' }} />
      </div>
      <div className={styles.platform_text}>
        <span>{title}</span>
      </div>
    </div>
  );
};

Group.propTypes = {
  icon: PropTypes.object,
  title: PropTypes.string,
  tooltip: PropTypes.string,
  selectstate: PropTypes.string,
  keyGroup: PropTypes.string,
};
