import { faAt, faKey } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropType from 'prop-types'
import React from 'react'
import { Alert, Button, Col, Form, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Errors from '../order_form/components/Errors'
import styles from './loginform.module.scss'

const LoginForm = ({
  credentials = {},
  onChange = () => {},
  onSubmit = () => {},
  errors = {},
}) => (
  <Form>
    {errors?._error?.length > 0 && (
      <Alert variant="danger">
        <Errors errors={errors._error} />
      </Alert>
    )}
    <Form.Group controlId="email">
      <Row>
        <Col md={4} className="text-md-right">
          <Form.Label className={`col-form-label ${styles.label}`}>
            E-Mail Address <FontAwesomeIcon icon={faAt} />
          </Form.Label>
        </Col>
        <Col md={6}>
          <Form.Control
            type="email"
            onChange={e => onChange({ ...credentials, email: e.target.value })}
            isInvalid={errors.email?.length > 0}
          />
          <Form.Control.Feedback type="invalid">
            <Errors errors={errors.email} />
          </Form.Control.Feedback>
        </Col>
      </Row>
    </Form.Group>
    <Form.Group controlId="password">
      <Row>
        <Col md={4} className="text-md-right">
          <Form.Label className={`col-form-label ${styles.label}`}>
            Password <FontAwesomeIcon icon={faKey} />
          </Form.Label>
        </Col>
        <Col md={6}>
          <Form.Control
            type="password"
            onChange={e =>
              onChange({ ...credentials, password: e.target.value })
            }
            isInvalid={errors.password?.length > 0}
          />
          <Form.Control.Feedback type="invalid">
            <Errors errors={errors.password} />
          </Form.Control.Feedback>
        </Col>
      </Row>
    </Form.Group>
    <Row>
      <Col lg={{ span: 2, offset: 4 }} className="text-center mb-2">
        <Button type="submit" onClick={onSubmit}>
          Login
        </Button>
      </Col>
      <Col lg="4" className="text-center">
        <Link className="link" to="/reset">
          Forgot password?
        </Link>
      </Col>
    </Row>
  </Form>
)

LoginForm.propTypes = {
  credentials: PropType.shape({
    email: PropType.string,
    password: PropType.string,
  }),
  onChange: PropType.func.isRequired,
  onSubmit: PropType.func.isRequired,
  errors: PropType.object,
}

export default LoginForm
