import { connect } from "react-redux";
import { selectSession, selectUserOrganisation } from "../../session/sessionSlice";
import CustomAudiences from "../components/CustomAudiences";
import { facebookSavedAudienceUpdate, selectReadPermissions } from "../organisationSettingsSlice";


const mapStateToProps = state => ({
    title: 'Saved Audiences',
    value: selectReadPermissions(state)?.facebook_saved_audiences,
    audiences: selectSession(state).facebook_saved_audiences,
    preselectedValues: selectUserOrganisation(state)?.settings?.ad_settings?.facebook_saved_audiences,
})

const mapDispatchToProps = dispatch => ({
    onChange: saved_audiences => dispatch(facebookSavedAudienceUpdate(saved_audiences))
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomAudiences)
