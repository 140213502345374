import { connect } from 'react-redux';
import {
  isArchived,
  STATUS_APPROVED,
  STATUS_COMPLETED,
  STATUS_DRAFT,
  STATUS_LIVE,
  STATUS_NOT_APPROVED,
  STATUS_PENDING_APPROVAL,
} from '../../../lib/orders';
import { selectSession, selectUser } from '../../session/sessionSlice';
import StatusBadge from '../components/StatusBadge';
import { hasLiveOrderBeingViewed, selectLiveOrder, selectOrder } from '../orderFormSlice';

const mapStateToProps = (state) => {
  const order = selectOrder(state);
  const session = selectSession(state);
  const user = selectUser(state);
  const isEditedLiveOrder = hasLiveOrderBeingViewed(state);
  const liveOrder = selectLiveOrder(state);
  const orderStatus = isEditedLiveOrder ? liveOrder.status : order.status;

  let variant = 'primary';
  let label = session.order_statuses.find(
    (status) => status.id === orderStatus || (!order.id && status.id === STATUS_DRAFT)
  ).label;

  if (orderStatus === STATUS_PENDING_APPROVAL) {
    variant = STATUS_PENDING_APPROVAL;
  } else if (orderStatus === STATUS_APPROVED || orderStatus === STATUS_LIVE) {
    variant = 'success';
  } else if (orderStatus === STATUS_NOT_APPROVED) {
    variant = 'danger';
  } else if (orderStatus === STATUS_COMPLETED) {
    variant = 'secondary';
  } else if (orderStatus === STATUS_DRAFT || !order.id) {
    variant = 'secondary';
  }

  if (isArchived(order, user)) {
    variant = 'secondary';
    label = 'Archived';
  }

  return {
    variant,
    label,
  };
};

export default connect(mapStateToProps)(StatusBadge);
