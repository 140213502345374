import { connect } from 'react-redux'
import Slots from '../components/Slots'
import { selectOwnedSpace, selectOwnedSpaceErrors, updateSlots } from '../ownedSpaceFormSlice'

const mapStateToProps = state => ({
    value: selectOwnedSpace(state).slots,
    errors: selectOwnedSpaceErrors(state).slots,
    is_multitenancy: selectOwnedSpace(state).is_multitenancy,
})


const mapDispatchToProps = dispatch => ({
    onChange: value => dispatch(updateSlots(value)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Slots)
