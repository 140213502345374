import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Accordion, Badge, Card, Col, Row, useAccordionToggle, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { MPA_ONBOARDING_COMPLETED, MPA_ONBOARDING_FAILED, MPA_ONBOARDING_SUBMITTED } from '../../../lib/constants';
import {
  checkMPAEligibility, onboardMPASupplier,
  selectId,
  selectFacebookMPA,
  selectMPALastCheckedEligibilityTimestamp,
  selectMPAEligibilityRequestStatus,
  selectMPAOnboardingRequestErrors,
  selectMPAOnboardingRequestStatus
} from '../organisationSettingsSlice';
import { Eligibility } from './FacebookMPA/Elegibility';
import { OnboardingStatus } from './FacebookMPA/OnboardingStatus';
import { OnboardingForm } from './FacebookMPA/OnboardingForm';
import styles from './facebook_mpa.module.scss';
import styled from 'styled-components';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';

const CustomToggle = ({ children, eventKey, panelIndex, setPanelIndex }) => {
  const customOnClick = useAccordionToggle(eventKey, () => {
    setPanelIndex(eventKey === panelIndex ? null : eventKey);
  });

  const arrow =
    eventKey === panelIndex ? (
      <FontAwesomeIcon icon={faAngleUp} fixedWidth />
    ) : (
      <FontAwesomeIcon icon={faAngleDown} fixedWidth />
    );

  return (
    <Card.Header className='bg-white' onClick={customOnClick}>
      {children} <span className='float-right'>{arrow}</span>
    </Card.Header>
  );
};

const HeadDesc = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-aligned: left;
`;

const Container = styled.div`
  flex-grow: 1;
  font-size: 14px;

  .alert svg {
    margin-right: 8px;
  }

  .badge-not-eligible {
    color: #ffffff;
    background-color: #ef767a;
  }

  .badge-eligible {
    background-color: #87f5c0;
  }

  .alert-submitted {
    color: #ffffff;
    background-color: #5995ed;
  }

  .alert-failed {
    color: #ffffff;
    background-color: #ef767a;

    a {
      color: #000000;
    }
  }

  .alert-not-eligible-info {
    margin-top: 24px;
    color: #000000;
    background-color: #b5d0f7;
    font-size: 14px;

    a {
      color: #000000;
      text-decoration: underline;
    }

    svg {
      margin-right: 8px;
    }
  }
`;

const StepTitle = styled.h5`
  color: #000;
  padding-top: 10px;
`;

export const FacebookMPA = ({ controlId }) => {
  const dispatch = useDispatch();
  const supplierId = useSelector(selectId);
  const facebookMPA = useSelector(selectFacebookMPA);
  const isEligible = facebookMPA?.eligibility.is_eligible;
  const notEligibleReason = facebookMPA?.eligibility.reason_description;
  const lastCheckedEligibilityTimestamp = useSelector(selectMPALastCheckedEligibilityTimestamp);
  const eligibilityRequestStatus = useSelector(selectMPAEligibilityRequestStatus);
  const mpaOnboardingRequestStatus = useSelector(selectMPAOnboardingRequestStatus);
  const mpaOnboardingRequestErrors = useSelector(selectMPAOnboardingRequestErrors);
  const mpaOnboardingStatus = facebookMPA?.status || 'not-started';
  const mpaOnboardingUnsuccessfulReason = facebookMPA?.onboarding.error_user_msg;
  const mpaOnboardingSavedFormFields = facebookMPA?.settings || {};
  const [panelIndex, setPanelIndex] = useState();

  const handleClickCheckEligibility = () => {
    dispatch(checkMPAEligibility(supplierId));
  };

  const handleSubmitOnboardingForm = (formFields) => {
    dispatch(onboardMPASupplier({ supplierId, formFields }));
  };

  return (
    <section>
      <Accordion className='mb-2'>
        <Card className={styles.card}>
          <CustomToggle eventKey={controlId} panelIndex={panelIndex} setPanelIndex={setPanelIndex}>
            Meta - Managed Partner Ads <Badge variant='new'>New</Badge>
          </CustomToggle>
          <Accordion.Collapse eventKey={controlId}>
            <Card.Body className={styles.card_body}>
              <Container className='container'>
                <Row>
                  <Col md='8'>
                    <StepTitle>Step 1: Check Eligibility</StepTitle>
                    <HeadDesc>
                      Check if suppliers are eligible for Managed Partner Ads. This is determined based on their ad
                      spend on Facebook.
                    </HeadDesc>
                  </Col>
                </Row>
                <Row>
                  <Col md='8'>
                    <span style={{ display: 'flex', gap: '22px' }}>
                      <Eligibility
                        isChecking={eligibilityRequestStatus === 'loading'}
                        isEligible={isEligible}
                        lastStatusCheck={lastCheckedEligibilityTimestamp}
                        notEligibleReason={notEligibleReason}
                        onClickCheckEligibility={handleClickCheckEligibility}
                      />
                      {isEligible && <OnboardingStatus status={mpaOnboardingStatus} />}
                    </span>

                    {isEligible && (
                      <div style={{ marginTop: '18px' }}>
                        {mpaOnboardingStatus === MPA_ONBOARDING_SUBMITTED && (
                          <Alert variant='submitted'>
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                              <span>
                                <FontAwesomeIcon icon={faInfoCircle} />
                              </span>
                              <span>
                                Submission successful! Your supplier’s onboarding may take up to an hour to be
                                completed. Please check back later for updates.
                              </span>
                            </span>
                          </Alert>
                        )}
                        {mpaOnboardingStatus === MPA_ONBOARDING_FAILED && (
                          <Alert variant='failed'>
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                              <span>
                                <FontAwesomeIcon icon={faInfoCircle} />
                              </span>
                              <span>
                                {mpaOnboardingUnsuccessfulReason}
                                <div style={{ paddingTop: '6px' }}>
                                  Please reach out to <a href='mailto://support@zitcha.com'>Zitcha Support</a> for
                                  further assistance.
                                </div>
                              </span>
                            </span>
                          </Alert>
                        )}
                      </div>
                    )}
                  </Col>
                </Row>
              </Container>
              <Container className='container'>
                <Row>
                  <Col md='8'>
                    {!isEligible && (
                      <Alert variant='not-eligible-info'>
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                          <span>
                            <FontAwesomeIcon icon={faCircleInfo} />
                          </span>
                          <span>The supplier must be eligible before you can action the following steps.</span>
                        </span>
                      </Alert>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col>&nbsp;</Col>
                </Row>
                <Row>
                  <Col md='8'>
                    <StepTitle>Step 2: Onboard Supplier</StepTitle>
                    {isEligible && (
                      <HeadDesc>
                        Submit Supplier for onboarding with Meta. Once submitted this section will no longer be
                        editable.
                      </HeadDesc>
                    )}
                  </Col>
                </Row>
                {isEligible && (
                  <Row>
                    <Col md='8'>
                      <OnboardingForm
                        onboardingUnsuccessfulReason={mpaOnboardingUnsuccessfulReason}
                        errors={mpaOnboardingRequestErrors?.errors}
                        onboardingStatus={mpaOnboardingStatus}
                        onSubmit={handleSubmitOnboardingForm}
                        previousFormFields={mpaOnboardingSavedFormFields}
                        readOnly={
                          mpaOnboardingRequestStatus === 'loading' ||
                          [MPA_ONBOARDING_SUBMITTED, MPA_ONBOARDING_COMPLETED].includes(mpaOnboardingStatus)
                        }
                        supplierId={supplierId}
                      />
                    </Col>
                  </Row>
                )}
              </Container>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </section>
  );
};

FacebookMPA.propTypes = {
  controlId: PropTypes.string.isRequired,
};
