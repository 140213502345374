
import { connect } from 'react-redux'
import PackageList from '../components/PackageList'
import { selectAllPackages } from '../packagesSlice'

const mapStateToProps = state => ({
    packages: selectAllPackages(state),
})


export default connect(mapStateToProps)(PackageList)
