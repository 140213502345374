import React from 'react'
import PropTypes from 'prop-types'
import { Badge } from 'react-bootstrap'
import styled from 'styled-components'
import { STATUS_PENDING_APPROVAL } from '../../../lib/orders'

const NewBadge = styled(Badge)`
    color: #3A3B4F;
    background-color: #FFE787;
`

const StatusBadge = ({ variant, label }) => {
    let SBadge = Badge
    if (variant === STATUS_PENDING_APPROVAL) {
        SBadge = NewBadge
    }

    return <SBadge variant={variant || 'primary'}>
        {label}
    </SBadge>
}

StatusBadge.propTypes = {
    variant: PropTypes.oneOf(['info', 'primary', 'secondary', 'danger', STATUS_PENDING_APPROVAL, 'success']),
    label: PropTypes.string.isRequired,
}

export default StatusBadge
