
import { connect } from 'react-redux'
import { selectAvailableAdTypes } from '../../../order_form/orderFormSlice'
import { selectAdTypes } from '../../../session/sessionSlice'
import AdFormatSelect from '../../components/PackageForm/AdFormatSelect'
import { addAdFormat, selectPackageAdFormatTemplatesErrors } from '../../packageFormSlice'

const mapStateToProps = state => ({
    adFormats: selectAdTypes(state),
    enabledAdFormats: selectAvailableAdTypes(state),
    errors: selectPackageAdFormatTemplatesErrors(state),
})


const mapDispatchToProps = dispatch => ({
    onAdd: adFormat => dispatch(addAdFormat(adFormat)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AdFormatSelect)
