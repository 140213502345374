import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import Errors from "../Errors";

const Dropdown = ({
  header,
  controlId,
  readOnly = false,
  disabled = false,
  required = false,
  value = "",
  onChange = () => {},
  errors = [],
  options,
  info,
}) => {
  return (
    <Form.Group controlId={controlId}>
      <Form.Label className="font-weight-bold">
        {header} {required && "*"}
        {info && (
          <OverlayTrigger
            placement="left"
            overlay={<Tooltip id={`${controlId}-info-popover`}>{info}</Tooltip>}
          >
            <FontAwesomeIcon icon={faQuestionCircle} size="sm" />
          </OverlayTrigger>
        )}
      </Form.Label>
      <Form.Control
        as="select"
        name={controlId}
        isInvalid={errors.length > 0}
        required={required}
        readOnly={readOnly}
        disabled={disabled}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      >
        {Object.keys(options).map((opt, i) => (
          <option value={opt} key={i}>
            {options[opt]}
          </option>
        ))}
      </Form.Control>
      <Form.Control.Feedback type="invalid">
        <Errors errors={errors} />
      </Form.Control.Feedback>
    </Form.Group>
  );
};

Dropdown.propTypes = {
  header: PropTypes.string.isRequired,
  controlId: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  options: PropTypes.object.isRequired,
};

export default Dropdown;
