import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import EditableAsset from '../../components/generic/EditableAsset';
import CollapseableTooltipBox from '../../components/common/CollapseableTooltipBox';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';

import landscape_image_1 from './placeholder_images/landscape_image_1.svg';
import landscape_image_2 from './placeholder_images/landscape_image_2.svg';
import landscape_image_3 from './placeholder_images/landscape_image_3.svg';

import landscape_video_1 from './placeholder_images/landscape_video_1.svg';
import landscape_video_2 from './placeholder_images/landscape_video_2.svg';
import landscape_video_3 from './placeholder_images/landscape_video_3.svg';

import portrait_image_1 from './placeholder_images/portrait_image_1.svg';
import portrait_image_2 from './placeholder_images/portrait_image_2.svg';
import portrait_image_3 from './placeholder_images/portrait_image_3.svg';

import portrait_video_1 from './placeholder_images/portrait_video_1.svg';
import portrait_video_2 from './placeholder_images/portrait_video_2.svg';
import portrait_video_3 from './placeholder_images/portrait_video_3.svg';
import { RadioOption } from '../../components/RadioOption';
import { capitalizeFirstLetter } from '../../../../lib/strings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';

const getPlaceholderSrc = (screenType, assetType, creativeAssetIndex) => {
  const placeholders = [
    {
      Landscape: {
        image: landscape_image_1,
        video: landscape_video_1,
      },
      Portrait: {
        image: portrait_image_1,
        video: portrait_video_1,
      },
    },
    {
      Landscape: {
        image: landscape_image_2,
        video: landscape_video_2,
      },
      Portrait: {
        image: portrait_image_2,
        video: portrait_video_2,
      },
    },
    {
      Landscape: {
        image: landscape_image_3,
        video: landscape_video_3,
      },
      Portrait: {
        image: portrait_image_3,
        video: portrait_video_3,
      },
    },
  ];

  return placeholders[creativeAssetIndex][screenType][assetType];
};

const GuidelineLabel = styled.span`
  font-weight: bold;
  font-size: 14px;
  color: black;
`;

const GuidelineDescription = styled.span`
  font-size: 14px;
  color: black;
`;

const SmallPaddingTop = styled.div`
  padding-top: 8px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const LabelText = styled.span`
  font-size: 14px;
  color: black;
  font-weight: bold;
  margin-right: 5px;
`;

const DescriptionText = styled.span`
  font-size: 14px;
  color: black;
  font-weight: normal;
`;

const CreativeGuidelineContent = ({ screenType, mediaType }) => {
  return (
    <>
      <GuidelineLabel>Aspect ratio: </GuidelineLabel>
      <GuidelineDescription>{screenType === 'Landscape' ? '16:9' : '9:16'}</GuidelineDescription>
      <br />
      <GuidelineLabel>Resolution: </GuidelineLabel>
      <GuidelineDescription>
        {screenType === 'Landscape' ? '1920 x 1080 pixels minimum' : '2160 x 3840 pixels minimum'}
      </GuidelineDescription>
      <br />
      <GuidelineLabel>Accepted file extensions: </GuidelineLabel>
      <GuidelineDescription>{mediaType === 'video' ? 'mp4, mpeg4, mov' : 'jpeg'}</GuidelineDescription>
      <br />
      <GuidelineLabel>Max File Size: </GuidelineLabel>
      <GuidelineDescription>512 Mb</GuidelineDescription>
      {mediaType === 'video' && (
        <>
          <br />
          <GuidelineDescription>
            Video content must not contain assets that are static and displayed on screen for more than 3 seconds i.e.
            logos, terms and conditions, etc.
          </GuidelineDescription>
        </>
      )}
      <br />
    </>
  );
};

const BlueBackgroundPopover = styled(Popover)`
  background-color: #d0c6ff;

  & .arrow::after {
    border-top-color: #d0c6ff;
  }
`;

const singleMediaTypeInfoPopoverContent = () => {
  return (
    <BlueBackgroundPopover id='blue-popover'>
      <Popover.Content>This media type has been pre-selected by the Retailer.</Popover.Content>
    </BlueBackgroundPopover>
  );
};

const SingleMediaTypeOption = ({ mediaType }) => {
  return (
    <TextContainer>
      <div>
        <LabelText>{capitalizeFirstLetter(mediaType)}</LabelText>
        <OverlayTrigger placement='top' overlay={singleMediaTypeInfoPopoverContent()}>
          <FontAwesomeIcon icon={faCircleInfo} size='sm' />
        </OverlayTrigger>
      </div>
      <DescriptionText>{getMediaTypeDescription(mediaType)}</DescriptionText>
    </TextContainer>
  );
};

const getMediaTypeDescription = (mediaType) => {
  switch (mediaType) {
    case 'video':
      return 'Create a short and informative moving ad.';
    case 'image':
      return 'Create a static ad with fixed visual content.';
    default:
      return '';
  }
};

const SelectMediaType = ({
  availableMediaTypes,
  creativeAssetIndex,
  selectedMediaType,
  onSelectMediaType,
  readOnly,
}) => {
  if (availableMediaTypes.length === 1) {
    return <SingleMediaTypeOption mediaType={availableMediaTypes[0]} />;
  }
  return availableMediaTypes.map((mediaType) => (
    <RadioOption
      key={`select_media_type_${creativeAssetIndex}_${mediaType}`}
      name={`select_media_type_${creativeAssetIndex}_${mediaType}`}
      value={mediaType}
      checked={selectedMediaType === mediaType}
      onChange={onSelectMediaType}
      label={capitalizeFirstLetter(mediaType)}
      description={getMediaTypeDescription(mediaType)}
      readOnly={readOnly}
    />
  ));
};

export const UploadDynamicAsset = ({
  screenType,
  availableMediaTypes,
  ad,
  creativeAssetIndex,
  handleChange,
  value,
  readOnly,
  orderAssets,
}) => {
  const selectedMediaType = value.media_type;
  const assetValueForSelectedMediaType = value[selectedMediaType];
  const urlForSelectedAsset = orderAssets.find((a) => a.id === assetValueForSelectedMediaType)?.url;

  //useCallback to prevent triggering initialiser useEffect on every render
  const handleSelectMediaType = useCallback(
    (selectedMediaType) => {
      const updatedBroadsignMedia = JSON.parse(JSON.stringify(ad.broadsign_media));
      updatedBroadsignMedia[creativeAssetIndex].find((v) => v.screen_type === screenType).media_type =
        selectedMediaType;
      handleChange({ broadsign_media: updatedBroadsignMedia });
    },
    [ad.broadsign_media, creativeAssetIndex, handleChange, screenType]
  );

  const updateAssetValue = (assetId) => {
    const updatedBroadsignMedia = JSON.parse(JSON.stringify(ad.broadsign_media));
    updatedBroadsignMedia[creativeAssetIndex].find((v) => v.screen_type === screenType)[selectedMediaType] = assetId;

    const flattenedMedia = [...ad.media, assetId];

    handleChange({
      broadsign_media: updatedBroadsignMedia,
      media: flattenedMedia,
    });
  };

  //initialise value of media_type to first option
  useEffect(() => {
    if (!selectedMediaType) {
      handleSelectMediaType(availableMediaTypes[0]);
    }
  }, [availableMediaTypes, selectedMediaType, handleSelectMediaType]);

  if (!selectedMediaType) {
    handleSelectMediaType(availableMediaTypes[0]);
    return <>Loading Media Types</>;
  }

  const assetUploadedForSelectedOptions = ad.media.find((mediaId) => mediaId === assetValueForSelectedMediaType);

  return (
    <>
      <CollapseableTooltipBox
        label='Creative Guidelines'
        heading={screenType}
        reducedSpacing={true}
        isBoldHeading={true}
        bgColor={'white'}
      >
        <CreativeGuidelineContent screenType={screenType} mediaType={selectedMediaType} />
      </CollapseableTooltipBox>
      <SmallPaddingTop />
      <SelectMediaType
        availableMediaTypes={availableMediaTypes}
        creativeAssetIndex={creativeAssetIndex}
        selectedMediaType={selectedMediaType}
        onSelectMediaType={(newMediaType) => {
          handleSelectMediaType(newMediaType);
        }}
        readOnly={readOnly}
      />
      <EditableAsset
        type={selectedMediaType}
        controlId={`${selectedMediaType}-${screenType}`}
        readOnly={readOnly}
        field={{ type: selectedMediaType }}
        header={''}
        value={urlForSelectedAsset}
        onChange={updateAssetValue}
        limitPreviewHeight={true}
        hideButtonOnReadOnly={true}
        emptyStatePlaceholder={
          <img
            src={getPlaceholderSrc(screenType, selectedMediaType, creativeAssetIndex)}
            alt='./placeholder'
            style={{ width: '100%' }}
          />
        }
        forceHideButton={assetUploadedForSelectedOptions}
        propertyForOnChange={'id'}
      />
      {assetUploadedForSelectedOptions && !readOnly && (
        <Button
          variant='outline-secondary'
          className='w-100'
          style={{ marginTop: '-10px' }}
          onClick={() => updateAssetValue(undefined)}
        >
          <div>Remove Media</div>
        </Button>
      )}
    </>
  );
};
