import React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import PropTypes from 'prop-types';
import $styles from './breadcrumbs.module.scss';
import { useNavigate } from 'react-router-dom';

const Breadcrumbs = ({ items }) => {
  const navigate = useNavigate();
  return (
    <Breadcrumb className={$styles.breadcrumb}>
      {items &&
        items.map((item, index) => (
          <Breadcrumb.Item
            key={`${item.title}${index}`}
            {...(item.path && { onClick: () => navigate(item.path) })}
            active={item?.active}
          >
            {item.title}
          </Breadcrumb.Item>
        ))}
    </Breadcrumb>
  );
};

Breadcrumbs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      title: PropTypes.string.isRequired,
      active: PropTypes.bool,
    }).isRequired
  ).isRequired,
};

export default Breadcrumbs;
