import React, { useRef } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import Chrome from './layout/Chrome';
import OrderPagination from '../features/orders/components/OrderPagination';
import Orders from '../features/orders/components/Orders';
import Filters from '../features/orders/components/Filters';
import useOrdersLogic from '../features/orders/hooks/useOrdersLogic';

const AllOrders = () => {
  const listTopRef = useRef(null);
  const { data:orders, error, isLoading, handleClick, searchParams, setSearchParams } = useOrdersLogic({
    archived: false
  });

  return (
    <>
      <Helmet>
        <title>Orders - {process.env.REACT_APP_NAME}</title>
      </Helmet>

      <Chrome>
        <Container className='pt-4' ref={listTopRef}>
          <Row>
            <Col>
              <h3>Orders</h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <Filters onChange={setSearchParams} filterFields={searchParams} />
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col>
              <Orders data={orders?.data?.data} isLoading={isLoading} error={error} />
            </Col>
          </Row>

          <Row className='mb-3'>
            <Col>
              <OrderPagination
                totalItems={orders?.data.total}
                currentPage={orders?.data.current_page || 1}
                itemsPerPage={orders?.data.per_page || 20}
                onClick={handleClick}
                disabled={isLoading}
                ref={listTopRef}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Link to='/orders/archive'>
                <Button disabled={isLoading} variant='outline-secondary'>
                  Archived Orders
                </Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </Chrome>
    </>
  );
};

export default AllOrders;
