import { connect } from 'react-redux'
import EnableChannel from '../components/EnableChannel';
import { selectOwnedSpace, updateAppChannel,  updateWebChannel } from '../ownedSpaceFormSlice'

const mapStateToProps = state => ({
    is_app: selectOwnedSpace(state).is_app,
    is_web: selectOwnedSpace(state).is_web,
})

const mapDispatchToProps = dispatch => ({
    onAppChannelChange: value => dispatch(updateAppChannel(value)),
    onWebChannelChange: value => dispatch(updateWebChannel(value)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EnableChannel)
