import React from "react";
import styled from "styled-components";
import propTypes from "prop-types";
import CurrencyBadge from "../../CurrencyBadge";

const StyledCurrencyBadgeWrapper = styled.div`
  position: absolute;
  top: -5px;
  right: 0;
  margin-bottom: 1rem;
`;

const StyledCurrencyBadge = ({ currency }) => (
  <StyledCurrencyBadgeWrapper>
    <CurrencyBadge currency={currency} />
  </StyledCurrencyBadgeWrapper>
);

StyledCurrencyBadge.prototype = {
  currency: propTypes.string.isRequired,
};

export default StyledCurrencyBadge;
