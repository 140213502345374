import { faDollar } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const SectionHeader = styled.h3`
  margin-top: 30px;
`;

const HeaderDesc = styled.div`
  font-weight: 400;
  font-size: 20px;
  color: #777785;
`;

const StandardRatesSection = ({
  values = { standard_rates: { Portrait: 0, Landscape: 0, 'Landscape_&_Portrait': 0 } },
  isSaving = false,
  onChange = () => {},
  formErrors = {},
}) => {
  const [fieldErrors, setFieldErrors] = useState({});

  const getErrorsAsString = (errors) => {
    let err = '';
    if (Array.isArray(errors)) {
      err = errors.join(' ');
    } else {
      err = errors;
    }

    return err;
  };

  useEffect(() => {
    let errors = {};

    if (formErrors['standard_rates.Portrait']) {
      errors = {
        ...errors,
        portrait: getErrorsAsString(formErrors['standard_rates.Portrait'])
          .replaceAll('standard_rates.Portrait', 'portrait screens')
          .replaceAll('Portrait', 'portrait screens'),
      };
    }
    if (formErrors['standard_rates.Landscape']) {
      errors = {
        ...errors,
        landscape: getErrorsAsString(formErrors['standard_rates.Landscape'])
          .replaceAll('standard_rates.Landscape', 'landscape screens')
          .replaceAll('Landscape', 'landscape screens'),
      };
    }
    if (formErrors['standard_rates.Landscape_&_Portrait']) {
      errors = {
        ...errors,
        bundle: getErrorsAsString(formErrors['standard_rates.Landscape_&_Portrait'])
          .replaceAll('standard_rates.Landscape_&_Portrait', 'bundled (landscape and portrait)')
          .replaceAll('Landscape_&_Portrait', 'bundled (landscape and portrait)'),
      };
    }

    setFieldErrors(errors);
  }, [formErrors]);

  return (
    <>
      <Row className='mb-3'>
        <Col>
          <SectionHeader>Standard rates</SectionHeader>
          <HeaderDesc>Default cost per screen slot (when no promotions or events are booked)</HeaderDesc>
        </Col>
      </Row>
      <hr />
      <Row className='mb-3'>
        <Col md={4}>
          <Form.Group>
            <Form.Label>
              <strong>Landscape screens</strong>
            </Form.Label>
            <InputGroup>
              <InputGroup.Append>
                <InputGroup.Text>
                  <FontAwesomeIcon icon={faDollar} />
                </InputGroup.Text>
              </InputGroup.Append>
              <Form.Control
                type='number'
                value={values.standard_rates?.['Landscape'] ?? 0}
                disabled={isSaving}
                onChange={(e) => {
                  let props = { ...values.standard_rates, Landscape: Number(e.target.value) };
                  onChange({ ...values, standard_rates: props });
                }}
              />
            </InputGroup>
          </Form.Group>
          {fieldErrors?.landscape && <Form.Text className='text-danger'>{fieldErrors?.landscape}</Form.Text>}
        </Col>
        <Col md={4}>
          <Form.Group>
            <Form.Label>
              <strong>Portrait screens</strong>
            </Form.Label>
            <InputGroup>
              <InputGroup.Append>
                <InputGroup.Text>
                  <FontAwesomeIcon icon={faDollar} />
                </InputGroup.Text>
              </InputGroup.Append>
              <Form.Control
                type='number'
                value={values.standard_rates?.['Portrait'] ?? 0}
                disabled={isSaving}
                onChange={(e) => {
                  let props = { ...values.standard_rates, Portrait: Number(e.target.value) };
                  onChange({ ...values, standard_rates: props });
                }}
              />
            </InputGroup>
          </Form.Group>
          {fieldErrors?.portrait && <Form.Text className='text-danger'>{fieldErrors?.portrait}</Form.Text>}
        </Col>
        <Col md={4}>
          <Form.Group>
            <Form.Label>
              <strong>Bundled (landscape and portrait)</strong>
            </Form.Label>
            <InputGroup>
              <InputGroup.Append>
                <InputGroup.Text>
                  <FontAwesomeIcon icon={faDollar} />
                </InputGroup.Text>
              </InputGroup.Append>
              <Form.Control
                type='number'
                value={values.standard_rates?.['Landscape_&_Portrait'] ?? 0}
                disabled={isSaving}
                onChange={(e) => {
                  let props = { ...values.standard_rates, 'Landscape_&_Portrait': Number(e.target.value) };
                  onChange({ ...values, standard_rates: props });
                }}
              />
            </InputGroup>
          </Form.Group>
          {fieldErrors?.bundle && <Form.Text className='text-danger'>{fieldErrors?.bundle}</Form.Text>}
        </Col>
      </Row>
    </>
  );
};

StandardRatesSection.propTypes = {
  values: PropTypes.shape({
    standard_rates: PropTypes.shape({
      Portrait: PropTypes.number,
      Landscape: PropTypes.number,
      'Landscape_&_Portrait': PropTypes.number,
    }),
  }),
  isSaving: PropTypes.bool,
  onChange: PropTypes.func,
  FormErrors: PropTypes.object,
};

export default StandardRatesSection;
