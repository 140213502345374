export function isArchived(order, user) {
  const isOwner = order.owner_id === user.active_organisation_id;
  if (isOwner) {
    return order.is_archive_owner;
  }
  return order.is_archive_supplier;
}

export const STATUS_PENDING_APPROVAL = 'pending_approval';
export const STATUS_APPROVED = 'approved';
export const STATUS_NOT_APPROVED = 'not_approved';
export const STATUS_COMPLETED = 'completed';
export const STATUS_PAUSED = 'paused';
export const STATUS_LIVE = 'live';
export const STATUS_DRAFT = 'draft';

export const ORDER_FORM_STATE = {
  EDIT: 'edit',
  CREATE: 'create',
  VIEW: 'view',
  LIVE_EDIT: 'live_edit',
};

export const isPreApprovalStage = (order) => [STATUS_PENDING_APPROVAL, STATUS_DRAFT].includes(order.status);

export const isLive = (order) => order.status === STATUS_LIVE;
export const isEditingState = (order) => order?.can_edit;
export const isBeingLiveEdited = (order) =>
  Boolean(order.edited_snapshot_id) && order.edited_snapshot.status !== STATUS_APPROVED;

export function approvalError(order) {
  if (!order.valid_budgets) {
    return 'All ads must have a budget.';
  }

  if (!order.valid_locations) {
    return 'All ads must have location.';
  }

  if (!order.after_minimum_business_days) {
    return 'Order is set go live on a non-actionable date.';
  }

  if (!order.after_minimum_hours) {
    return 'Order is set go live on a non-actionable time. There should be at least 2 hours between order approval and go live';
  }

  if (!order.valid_link) {
    return 'Link is mandatory for ads.';
  }

  return '';
}

export const stripAdErrors = (errors, index) => {
  let newErrors = {};
  const phrase = `data_json.${index}.`;
  Object.keys(errors).forEach((errorLabel) => {
    if (errorLabel.startsWith(phrase)) {
      newErrors[errorLabel.replace(phrase, '')] = errors[errorLabel];
    }
  });
  return newErrors;
};

export const isOrderStatusPostApproval = (orderStatus) => {
  const orderStatusArray = [STATUS_LIVE, STATUS_APPROVED, STATUS_COMPLETED];
  return orderStatusArray.includes(orderStatus);
};
