import PropTypes from 'prop-types';
import { Button, Col, Container, Row } from 'react-bootstrap';
import ConnectedInsightsView from '../../metrics/ConnectedInsightsView';
import styled from 'styled-components';
import { SkuMetricsModal } from './SkuMetricsModal';
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpand } from '@fortawesome/pro-light-svg-icons';
import moment from 'moment';
import { getMpaProductMetrics } from '../../../../lib/api';

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Icon = styled(FontAwesomeIcon)`
  margin-left: 0.5rem;
`;

const SkuMetricsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const MetricsHeading = styled.h4`
  font-size: 18px;
  font-weight: 500;
  color: black;
`;

const MetricsSubText = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #3a3b4f;
`;

const SkuMetrics = ({ orderAdId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [skuMetrics, setSkuMetrics] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const handleModalClose = () => setIsModalOpen(false);
  const handleModalOpen = function () {
    setIsModalOpen(true);

    if (orderAdId) {
      fetchSkuMetrics();
    }
  };

  const fetchSkuMetrics = async () => {
    setIsLoading(true);
    try {
      const response = await getMpaProductMetrics(orderAdId);
      setSkuMetrics(response.data.data);
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <SkuMetricsContainer>
      <div>
        <MetricsHeading>Stock Keeping Unit (SKU) Metrics</MetricsHeading>
        <MetricsSubText>View the performance of specific products.</MetricsSubText>
      </div>
      <StyledButton variant='outline-primary' onClick={handleModalOpen}>
        View SKU Metrics <Icon icon={faExpand} />
      </StyledButton>
      <SkuMetricsModal
        isOpen={isModalOpen}
        handleClose={handleModalClose}
        skuMetrics={skuMetrics}
        isLoading={isLoading}
        error={error}
      />
    </SkuMetricsContainer>
  );
};

export const MpaMetricsTab = ({ insights = {}, refreshedAt, orderAdId, currency }) => {
  const OVERVIEW_FIELDS = [
    'clicks',
    'inline_link_clicks',
    'impressions',
    'reach',
    'cost_per_inline_link_click',
    'cpc',
    'spend',
    'total_roas',
    'online_sales_value', //Purchase Value
    'online_sales', //Quantity purchased
    'quantity_added_to_cart',
  ];

  const additionalInsightsSchema = [
    {
      type: 'field',
      name: 'online_sales_value',
      title: 'Purchase Value',
    },
    {
      type: 'field',
      name: 'quantity_added_to_cart',
      title: 'Added to Cart',
    },
  ];

  return (
    <Container>
      <Row>
        <Col sm='12'>
          <SkuMetrics orderAdId={orderAdId} />
          <MetricsHeading style={{ marginBottom: '24px' }}>Overall Ad Metrics</MetricsHeading>
          <ConnectedInsightsView
            insights={insights}
            overviewFields={OVERVIEW_FIELDS}
            sortByName={true}
            style={{ marginBottom: '24px' }}
            additionalInsightsSchema={additionalInsightsSchema}
            currency={currency}
          />
          {refreshedAt && (
            <MetricsSubText
              style={{
                textAlign: 'center',
                marginBottom: '24px',
              }}
            >
              Updated {moment(refreshedAt)?.fromNow()}
            </MetricsSubText>
          )}
        </Col>
      </Row>
    </Container>
  );
};

MpaMetricsTab.propTypes = {
  insights: PropTypes.object,
  refreshedAt: PropTypes.string,
  orderAdId: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
};
