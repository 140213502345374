import React from 'react';
import { LinkVariable } from './LinkVariable';
import { ImageVariable } from './ImageVariable';
import { VideoVariable } from './VideoVariable';
import styles from '../content_tab.module.scss';
import { ProductVariable } from './ProductVariableComponents/ProductVariable';

export const GenericContentBlockVariable = ({
  variableDefinition,
  onUpdate,
  brandStorePages,
  reduxValue,
  readOnly,
  variableErrors,
  productSets,
}) => {
  const type = variableDefinition.type;
  if (type === 'hidden') {
    return null;
  }
  const { label, constraints, guidelines } = variableDefinition;
  const renderVariable = () => {
    switch (type) {
      case 'link':
        return (
          <LinkVariable
            label={label}
            handleUpdateValue={onUpdate}
            constraints={constraints}
            brandStorePages={brandStorePages}
            reduxValue={reduxValue}
            readOnly={readOnly}
            variableErrors={variableErrors}
          />
        );
      case 'image':
        return (
          <ImageVariable
            label={label}
            handleUpdateValue={onUpdate}
            creativeGuidelines={guidelines}
            reduxValue={reduxValue}
            readOnly={readOnly}
            constraints={constraints}
            variableErrors={variableErrors}
          />
        );
      case 'video':
        return (
          <VideoVariable
            label={label}
            handleUpdateValue={onUpdate}
            creativeGuidelines={guidelines}
            reduxValue={reduxValue}
            readOnly={readOnly}
            variableErrors={variableErrors}
          />
        );
      case 'products':
        return (
          <ProductVariable
            handleChange={onUpdate}
            selectedValues={reduxValue}
            readOnly={readOnly}
            constraints={constraints}
            variableErrors={variableErrors}
            productSets={productSets}
            info={"Number of products per row will automatically adapt based on the brand store screen size."}
          />
        );
      default:
        return null;
    }
  };

  return <div className={styles.variable_section}>{renderVariable()}</div>;
};
