import React from 'react'
import styled from 'styled-components'
import devices from '../../../lib/devices'
import { Subheader, SubheaderWrapper, Subsection } from './AdGrid'

const PropSection = styled(Subsection)`
    @media screen and (max-width: ${devices.tablet}) {
        grid-column: 1/-1;
    }
`

const PropertiesSection = ({ children }) =>
    <PropSection>
        <SubheaderWrapper>
            <Subheader>Properties</Subheader>
        </SubheaderWrapper>
        <div>
            {children}
        </div>
    </PropSection>

export default PropertiesSection
