import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-bootstrap';

const Hidden = ({ controlId, value = '' }) => {
  return (
    <Form.Group controlId={controlId}>
      <Form.Control type='hidden' value={value} />
    </Form.Group>
  );
};

Text.propTypes = {
  controlId: PropTypes.string.isRequired,
  value: PropTypes.string,
};

export default Hidden;
