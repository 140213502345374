import axios from '../../axios'

export async function getProductSets(catalogueId) {
    return axios.get(`/productsets/${catalogueId}`)
}

export async function getProductSetsLinkSortPermissions(supplierId, catalogueId, searchTerm='', page=1) {
    return axios.get(`/productsets/${supplierId}/${catalogueId}?page=${page}&search_term=${searchTerm}`)
}

export async function addProductToProductSet(productSetID, IDs) {
    return axios.post(`/productsets/${productSetID}/products`, { products: IDs })
}
