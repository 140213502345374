
import { connect } from 'react-redux'
import TextArea from '../../components/form_elements/TextArea'
import { selectPackageDescription, updateDescription, selectPackageDescriptionErrors } from '../../packageFormSlice'

const mapStateToProps = state => ({
    controlId: "package-description",
    label: "Description",
    description: "Text over 82 characters may be truncated.",
    maxLengthSoft: 82,
    required: true,
    value: selectPackageDescription(state),
    errors: selectPackageDescriptionErrors(state),
})


const mapDispatchToProps = dispatch => ({
    onChange: description => dispatch(updateDescription(description)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TextArea)
