import React, { useState } from 'react';
import { AssetUploadAccordionCard } from './AssetUploadAccordionCard';
import { AssetCardContent } from './AssetCardContent';

export const isAssetComplete = (ad, creativeAssetIndex) => {
  const selectedScreenTypes = ad?.screen_types || [];

  if (selectedScreenTypes.length === 0) {
    return false;
  }

  for (const screenType of selectedScreenTypes) {
    const assetObject = ad?.broadsign_media?.[creativeAssetIndex]?.find((a) => a.screen_type === screenType);
    const selectedMediaType = assetObject?.media_type;
    const assetId = assetObject?.[selectedMediaType];
    if (!assetId) {
      return false;
    }
  }
  return true;
};

export const CreativeAssetUploadSection = ({ ad, handleChange, orderAssets, readOnly }) => {
  const [expandedCard, setExpandedCard] = useState(null);

  return (
    <div style={{ gap: 16, display: 'flex', flexDirection: 'column' }}>
      {ad.broadsign_media &&
        ad.broadsign_media.map((_, index) => (
          <AssetUploadAccordionCard
            key={index}
            title={`Creative Asset ${index + 1}`}
            expanded={expandedCard === index}
            onToggle={() => setExpandedCard(expandedCard !== index ? index : null)}
            optional={index !== 0}
            completed={isAssetComplete(ad, index)}
            orderAssets={orderAssets}
          >
            <AssetCardContent
              ad={ad}
              creativeAssetIndex={index}
              handleChange={handleChange}
              orderAssets={orderAssets}
              readOnly={readOnly}
            />
          </AssetUploadAccordionCard>
        ))}
    </div>
  );
};
