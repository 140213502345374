import PropTypes from "prop-types";

import React from "react";
import Checkbox from "./generic/Checkbox";
import ColorPicker from "./generic/ColorPicker";
import CurrencyAmount from "./generic/CurrencyAmount";
import Dropdown from "./generic/Dropdown";
import Radio from "./generic/Radio";
import Text from "./generic/Text";
import Hidden from "./generic/Hidden";
import TextArea from "./generic/TextArea";
import Url from "./generic/Url";
import EditableAsset from "./generic/EditableAsset";

const WebAdsDynamicField = ({
  controlId,
  field,
  placeholder,
  comment,
  readOnly = false,
  errors = [],
  onChange = () => { },
  value,
  assets,
  meta = {},
  ad,
  disabled = false,
}) => {
  if (value === undefined && field?.default) {
    onChange(field.default);
  }

  if (field.type === "text") {
    return (
      <Text
        controlId={controlId}
        placeholder={placeholder}
        header={field.label}
        readOnly={readOnly}
        required={field?.constraints?.required}
        maxLength={field?.constraints?.maxlen}
        maxLengthSoft={field?.constraints?.maxlensoft}
        notice={field.notice}
        info={field.comment}
        errors={errors}
        value={value}
        disabled={disabled}
        onChange={onChange}
      />
    );
  }

  if (field.type === "hidden") {
    return <Hidden controlId={controlId} value={value} />;
  }

  if (field.type === "currency") {
    return (
      <CurrencyAmount
        controlId={controlId}
        placeholder={placeholder}
        header={field.label}
        readOnly={readOnly}
        required={field?.constraints?.required}
        notice={field.notice}
        info={field.comment}
        disabled={disabled}
        errors={errors}
        value={value}
        onChange={onChange}
      />
    );
  }

  if (field.type === "textarea") {
    return (
      <TextArea
        controlId={controlId}
        placeholder={placeholder}
        header={field.label}
        readOnly={readOnly}
        required={field?.constraints?.required}
        maxLength={field?.constraints?.maxlen}
        maxLengthSoft={field?.constraints?.maxlensoft}
        notice={field.notice}
        disabled={disabled}
        info={field.info}
        errors={errors}
        value={value}
        onChange={onChange}
      />
    );
  }

  if (field.type === "link") {
    return (
      <Url
        controlId={controlId}
        placeholder={placeholder}
        header={field.label}
        readOnly={readOnly}
        required={field?.constraints?.required}
        maxLength={field?.constraints?.maxlen}
        maxLengthSoft={field?.constraints?.maxlensoft}
        notice={field.notice ? field.notice : "Please enter a valid full URL, e.g. http://www.example.com"}
        info={field.comment}
        disabled={disabled}
        errors={errors}
        value={value}
        onChange={onChange}
      />
    );
  }

  if (field.type === "color") {
    return (
      <ColorPicker
        controlId={controlId}
        header={field.label}
        readOnly={readOnly}
        required={field?.constraints?.required}
        notice={field.notice}
        info={field.comment}
        disabled={disabled}
        errors={errors}
        value={value}
        onChange={onChange}
      />
    );
  }

  if (field.type === "image" || field.type === "video") {

    return (
      <EditableAsset
        ad={ad}
        controlId={controlId}
        header={field.label}
        readOnly={readOnly}
        required={field?.constraints?.required}
        field={field}
        notice={field.notice}
        info={field.comment}
        errors={errors}
        value={value}
        disabled={disabled}
        files={assets?.filter ? assets.filter(a => a.url === value) : assets}
        onChange={onChange}
      />
    );
  }

  if (field.type === "select") {
    if (field?.variant === "radio") {
      return (
        <Radio
          controlId={controlId}
          header={field.label}
          readOnly={readOnly}
          required={field?.constraints?.required}
          options={field.options}
          info={field.comment}
          disabled={disabled}
          errors={errors}
          value={value}
          onChange={onChange}
        />
      );
    } else if (!field?.variant || field?.variant === "dropdown") {
      return (
        <Dropdown
          controlId={controlId}
          header={field.label}
          readOnly={readOnly}
          required={field?.constraints?.required}
          options={field.options}
          info={field.comment}
          disabled={disabled}
          errors={errors}
          value={value}
          onChange={onChange}
        />
      );
    }
  }

  if (field.type === "select_one_or_many") {
    if (field.variant === "checkbox") {
      return (
        <Checkbox
          controlId={controlId}
          header={field.label}
          readOnly={readOnly}
          required={field?.constraints?.required}
          options={field.options}
          info={field.comment}
          errors={errors}
          value={value}
          onChange={onChange}
        />
      );
    }
  }

  return "";
};

WebAdsDynamicField.propTypes = {
  controlId: PropTypes.string.isRequired,
  field: PropTypes.shape({
    type: PropTypes.oneOf([
      "text",
      "hidden",
      "textarea",
      "asset",
      "select",
      "select_one_or_many",
      "group",
      "link",
      "filter",
      "feed",
      "image",
      "video",
      "mixed",
      "currency",
      "color",
    ]).isRequired,
  }).isRequired,
  placeholder: PropTypes.string,
  comment: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  ad: PropTypes.object.isRequired,
};

export default WebAdsDynamicField;
