import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { BootstrapDataTable } from '../../../common/data/BootstrapDataTable';

// Styled components
const Container = styled.div`
  margin-top: 16px;

  #sku-metrics-table {
    border: none;
    max-height: 400px;
    overflow-y: auto;

    .table-header {
      background-color: #3a3b4f;
      color: white;
      text-overflow: ellipsis;
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
    }

    .table {
      tbody tr {
        height: 60px;
      }

      td {
        border-left: none;
        border-right: none;
        padding-left: 16px;
        padding-right: 16px;
        color: #00001e;
        font-weight: 700;
      }
    }
  }
`;

// Utility function for formatting currency
const formatCurrency = (amount, currency) => {
  return new Intl.NumberFormat(undefined, { style: 'currency', currency: currency }).format(amount).replace('A$', '$');
};

const ProductCellContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ProductIcon = styled.img`
  max-width: 32px;
  max-height: 32px;
  margin-right: 10px;
`;

const ProductCell = ({ productIcon, productName }) => (
  <ProductCellContainer>
    <ProductIcon src={productIcon} alt={productName} />
    {productName}
  </ProductCellContainer>
);

ProductCell.propTypes = {
  productIcon: PropTypes.string.isRequired,
  productName: PropTypes.string.isRequired,
};

const columns = [
  {
    dataField: 'product',
    text: 'Product',
    formatter: (cell, row) => <ProductCell productIcon={row?.image_url ?? ''} productName={row?.name ?? ''} />,
  },
  {
    dataField: 'offerId',
    text: 'SKU',
  },
  {
    dataField: 'quantity_added_to_cart',
    text: 'Quantity Added to Cart',
    classes: 'text-left',
    headerClasses: 'text-left',
  },
  {
    dataField: 'online_sales',
    text: 'Quantity Purchased',
    classes: 'text-left',
    headerClasses: 'text-left',
  },
  {
    dataField: 'online_sales_value',
    text: 'Purchase Value',
    classes: 'text-right',
    headerClasses: 'text-right',
    formatter: (cell) => { },
  },
];

// Main SKU Metrics Table component
export const SkuMetricsTable = ({ skuMetrics, currency }) => {
  return (
    <Container>
      <BootstrapDataTable
        keyField='offerId'
        data={skuMetrics}
        columns={columns.map(v => v.dataField !== 'online_sales_value' ? v : { ...v, ...{ formatter: (cell) => formatCurrency(cell, currency) } })}
        headerWrapperClasses='table-header'
        id='sku-metrics-table'
        paginationEnable={false}
      />
    </Container>
  );
};

SkuMetricsTable.propTypes = {
  skuMetrics: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      image_url: PropTypes.string.isRequired,
      offerId: PropTypes.string.isRequired, // this is SKU
      quantity_added_to_cart: PropTypes.string.isRequired,
      online_sales: PropTypes.string.isRequired,
      online_sales_value: PropTypes.string.isRequired,
    })
  ).isRequired,
  currency: PropTypes.string.isRequired,
};
