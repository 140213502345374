import React, { useState } from 'react';
import { Button, Form, InputGroup, Row, Col, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectSession } from '../../session/sessionSlice';
import { useNavigate } from 'react-router-dom';
import styles from './list.module.scss';
import styled from 'styled-components';

const StyledImgDiv = styled.div`
  width: 100px;
  margin-right: 1rem;
  position: relative;
  text-align: center;
`;
const StyledImgDivDashed = styled(StyledImgDiv)`
  border: 2px dashed #ddd;
  height: 50px;
`;
const StyledLogo = styled.img`
  max-width: 100px;
  max-height: 50px;
  margin: 0 auto;
`;

const applyFilters = (organisation, filter) => {
  return organisation.filter(
    (org) => !filter.searchTerm || org.name.toLowerCase().includes(filter.searchTerm.toLowerCase())
  );
};

const List = ({ header, organisations, onClick, isSupplier }) => {
  const navigate = useNavigate();
  const session = useSelector(selectSession);
  const [filters, setFilters] = useState({ searchTerm: null });
  const [tablePage, setTablePage] = useState(0);
  const handleFilterChange = (filterType) => setFilters({ ...filters, ...filterType });

  const filteredOrganisations = applyFilters(_.orderBy(organisations, 'name', 'asc'), filters);

  function nextTable() {
    let x = tablePage;
    x++;
    setTablePage(x);
  }

  function prevTable() {
    let x = tablePage;
    if (tablePage >= 1) x--;
    setTablePage(x);
  }

  let tablePageAmount = 8;

  return (
    <Row>
      <Col md={12}>
        {!isSupplier && organisations && organisations.length > 5 && (
          <div className='d-flex align-items-center justify-content-end py-2'>
            <Form.Group className={styles.search_bar}>
              <InputGroup>
                <Form.Control
                  type='text'
                  value={filters.searchTerm}
                  style={session?.theme?.components?.form_control}
                  onChange={(e) => {
                    handleFilterChange({ searchTerm: e.target.value });
                    setTablePage(0);
                  }}
                  placeholder='Search Suppliers'
                />
                <InputGroup.Text className={styles.search_icon}>
                  <FontAwesomeIcon icon={faSearch} />
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>
            <Button
              className={styles.invite_button}
              variant='primary'
              onClick={() => navigate('/organisations/invite')}
            >
              Invite New Supplier
            </Button>
          </div>
        )}
      </Col>
      <Col>
        <hr />
      </Col>
      <Col md={12}>
        <Table className={styles.tablestyle}>
          <thead>
            <tr>
              <th>Logo</th>
              <th>Supplier Name</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {filteredOrganisations.map(
              (organisation, index) =>
                tablePage * tablePageAmount < index + 1 &&
                index < (tablePage + 1) * tablePageAmount && (
                  <tr key={`${organisation.name}${index}`}>
                    <td>
                      {organisation.logo ? (
                        <StyledImgDiv>
                          <StyledLogo src={organisation.logo} alt={`${organisation.name} logo`} />
                        </StyledImgDiv>
                      ) : (
                        <StyledImgDivDashed />
                      )}
                    </td>
                    <td>
                      {isSupplier ? (
                        <p className='m-0'>{organisation.name}</p>
                      ) : (
                        <Link
                          to={'#'}
                          onClick={(e) => {
                            e.preventDefault();
                            return onClick(organisation);
                          }}
                        >
                          {organisation.name}
                        </Link>
                      )}
                    </td>
                    <td>
                      {onClick && (
                        <Link
                          to={'#'}
                          className='ml-auto'
                          onClick={(e) => {
                            e.preventDefault();
                            return onClick(organisation);
                          }}
                        >
                          <FontAwesomeIcon icon={faChevronRight} />
                        </Link>
                      )}
                    </td>
                  </tr>
                )
            )}
          </tbody>
        </Table>
        <div className={styles.paginator}>
          <Link to={'#'} onClick={prevTable}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </Link>
          <span>
            {tablePage + 1} of {Math.floor(filteredOrganisations.length / tablePageAmount + 1)}
          </span>
          <Link
            to={'#'}
            onClick={tablePage + 1 < Math.floor(filteredOrganisations.length / tablePageAmount + 1) ? nextTable : null}
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </Link>
        </div>
      </Col>
    </Row>
  );
};

export default List;
