import axios from '../../axios/axiosMainInstance';

export async function brandStoreBlocks() {
  return axios.get('/brand-stores/blocks');
}

export async function brandStorePreview(order) {
  return axios.post('/brand-stores/preview', order);
}

export async function getSavedBrandStoreOrder(orderId) {
  return axios.get(`/brand-stores/${orderId}`);
}

export async function saveBrandStore(orderId, order) {
  return axios.put(`brand-stores/${orderId}`, order);
}

export async function submitBrandStore(orderId, order) {
  return axios.post(`brand-stores/request-approval/${orderId}`, order);
}

export async function approveBrandStore(orderId, order) {
  return axios.post(`brand-stores/approve/${orderId}`, order);
}

export async function provideRetailerFeedbackForBrandStore(orderId, feedback) {
  return axios.post(`brand-stores/retailer-feedback/${orderId}`, { feedback });
}

export async function createBrandStoreConfigurationApi(retailer_id, order) {
  return axios.post(`brand-stores/organisations/${retailer_id}`, order);
}

export async function updateBrandStoreConfigurationApi(orderId, order) {
  return axios.post(`brand-stores/configuration/${orderId}`, order);
}
