import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import propTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const StyledNewWalletBtn = styled.button`
  background-color: #87f5c0;
  color: #3a3b4f;
  border: 1px solid #87f5c0;
  box-shadow: 1px 1px 1px #ccc;
  border-radius: 4px;
  width: 150px;
  padding: 0.5rem;
  font-size: 1rem;
`;

const NewWalletButton = ({ handleClick = () => {} }) => {
  return (
    <StyledNewWalletBtn onClick={handleClick} type="button">
      <FontAwesomeIcon icon={faPlus} size="md" className="mr-2" />
      <span>New</span>
    </StyledNewWalletBtn>
  );
};

NewWalletButton.propTypes = {
  handleClick: propTypes.func.isRequired,
};

export default NewWalletButton;
