import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import CreatableSelect from 'react-select/creatable';
import { DescriptionContainer, ErrorFeedback, LabelText } from "./Common";
import Errors from './Errors';


const CreatableDropdown = ({
  controlId,
  label,
  description,
  readOnly = false,
  disabled = false,
  required = false,
  value,
  onChange = () => { },
  onCreate = () => { },
  errors = [],
  options = [],
}) => {
  return <Form.Group controlId={controlId}>
    <LabelText className="font-weight-bold">
      {label} {required && "*"}
    </LabelText>
    <DescriptionContainer>
      {description}
    </DescriptionContainer>
    <CreatableSelect
      readOnly={readOnly}
      disabled={disabled}
      required={required}
      value={options.find(option => option.value === value) || null}
      onChange={(result) => onChange(result)}
      onCreateOption={result => onCreate(result)}
      options={options}
    />
    <ErrorFeedback type="invalid" isInvalid={errors.length > 0}>
      <Errors errors={errors} />
    </ErrorFeedback>
  </Form.Group>
};

CreatableDropdown.propTypes = {
  controlId: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  errors: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  onCreate: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  })).isRequired,
};

export default CreatableDropdown;
