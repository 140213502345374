import React, { useState } from 'react';
import { Alert, Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import { resetPasswordConfirm } from '../lib/api';
import Footer from './layout/Footer';

const ResetPasswordConfirm = () => {
  const navigate = useNavigate();
  let { token } = useParams();
  const [form, setForm] = useState({
    password: '',
    password_confirmation: '',
  });
  const [errors, setErrors] = useState({});
  const handleChange = (field) => {
    setForm({ ...form, ...field });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    resetPasswordConfirm(form.password, form.password_confirmation, token)
      .then((resp) => navigate('/'))
      .catch((err) => {
        if (err.response) {
          setErrors(err.response.data);
        }
      });
  };

  return (
    <>
      <Helmet>
        <title>Reset Password - {process.env.REACT_APP_NAME}</title>
      </Helmet>
      <main className='py-4'>
        <Container>
          <Row className='justify-content-center'>
            <Col md={8}>
              <Card>
                <Card.Body>
                  <Alert variant='primary' className='text-center'>
                    Enter your new password.
                  </Alert>
                  <Form>
                    <Form.Group controlId='password'>
                      <Row>
                        <Col lg='4' className='d-flex justify-content-end align-items-center'>
                          <Form.Label className='m-0'>Password</Form.Label>
                        </Col>
                        <Col lg='6'>
                          <Form.Control
                            required
                            type='password'
                            autoComplete='off'
                            onChange={(e) => handleChange({ password: e.target.value })}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group controlId='confirm-password'>
                      <Row>
                        <Col lg='4' className='text-right d-flex justify-content-end align-items-center'>
                          <Form.Label className='m-0'>Confirm Password</Form.Label>
                        </Col>
                        <Col lg='6'>
                          <Form.Control
                            required
                            type='password'
                            autoComplete='off'
                            onChange={(e) =>
                              handleChange({
                                password_confirmation: e.target.value,
                              })
                            }
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Row>
                      <Col className='text-center mb-2'>
                        <Button type='submit' variant='dark' onClick={handleSubmit}>
                          Reset Password
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </main>
      <Footer />
    </>
  );
};

export default ResetPasswordConfirm;
