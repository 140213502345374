import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';
import { Alert, Badge, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh, faWarning } from '@fortawesome/pro-regular-svg-icons';

const Container = styled.div`
  flex-grow: 1;
  font-size: 14px;

  .alert svg {
    margin-right: 8px;
  }

  .badge-not-eligible {
    color: #000000 !important;
    background-color: rgb(239,118,122) !important;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
  }

  .badge-eligible {
    background-color: #87f5c0;
  }

  .alert-not-eligible {
    margin-top: 24px;
    color: #000000;
    background-color: #ef767a;

    a {
      color: #000000;
      text-decoration: underline;
    }

    svg {
      margin-right: 8px;
    }
  }
`;

const Label = styled.span`
  font-size: 1rem;
  font-weight: 700; 
  margin-right: 6px;
`;

const NotEligibleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 22px;
`;

const EligibleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const LastCheckLabel = styled.span`
  color: #777785;
  font-size: 1rem;
  font-weight: 400;
  margin-left: 10px;
`;

export const Eligibility = ({
  isChecking,
  isEligible,
  lastStatusCheck,
  notEligibleReason,
  onClickCheckEligibility,
}) => {
  const renderEligible = () => {
    return (
      <EligibleContainer>
        <Label>Eligibility Status: </Label>
        <Badge variant='eligible'>Eligible</Badge>
      </EligibleContainer>
    );
  };

  const renderNotEligible = () => {
    return (
      <>
        <NotEligibleContainer>
          <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Label>Eligibility Status:</Label>
              <Badge variant='not-eligible'>Not Eligible</Badge>
              <LastCheckLabel>{`Last check made on ${moment(lastStatusCheck).format('DD/MM/YY HH:mm')}`}</LastCheckLabel>
            </div>
          </div>
        </NotEligibleContainer>
        {notEligibleReason &&
          <Alert variant='not-eligible'>
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <span>
                <FontAwesomeIcon icon={faWarning} />
              </span>
              <span>
                {notEligibleReason}
                <span style={{ paddingLeft: '6px' }}>
                  Please reach out to <a href='mailto://support@zitcha.com'>Zitcha Support</a> for further assistance.
                </span>
              </span>
            </span>
          </Alert>}
          <div>
            <Button disabled={isChecking} onClick={onClickCheckEligibility} variant='outline-primary'>
              {isChecking && (
                <>
                  <FontAwesomeIcon icon={faRefresh} spin />
                  <span> </span>
                </>
              )}
              Re-check eligibility
            </Button>
          </div>
      </>
    );
  };

  return <Container>{isEligible ? renderEligible() : renderNotEligible()}</Container>;
};

Eligibility.propTypes = {
  isChecking: PropTypes.bool,
  isEligible: PropTypes.bool,
  lastStatusCheck: PropTypes.instanceOf(Date),
  notEligibleReason: PropTypes.string,
  onClickCheckEligibility: PropTypes.func,
};
