// PLEASE DO NOT USE THESE CONSTANTS, USE ./lib/enums/ad_types.js instead
export const FACEBOOK_DISPLAY = 'facebook_display'
export const FACEBOOK_BOOSTED = 'facebook_boosted'
export const FACEBOOK_ONLINE_CONVERSION_DPA = 'facebook_online_conversion_dpa'
export const FACEBOOK_MANAGED_PARTNER_AD = 'facebook_managed_partner_ad'
export const FACEBOOK_CAROUSEL = 'facebook_carousel'
export const YOUTUBE_BUMPER = 'youtube_bumper'
export const GOOGLE_RESPONSIVE_SEARCH = 'google_responsive_search'
export const GOOGLE_EXPANDED_TEXT = 'google_expanded_text'
export const GOOGLE_SMART_SHOPPING = 'google_smart_shopping'
export const GOOGLE_SHORT_VIDEO = 'google_short_video'
export const GOOGLE_PERFORMANCE_MAX = 'google_performance_max'
export const NATIVE_BANNER = 'native_banner'
export const NATIVE_PRODUCT = 'native_product'

// Space format
export const OWNED_SPACE_BANNERS = 'banners'
export const OWNED_SPACE_PRODUCTS = 'product'

// Space subformat
export const OWNED_SPACE_SUBFORMAT_SEARCH = 'search'
export const OWNED_SPACE_SUBFORMAT_WEB = 'web'