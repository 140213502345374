import axios from '../../axios'

export async function getOwnedSpaceUsage(retailerId, filter, limit, page, order) {
    filter = encodeURIComponent(JSON.stringify(filter));
    return axios.get(`/organisations/${retailerId}/report/inventory/webspaces?filter=${filter}&limit=${limit}&page=${page}&orderby=${order}`);
}

export async function getAudiencesUsage(retailerId, filter, limit, page, order) {
    filter = encodeURIComponent(JSON.stringify(filter));
    return axios.get(`/organisations/${retailerId}/report/inventory/audiences?filter=${filter}&limit=${limit}&page=${page}&orderby=${order}`);
}

export async function getPackagesUsage(retailerId, filter, limit, page, order) {
    filter = encodeURIComponent(JSON.stringify(filter));
    return axios.get(`/organisations/${retailerId}/report/inventory/packages?filter=${filter}&limit=${limit}&page=${page}&orderby=${order}`);
}

export async function getAudienceUsage(retailerId, audienceId) {
    return axios.get(`/organisations/${retailerId}/report/inventory/audiences/${audienceId}`);
}

// Returns a temporary URL to the inventory reports file
export function getInventoryReportUrl(retailerId, asset, filter, order, limit, page) {
    filter = encodeURIComponent(JSON.stringify(filter));
    return axios.get(`/organisations/${retailerId}/report/inventory/${asset}?filter=${filter}&limit=${limit}&page=${page}&orderby=${order}&format=csv`);
}