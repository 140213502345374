import React from 'react';
import styled from 'styled-components';

const Frame = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  width: fit-content;
  margin-bottom: 32px;
`;

const Detail = styled.p`
  color: #000000;
  font-family: 'Roboto-Bold', Helvetica, sans-serif;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16.8px;
  margin-top: -1px;
  white-space: nowrap;
`;

const Label = styled.span`
  font-weight: 700;
`;

const Value = styled.span`
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 16.8px;
`;

export const SkuOrderDetails = ({ name, orderNumber, dates }) => {
  return (
    <Frame>
      <Detail>
        <Label>Order</Label>
        <Value>: {name}</Value>
      </Detail>
      <Detail>
        <Label>Order #</Label>
        <Value>: {orderNumber}</Value>
      </Detail>
      <Detail>
        <Label>Dates</Label>
        <Value>: {dates}</Value>
      </Detail>
    </Frame>
  );
};
