import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import styles from '../ad_section.module.scss';
import moment from 'moment';
import DynamicField from '../components/DynamicField';

export const AdDetailsTab = ({ ad, errors, handleChange, structureFields, structure, readOnly, controlId, order }) => {
  return (
    <div className={styles.form_section}>
      <Container>
        <Row>
          <Col sm='12'>
            {structureFields
              .filter((field) => !['hidden', 'asset', 'feed'].includes(field.type))
              .filter((field) => {
                if (!field.deprecated_at) {
                  return true;
                }
                if (order.created_at) {
                  return moment(field.deprecated_at).isAfter(ad.created_at);
                }
                return moment(field.deprecated_at).isAfter(moment());
              })
              .map((field) => (
                <div
                  key={field.id}
                  style={{
                    marginTop: 0,
                    marginBottom: '25px',
                  }}
                >
                  <DynamicField
                    key={field.id}
                    controlId={`${controlId}.${field.id}`}
                    platform={structure.platform}
                    field={field}
                    readOnly={readOnly}
                    errors={errors[field.id]}
                    meta={{ ad: ad }}
                    value={ad[field.id]}
                    onChange={(val) => handleChange({ [field.id]: val })}
                  />
                </div>
              ))}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

AdDetailsTab.propTypes = {
  ad: PropTypes.object.isRequired,
  errors: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  structureFields: PropTypes.array.isRequired,
  readOnly: PropTypes.bool.isRequired,
  controlId: PropTypes.string.isRequired,
  order: PropTypes.object.isRequired,
};
