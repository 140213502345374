import React from 'react';
import { GenericContentBlock } from './ContentBlockInputs/GenericContentBlock';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

export const ContentBlocks = ({
  currentPageContent,
  currentPageId,
  removeBlock,
  editBlock,
  saveBlock,
  blockTypes,
  editingBlockId,
  brandStorePages,
  moveBlock,
  readOnly,
  brandStoreErrors,
  productSets,
}) => {
  return (
    <>
      <DndProvider backend={HTML5Backend}>
        {currentPageContent.map((blockState, index) => {
          const contentBlockErrors = brandStoreErrors?.pages?.[currentPageId]?.blocks?.[blockState.id]?.errorMessages;
          return (
            <React.Fragment key={blockState.id}>
              <GenericContentBlock
                key={blockState.id}
                editingBlock={editingBlockId === blockState.id}
                blockState={blockState}
                removeBlock={removeBlock}
                editBlock={editBlock}
                saveBlock={saveBlock}
                currentPageId={currentPageId}
                blockId={blockState.id}
                blockTypes={blockTypes}
                brandStorePages={brandStorePages}
                readOnly={readOnly}
                contentBlockErrors={contentBlockErrors}
                moveBlock={moveBlock}
                index={index}
                productSets={productSets}
              />
            </React.Fragment>
          );
        })}
      </DndProvider>
    </>
  );
};
