import {RequestStatus} from "../../types";
import ProductGrid from "../ProductGrid";
import React from "react";
import ProductSelectorThrobber from "./ProductSelectorThrobber";
import {FailedProducts, NoProducts} from "./OtherStates";

type SelectorBodyProps = {
    status: RequestStatus,
    productsCount: number,
};

const ProductSelectorBody = React.forwardRef((props: React.PropsWithChildren<SelectorBodyProps>, ref): JSX.Element => {
    const {
        status,
        productsCount,
        children
    } = props;

    if (status === 'loading' && productsCount === 0) {
        return <ProductSelectorThrobber/>;
    }

    if (status === 'failed') {
        return <FailedProducts/>
    }

    if (productsCount === 0) {
        return <NoProducts/>;
    }

    if (status === 'loading' && productsCount > 0) {
        return <ProductGrid ref={ref}>
            {children}
            <ProductSelectorThrobber/>
        </ProductGrid>;
    }

    return <ProductGrid ref={ref}>
        {children}
    </ProductGrid>
});

export default ProductSelectorBody;