import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { selectOwnerOrganisation } from '../../../orderFormSlice';

import styles from "./linkedin_text_ad.module.scss";

const LinkedInTextAd = ({
  headline,
  description,
  link,
}) => {
  const fallback_logo = "/ad_preview/logo.png";
  const ownerOrganisation = useSelector(selectOwnerOrganisation);
  const isValidHttpUrl = (string) => {
      let url;
      
      try {
        url = new URL(string);
      } catch (_) {
        return false;  
      }
    
      return true;
  }
  
  return (
    <div className={styles.container}>

      <div className={styles.card}>
        <div className={styles.promoted}>
          <span>Promoted</span>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"> 
            <title>overflow-web-small</title> 
            <g><g><path d="M3,9.5A1.5,1.5,0,1,1,4.5,8,1.5,1.5,0,0,1,3,9.5ZM11.5,8A1.5,1.5,0,1,0,13,6.5,1.5,1.5,0,0,0,11.5,8Zm-5,0A1.5,1.5,0,1,0,8,6.5,1.5,1.5,0,0,0,6.5,8Z"></path></g></g>
          </svg>          
        </div>
        <div>
          <div className={styles.content}>
            <div className={styles.logo}>
              <img src={(ownerOrganisation?.settings?.logo?.url || ownerOrganisation?.logo) ?? fallback_logo} alt="" />
            </div>
            <div className={styles.text}>
              <a 
                className={styles.title} 
                href={isValidHttpUrl(link) ? new URL(link).protocol + "//" + new URL(link).hostname : link}
                target="_blank" rel="noreferrer"
              >
                {headline}
              </a>
              <div className={styles.description}>{description}</div>
            </div>
          </div>

          <div className={styles.content}>
            <div className={styles.logo}>
              <img src="https://static.licdn.com/sc/h/dd7cqvleum9kenb0c7jh3ov5f" alt="" />
            </div>
            <div className={styles.text}>
              <a className={styles.title} href>Invitation from LinkedIn</a>
              <div className={styles.description}>Place ads on LinkedIn. Acquire new customers for your business. Try it now.</div>
            </div>
          </div>

          <div className={styles.content}>
            <div className={styles.logo}>
              <img src="https://static.licdn.com/sc/h/dd7cqvleum9kenb0c7jh3ov5f" alt="" />
            </div>
            <div className={styles.text}>
              <a className={styles.title} href>Invitation from LinkedIn</a>
              <div className={styles.description}>Place ads on LinkedIn. Acquire new customers for your business. Try it now.</div>
            </div>
          </div>
        </div>

      </div>


      <p className={styles.note}>Note: The order your ad appears in the right column will depend on auction results.</p>
    </div>
  )
}

LinkedInTextAd.propTypes = {
  headline: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
}

export default LinkedInTextAd