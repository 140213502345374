
import { connect } from 'react-redux'
import Number from '../../components/form_elements/Number'
import { selectPackageStockAvailability, selectPackageStockAvailabilityErrors, updateStockSize } from '../../packageFormSlice'

const mapStateToProps = state => ({
    controlId: "package-stock-size",
    label: "Stock Amount",
    description: "The maximum number of packages you want to offer.",
    value: selectPackageStockAvailability(state),
    errors: selectPackageStockAvailabilityErrors(state),
})


const mapDispatchToProps = dispatch => ({
    onChange: name => dispatch(updateStockSize(name)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Number)
