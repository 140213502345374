import React, { useMemo } from "react";
import propTypes from "prop-types";
import {
  Controller,
  useFormContext,
  useFormState,
  useWatch,
} from "react-hook-form";
import { Alert, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

const StyledNameWarning = styled(Alert)`
  background-color: #ffe787;
  color: #3a3b4f;
  font-size: 0.75rem;
  padding: 0.75rem;
`;

const WalletName = ({ existingWallets = [] }) => {
  const methods = useFormContext();
  const { control } = methods;
  const { errors, isSubmitted, touchedFields } = useFormState();

  const nameValue = useWatch({
    name: "name",
  });

  const showNameWarning = useMemo(
    () => existingWallets.includes(nameValue?.trim().toLowerCase()),
    [nameValue, existingWallets]
  );

  return (
    <Form.Group>
      <Form.Label>Name *</Form.Label>
      <Controller
        name="name"
        control={control}
        render={({ field }) => (
          <Form.Control
            type="text"
            isInvalid={
              (isSubmitted || touchedFields.name) && errors.name?.message
            }
            {...field}
          />
        )}
      />

      {showNameWarning && (
        <StyledNameWarning className="d-flex align-items-baseline">
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            size="md"
            className="mr-2"
          />
          <p className="m-0">This wallet name already exists </p>
        </StyledNameWarning>
      )}

      {(isSubmitted || touchedFields.name) && (
        <Form.Control.Feedback type="invalid">
          {errors.name?.message}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

WalletName.prototype = {
  existingWallets: propTypes.arrayOf(propTypes.string).isRequired,
};

export default WalletName;
