import React, { useEffect } from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { loginAdminAsUser } from '../features/session/sessionSlice';
import Footer from './layout/Footer';

const LoginAs = () => {
  const dispatch = useDispatch();
  const status = useSelector((state) => state.session.status);

  let location = useLocation();
  let params = new URLSearchParams(location.search);

  useEffect(() => {
    if (params.has('token')) {
      handleLogin(params.get('token'));
    }
  }, []);

  const handleLogin = async (token) => {
    dispatch(loginAdminAsUser({ token: token }));
  };

  if (status === 'authenticated' || status === 'failed') {
    return <Navigate to='/' replace />;
  }

  return (
    <>
      <Helmet>
        <title>Login - {process.env.REACT_APP_NAME}</title>
      </Helmet>

      <main className='py-4 mt-4'>
        <Container>
          <Row className='justify-content-center'>
            <Col md={12}>
              <Row className='justify-content-center'>
                <div className='h1'>Redirecting, please wait ...</div>
              </Row>
              {status === 'loading' && (
                <Row className='text-center d-flex align-items-center justify-content-center'>
                  <Spinner animation='border' />
                  &nbsp;
                </Row>
              )}
              {status !== 'loading' && (
                <Row className='justify-content-center'>
                  <div className='h2'>Something went wrong, please close this tab and try again.</div>
                </Row>
              )}
            </Col>
          </Row>
        </Container>
      </main>
      <Footer />
    </>
  );
};

export default LoginAs;
