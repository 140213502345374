import React from 'react';
import styles from '../content_tab.module.scss';
import propertiesStyles from '../../../brand_page_properties.module.scss';
import { GenericContentBlockVariable } from './GenericContentBlockVariable';
import { ActionButton } from '../../PagesTabComponents/ActionButton';
import { faBars } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import { setBlockVariableValue } from '../../../../state_management/BrandStoreSlice';
import { DragDropCard } from '../../../DragDropComponents/DragDropCard';

const ContentBlockErrorBadge = () => {
  return (
    <div className={propertiesStyles.error_badge}>
      <div className={propertiesStyles.error_badge_text}>Error</div>
    </div>
  );
};

const ContentBlockHeader = ({
  name,
  editingBlock,
  onClickRemove,
  onClickEdit,
  onClickSave,
  readOnly,
  contentBlockErrors,
}) => {
  return (
    <div className={styles.block_header}>
      <div className={styles.left_side}>
        <FontAwesomeIcon
          icon={faBars}
          className={styles.summary_bars_icon}
          style={readOnly && { color: 'transparent' }}
        />
        <div className={styles.block_name_header}>{name}</div>
        {contentBlockErrors && Object.values(contentBlockErrors).length > 0 && <ContentBlockErrorBadge />}
      </div>
      <div className={styles.right_side}>
        {editingBlock ? (
          <ActionButton type={readOnly ? 'hide' : 'save'} onClick={onClickSave} />
        ) : (
          <>
            <ActionButton type={readOnly ? 'view' : 'edit'} onClick={onClickEdit} />
            {!readOnly && <ActionButton type='remove' onClick={onClickRemove} />}
          </>
        )}
      </div>
    </div>
  );
};

const ContentBlockBody = ({
  blockTypeDefinition,
  handleUpdateVariable,
  blockId,
  currentPageId,
  brandStorePages,
  blockState,
  readOnly,
  contentBlockErrors,
  productSets,
}) => {
  return (
    <>
      {blockTypeDefinition.meta.variables.map((variable) => {
        const variableErrors = contentBlockErrors?.[variable.key];
        return (
          <GenericContentBlockVariable
            key={variable.key}
            variableDefinition={variable}
            onUpdate={(value) => handleUpdateVariable(variable.key, blockId, currentPageId, value)}
            brandStorePages={brandStorePages}
            reduxValue={blockState.data[variable.key]}
            readOnly={readOnly}
            variableErrors={variableErrors}
            productSets={productSets}
          />
        );
      })}
    </>
  );
};

export const GenericContentBlock = ({
  editingBlock,
  blockState,
  removeBlock,
  editBlock,
  saveBlock,
  currentPageId,
  blockId,
  blockTypes,
  brandStorePages,
  contentBlockErrors,
  readOnly,
  moveBlock,
  index,
  productSets,
}) => {
  const dispatch = useDispatch();
  const handleUpdateVariable = (variableKey, blockId, currentPageId, value) => {
    dispatch(
      setBlockVariableValue({
        blockId: blockId,
        variableKey: variableKey,
        value: value,
      })
    );
  };
  const blockTypeDefinition = blockTypes.find((blockType) => blockType.id === blockState.block_id);
  return (
    <div className={styles.generic_content_block}>
      {readOnly ? (
        <div className={styles.generic_content_block_header}>
          <ContentBlockHeader
            name={blockState.name}
            editingBlock={editingBlock}
            onClickRemove={() => removeBlock(blockId)}
            onClickEdit={() => editBlock(blockId)}
            onClickSave={() => saveBlock()}
            readOnly={readOnly}
            contentBlockErrors={contentBlockErrors}
          />
        </div>
      ) : (
        <DragDropCard
          key={blockState.id}
          id={blockState.id}
          index={index}
          moveCard={moveBlock}
          readOnly={readOnly}
          type='contentCard'
        >
          <div className={styles.generic_content_block_header}>
            <ContentBlockHeader
              name={blockState.name}
              editingBlock={editingBlock}
              onClickRemove={() => removeBlock(blockId)}
              onClickEdit={() => editBlock(blockId)}
              onClickSave={() => saveBlock()}
              readOnly={readOnly}
              contentBlockErrors={contentBlockErrors}
            />
          </div>
        </DragDropCard>
      )}
      <div className={styles.generic_content_block_body} style={{ display: editingBlock ? 'block' : 'none' }}>
        <ContentBlockBody
          blockTypeDefinition={blockTypeDefinition}
          handleUpdateVariable={handleUpdateVariable}
          blockId={blockId}
          currentPageId={currentPageId}
          brandStorePages={brandStorePages}
          blockState={blockState}
          readOnly={readOnly}
          contentBlockErrors={contentBlockErrors}
          productSets={productSets}
        />
      </div>
    </div>
  );
};
