import { connect } from 'react-redux'
import {  selectOrder } from '../orderFormSlice'
import DateRangeDescription from '../components/DateRangeDescription'

const mapStateToProps = state => ({
    start_datetime: selectOrder(state).start_at,
    stop_datetime: selectOrder(state).stop_at,
})


const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(DateRangeDescription)
