import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import Chrome from '../../layout/Chrome';
import { useSelector } from 'react-redux';
import { selectSession } from '../../../features/session/sessionSlice';
import Breadcrumbs from '../../components/Breadcrumbs';
import styled from 'styled-components';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getBroadsignSettings, saveBroadsignSettings } from '../../../lib/api/broadsign';
import AvailabilitySection from './Sections/AvailabilitySection';
import { PromotionalRates } from './Sections/PromotionalRatesSection/PomotionalRates';
import StandardRatesSection from './Sections/StandardRatesSection';

const Header = styled.h1`
  margin-bottom: 20px;
`;
const HeaderDesc = styled.div`
  font-weight: 400;
  font-size: 20px;
  margin-bottom: 30px;
  color: #777785;
`;

const StyledSave = styled(Button)`
  &,
  &:hover,
  &:focus,
  &:active,
  &::selection {
    border-color: #644bf8 !important;
    background-color: #644bf8 !important;
  }
  margin-top: 30px;
`;

const breadcrumbItems = [
  {
    path: '/organisation/channels',
    title: 'Channel Settings',
  },
  {
    path: '/organisation/instorescreens-settings',
    title: 'In-Store Screens Settings',
    active: true,
  },
];

const OrganisationInStoreScreensSettings = () => {
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const session = useSelector(selectSession);
  const [organisation, setOrganisation] = useState(session.user.organisation);
  const [formErrors, setFormErrors] = useState({});
  const [rateRulesErrors, setRateRuleErrors] = useState({});
  const [msg, setMsg] = useState(null);
  const [availableScreenTypes, setAvailableScreenTypes] = useState([]);
  const [screenSettingsForm, setScreenSettingsForm] = useState({
    orientation: [],
    max_booking_date: '',
    //This is hard coded for now
    notification_empty_creatives: {
      unit: 'WEEK',
      period: 1,
    },
    standard_rates: { Portrait: 0, Landscape: 0, 'Landscape_&_Portrait': 0 },
    price_rules: [],
  });

  const loadData = async () => {
    try {
      const response = await getBroadsignSettings();
      if (response?.data) {
        setAvailableScreenTypes(response.data?.available_screen_types);
        setScreenSettingsForm({
          ...screenSettingsForm,
          orientation: response.data?.screen_types,
          max_booking_date: response.data?.max_booking_date,
          standard_rates: response.data?.standard_rates,
        });
      }
    } catch (error) {
      console.error('setScreenSettingsForm: error loading in-screen settings data', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMsg(null);
    setFormErrors({});
    setRateRuleErrors({});

    if (isLoading || isSaving) {
      return;
    }

    setIsSaving(true);

    try {
      await saveBroadsignSettings(screenSettingsForm);
      //clear out price rules when the changes are saved
      setScreenSettingsForm({ ...screenSettingsForm, price_rules: [] });
      setMsg({
        type: 'success',
        body: 'Settings have been saved successfully.',
      });
      //reload page
      setIsLoading(true);
      loadData();
    } catch (error) {
      const errors = error?.response?.data?.errors || [];
      setMsg({
        type: 'danger',
        body: 'Could not save the settings. please check settings form for any errors.',
      });
      setFormErrors(errors);
      if (error?.response?.data.error_code === 'dates_overlap') {
        setRateRuleErrors(error?.response?.data);
      }
      console.error('setScreenSettingsForm: error saving in-screen settings data', error);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>
          In-store Screens Settings {organisation?.name ?? session.user?.active_organisation?.name} -{' '}
          {process.env.REACT_APP_NAME}
        </title>
      </Helmet>
      <Chrome>
        <Container className='pt-4'>
          <Breadcrumbs items={breadcrumbItems} />
          <Form>
            {msg && (
              <Alert className='mt-3' variant={msg.type}>
                {msg.body}
              </Alert>
            )}
            <Row className='mb-3'>
              <Col md={9}>
                <Header>In-Store Screens Settings</Header>
                <HeaderDesc>Setup and configure your in-store screens.</HeaderDesc>
              </Col>
              <Col md={3}>
                {!isLoading && (
                  <StyledSave
                    variant='primary'
                    className='mr-auto px-5 py-2 float-right'
                    type='button'
                    onClick={handleSubmit}
                    size='md'
                    disabled={isSaving}
                  >
                    <span className='small d-flex'>
                      {isSaving && (
                        <Spinner
                          as='span'
                          size='sm'
                          animation='border'
                          role='status'
                          aria-hidden='true'
                          className='mr-2'
                        />
                      )}
                      <span>Save Changes</span>
                    </span>
                  </StyledSave>
                )}
              </Col>
            </Row>
            {isLoading && (
              <Row className='text-center d-flex align-items-center justify-content-center'>
                <FontAwesomeIcon className='fa-spin' style={{ cursor: 'pointer' }} icon={faSpinner} size='4x' />
              </Row>
            )}
            {!isLoading && (
              <>
                <AvailabilitySection
                  values={screenSettingsForm}
                  isSaving={isSaving}
                  onChange={(value) => {
                    setScreenSettingsForm({ ...screenSettingsForm, ...value });
                  }}
                  formErrors={formErrors}
                />
                <Row>
                  <Col>&nbsp;</Col>
                </Row>
                <StandardRatesSection
                  values={screenSettingsForm}
                  isSaving={isSaving}
                  onChange={(value) => {
                    setScreenSettingsForm({ ...screenSettingsForm, ...value });
                  }}
                  formErrors={formErrors}
                />
                <PromotionalRates
                  portraitAvailable={availableScreenTypes.find((e) => e?.key === 'Portrait') ? true : false}
                  landscapeAvailable={availableScreenTypes.find((e) => e?.key === 'Landscape') ? true : false}
                  bundleAvailable={availableScreenTypes.find((e) => e?.key === 'Landscape_&_Portrait') ? true : false}
                  values={screenSettingsForm}
                  isSaving={isSaving}
                  onChange={(value) => {
                    setScreenSettingsForm({ ...screenSettingsForm, ...value });
                    setFormErrors({ overlap: {} });
                    setMsg(null);
                  }}
                  formErrors={{ ...formErrors, overlap: rateRulesErrors }}
                />
              </>
            )}
          </Form>
        </Container>
      </Chrome>
    </>
  );
};

export default OrganisationInStoreScreensSettings;
