import { connect } from 'react-redux'
import { selectAllBespokeProducts } from '../../bespoke/bespokeSlice'
import AdForms from '../components/AdForms'
import { adDelete, adUpdated, budgetUpdated, isOwnerOrganisation, selectAssets, selectAvailablePackages, selectErrors, selectOrderAds } from '../orderFormSlice'

const mapStateToProps = state => ({
    ads: selectOrderAds(state),
    packages: selectAvailablePackages(state),
    bespoke: selectAllBespokeProducts(state),
    isOwner: isOwnerOrganisation(state),
    assets: selectAssets(state),
    errors: selectErrors(state),
})


const mapDispatchToProps = dispatch => ({
    onChange: (ad) => {
        dispatch(adUpdated(ad));
        dispatch(budgetUpdated());
    },
    onDelete: (ad) => {
        dispatch(adDelete(ad.id))
        dispatch(budgetUpdated())
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(AdForms)
