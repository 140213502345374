import styled from "styled-components";

export const StyledPopMessage = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;

  &:hover {
    cursor: pointer;
  }
`;