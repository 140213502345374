import React from 'react';
import { FormCheck, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './ScreenTypeTile.module.scss';

export const ScreenTypeTile = ({
  label,
  icon,
  infoMessage,
  checked,
  onChange,
  value,
  tooltipPlacement = 'right',
  readOnly,
}) => {
  return (
    <div
      className={`${styles.tileContainer} ${checked ? styles.selected : ''}`}
      onClick={() => !readOnly && onChange(!checked)}
    >
      <div className={styles.illustrationContainer}>
        <div>
          <img src={icon} alt={label} draggable='false' />
        </div>
      </div>
      <div className={styles.optionFooter}>
        <FormCheck
          id={label}
          type='checkbox'
          label={label}
          checked={checked}
          value={value}
          style={{ pointerEvents: 'none' }}
          disabled={readOnly}
        />
        <div>
          {infoMessage && (
            <OverlayTrigger
              placement={tooltipPlacement}
              overlay={<Tooltip style={{ textAlign: 'justify' }}>{infoMessage}</Tooltip>}
            >
              <FontAwesomeIcon icon={faInfoCircle} size='sm' />
            </OverlayTrigger>
          )}
        </div>
      </div>
    </div>
  );
};
