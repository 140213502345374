import { faFileAlt, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { downloadPOE, deletePOE } from '../../../../../lib/api';
import { PLATFORM_BESPOKE_PRODUCT } from '../../../../../lib/platforms';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { truncate } from '../../../../../lib/strings';

const DeleteButton = styled.button`
  top: 8px;
  right: 8px;
  line-height: 0;
  border-radius: 50%;
  background: #fff;
  margin-right: -10px;
  margin-left: -10px;
`;

const AssetView = styled.div`
  display: flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin: 10px 0;
  padding: 5px 10px;
  .image-container {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    img {
      border-radius: 5px;
      max-width: 100%;
      max-height: 100%;
    }
  }
  .file-name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
`;

const NoMedia = ({ name }) => <div className={`p-1 w-100 h-100 rounded`}>{name}</div>;

const ImgMedia = ({ src, alt }) => <img className={`p-1 w-100 h-100 rounded`} alt={alt} src={src} />;

const VideoMedia = ({ src }) => (
  <video controls='' width='100%' className={`p-1 w-100 h-100 rounded`}>
    <source src={src} />
  </video>
);

/**
 * A small preview of a media file to view.
 * @param {file} param0 The file to render the preview.
 */

const MediaFileView = ({ adId, platform, file, onDelete = () => {}, readOnly = false }) => {
  let media = <NoMedia name={file.name} />;
  if (file.assettype === 'image') {
    media = <ImgMedia src={file.url} alt={file.name} />;
  } else if (file.assettype === 'video') {
    media = <VideoMedia src={file.url} />;
  }
  const handleDownload = (file) => {
    const fileName = file.name;

    downloadPOE(file)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error('Error downloading file.');
        }

        const downloadLink = document.createElement('a');
        const objectUrl = URL.createObjectURL(response.data);
        downloadLink.href = objectUrl;
        downloadLink.download = fileName;
        downloadLink.click();

        URL.revokeObjectURL(objectUrl);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleDelete = (poeId, adId) => {
    deletePOE(poeId, adId)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error('Error deleting file.');
        }
      })
      .catch((error) => {
        throw new Error('Error deleting image.');
      });
  };

  const Filename = () => (
    <OverlayTrigger
      placement='top'
      overlay={
        <Popover id='popover-basic'>
          <Popover.Content>{file.name}</Popover.Content>
        </Popover>
      }
      trigger={['hover', 'focus']}
    >
      <span>{truncate(file.name, 25)}</span>
    </OverlayTrigger>
  );

  return platform && platform === PLATFORM_BESPOKE_PRODUCT ? (
    <AssetView>
      <div className='image-container'>
        {file.assettype === 'image' ? (
          <img src={file.url} alt='asset' />
        ) : (
          <FontAwesomeIcon icon={faFileAlt} size='2x' />
        )}
      </div>
      <div className='file-name'>
        <span>{file.name.length > 25 ? <Filename /> : file.name}</span>
        <Button variant='primary' onClick={() => handleDownload(file)}>
          Download <FontAwesomeIcon icon={faDownload} />
        </Button>
      </div>
      {!readOnly && (
        <DeleteButton
          className='p-0 align-items-center mt-3'
          onClick={(e) => {
            e.preventDefault();
            onDelete(file);
          }}
          style={{ position: 'relative', top: '-35px', left: '8px' }}
        >
          <span>
            <FontAwesomeIcon icon={faTimesCircle} size='1x' onClick={() => handleDelete(file.id, adId)} />
          </span>
        </DeleteButton>
      )}
    </AssetView>
  ) : (
    <div className='m-3 position-relative'>
      {media}
      {!readOnly && (
        <DeleteButton
          className='border-0 p-0'
          onClick={(e) => {
            e.preventDefault();
            onDelete(file);
          }}
        >
          <FontAwesomeIcon icon={faTimesCircle} size='1x' />
        </DeleteButton>
      )}
    </div>
  );
};

MediaFileView.propTypes = {
  file: PropTypes.shape({
    assettype: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),
  readOnly: PropTypes.bool,
  onDelete: PropTypes.func,
};

export default React.memo(MediaFileView);
