import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import Chrome from '../app/layout/Chrome';
import TransactionsView from '../features/wallets/TransactionsView';
import QueryResult from '../features/wallets/QueryResult';
import { getSingleWallet } from '../lib/api/wallets';
import { useRequest } from '../lib/hooks/useRequest';

const SingleWalletPage = () => {
  const [wallet, setWallet] = useState(null);
  const { wallet_id } = useParams();
  const navigate = useNavigate();

  const { response, loading, error } = useRequest(() => getSingleWallet(wallet_id));

  useEffect(() => {
    if (response !== null) {
      setWallet(response?.data);
    }
  }, [response]);

  return (
    <>
      <Helmet>
        <title>View Wallet - {process.env.REACT_APP_NAME}</title>
      </Helmet>

      <Chrome>
        <Container>
          <QueryResult loading={loading} error={error}>
            <Row>
              <Col>
                {wallet && (
                  <>
                    <TransactionsView
                      walletID={wallet.id}
                      isSupplier={true}
                      changeView={() => {
                        navigate('/wallets');
                      }}
                    />
                  </>
                )}
              </Col>
            </Row>
          </QueryResult>
        </Container>
      </Chrome>
    </>
  );
};

export default SingleWalletPage;
