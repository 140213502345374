import React, { useEffect, useRef, useState } from 'react';
import { Badge, Col, Form, Pagination, Row } from 'react-bootstrap';
import { debounce } from 'debounce';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Chrome from '../../app/layout/Chrome';
import { selectSession, selectUser } from '../session/sessionSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight, faChevronUp, faSpinner } from '@fortawesome/free-solid-svg-icons';
import Chart from 'react-google-charts';
import { getInventoryReportUrl, getOwnedSpaceUsage } from '../../lib/api/inventory';
import moment from 'moment';
import { ToolTip } from './UtilisationToolTip';
import { InventoryControls } from './InventoryControls';
import styles from '../../app/organisation_web_settings.module.scss';
import inventory_styles from './inventory_controls.module.scss';
import ChannelPagination from '../../app/components/ChannelPagination';
import { cachedFetchBrands, selectAllBrands } from '../brands/brandsSlice';
import { BootstrapDataTable } from '../common/data/BootstrapDataTable';

const upperCaseFirstChar = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const getUtilisationChartColumns = ({ period }) => {
  const periodId = period === 'weeks' ? 'Weeks' : period === 'days' ? 'Day' : 'Month';
  return [
    { type: 'string', id: 'Name' },
    { type: 'string', id: periodId },
    { type: 'string', role: 'tooltip' },
    { type: 'string', id: 'style', role: 'style' },
    { type: 'date', id: 'Start' },
    { type: 'date', id: 'End' },
  ];
};

const FormattedNameCell = ({ href, onClick, displayName, displayPath }) => {
  return (
    <div className='d-flex align-items-baseline' style={{ flexDirection: 'column' }}>
      <a href={href} onClick={onClick}>
        {displayName}
      </a>
      <p className={inventory_styles.category_text}>{displayPath}</p>
    </div>
  );
};

const getWebspaceColumns = (session, navigate) => {
  return [
    {
      dataField: 'name',
      text: 'Name',
      style: {
        whiteSpace: 'nowrap',
      },
      formatter: (cell, row) => {
        const url = '/inventory/ownedspace/usage/' + row.owned_space_id;
        const pathElements = row.name.split(' > ');
        const displayName = pathElements[pathElements.length - 1];
        const displayPath = pathElements.slice(0, pathElements.length - 1).join(' > ');
        return (
          <FormattedNameCell
            href={url}
            onClick={(e) => {
              e.preventDefault();
              navigate(url);
            }}
            displayName={displayName}
            displayPath={displayPath}
          />
        );
      },
    },
    {
      dataField: 'status',
      text: 'Status',
      formatter: (cell, row) => {
        return (
          <div>
            {row?.is_active && (
              <Badge className={[styles.statusBadge, styles.statusBadgeActive].join(' ')}>Active</Badge>
            )}

            {!row?.is_active && (
              <Badge className={[styles.statusBadge, styles.statusBadgeInactive].join(' ')}>Inactive</Badge>
            )}
          </div>
        );
      },
    },
    {
      dataField: 'category',
      text: 'Category',
      formatter: (cell, row) => {
        if (row?.category && row.category !== '') {
          return row.category;
        }
        return 'N/A';
      },
    },
    {
      dataField: 'format',
      text: 'Format',
      formatter: upperCaseFirstChar,
    },
    {
      dataField: 'subformat',
      text: 'Subformat',
      formatter: upperCaseFirstChar,
    },
    {
      dataField: 'filter_summary.ads',
      text: 'Campaigns',
      //sort: true,
      sortCaret: (order) => {
        if (!order)
          return (
            <span className='d-inline-block'>
              <FontAwesomeIcon size={'10px'} className='pl-2 w-100' icon={faChevronRight} />
            </span>
          );
        else if (order === 'asc')
          return (
            <span className='d-inline-block'>
              <FontAwesomeIcon size={'10px'} className='pl-2 w-100' icon={faChevronUp} />
            </span>
          );
        else if (order === 'desc')
          return (
            <span className='d-inline-block'>
              <FontAwesomeIcon size={'10px'} className='pl-2 w-100' icon={faChevronDown} />
            </span>
          );
        return null;
      },
    },
    {
      dataField: 'revenue',
      text: 'Revenue',
      headerFormatter: (column) => {
        return (
          <div style={{ width: '110px' }}>
            {column.text}{' '}
            <span className='badge badge-light'>{session.user.active_organisation?.settings?.defaultCurrency}</span>
          </div>
        );
      },
      formatter: (cell, row) => {
        return <div>{row?.filter_summary?.revenue}</div>;
      },
      //sort: true,
      sortCaret: (order) => {
        if (!order)
          return (
            <span className='d-inline-block'>
              <FontAwesomeIcon size={'10px'} className='pl-2 w-100' icon={faChevronRight} />
            </span>
          );
        else if (order === 'asc')
          return (
            <span className='d-inline-block'>
              <FontAwesomeIcon size={'10px'} className='pl-2 w-100' icon={faChevronUp} />
            </span>
          );
        else if (order === 'desc')
          return (
            <span className='d-inline-block'>
              <FontAwesomeIcon size={'10px'} className='pl-2 w-100' icon={faChevronDown} />
            </span>
          );
        return null;
      },
    },
    {
      dataField: 'filter_summary.impressions',
      text: 'Impressions',
      //sort: true,
      sortCaret: (order) => {
        if (!order)
          return (
            <span className='d-inline-block'>
              <FontAwesomeIcon size={'10px'} className='pl-2 w-100' icon={faChevronRight} />
            </span>
          );
        else if (order === 'asc')
          return (
            <span className='d-inline-block'>
              <FontAwesomeIcon size={'10px'} className='pl-2 w-100' icon={faChevronUp} />
            </span>
          );
        else if (order === 'desc')
          return (
            <span className='d-inline-block'>
              <FontAwesomeIcon size={'10px'} className='pl-2 w-100' icon={faChevronDown} />
            </span>
          );
        return null;
      },
    },
    {
      dataField: 'utilisation',
      text: 'Utilisation (%)',
      formatter: (cell, row) => {
        const period = row?.filter_utilisation?.by;
        let chartData = getUtilisationChartColumns(period);
        chartData = [
          chartData,
          ...row?.filter_utilisation?.data?.map((d) => [
            'Period',
            '' + d.percent,
            ToolTip(
              d.percent,
              period,
              period === 'days' ? new Date(d.date) : new Date(d.start_at),
              period === 'days' ? moment(d.date).add(1, 'days') : new Date(d.stop_at),
              period === 'days'
                ? 1
                : Math.ceil(moment.duration(moment(d.stop_at).diff(moment(d.start_at))).asDays() + 1)
            ),
            d.utilisation_color,
            period === 'days'
              ? new Date(d.date)
              : period === 'months'
                ? new Date(moment(d.start_at).startOf('month').format('YYYY-MM-DD'))
                : period === 'weeks'
                  ? new Date(moment(d.start_at).startOf('week').format('YYYY-MM-DD'))
                  : new Date(d.start_at),
            period === 'days'
              ? moment(d.date).add(1, 'days')
              : period === 'months'
                ? new Date(moment(d.stop_at).endOf('month').format('YYYY-MM-DD'))
                : period === 'weeks'
                  ? new Date(moment(d.stop_at).endOf('week').format('YYYY-MM-DD'))
                  : new Date(d.stop_at),
          ]),
        ];
        return (
          <Chart
            chartType='Timeline'
            data={chartData}
            labels='none'
            legendToggle
            is3D='true'
            className={`inventory-management-google-chart ${inventory_styles.google_chart_styles_overrides}`}
            options={{
              width: '350',
              height: '45',
              tooltip: { isHtml: true },
              allowHTML: true,
              alternatingRowStyle: true,
              bar: { groupWidth: '100%' },
              vAxis: { textPosition: 'none', viewWindowMode: 'maximized' },
              hAxis: { textPosition: 'none' },
              timeline: {
                groupByRowLabel: true,
                showRowLabels: false,
                showBarLabels: period !== 'days',
              },
            }}
          />
        );
      },
    },
  ];
};

const OrganisationInventoryHome = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const session = useSelector(selectSession);
  const suppliers = useSelector(selectAllBrands);
  const navigate = useNavigate();
  const [pageItemCount, setPageItemCount] = useState(30);
  const hasWebSpaces = session?.channels_enabled;
  const [filterByWebSpaceSupplier, setFilterByWebSpaceSupplier] = useState([]);
  const [filterByWebSpaceFormat, setFilterByWebSpaceFormat] = useState([]);

  const [filterByWebSpaceName, setFilterByWebSpaceName] = useState(null);
  const [filterByWebSpaceStatus, setFilterByWebSpaceStatus] = useState('all');
  const [filterByWebSpaceDateRange, setFilterByWebSpaceDateRange] = useState('last_30_days');

  const [isWebSpacesLoading, setIsWebSpacesLoading] = useState(true);
  const [webspaceData, setWebSpaceData] = useState([]);
  const [activeWebSpacesPage, setActiveWebSpacesPage] = useState(1);
  const [webspacesPaginationItems, setWebspacesPaginationItems] = useState([]);
  const [downloadIsProcessing, setDownloadIsProcessing] = useState(false);
  const [orderBy, setOrderBy] = useState('none');
  const [pageCount, setPageCount] = useState({ firstItem: 0, lastItem: 0, total: 0 });
  let filter = {};
  let order = 'none';
  const listTopRef = useRef(null);

  const setWebspaceFilters = () => {
    filter = {};

    if (filterByWebSpaceSupplier.length > 0) {
      filter = { ...filter, supplier: filterByWebSpaceSupplier };
    }
    if (filterByWebSpaceFormat.length > 0) {
      filter = { ...filter, format: filterByWebSpaceFormat };
    }
    if (filterByWebSpaceName && filterByWebSpaceName.length > 0) {
      filter = { ...filter, name: filterByWebSpaceName };
    }
    if (filterByWebSpaceStatus && filterByWebSpaceStatus.length > 0) {
      filter = { ...filter, status: filterByWebSpaceStatus };
    }
    if (filterByWebSpaceDateRange) {
      filter = { ...filter, range: filterByWebSpaceDateRange };
    }
    if (orderBy && orderBy !== 'none') {
      order = orderBy;
    }
  };

  useEffect(() => {
    setWebspaceFilters();
    setIsWebSpacesLoading(true);
    getOwnedSpaceUsage(user.active_organisation.id, filter, pageItemCount, activeWebSpacesPage, order)
      .then((res) => {
        let data = res.data?.data;
        let total = res.data?.total;

        setPageItemCount(res.data?.records_per_page ?? 30);
        let {
          allItems: items,
          firstItem,
          lastItem,
        } = ChannelPagination(pageItemCount, total, activeWebSpacesPage, setActiveWebSpacesPage);

        if (data) {
          setWebspacesPaginationItems(items);
          setWebSpaceData(data);
          setPageCount({ firstItem, lastItem, total });
        }

        setTimeout(() => {
          setIsWebSpacesLoading(false);
          listTopRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
          });
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          setIsWebSpacesLoading(false);
          listTopRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
          });
        }, 500);
      });
  }, [
    filterByWebSpaceSupplier,
    filterByWebSpaceFormat,
    filterByWebSpaceName,
    activeWebSpacesPage,
    filterByWebSpaceDateRange,
    filterByWebSpaceStatus,
    pageItemCount,
    orderBy,
  ]);

  const handleOrderByChange = debounce((val) => {
    //reset page parameter to 1
    setActiveWebSpacesPage(1);
    setOrderBy(val);
  }, 500);

  const handlePerformanceReportDownloadClick = () => {
    //webspaces
    setWebspaceFilters();
    setDownloadIsProcessing(true);
    getInventoryReportUrl(
      user.active_organisation.id,
      'webspaces',
      filter,
      order,
      pageItemCount,
      activeWebSpacesPage
    ).then((res) => {
      setDownloadIsProcessing(false);
      window.open(res.data.data.url);
    });
  };

  const handleFilterByWebSpaceNameValue = debounce((val) => {
    //reset page parameter to 1
    setActiveWebSpacesPage(1);
    setFilterByWebSpaceName(val);
  }, 500);

  const handleFilterByWebSpaceStatusValue = debounce((val) => {
    setActiveWebSpacesPage(1);
    setFilterByWebSpaceStatus(val);
  }, 500);

  const filterWebSpaceBySupplier = (selection) => {
    setActiveWebSpacesPage(1);
    if (selection === 'all') {
      setFilterByWebSpaceSupplier([]);
    } else {
      setFilterByWebSpaceSupplier([selection]);
    }
  };

  const filterByWebSpaceFormats = (format) => {
    setActiveWebSpacesPage(1);
    if (format === 'all') {
      setFilterByWebSpaceFormat([]);
    } else {
      setFilterByWebSpaceFormat([format]);
    }
  };

  const filterWebSpaceByDateRange = (selection) => {
    setFilterByWebSpaceDateRange(selection);
  };

  const filterByWebSpaceNameValue = (val) => {
    handleFilterByWebSpaceNameValue(val);
  };

  const filterByWebSpaceStatusValue = (status) => {
    handleFilterByWebSpaceStatusValue(status);
  };

  const webSpaceNoDataForFilter = () => {
    return <div className={styles.webSpaceNoDataForFilter}>There are no owned web spaces defined for this filter</div>;
  };

  useEffect(() => {
    dispatch(cachedFetchBrands());
  }, []);

  return (
    <>
      <Helmet>
        <title>
          Inventory home for Organisation {session.user?.active_organisation?.name} -{process.env.REACT_APP_NAME}
        </title>
      </Helmet>
      <Chrome>
        <div style={{ width: '100%', padding: '50px' }}>
          <Form ref={listTopRef}>
            <Row className='mb-3'>
              <Col>
                <div className='rounded my-1 d-flex'>
                  <div className='d-inline-block'>
                    <h3 className={styles.topHeading}>Your Inventory</h3>
                  </div>
                </div>
              </Col>
            </Row>
            <div>
              <div>
                <InventoryControls
                  onChangeFormatDropdown={(e) => filterByWebSpaceFormats(e.target.value)}
                  onChangeStatusDropdown={(e) => filterByWebSpaceStatusValue(e.target.value)}
                  onChangeSupplierDropdown={(e) => filterWebSpaceBySupplier(e.target.value)}
                  suppliers={suppliers}
                  onChangeDateRangeDropdown={(e) => filterWebSpaceByDateRange(e.target.value)}
                  onChangeFilterTextBox={(e) => filterByWebSpaceNameValue(e.target.value)}
                  onChangeSortDropdown={(e) => handleOrderByChange(e.target.value)}
                  downloadIsProcessing={downloadIsProcessing}
                  webSpacesLoading={isWebSpacesLoading}
                  onClickCsvDownloadButton={handlePerformanceReportDownloadClick}
                  tableEntryFrom={pageCount.firstItem}
                  tableEntryTo={pageCount.lastItem}
                  tableEntryTotal={pageCount.total}
                />
                <Row>
                  <Col>
                    {isWebSpacesLoading && (
                      <div
                        className='text-center d-flex align-items-center justify-content-center'
                        style={{ height: '300px' }}
                      >
                        <FontAwesomeIcon
                          className={`fa-spin ${inventory_styles.loading_spinner}`}
                          icon={faSpinner}
                          size='4x'
                        />
                      </div>
                    )}
                    {hasWebSpaces && !isWebSpacesLoading && (
                      <>
                        <div style={{ overflowY: 'scroll' }} className='inventory-home-table-container'>
                          <BootstrapDataTable
                            id='inventory-home-table'
                            keyField='id'
                            headerWrapperClasses={styles.webspacesTemplatesHeader}
                            data={webspaceData}
                            columns={getWebspaceColumns(session, navigate)}
                            noDataIndication={webSpaceNoDataForFilter}
                            rowStyle={(row, rowIndex) => {
                              const backgroundColor = rowIndex % 2 === 0 ? '#f4f2ff' : '#fff';
                              return { backgroundColor };
                            }}
                            paginationEnable={false}
                          />
                        </div>
                        <div
                          className={`mb-2 d-flex justify-content-between ${inventory_styles.bottom_controls_background}`}
                        >
                          <div>
                            <Pagination className='mb-0'>{webspacesPaginationItems}</Pagination>
                          </div>
                          <div>
                            <span>Show</span>
                            <select
                              className='custom-select d-inline-block w-auto'
                              id='inlineFormCustomSelectPref'
                              onChange={(e) => setPageItemCount(parseInt(e.target.value))}
                            >
                              <option value='10' selected={pageItemCount === 10}>
                                10
                              </option>
                              <option value='20' selected={pageItemCount === 20}>
                                20
                              </option>
                              <option value='30' selected={pageItemCount === 30}>
                                30
                              </option>
                              <option value='40' selected={pageItemCount === 40}>
                                40
                              </option>
                              <option value='50' selected={pageItemCount === 50}>
                                50
                              </option>
                              <option value='60' selected={pageItemCount === 60}>
                                60
                              </option>
                              <option value='70' selected={pageItemCount === 70}>
                                70
                              </option>
                              <option value='80' selected={pageItemCount === 80}>
                                80
                              </option>
                              <option value='90' selected={pageItemCount === 90}>
                                90
                              </option>
                              <option value='100' selected={pageItemCount === 100}>
                                100
                              </option>
                            </select>{' '}
                            <span>results per page</span>
                          </div>
                        </div>
                      </>
                    )}
                    {!hasWebSpaces && <p>There are no owned web spaces defined.</p>}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div>&nbsp;</div>
                  </Col>
                </Row>
              </div>
            </div>
          </Form>
        </div>
      </Chrome>
    </>
  );
};

export default OrganisationInventoryHome;
