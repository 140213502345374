import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import Chrome from '../app/layout/Chrome';
import WalletsView from '../features/wallets/WalletsView';
import QueryResult from '../features/wallets/QueryResult';
import { getRetailerWallets } from '../lib/api/wallets';
import { useRequest } from '../lib/hooks/useRequest';
import NoWallet from '../features/wallets/NoWallet';
import { useNavigate } from 'react-router-dom';

const RetailerWallets = () => {
  const [retailers, setRetailers] = useState([]);
  const navigate = useNavigate();

  const handleWalletSelection = useCallback((wallet) => {
    navigate(`/wallet/${wallet.id}`);
  }, [navigate]);

  const { response, loading, error } = useRequest(() => getRetailerWallets());


  useEffect(() => {
    if (response !== null) {
      setRetailers(response?.data);
    }
  }, [response]);

  return (
    <>
      <Helmet>
        <title>My Wallets - {process.env.REACT_APP_NAME}</title>
      </Helmet>

      <Chrome>
        <Container>
          <Row>
            <Col>
              <h1>Wallets</h1>
              <div>
                <QueryResult loading={loading} error={error}>
                  {retailers.length === 0 ? (
                    <NoWallet isSupplier={true} />
                  ) : (
                    retailers.map(({ retailer, wallets }) => (
                      <div className='mb-5' key={retailer.id}>
                        <p className='mb-1'>Retailer: {retailer.name}</p>
                        <WalletsView
                          currency={retailer?.currency}
                          handleWalletSelection={handleWalletSelection}
                          isSupplier={true}
                          wallets={wallets}
                          loading={false}
                          error={null}
                        />
                      </div>
                    ))
                  )}
                </QueryResult>
              </div>
            </Col>
          </Row>
        </Container>
      </Chrome>
    </>
  );
};

export default RetailerWallets;
