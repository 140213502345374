import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import FancyThrobber from './FancyThrobber';

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 300px;
  overflow: visible;
`;

const PromptList = styled.div`
  transform-style: preserve-3d;
`;

const PromptContainer = styled.div`
  margin-left: 20px;
  font-size: 0.8rem;
  opacity: 0;
  display: none;
  transform: rotateX(90deg);
  transition: opacity 3s, transform 3s;
  text-wrap: avoid;
  white-space: nowrap;

  &.fade-in {
    display: block;
    opacity: 1;
    transform: rotateX(0deg);
  }

  ${props => props.active && `
    transition-delay: 3s;
    transition-timing-function: ease-in-out;
    transition-property: opacity, transform;
    transition-duration: 3s;
    transition-delay: 0s;
    animation: fade-in 3s 0s both;
  `}
`;

type TextThrobberProps = {
    prompts: string[],
    delay?: number,
};

const TextThrobber = ({prompts, delay = 6000}: TextThrobberProps): JSX.Element => {
    const [currentPromptIndex, setCurrentPromptIndex] = useState<number>(0);

    useEffect(() => {
        const randomIndex = Math.floor(Math.random() * prompts.length);
        setCurrentPromptIndex(randomIndex);
    }, [prompts]);

    useEffect(() => {
        const interval = setInterval(() => {
            const nextPromptIndex = (currentPromptIndex + 1) % prompts.length;
            setCurrentPromptIndex(nextPromptIndex);
        }, delay);

        return () => {
            clearInterval(interval);
        };
    }, [currentPromptIndex, delay, prompts.length]);

    return (
        <Container>
            <FancyThrobber/>
            <PromptList>
                {prompts.map((prompt, index) => (
                    <PromptContainer
                        key={index}
                        active={index === currentPromptIndex}
                        className={index === currentPromptIndex ? 'fade-in' : ''}
                        data-testid={`prompt-container-${index}`}
                    >
                        {prompt}
                    </PromptContainer>
                ))}
            </PromptList>
        </Container>
    );
};

export default TextThrobber;
