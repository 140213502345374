import moment from "moment/moment";

export const ToolTip = (utilisation, showBy, startDate, endDate, duration) => {
    if (showBy === 'days') {
        return `<div style="width: 190px; margin: 10px">
        <p><strong>Utilisation:</strong> ${utilisation}%</p>
        <p><strong>Date:</strong> ${moment(startDate).format('YYYY-MM-DD')}</p>
        <p><strong>Duration:</strong> ${duration} day(s)</p>
        </div>`;
    } else if (showBy === 'weeks') {
        return `<div style="width: 190px; margin: 10px">
        <p><strong>Utilisation:</strong> ${utilisation}%</p>
        <p><strong>Date:</strong> ${moment(startDate).format('YYYY-MM-DD')} <strong>to</strong> ${moment(endDate).format('YYYY-MM-DD')}</p>
        <p><strong>Duration:</strong> ${duration} day(s)</p>
        </div>`;
    } else {
        return `<div style="width: 190px; margin: 10px">
        <p><strong>Utilisation:</strong> ${utilisation}%</p>
        <p><strong>Month:</strong> ${moment(startDate).format('MMMM')}</p>
        <p><strong>Date:</strong> ${moment(startDate).format('YYYY-MM-DD')} <strong>to</strong> ${moment(endDate).format('YYYY-MM-DD')}</p>
        <p><strong>Duration:</strong> ${duration} day(s)</p>
        </div>`;
    }
}