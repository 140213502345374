import React from 'react';
import { Spinner } from 'react-bootstrap';
import styles from './LoadingSpinner.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import PropTypes from 'prop-types';

export const LoadingCompleteBadge = ({ loadingState }) => (
  <div className={styles.spinnerContainer}>
    {loadingState === 'loading' ? (
      <Spinner className={styles.spinner} animation='border' />
    ) : loadingState === 'complete' ? (
      <FontAwesomeIcon icon={faCircleCheck} className={styles.tick} />
    ) : null}
  </div>
);

LoadingCompleteBadge.propTypes = {
  loadingState: PropTypes.oneOf(['loading', 'complete', 'none']),
};
