import { faAngleDown, faAngleUp, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { debounce } from 'debounce'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { Accordion, Button, Card, Form, useAccordionToggle, InputGroup } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import axios from '../../../axios'
import { selectSession } from '../../session/sessionSlice';
import { getProductSetsLinkSortPermissions } from '../../../lib/api'
import { DEFAULT_DEBOUNCE_TIME } from '../../../lib/defaults'
import { selectId } from '../organisationSettingsSlice'
import styles from './product_permissions.module.scss'


const ProductSetPermissions = ({ controlId, catalogueId, value = [], onChange = () => { }, disableProductSets = false, searchFilter = '' }) => {
    const [productSets, setProductSets] = useState([])
    const [nextPageUrl, setNextPageUrl] = useState(null)
    const supplierId = useSelector(selectId)
    
    const debounceHandler = useMemo(() =>
        debounce((supplierId, catalogueId, searchFilter) => {
            getProductSetsLinkSortPermissions(supplierId, catalogueId, searchFilter)
                .then(resp => {
                    setProductSets(resp.data.data)
                    if (resp.data.next_page_url) {
                        setNextPageUrl(resp.data.next_page_url)
                    }
                })
        }, DEFAULT_DEBOUNCE_TIME), [])

    useEffect(() => {
        if (supplierId && catalogueId) {
            debounceHandler(supplierId, catalogueId, searchFilter)
        }
    }, [supplierId, catalogueId, searchFilter])

    const handleViewMore = () => {
        let nextPage = nextPageUrl
        if (searchFilter !== null && searchFilter !== '') {
            nextPage += "&search_term=" + searchFilter
        }
        axios.get(nextPage)
            .then(resp => {
                setProductSets([...productSets, ...resp.data.data])
                if (resp.data.next_page_url) {
                    setNextPageUrl(resp.data.next_page_url)
                } else {
                    setNextPageUrl(null)
                }
            })
    }

    return (
        <ul className={styles.ls_none}>
            {productSets.length === 0 && !disableProductSets ?
                <li>
                    No products sets available.
                </li>
                :
                <>
                    {productSets.map(productSet =>
                        <li key={productSet.id}>
                            <Form.Group controlId={`${controlId}-${productSet.id}`}>
                                <div className="d-flex justify-contents-center">
                                    {!disableProductSets && <Form.Check
                                        inline
                                        id={`${controlId}-${productSet.id}`}
                                        className="mr-2"
                                        disabled={disableProductSets}
                                        checked={value.some(x => x.id === productSet.id)}
                                        onChange={e => onChange(productSet, e.target.checked)}
                                    />}
                                    <Form.Label>{productSet.name} {typeof productSet.product_count === 'number' && `(${productSet.product_count} products)`}</Form.Label>
                                </div>
                            </Form.Group>
                        </li>
                    )}
                    {nextPageUrl && <Button variant="outline-primary" onClick={handleViewMore}>View more</Button>}
                </>}
        </ul>
    )
}

ProductSetPermissions.propTypes = {
    controlId: PropTypes.string.isRequired,
    catalogueId: PropTypes.string.isRequired,
    value: PropTypes.arrayOf(PropTypes.shape({
        target_id: PropTypes.string,
    })),
    onChange: PropTypes.func,
    disableProductSets: PropTypes.bool,
}


const ProductPermissions = ({
    controlId,
    title,
    catalogueValue = [],
    productSetValue = [],
    catalogues = [],
    disableCatalogues = false,
    disableProductSets = false,
    onChange = () => { },
}) => {
    const [panelIndex, setPanelIndex] = useState()
    const [searchTerm, setSearchTerm] = useState()
    const session = useSelector(selectSession)
    const CustomToggle = ({ children, eventKey }) => {
        const customOnClick = useAccordionToggle(eventKey, () => {
            setPanelIndex(eventKey === panelIndex ? null : eventKey)
        })

        const arrow =
            eventKey === panelIndex ? (
                <FontAwesomeIcon icon={faAngleUp} fixedWidth />
            ) : (
                    <FontAwesomeIcon icon={faAngleDown} fixedWidth />
            )

        return (
            <Card.Header
                className='bg-white'
                onClick={customOnClick}
            >
                {children} <span className='float-right'>{arrow}</span>
            </Card.Header>
        )
    }

    const handleCatalogueChange = (catalogue, checked) => {
        let v = catalogueValue.filter(x => x.id !== catalogue.id)
        if (checked) {
            v.push(catalogue)
        }
        onChange(v, productSetValue)
    }

    const handleProductSetChange = (productSet, checked) => {
        let v = productSetValue.filter(x => x.id !== productSet.id)
        if (checked) {
            v.push(productSet)
        }
        onChange(catalogueValue, v)
    }

    const handleFilterChange = (term) => {
        setSearchTerm(term)
    }

    return (
        <section>
            <Accordion className="mb-2">
                <Card className={styles.card_border}>
                    <CustomToggle eventKey={controlId}>
                        {title}
                    </CustomToggle>
                    <Accordion.Collapse eventKey={controlId}>
                        <Card.Body className={styles.card_body}>
                            <div className="px-1 py-1">
                                <Form.Group className={styles.search_bar}>
                                    <InputGroup>
                                        <InputGroup.Text className={styles.search_icon}>
                                            <FontAwesomeIcon icon={faSearch} />
                                        </InputGroup.Text>
                                        <Form.Control 
                                            type="text" 
                                            placeholder="Search"
                                            style={session?.theme?.components?.form_control}
                                            onChange={e => handleFilterChange(e.target.value)}
                                        />
                                    </InputGroup>
                                </Form.Group>
                            </div>
                            {catalogues.length > 0 ? (
                                catalogues.map(catalogue => (
                                    <div key={catalogue.id}>
                                        <Form.Group controlId={`${controlId}-${catalogue.id}`}>
                                            <div className="d-flex justify-contents-center">
                                                {!disableCatalogues && <Form.Check
                                                    inline
                                                    id={`${controlId}-${catalogue.id}`}
                                                    className="mr-2"
                                                    disabled={disableCatalogues}
                                                    checked={catalogueValue.some(x => x.id === catalogue.id)}
                                                    onChange={e => handleCatalogueChange(catalogue, e.target.checked)}
                                                />}
                                                <Form.Label className="mb-0">{catalogue.name}</Form.Label>
                                            </div>
                                            <ProductSetPermissions
                                                controlId={controlId}
                                                catalogueId={catalogue.id}
                                                value={productSetValue}
                                                disableProductSets={disableProductSets}
                                                onChange={(productSet, checked) => handleProductSetChange({ ...productSet, platform: catalogue.platform }, checked)}
                                                searchFilter={searchTerm}
                                            />
                                        </Form.Group>

                                    </div>
                                ))
                            ) : (
                                <span>
                                    Sorry, there haven't been any created catalogues.
                                </span>
                            )}
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>

        </section>
    )
}

ProductPermissions.propTypes = {
    controlId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    value: PropTypes.array,
    audiences: PropTypes.array,
    onChange: PropTypes.func,
    disableCatalogues: PropTypes.bool,
    disableProductSets: PropTypes.bool,
}

export default ProductPermissions
