import React from 'react';
import styled from 'styled-components';

const Header = styled.div`
  font-weight: bold;
  font-size: 14px;
  color: black;
  margin-bottom: 12px;
`;

const BudgetValue = styled.div`
  font-size: 18px;
  color: black;
`;

const EmptySpace = styled.div`
  height: 138px;
  margin: 24px;
`;

export const BroadsignBudget = ({ ad, currency = '$' }) => {
  // Format the number with commas and 2 decimal points
  const formattedTotalBudget = ad.budget
    ? currency + ad.budget.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    : currency + '0.00';

  return (
    <div style={{ paddingLeft: '24px', paddingRight: '24px' }}>
      <Header>Ad budget</Header>
      <BudgetValue>Total Budget: {formattedTotalBudget}</BudgetValue>
      <EmptySpace />
    </div>
  );
};
