import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { ScreenTypeTile } from './ScreenTypeTile';
import portraitScreen from './portrait_screen.svg';
import landscapeScreen from './landscape_screen.svg';
import { useSelector } from 'react-redux';
import { selectOrder, selectOrderFormOrganisationLoading, selectOrderFormState } from '../../orderFormSlice';
import {
  ORDER_FORM_STATE,
  STATUS_APPROVED,
  STATUS_COMPLETED,
  STATUS_LIVE,
  STATUS_NOT_APPROVED,
} from '../../../../lib/orders';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export const ScreenTab = ({ ad, errors, readOnly, handleChange, validScreenTypeConfigurations }) => {
  const orderFormState = useSelector(selectOrderFormState);
  const order = useSelector(selectOrder);
  const isLoadingOrderFormOrganisation = useSelector(selectOrderFormOrganisationLoading);
  const isNewOrder = orderFormState === ORDER_FORM_STATE.CREATE;

  const updateScreenTypes = (type, checked) => {
    let newTypes;
    if (checked) {
      newTypes = [...new Set([...(ad?.screen_types || []), type])];
    } else {
      newTypes = (ad?.screen_types || []).filter((item) => item !== type);
    }
    handleChange({
      screen_types: newTypes,
      start_at: null,
      stop_at: null,
      schedule_weeks_selected: [],
      calendar_range_start_at: null,
      calendar_range_stop_at: null,
    });
  };

  const isLandscapeAvailable = validScreenTypeConfigurations.includes('Landscape');
  const isPortraitAvailable = validScreenTypeConfigurations.includes('Portrait');
  const isBothAvailable = validScreenTypeConfigurations.includes('Landscape_&_Portrait');

  const isLandscapeOnlyAvailable = isLandscapeAvailable && !isBothAvailable;
  const isPortraitOnlyAvailable = isPortraitAvailable && !isBothAvailable;
  const isAnyAvailable = isPortraitAvailable && isLandscapeAvailable && isBothAvailable;
  const isBothOnlyAvailable = isBothAvailable && !isPortraitAvailable && !isLandscapeAvailable;

  const isLandscapeRequired =
    isLandscapeOnlyAvailable || (isLandscapeAvailable && !isAnyAvailable) || isBothOnlyAvailable;
  const isPortraitRequired = isPortraitOnlyAvailable || (isPortraitAvailable && !isAnyAvailable) || isBothOnlyAvailable;

  useEffect(() => {
    // Do not reset pre selection for live and approved orders
    if ([STATUS_APPROVED, STATUS_LIVE, STATUS_COMPLETED, STATUS_NOT_APPROVED].includes(order?.status)) {
      return;
    }

    // only preselect values for new orders
    if (!isNewOrder) {
      return;
    }

    const preselectedScreenTypes = [];
    if (isLandscapeRequired) {
      preselectedScreenTypes.push('Landscape');
    }
    if (isPortraitRequired) {
      preselectedScreenTypes.push('Portrait');
    }

    /**
     * Using setTimeout with a delay of 0 to defer the execution of handleChange. This approach puts the handleChange call
     * at the back of the execution stack, ensuring that it executes after the current rendering cycle completes.
     * This is necessary to avoid potential race conditions caused by the way handleChange overrides the entire ad state.
     * In this context, pre-selecting a screen type is analogous to a user action, so deferring the handleChange execution
     * allows for proper state updating without conflicts.
     *
     * Note: This is a workaround for the current state management approach where handleChange overrides the entire state.
     * This might lead to race conditions when multiple state updates occur rapidly. A more robust long-term solution would
     * involve refining the state management strategy, potentially adopting a reducer approach that focuses on property
     * updates rather than replacing the entire ad object.
     */
    setTimeout(
      () =>
        handleChange({
          screen_types: preselectedScreenTypes,
          start_at: null,
          stop_at: null,
          schedule_weeks_selected: [],
          calendar_range_start_at: null,
          calendar_range_stop_at: null,
        }),
      0
    );
  }, [orderFormState, isLandscapeRequired, isPortraitRequired]);

  return (
    <div style={{ paddingLeft: '24px', paddingRight: '24px' }}>
      {isLoadingOrderFormOrganisation ? (
        <Row className='text-center d-flex align-items-center justify-content-center'>
          <FontAwesomeIcon className='fa-spin' style={{ cursor: 'pointer' }} icon={faSpinner} size='4x' />
        </Row>
      ) : (
        <Row style={{ gap: '16px', justifyContent: 'center', display: 'flex' }}>
          {(!isPortraitOnlyAvailable || !isNewOrder) && (
            <Col style={{ padding: 0 }}>
              <ScreenTypeTile
                icon={landscapeScreen}
                infoMessage='Landscape screens typically have a resolution of 1920 x 1080 pixels'
                label='Landscape'
                value='Landscape'
                checked={ad?.screen_types?.includes('Landscape') || false}
                onChange={(checked) => updateScreenTypes('Landscape', checked)}
                readOnly={readOnly || (isNewOrder && isLandscapeRequired)}
              />
            </Col>
          )}
          {(!isLandscapeOnlyAvailable || !isNewOrder) && (
            <Col style={{ padding: 0 }}>
              <ScreenTypeTile
                icon={portraitScreen}
                infoMessage='Portrait screens typically have a resolution of 1080 x 1920 pixels'
                label='Portrait'
                value='Portrait'
                tooltipPlacement='left'
                checked={ad?.screen_types?.includes('Portrait') || false}
                onChange={(checked) => updateScreenTypes('Portrait', checked)}
                readOnly={readOnly || (isNewOrder && isPortraitRequired)}
              />
            </Col>
          )}
        </Row>
      )}
      {errors?.screen_types && (
        <Row>
          <Col>
            <div className='text-danger'>{errors.screen_types}</div>
          </Col>
        </Row>
      )}
    </div>
  );
};
