import React from 'react';
import Budget from '../components/WebAds/Budget';

export const BudgetTab = ({
  liveOrderViewed,
  controlId,
  showAdBudget,
  ad,
  webSpace,
  readOnly,
  errors,
  handleChange,
  currency,
}) => (
  <Budget
    key={liveOrderViewed}
    controlId={controlId}
    showBudget={showAdBudget}
    ad={ad}
    webSpace={webSpace}
    readOnly={readOnly}
    errors={errors}
    startDate={ad.start_at}
    stopDate={ad.stop_at}
    onChange={handleChange}
    currency={currency}
  />
);
