import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 32px;
`;

const Text = styled.div`
  margin-top: 16px;
  font-size: 35px;
`;

const SubText = styled.div`
  margin-top: 8px;
  font-size: 18px;
  font-weight: 300;
`;

const Icon = () => {
  return (
    <svg width='140' height='141' viewBox='0 0 140 141' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_617_88072)'>
        <rect y='0.300049' width='140' height='140' rx='70' fill='#EAECF0' />
        <path
          d='M110.133 38.2916H29.8659C27.2886 38.2916 25.1992 40.3294 25.1992 42.8432V138.427C25.1992 140.94 27.2886 142.978 29.8659 142.978H110.133C112.71 142.978 114.799 140.94 114.799 138.427V42.8432C114.799 40.3294 112.71 38.2916 110.133 38.2916Z'
          fill='white'
        />
        <rect x='35' y='64.463' width='19.6859' height='16.8' rx='6' fill='#EAECF0' />
        <rect x='60.1562' y='64.463' width='19.6859' height='16.8' rx='6' fill='#EAECF0' />
        <rect x='85.3125' y='64.463' width='19.6859' height='16.8' rx='6' fill='#EAECF0' />
        <rect x='35' y='86.1285' width='19.6859' height='16.8' rx='6' fill='#EAECF0' />
        <rect x='60.1562' y='86.1285' width='19.6859' height='16.8' rx='6' fill='#EAECF0' />
        <rect x='85.3125' y='86.1285' width='19.6859' height='16.8' rx='6' fill='#EAECF0' />
        <rect x='35' y='107.794' width='19.6859' height='16.8' rx='6' fill='#EAECF0' />
        <rect x='60.1562' y='107.794' width='19.6859' height='16.8' rx='6' fill='#EAECF0' />
        <path
          d='M25.1992 42.2916C25.1992 40.0824 26.9901 38.2916 29.1992 38.2916H110.801C113.01 38.2916 114.801 40.0824 114.801 42.2916V55.8771H25.1992V42.2916Z'
          fill='#98A2B3'
        />
        <rect x='35.5' y='29.1729' width='12.1797' height='18.2374' rx='6.08984' fill='#98A2B3' stroke='#EAECF0' />
        <rect x='92.3203' y='29.1729' width='12.1797' height='18.2374' rx='6.08984' fill='#98A2B3' stroke='#EAECF0' />
        <rect
          x='106.645'
          y='71.2273'
          width='4.07031'
          height='16.2492'
          rx='2.03516'
          transform='rotate(45 106.645 71.2273)'
          fill='#98A2B3'
        />
        <rect
          x='95.1562'
          y='74.1052'
          width='4.07031'
          height='16.2492'
          rx='2.03516'
          transform='rotate(-45 95.1562 74.1052)'
          fill='#98A2B3'
        />
      </g>
      <defs>
        <clipPath id='clip0_617_88072'>
          <rect y='0.300049' width='140' height='140' rx='70' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export const NoScreensAvailable = () => {
  return (
    <Container>
      <Icon />
      <Text>No screens available for those dates</Text>
      <SubText>Please select another date range from the dropdown.</SubText>
    </Container>
  );
};
