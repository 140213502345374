import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { formatToCurrency } from '../../../lib/financial';

const ApprovalConfirmationModal = ({
  show = false,
  title,
  isAlreadyApproved,
  handleApprove,
  handleCancel,
  totalCost,
  currency,
  wallet,
  supplier,
}) => {
  return (
    <Modal size='lg' dialogClassName='' show={show} onHide={handleCancel}>
      <Modal.Header closeButton className='ml-2 pl-4'>
        {title}
      </Modal.Header>
      <Modal.Body>
        <Container className='container-fluid'>
          <Row>
            {isAlreadyApproved && <Col>By approving, the edits to the Brand Store will now go live.</Col>}
            {!isAlreadyApproved && (
              <Col>
                By approving this Brand Store, the total cost of{' '}
                <strong>{formatToCurrency(currency, totalCost)}</strong> will be deducted from the selected wallet{' '}
                <strong>{wallet?.name}</strong> for the supplier <strong>{supplier}</strong>.
              </Col>
            )}
          </Row>
          {!isAlreadyApproved && (
            <>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              <Row>
                <Col>The Brand Store will go live at the scheduled date.</Col>
              </Row>
            </>
          )}
          <Row>
            <Col>&nbsp;</Col>
          </Row>
          <Row>
            <Col>Are you sure you want to approve?</Col>
          </Row>
          <Row>
            <Col>&nbsp;</Col>
          </Row>
          <Modal.Footer className='pr-0'>
            <Button className='mr-0' onClick={() => handleApprove()}>
              Approve
            </Button>
            <Button className='mr-0' onClick={() => handleCancel()}>
              Cancel
            </Button>
          </Modal.Footer>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

ApprovalConfirmationModal.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  isAlreadyApproved: PropTypes.bool,
  handleApprove: PropTypes.func,
  handleCancel: PropTypes.func,
  totalCost: PropTypes.number,
  currency: PropTypes.string,
  wallet: PropTypes.object,
  supplier: PropTypes.string,
};

export default ApprovalConfirmationModal;
