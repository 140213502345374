import React from 'react';
import EditableAsset from '../../../../../order_form/components/generic/EditableAsset';
import VideoUploadPlaceholder from './VideoUploadPlaceholder.svg';
import CollapseableTooltipBox from '../../../../../order_form/components/common/CollapseableTooltipBox';
import styles from '../content_tab.module.scss';
import { Form } from 'react-bootstrap';

const CreativeGuidelineContent = ({ creativeGuidelines }) => {
  return creativeGuidelines.map(({ label, description }, index) => (
    <React.Fragment key={index}>
      <strong>{label}: </strong>
      {description}
      <br />
    </React.Fragment>
  ));
};

export const VideoVariable = ({
  label,
  handleUpdateValue,
  creativeGuidelines,
  reduxValue,
  readOnly,
  variableErrors,
}) => {
  return (
    <>
      {creativeGuidelines ? (
        <div>
          <CollapseableTooltipBox label='Creative Guidelines' heading={label} reducedSpacing={true}>
            <div className={styles.creative_guidelines}>
              <CreativeGuidelineContent creativeGuidelines={creativeGuidelines} />
            </div>
          </CollapseableTooltipBox>
        </div>
      ) : (
        <Form.Label className={styles.variable_label}>{label}</Form.Label>
      )}
      <div style={{ clear: 'both' }}>
        <EditableAsset
          type='video'
          controlId='video'
          field={{ type: 'video' }}
          value={reduxValue}
          onChange={(asset) => handleUpdateValue(asset)}
          limitPreviewHeight={true}
          readOnly={readOnly}
          hideButtonOnReadOnly={true}
          emptyStatePlaceholder={<img src={VideoUploadPlaceholder} alt='placeholder' style={{ width: '100%' }} />}
          header={''}
          errors={variableErrors}
        />
      </div>
    </>
  );
};
