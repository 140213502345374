import React, { useEffect } from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import Footer from './layout/Footer';
import { handleLinkOrg } from '../features/session/sessionSlice';

const LinkOrg = () => {
  const dispatch = useDispatch();
  let location = useLocation();
  let params = new URLSearchParams(location.search);

  useEffect(() => {
    if (params.has('token')) {
      handleLink(params.get('token'));
    }
  }, []);

  const handleLink = async (token) => {
    dispatch(handleLinkOrg({ token: token }));
  };

  const status = useSelector((state) => state.session.status);

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME}</title>
      </Helmet>

      <main className='py-4 mt-4'>
        <Container>
          <Row className='justify-content-center'>
            <Col md={12}>
              <Row className='justify-content-center'>
                <div className='h1'>We are linking your organisation, please wait...</div>
              </Row>
              {status === 'loading' && (
                <Row className='text-center d-flex align-items-center justify-content-center'>
                  <Spinner animation='border' />
                  &nbsp;
                </Row>
              )}
              {status === 'success' && <Navigate to='/login' replace />}
              {status === 'failed' && (
                <Row className='justify-content-center'>
                  <div className='h2'>Something went wrong, please try again</div>
                </Row>
              )}
            </Col>
          </Row>
        </Container>
      </main>
      <Footer />
    </>
  );
};

export default LinkOrg;
