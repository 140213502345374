import {Form} from "react-bootstrap";
import Errors from "./Errors";
import React from "react";
import styled from 'styled-components';

type ErrorsFormFeedbackProps = {
    errors: string[]
}

const FeedbackContainer = styled(Form.Control.Feedback)`
  ${props => props.invalid && 'display: block'}
`

const ErrorsFormFeedback = ({errors}: ErrorsFormFeedbackProps) =>
    <FeedbackContainer type="invalid" invalid={errors.length > 0}>
        <Errors errors={errors}/>
    </FeedbackContainer>

export default ErrorsFormFeedback;
