import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Button, Col, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap';
import { NoEventsPlaceholder } from './NoEventsPlaceholder';
import { EventRow } from './EventRow';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { deleteInStoreRateRulesSettings, getBroadsignRateRulesSettings } from '../../../../../lib/api/broadsign';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const PromotionalRatesHeader = styled.h3`
  margin-top: 60px;
`;

export const PromotionalRates = ({
  portraitAvailable,
  landscapeAvailable,
  bundleAvailable,
  values = [],
  isSaving = false,
  onChange = () => {},
  formErrors = {},
}) => {
  const [events, setEvents] = useState([]);
  const [updatedOrNewEvents, setUpdatedOrNewEvents] = useState([]);
  const [tableErrors, setTableErrors] = useState('');
  const [isRateRulesLoading, setIsRateRulesLoading] = useState(true);
  const [tableIsDisabled, setTableisDisabled] = useState(false);

  const saveEvent = (updatedEvent) => {
    clearMessages();
    const updatedEvents = events.map((event) => (event.id === updatedEvent.id ? updatedEvent : event));
    const uornEvents = [...updatedOrNewEvents.filter((event) => event.id !== updatedEvent.id), updatedEvent];
    onSaveValidate(updatedEvent, [...updatedEvents, ...uornEvents]);
    setEvents(updatedEvents);
    setUpdatedOrNewEvents(uornEvents);
    onChange({ ...values, price_rules: uornEvents });
  };

  const onSaveValidate = (event, allEvents) => {
    setTableErrors('');
    const existingEvents = allEvents.filter((e) => e.id !== event.id);
    existingEvents.forEach((eve) => {
      const thisStartDate = new Date(event.start_date);
      const thisEndDate = new Date(event.end_date);

      const startDate = new Date(eve.start_date);
      const endDate = new Date(eve.end_date);

      if (thisStartDate <= endDate && startDate <= thisEndDate) {
        //overlapping dates
        //check priority
        if (eve.priority === event.priority) {
          setTableErrors('Events with overlapping dates must have unique priorities.');
        }
      }
    });
  };

  let ruleErrors = [];

  // Map of field names to more user-friendly names.
  const fieldMapping = {
    name: 'Name',
    start_date: 'Start Date',
    end_date: 'End Date',
    priority: 'Priority',
    'prices.Landscape': 'Landscape Cost',
    'prices.Portrait': 'Portrait Cost',
    'prices.Landscape_&_Portrait': 'Bundle Cost',
  };

  const seenFields = new Set(); // to track which fields we've already processed

  const keys = Object.keys(formErrors || []);
  keys.forEach((errorKey) => {
    if (errorKey.startsWith('price_rules.')) {
      // Extract the field name without the row number.
      const fieldName = errorKey.replace(/price_rules\.\d+\./, '');

      // If we haven't processed this field before, add it to our errors and mark it as seen.
      if (!seenFields.has(fieldName)) {
        seenFields.add(fieldName);
        const userFriendlyName = fieldMapping[fieldName] || fieldName;
        ruleErrors.push(`The '${userFriendlyName}' field is required in every row.`);
      }
    }
  });

  const clearMessages = () => {
    setTableErrors('');
    ruleErrors = [];
  };

  const loadRulesData = useCallback(async () => {
    setIsRateRulesLoading(true);
    try {
      const response = await getBroadsignRateRulesSettings();
      if (response.data?.data) {
        setEvents(response.data?.data);
        setUpdatedOrNewEvents([]);
        onChange({ ...values, price_rules: [] });
      }
    } catch (error) {
      console.error('setScreenSettingsForm: error loading screen rates data', error);
    } finally {
      setIsRateRulesLoading(false);
    }
  }, []);

  const deleteData = useCallback(async (id) => {
    setTableErrors('');
    setTableisDisabled(true);
    try {
      await deleteInStoreRateRulesSettings(id);
      //reload data
      loadRulesData();
    } catch (error) {
      setTableErrors('Unable to delete row, please try again.');
      console.error('setScreenSettingsForm: error loading screen rates data', error);
    } finally {
      setTableisDisabled(false);
    }
  }, []);

  useEffect(() => {
    loadRulesData();
  }, []);

  const getNextId = () => {
    if (events.length === 0) return -1;
    const ids = events.map((e) => e?.id).filter((e) => e < 0);
    if (ids.length === 0) return -1;
    return Math.min(...ids) - 1;
  };

  const addEvent = () => {
    const newEvent = {
      id: getNextId(),
      name: '',
      start_date: '',
      end_date: '',
      prices: {
        Landscape: 0,
        Portrait: 0,
        'Landscape_&_Portrait': 0,
      },
      priority: '',
    };
    setEvents([...events, newEvent]);
  };

  const handleRemoveEvent = (id) => {
    const updatedEvents = events.filter((event) => event.id !== id);
    const uornEvents = updatedOrNewEvents.filter((event) => event.id !== id);
    setEvents(updatedEvents);
    setUpdatedOrNewEvents(uornEvents);

    onChange({ ...values, price_rules: uornEvents });
  };

  const removeEvent = (id) => {
    if (id > 0) {
      //This is not a new row
      deleteData(id);
    } else {
      handleRemoveEvent(id);
    }
  };

  return (
    <>
      <PromotionalRatesHeader>Promotional Rates</PromotionalRatesHeader>
      <hr />
      {isRateRulesLoading && (
        <Row className='text-center d-flex align-items-center justify-content-center'>
          <FontAwesomeIcon className='fa-spin' style={{ cursor: 'pointer' }} icon={faSpinner} size='4x' />
        </Row>
      )}
      {!isRateRulesLoading && (
        <Row>
          <Col>
            <Button disabled={isSaving || tableIsDisabled} onClick={addEvent}>
              + Add event
            </Button>
            {tableErrors !== '' && (
              <Alert className='mt-3' variant='danger'>
                {tableErrors}
              </Alert>
            )}
            {formErrors?.overlap?.error_code && (
              <Alert className='mt-3' variant='danger'>
                {formErrors.overlap.error}
              </Alert>
            )}
            {ruleErrors.length > 0 && (
              <Alert className='mt-3' variant='danger'>
                <ul>
                  {ruleErrors.map((error, index) => (
                    <li key={index}>{error}</li>
                  ))}
                </ul>
                {formErrors.overlap.error}
              </Alert>
            )}

            <Table striped bordered hover style={{ marginTop: '18px' }}>
              <thead>
                <tr>
                  <th>Event Name</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Portrait Cost</th>
                  <th>Landscape Cost</th>
                  <th>Bundle Cost</th>
                  <th style={{ width: '130px' }}>
                    Priority{' '}
                    <OverlayTrigger
                      placement='top'
                      overlay={
                        <Tooltip id='info-popover'>
                          Select the event's start and end dates before priority. Events should have unique priorities.
                        </Tooltip>
                      }
                    >
                      <FontAwesomeIcon icon={faCircleInfo} size='sm' />
                    </OverlayTrigger>
                  </th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {events.length === 0 ? (
                  <NoEventsPlaceholder />
                ) : (
                  events.map((event) => (
                    <EventRow
                      key={event.id}
                      event={event}
                      portraitAvailable={portraitAvailable}
                      landscapeAvailable={landscapeAvailable}
                      bundleAvailable={bundleAvailable}
                      disabled={tableIsDisabled}
                      onSave={saveEvent}
                      onDelete={removeEvent}
                      isSaving={isSaving}
                    />
                  ))
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
      )}
    </>
  );
};
