import PropTypes from 'prop-types'
import React from 'react'
import { Form, FormCheck, OverlayTrigger, Popover } from 'react-bootstrap'
import $style from './audience.module.scss';

const formatNumber = n => new Intl.NumberFormat(undefined, { notation: 'compact', compactDisplay: 'short' }).format(n)

const renderEstimatedRange = (lower, upper) => {
    if (upper === 1000) {
        return `Below ${formatNumber(upper)}`
    }

    return lower === upper
        ? formatNumber(lower)
        : `${formatNumber(lower)} – ${formatNumber(upper)}`
}

const FacebookLookalikeAudiences = ({
    controlId,
    value = [],
    readOnly = false,
    disabled = false,
    audiences = [],
    onChange = () => { },
}) => {
    const handleChange = (audience, checked) => {
        if (checked) {
            onChange(audience, true)
        } else {
            onChange(audience, false)
        }
    }
    return (
        <Form.Group controlId={controlId}>
            <Form.Label className="font-weight-bold">Lookalike Audiences</Form.Label>
            <div>
                {audiences.length > 0 ? (
                    audiences.map(audience => (
                        <div key={audience.id} className={audience?.deleted ? $style.deleteItem: ''}>
                            <FormCheck>
                                <FormCheck.Input
                                    inline
                                    id={`${controlId}-facebook-lookalike-audience-${audience.id}`}
                                    className="mr-2"
                                    disabled={disabled || audience.deleted}
                                    readOnly={readOnly || audience.deleted}
                                    checked={!!value.find(e => e.id === audience.id)}
                                    onChange={e => handleChange(audience, e.target.checked)}
                                />
                                <OverlayTrigger
                                    key={audience.id}
                                    placement="top"
                                    overlay={<Popover id={`lookalike-audience-tooltip-${audience.id}`}>
                                        <Popover.Content>
                                            <div className="text-left">
                                                <b>Estimated Users:</b> {audience.estimated_users
                                                    ? renderEstimatedRange(audience.estimated_users.lower_bound, audience.estimated_users.upper_bound)
                                                    : 'N/A'}
                                            </div>
                                        </Popover.Content>
                                    </Popover>}
                                >
                                    <FormCheck.Label>
                                        {audience.name}
                                    </FormCheck.Label>
                                </OverlayTrigger>

                            </FormCheck>
                        </div>
                    ))
                ) : (
                    <p>There are no lookalike audiences available.</p>
                )}
            </div>
        </Form.Group>
    )
}

FacebookLookalikeAudiences.propTypes = {
    controlId: PropTypes.string.isRequired,
    value: PropTypes.object,
    audience: PropTypes.array,
    onChange: PropTypes.func,
    readOnly: PropTypes.bool,
    disabled: PropTypes.bool,
}

export default FacebookLookalikeAudiences
