import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { PreviewAccordion } from './PreviewAccordion';
import { AssetGroup } from './DigitalScreenMediaTypes';

const Container = styled.section`
  .accordion {
    width: 100%;
  }
`;

export interface DigitalScreenProps {
  media: AssetGroup[];
  orderAssets: Array<any>;
  screenTypes: Array<string>;
}

export const DigitalScreenAssets = ({ media, orderAssets, screenTypes }: DigitalScreenProps) => {
  const [openSections, setOpenSections] = useState<number[]>([]);
  const prevMediaRef = useRef(media);
  const assetRefs = [useRef<HTMLDivElement>(null), useRef<HTMLDivElement>(null), useRef<HTMLDivElement>(null)];
  const hasMediaChanged = (index: number) => {
    const prevAssetGroup = prevMediaRef.current[index];
    const currentAssetGroup = media[index];

    return currentAssetGroup.some((asset, assetIndex) => {
      if (!asset || !asset?.media_type) {
        return false;
      }
      const prevAsset = prevAssetGroup ? prevAssetGroup[assetIndex] : { media_type: null, video: null, image: null };
      // Check if the media_type has changed or if video or image ids have changed
      return (
        prevAsset.media_type !== asset.media_type || prevAsset.video !== asset.video || prevAsset.image !== asset.image
      );
    });
  };

  useEffect(() => {
    // Determine if any media asset needs to be expanded
    media.forEach((_, index) => {
      if (hasMediaChanged(index)) {
        setOpenSections((prev) => (!prev.includes(index) ? [...prev, index] : prev));

        // Scroll to the ref
        if (assetRefs[index].current) {
          setTimeout(() => {
            assetRefs[index]?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }, 100);
        }
      }
    });

    // Update ref for next comparison
    prevMediaRef.current = media;
  }, [media]);

  const shouldRenderAccordion = (assetGroup: AssetGroup) => {
    // If the asset group is empty or not an array, don't render.
    if (!Array.isArray(assetGroup) || assetGroup.length === 0) return false;

    // Check if there is any asset in the group that matches one of the screen types,
    // has a non-null media_type, and also has a non-null id for that media type.
    return assetGroup.some((asset) => {
      const isScreenTypeValid = screenTypes.includes(asset.screen_type);
      const isMediaTypeValid = asset.media_type !== null;
      const isMediaIdValid = asset[asset.media_type] !== null && asset[asset.media_type] !== undefined;

      return isScreenTypeValid && isMediaTypeValid && isMediaIdValid;
    });
  };

  return (
    <Container>
      {media.map((assetGroup, index) => {
        if (!shouldRenderAccordion(assetGroup)) return null;
        return (
          <PreviewAccordion
            key={index}
            creativeAssetIndex={index}
            title={`Creative Asset Group ${index + 1}`}
            isOpen={openSections.includes(index)}
            assetGroup={assetGroup}
            accordionRef={assetRefs[index]}
            onToggle={() => {
              if (openSections.includes(index)) {
                setOpenSections((prev) => prev.filter((section) => section !== index));
              } else {
                setOpenSections((prev) => [...prev, index]);
              }
            }}
            orderAssets={orderAssets}
            screenTypes={screenTypes}
          />
        );
      })}
    </Container>
  );
};
