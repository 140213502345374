import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import styles from '../ad_section.module.scss';
import { PLATFORM_MERCATO } from '../../../lib/platforms';
import AdBudget from '../components/AdBudget';
import Budget from '../components/Budget';
import { budgetUpdated } from '../orderFormSlice';

export const BudgetTab = ({
  ad,
  readOnly,
  liveOrderViewed,
  handleChange,
  dispatch,
  showAdBudget,
  controlId,
  mercatoDevices,
  errors,
  currency,
}) => {
  return (
    <div className={styles.form_section}>
      <Container>
        <Row>
          <Col sm='12'>
            {showAdBudget && ad?.platform !== PLATFORM_MERCATO && (
              <AdBudget
                controlId={`${controlId}.budget`}
                readOnly={readOnly || liveOrderViewed}
                label='Budget'
                adType={ad.type}
                value={ad.budget}
                onChange={(budget) => {
                  handleChange({ budget });
                  dispatch(budgetUpdated());
                }}
                errors={errors}
                currency={currency}
              />
            )}
            {showAdBudget && ad?.platform === PLATFORM_MERCATO && (
              <>
                <Budget
                  controlId={`${controlId}.budget`}
                  showBudget={showAdBudget}
                  ad={ad}
                  selectedDevices={mercatoDevices.filter((d) => ad?.selectedDevices?.includes(d.id))}
                  readOnly={readOnly || liveOrderViewed}
                  startDate={ad.start_at}
                  stopDate={ad.stop_at}
                  onChange={(budgetChange) => handleChange(budgetChange)}
                  errors={errors}
                  currency={currency}
                />
              </>
            )}{' '}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

BudgetTab.propTypes = {
  ad: PropTypes.object.isRequired,
  readOnly: PropTypes.bool.isRequired,
  liveOrderViewed: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  showAdBudget: PropTypes.bool.isRequired,
  controlId: PropTypes.string.isRequired,
  mercatoDevices: PropTypes.array.isRequired,
};
