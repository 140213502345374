import React, { useMemo } from 'react';
import { Form, Table } from 'react-bootstrap';
import PropType from 'prop-types';
import { OWNED_SPACE_PRODUCTS } from '../../../lib/webads';
import { updateSlots } from '../ownedSpaceFormSlice';
import { useDispatch } from 'react-redux';
import PopoverHint from '../../wallets/components/form/PopoverHint';

const Templates = ({
  templates = [],
  value = [],
  format = OWNED_SPACE_PRODUCTS,
  is_multitenancy = false,
  onChange = () => {},
}) => {
  const dispatch = useDispatch();

  const handleChange = (template, checked, append = true) => {
    let newValue = [];
    if (append) {
      newValue = value.filter((templ) => templ.id !== template.id);
    }
    if (checked) {
      newValue.push(template);
    }

    const max_delivered_products = template?.meta_fields?.max_delivered_products ?? null;

    if (typeof max_delivered_products === 'number') {
      dispatch(updateSlots(max_delivered_products));
    }

    onChange(newValue);
  };

  if (templates && templates.length > 0) {
    templates.map((template) => {
      return { value: template.id, label: template.name };
    });
  }

  const computedTemplates = useMemo(
    () => templates.filter((t) => t.format === format && t.is_multitenancy === is_multitenancy),
    [templates, format, is_multitenancy]
  );

  return (
    <article>
      <div className='mb-2'>
        <p className='mb-0'>
          <b>Templates</b>
        </p>
        <small className='mb-2'>
          Choose Template(s) to provide to suppliers.
          <br />
          <br />
        </small>
      </div>
      <div className='border rounded border-secondary p-3'>
        <Form.Group>
          <Table responsive='sm' style={{ fontSize: '0.80rem' }}>
            <thead>
              <tr>
                <th className='border-top-0 pt-0'>Template Name</th>
              </tr>
            </thead>
            <tbody>
              {computedTemplates.length > 0 ? (
                is_multitenancy && format === OWNED_SPACE_PRODUCTS ? (
                  computedTemplates.map((template, index) => (
                    <tr>
                      <td>
                        <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                          <Form.Check
                            key={template.id}
                            type='radio'
                            name='templates'
                            label={template.name}
                            id={template.id}
                            checked={value.find((x) => x.id === template.id)}
                            onChange={() => {
                              handleChange(computedTemplates[index], true, false);
                            }}
                            htmlFor={template.id}
                          />
                          {template.description && (
                            <PopoverHint
                              popoverText={template.description}
                              placement={computedTemplates.length === index + 1 ? 'top' : 'bottom'}
                            />
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  computedTemplates.map((template, index) => (
                    <tr>
                      <td>
                        <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                          <Form.Check
                            type='checkbox'
                            label={template.name}
                            id={template.id}
                            checked={value.some((x) => x.id === template.id)}
                            onChange={(e) => handleChange(template, e.target.checked)}
                            htmlFor={template.id}
                          />
                          {template.description && (
                            <PopoverHint
                              popoverText={template.description}
                              placement={computedTemplates.length === index + 1 ? 'top' : 'bottom'}
                            />
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
                )
              ) : (
                <tr>
                  <td colSpan={3}>
                    <span>
                      No templates have been created. <a href='mailto:support@zitcha.com'>Please contact support.</a>
                    </span>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Form.Group>
      </div>
    </article>
  );
};

Templates.propTypes = {
  templates: PropType.arrayOf(
    PropType.objectOf({
      id: PropType.string,
      name: PropType.string,
    })
  ),
  is_multitenancy: PropType.bool,
  value: PropType.arrayOf(PropType.string),
};

export default Templates;
