import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-bootstrap';
import Errors from '../../../../order_form/components/Errors';
import styles from '../../brand_page_properties.module.scss';

export const PageUrlText = ({
  header,
  controlId,
  placeholder = '',
  required = false,
  onChange = () => {},
  errors = [],
  minLength = 0,
  maxLength,
  baseUrl,
  reduxValue,
  isHomepage,
  readOnly,
}) => {
  return (
    <Form.Group controlId={controlId}>
      <Form.Label className='d-flex align-self-center align-items-center font-weight-bold'>
        <span>{header}</span>
      </Form.Label>
      <div className={styles.page_url}>
        <div id='baseUrl' className={styles.base_url}>
          {baseUrl}
        </div>
        {!isHomepage && (
          <div style={{ flex: 1 }}>
            <Form.Control
              type='text'
              disabled={readOnly}
              placeholder={placeholder}
              isInvalid={errors.length > 0}
              onChange={(e) => onChange(e.target.value)}
              required={required}
              minLength={minLength}
              maxLength={maxLength}
              className={styles.page_url_input}
              value={reduxValue}
            />
            <div className={styles.form_error_text}>
              <Errors errors={errors} />
            </div>
          </div>
        )}
      </div>
      <div className={styles.caption_text}>To update the base URL, please contact your retailer.</div>
    </Form.Group>
  );
};

PageUrlText.propTypes = {
  header: PropTypes.string.isRequired,
  controlId: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  maxLength: PropTypes.number,
  baseUrl: PropTypes.string,
};
