import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { capitalize } from 'lodash';
import moment from 'moment';
import { Button, Modal, Container, Row, Col } from 'react-bootstrap';

import { getBroadsignScreenAvailability } from '../../../../lib/api/broadsign';

import { DateRangeSection } from './DateRangeSection';
import { ScreenAvailabilityTable } from './ScreenAvailabilityTable';

import scheduleModalStyles from './ScheduleModalStyles.module.scss';

export const ScreensAvailabilityModal = ({ ad, isOpen, onChange, onClose, ownerId }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [requestStatus, setRequestStatus] = useState('idle');
  const [availabilityData, setAvailabilityData] = useState([]);

  const camelToSnakeCase = (str) => {
    return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
  };

  const objectKeysToSnakeCase = (obj) => {
    if (typeof obj !== 'object' || obj === null) {
      return obj;
    }

    if (Array.isArray(obj)) {
      return obj.map(objectKeysToSnakeCase);
    }

    const newObj = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        newObj[camelToSnakeCase(key)] = objectKeysToSnakeCase(obj[key]);
      }
    }
    return newObj;
  };

  const specificKeyReplacementsToMatchBackEnd = (obj) => {
    const snakeCaseObj = objectKeysToSnakeCase(obj);
    const newObj = {};

    for (const key in snakeCaseObj) {
      if (snakeCaseObj.hasOwnProperty(key)) {
        switch (key) {
          case 'start_date':
            newObj['start_at'] = snakeCaseObj[key];
            break;
          case 'end_date':
            newObj['stop_at'] = snakeCaseObj[key];
            break;
          default:
            newObj[key] = snakeCaseObj[key];
        }
      }
    }

    return newObj;
  };

  useEffect(() => {
    if (ad.calendar_range_start_at && ad.calendar_range_stop_at) {
      (async () => {
        try {
          setRequestStatus('loading');
          const response = await getBroadsignScreenAvailability(
            ownerId,
            ad.calendar_range_start_at.slice(0, 10),
            ad.calendar_range_stop_at.slice(0, 10),
            ad.screen_types.map((type) => capitalize(type))
          );
          // We need a unique id for each row. The API data does not include one so add it by using start_at and stop_at
          const data = response.data.map((row) => {
            const replacedRow = specificKeyReplacementsToMatchBackEnd(row);
            return { ...replacedRow, id: `${replacedRow.start_at}_${replacedRow.stop_at}` };
          });
          setAvailabilityData(data);
        } catch (error) {
          console.error('ScreenAvailabilityTable: error loading screen availability data', error);
        } finally {
          setRequestStatus('completed');
        }
      })();
    }
  }, [ad.calendar_range_start_at, ad.calendar_range_stop_at]);

  const handleChangeSelected = (newlySelected) => {
    const schedule_weeks_selected = availabilityData.filter((row) => {
      return newlySelected.includes(row.id);
    });

    const budget = schedule_weeks_selected?.reduce((acc, curr) => acc + curr.price, 0) || 0;

    const firstDate = schedule_weeks_selected?.[0]?.start_at;
    const lastDate = schedule_weeks_selected?.[schedule_weeks_selected.length - 1]?.stop_at;

    onChange({ schedule_weeks_selected, budget, start_at: firstDate, stop_at: lastDate });
  };

  const handleChangeDateRange = (startDate, endDate) => {
    const start = moment(startDate);
    const end = moment(endDate);

    onChange({
      calendar_range_start_at: start.isValid() ? start.format('YYYY-MM-DD') : null,
      calendar_range_stop_at: end.isValid() ? end.format('YYYY-MM-DD') : null,
      schedule_weeks_selected: [],
      budget: 0,
    });
  };

  const isSelectButtonDisabled = useMemo(() => {
    return (ad.schedule_weeks_selected || []).length === 0;
  }, [ad.schedule_weeks_selected]);

  const { startDate, endDate } = useMemo(() => {
    return {
      startDate: ad.calendar_range_start_at ? moment(ad.calendar_range_start_at).toDate() : null,
      endDate: ad.calendar_range_stop_at ? moment(ad.calendar_range_stop_at).toDate() : null,
    };
  }, [ad.calendar_range_start_at, ad.calendar_range_stop_at]);

  return (
    <Modal
      size='xl'
      show={isOpen}
      onHide={onClose}
      className='xxl_modal'
      contentClassName={scheduleModalStyles.modal_content}
    >
      <Modal.Header className={scheduleModalStyles.modal_header} closeButton>
        Screens Availability
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Row>
            <Col>
              <DateRangeSection
                startDate={startDate}
                endDate={endDate}
                errorMessage={errorMessage}
                onChangeDateRange={handleChangeDateRange}
                onError={setErrorMessage}
              />
            </Col>
          </Row>
          <Row>
            <Col className={scheduleModalStyles.scrollable_content}>
              {ad.calendar_range_start_at && ad.calendar_range_stop_at && (
                <ScreenAvailabilityTable
                  availabilityData={availabilityData}
                  onChangeSelected={handleChangeSelected}
                  requestStatus={requestStatus}
                  selected={(ad.schedule_weeks_selected || []).map((row) => row.id)}
                />
              )}
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <div className={scheduleModalStyles.footer_text}>Please select from the available dates.</div>
        <Button className='float-right' variant='primary' onClick={onClose} disabled={isSelectButtonDisabled}>
          Select
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ScreensAvailabilityModal.propTypes = {
  ad: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  ownerId: PropTypes.string.isRequired,
};
