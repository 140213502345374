import { useRef } from 'react';
import styled from 'styled-components';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
`;

const SelectContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Select = styled.select`
  border: none;
  box-sizing: border-box;
  background: #f5f5f5 url("data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='25' viewBox='0 0 25 25' fill='none'%3E%3Cpath d='M7.14258 10.5L12.1426 15.5L17.1426 10.5H7.14258Z' fill='black' fill-opacity='0.6' /%3E%3C/svg%3E") right center no-repeat;
  /* width: 90px; */
  background-color: white;
  padding-right: 25px;
  
  &:focus-visible {
    outline: none;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 24px;
`;

const Button = styled.button`
  border: none;
  border-radius: 4px;
`;

export const DatePickerHeader = ({
  date,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) => {
  const selectRef = useRef(null);

  return (
    <Header>
      <SelectContainer>
        <Select
          onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
          ref={selectRef}
          value={months[date.getMonth()]}
        >
          {months.map((month) => {
            return <option value={month}>{month} {date.getFullYear()}</option>;
          })}
        </Select>
      </SelectContainer>

      <ButtonContainer>
        <Button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
          <svg xmlns='http://www.w3.org/2000/svg' width='7' height='11' viewBox='0 0 7 11' fill='none'>
            <path
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M6.96797 1.675L5.79297 0.5L0.792969 5.5L5.79297 10.5L6.96797 9.325L3.1513 5.5L6.96797 1.675Z'
              fill='black'
              fill-opacity='0.6'
            />
          </svg>
        </Button>
        <Button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
          <svg xmlns='http://www.w3.org/2000/svg' width='8' height='11' viewBox='0 0 8 11' fill='none'>
            <path
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M2.14375 0.5L0.96875 1.675L4.78542 5.5L0.96875 9.325L2.14375 10.5L7.14375 5.5L2.14375 0.5Z'
              fill='black'
              fill-opacity='0.6'
            />
          </svg>
        </Button>
      </ButtonContainer>
    </Header>
  );
};
