import PropTypes from 'prop-types'
import React from 'react'
import styles from './google_text.module.scss'

const GoogleText = ({ header, description, link }) => (
    <div className={`py-3 px-4 ${styles.container}`}>
        <div style={{ color: "#808080", fontSize: "10px", textAlign: "center", width: "100%", marginBottom: "10px" }}>Not actual display of the ad on the device</div>
        <div className={styles.subtext}>
            <span className={styles.ad}>
        Ad<span style={{ padding: '0 5px' }}>·</span>
            </span>
            <span className={styles.link}>
                <span>{link}</span>
            </span>
            <span className={styles.arrow}></span>
        </div>
        <div className={styles.header}>{header}</div>
        <div className={styles.body}>{description}</div>
    </div>
)

GoogleText.propTypes = {
    header: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
}

export default GoogleText
