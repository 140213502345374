import { Col, Form, Row } from 'react-bootstrap';
import styles from './bespokeFields.module.scss';
import PropTypes from 'prop-types';
import DateSelect from '../../../organisation_settings/components/DateSelect';

const DateField = ({ field, index, updateLayoutData, layoutData, idHelper }) => {
  let errors = [];
  //demo values for form builder
  let minDateValue = '';
  let maxDateValue = '';

  return (
    <Row>
      <Col md={6}>
        <Form.Group className={styles.group_style} controlId={field.type + index + '-Name'}>
          <Form.Label>Name</Form.Label>
          <Form.Control
            type='text'
            value={layoutData[index]?.title ?? ''}
            onChange={(e) => updateLayoutData({ id: idHelper(e.target.value), title: e.target.value }, index)}
            isInvalid={!layoutData[index]?.title && errors.length > 0}
          />
        </Form.Group>
        <Form.Group className={styles.group_style} controlId={field.type + index + '-Default'}>
          <Form.Label>Description</Form.Label>
          <Form.Control
            type='text'
            value={layoutData[index]?.notice ?? ''}
            onChange={(e) => updateLayoutData({ notice: e.target.value }, index)}
          />
        </Form.Group>
        <Form.Group className={styles.group_style} controlId={field.type + index + '-Info'}>
          <Form.Label>Info</Form.Label>
          <Form.Control
            type='text'
            value={layoutData[index]?.info ?? ''}
            onChange={(e) => updateLayoutData({ info: e.target.value }, index)}
          />
        </Form.Group>
      </Col>
      <Col md={6} className={styles.date_small}>
        <div className={styles.group_style}>
          <label>Start Date</label>
          <DateSelect
            label=''
            controlId='startDate'
            value={layoutData[index]?.start_date ?? minDateValue}
            onChange={(id, v) => updateLayoutData({ start_date: v }, index)}
            errors={errors}
            placeholder='Day, Month, Year'
          />
        </div>
        <div className={styles.group_style}>
          <label>Stop Date</label>
          <DateSelect
            label=''
            controlId='endDate'
            value={layoutData[index]?.end_date ?? maxDateValue}
            onChange={(id, v) => updateLayoutData({ end_date: v }, index)}
            errors={errors}
            placeholder='Day, Month, Year'
          />
        </div>
      </Col>
    </Row>
  );
};

DateField.propTypes = {
  field: PropTypes.object,
  index: PropTypes.number,
  updateLayoutData: PropTypes.func,
  layoutData: PropTypes.func,
  idHelper: PropTypes.func,
  errors: PropTypes.array,
};

export default DateField;
