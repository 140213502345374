import React from 'react';
import { Button } from 'react-bootstrap';
import styles from './content_tab.module.scss';
import AddBlockPlaceholderIllustration from './AddBlockPlaceholderIllustration.svg';
import content_tab_styles from './content_tab.module.scss';

const AddBlockSection = ({ displayEmptyStatePrompt, onClickAddBlock, readOnly }) => {
  const emptyStateText = readOnly ? 'No blocks have been added to this page' : 'Start by adding a block to your page';
  return (
    <div className={displayEmptyStatePrompt || !readOnly ? content_tab_styles.add_block_background : undefined}>
      {displayEmptyStatePrompt && (
        <>
          <img
            className={styles.add_block_placeholder_illustration}
            src={AddBlockPlaceholderIllustration}
            alt='placeholder'
          />
          <p className={styles.add_block_placeholder_text}>{emptyStateText}</p>
        </>
      )}
      {!readOnly && (
        <Button className={styles.add_button} onClick={onClickAddBlock}>
          + Add Block
        </Button>
      )}
    </div>
  );
};

export default AddBlockSection;
