import React from 'react';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Nav} from 'react-bootstrap';
import styles from "../sidebar.module.scss";

export const SidebarLink = ({to, icon, label}) => {
    return (
        <Nav.Item as="li" role="presentation" className="mb-4">
            <Link className='nav-link' to={to}>
                <span className='text-white sidebar_button'>
                  <FontAwesomeIcon icon={icon} size="lg" className='sidebar_button'/>
                  <label className={`${styles.sidebar_nav_label} sidebar_button`}>
                    {label}
                  </label>
                </span>
            </Link>
        </Nav.Item>
    );
};