import { faBars, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { faBoxAlt, faBuilding, faCog, faSlidersV, faTvRetro } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useState } from 'react';
import { Button, Container, Dropdown, Nav, Navbar, OverlayTrigger, Popover } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { selectSession, selectUser } from '../../features/session/sessionSlice';
import SwitchActiveOrgModal from '../SwitchActiveOrgModal';
import styles from './topbar.module.scss';
import {
  MANAGE_ORGANISATION_SETTINGS,
  MANAGE_SUPPLIER_SETTINGS,
  OWNED_CHANNELS_MANAGEMENT_WEB,
  USER_MANAGEMENT,
} from '../../lib/permissions';

const StyledPopMessage = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;
`;

const StyledUser = styled.span`
  margin-right: -10px;
  padding: 0;
  float: right;
  font-size: 12px;
  color: #b9b9c0;
`;
const StyledName = styled.strong`
  font-size: 18px;
`;

const Topbar = ({ toggleSidebar }) => {
  const session = useSelector(selectSession);
  const user = useSelector(selectUser);
  const userPermissions = user?.role?.permissions ? user?.role?.permissions?.map((p) => p.name) : [];
  const userOrganisations = session?.user_organisations;
  const isRetailer = user.active_organisation?.is_retailer;
  const channelsEnabledFlagSet = session?.channels_enabled;
  const [showOrganisationsModal, setOrganisationsModal] = useState(false);
  const hasPermissions =
    userPermissions &&
    (userPermissions.includes(MANAGE_ORGANISATION_SETTINGS) ||
      userPermissions.includes(USER_MANAGEMENT) ||
      userPermissions.includes(OWNED_CHANNELS_MANAGEMENT_WEB) ||
      userPermissions.includes(MANAGE_SUPPLIER_SETTINGS));
  const canManageUsers =
    userPermissions.includes(MANAGE_ORGANISATION_SETTINGS) || userPermissions.includes(USER_MANAGEMENT);
  const canManageChannelWeb = userPermissions.includes(OWNED_CHANNELS_MANAGEMENT_WEB);
  const canManageSuppliers = userPermissions.includes(MANAGE_SUPPLIER_SETTINGS);

  const openOrganisationModal = useCallback(() => {
    setOrganisationsModal(true);
  }, []);

    return (
        <>
          <Navbar style={session.theme?.components?.topbar}>
            <Container fluid>
              <Navbar.Brand className="text-white" as={Link} to="/">
                <img
                  src={'/themes/' + process.env.REACT_APP_THEME + '/logo-white.png'}
                  alt={process.env.REACT_APP_NAME}
                  style={{marginLeft: '3rem', width: '100px'}}
                />
              </Navbar.Brand>

          <Button className='d-md-none rounded-circle mr-3' variant='link' onClick={toggleSidebar}>
            <FontAwesomeIcon icon={faBars} />
          </Button>

          <Nav className='d-flex align-items-center flex-nowrap ml-auto' as='ul' variant='light'>
            {/* <Nav.Item className="dropdown no-arrow mr-5" role="presentation" as="li">
            <Button className="nav-item" disabled={refreshStatus === 'loading'} variant="outline-light" onClick={e => {
              e.preventDefault()
              dispatch(refreshSession(false))
            }}>
              <span className="small d-flex" style={{gap: '0.5rem'}}>
                {refreshStatus === 'loading' &&
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />}  <span>Refresh</span>
              </span>
            </Button>
          </Nav.Item> */}
            <div className='d-none d-sm-block topbar-divider'></div>

            {hasPermissions ? (
              <Nav.Item as='li' role='presentation' className={`auto ${styles.nav_settings} ${styles.bd_left}`}>
                <OverlayTrigger
                  trigger='click'
                  rootClose
                  placement='left'
                  overlay={
                    <Popover id='add-popover-sidebar' className={styles.w200px}>
                      <Popover.Content>
                        <StyledPopMessage>
                          {canManageUsers && (
                            <Button
                              as={Link}
                              key='organistion_settings'
                              variant='light'
                              to='/organisation/settings'
                              className={styles.btnlft}
                            >
                              <div>
                                <FontAwesomeIcon icon={faBuilding} /> Org. Settings
                              </div>
                            </Button>
                          )}
                          {channelsEnabledFlagSet && canManageChannelWeb && (
                            <Button
                              as={Link}
                              key='channel_settings'
                              variant='light'
                              to='/organisation/channels'
                              className={styles.btnlft}
                            >
                              <div>
                                <FontAwesomeIcon icon={faTvRetro} /> Channel Settings
                              </div>
                            </Button>
                          )}
                          {isRetailer && canManageSuppliers && (
                            <Button
                              as={Link}
                              key='supplier_settings'
                              variant='light'
                              to='/settings/suppliers'
                              className={styles.btnlft}
                            >
                              <div>
                                <FontAwesomeIcon icon={faSlidersV} /> Supplier Settings
                              </div>
                            </Button>
                          )}
                          {isRetailer && (
                            <Button
                              as={Link}
                              key='packages_list'
                              variant='light'
                              to='/packages'
                              className={styles.btnlft}
                            >
                              <div>
                                <FontAwesomeIcon icon={faBoxAlt} /> Package Settings
                              </div>
                            </Button>
                          )}
                        </StyledPopMessage>
                      </Popover.Content>
                    </Popover>
                  }
                >
                  <span className={styles.settings_text}>
                    <FontAwesomeIcon className={styles.settings_icon} icon={faCog} size='2x' />
                    Settings
                  </span>
                </OverlayTrigger>
              </Nav.Item>
            ) : (
              <Nav.Item as='li' role='presentation' className='mt-auto'></Nav.Item>
            )}

            <Nav.Item className={`dropdown ${styles.bd_left}`} role='presentation' as='li'>
              <Dropdown className='nav-item' variant='light'>
                <Dropdown.Toggle as={Nav.Link} className={styles.no_arrow}>
                  <StyledUser className='d-none d-lg-inline text-right text-white small'>
                    <StyledName>
                      {session.user.first_name} {session.user.last_name}
                    </StyledName>
                    <br />
                    {session.user.active_organisation.name}
                  </StyledUser>
                </Dropdown.Toggle>
                <Dropdown.Menu className={`dropdown-menu-right animated--grow-in ${styles.mr_10}`}>
                  {userOrganisations && userOrganisations.length > 1 && (
                    <Dropdown.Item onClick={openOrganisationModal}>Switch Organisation</Dropdown.Item>
                  )}
                  <Dropdown.Item href='/logout'>
                    <FontAwesomeIcon className='pr-1' icon={faSignOutAlt} /> Log out
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav.Item>

            <SwitchActiveOrgModal show={showOrganisationsModal} onHide={() => setOrganisationsModal(false)} />
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default Topbar;
