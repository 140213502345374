export const getRandomIntegerInRange = (min, max) => {
    if (typeof min !== 'number' || typeof max !== 'number') {
        throw new TypeError('Both min and max should be numbers.');
    }

    if (min > max) {
        throw new Error('Min should not be greater than max.');
    }

    // Math.random() gives a number between 0 (inclusive) and 1 (exclusive)
    // By multiplying with (max - min + 1), we get a number in the range 0 (inclusive) to (max - min + 1) (exclusive)
    // By adding min, we shift this range to be min (inclusive) to max + 1 (exclusive)
    // Math.floor() then rounds this down to an integer, giving a number in the range min (inclusive) to max (inclusive)
    return Math.floor(Math.random() * (max - min + 1) + min);
}