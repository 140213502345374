/* Template Fields */

const TEMPLATE_FIELD_TYPE_IMAGE = 'image';
const TEMPLATE_FIELD_TYPE_LINK = 'link';
const TEMPLATE_FIELD_TYPE_TEXTAREA = 'textarea';
const TEMPLATE_FIELD_TYPE_COLOR = 'color';
const TEMPLATE_FIELD_TYPE_VIDEO = 'video';
const TEMPLATE_FIELD_TYPE_TEXT = 'text';
const TEMPLATE_FIELD_TYPE_CURRENCY = 'currency';

const BESPOKE_PHYSICAL = 'physical';
const BESPOKE_DIGITAL = 'digital';

const PRODUCT_SET_TYPE_FACEBOOK_MPA = 'mpa';
const MPA_STATUS_COMPLETED = 'completed';

export const MPA_ONBOARDING_NEW = 'new';
export const MPA_ONBOARDING_SUBMITTED = 'submitted';
export const MPA_ONBOARDING_IN_PROGRESS = 'in_progress';
export const MPA_ONBOARDING_FINALIZING = 'finalizing';
export const MPA_ONBOARDING_WAITING_FOR_SUPPPLIER = 'waiting_for_supplier_input';
export const MPA_ONBOARDING_COMPLETED = 'completed';
export const MPA_ONBOARDING_FAILED = 'failed';

export {
  TEMPLATE_FIELD_TYPE_TEXT,
  TEMPLATE_FIELD_TYPE_CURRENCY,
  TEMPLATE_FIELD_TYPE_COLOR,
  TEMPLATE_FIELD_TYPE_IMAGE,
  TEMPLATE_FIELD_TYPE_LINK,
  TEMPLATE_FIELD_TYPE_TEXTAREA,
  TEMPLATE_FIELD_TYPE_VIDEO,
  BESPOKE_PHYSICAL,
  BESPOKE_DIGITAL,
  PRODUCT_SET_TYPE_FACEBOOK_MPA,
  MPA_STATUS_COMPLETED,
}