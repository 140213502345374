import React, { ReactNode, useRef } from 'react';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';

export interface AccordionHeaderProps {
  children: ReactNode;
  eventKey: string;
  isOpen: boolean;
  toggleOpen: (key: string) => void;
}

export const AccordionHeader = ({ children, eventKey, isOpen, toggleOpen }: AccordionHeaderProps) => {
  const headerRef = useRef(null);

  const arrow = isOpen ? (
    <FontAwesomeIcon icon={faAngleUp} fixedWidth />
  ) : (
    <FontAwesomeIcon icon={faAngleDown} fixedWidth />
  );

  return (
    <Card.Header className='bg-grey' onClick={() => toggleOpen(eventKey)} ref={headerRef}>
      {children} <span className='float-right'>{arrow}</span>
    </Card.Header>
  );
};
