import React from 'react';
import { BRAND_STORE_ORDER_STATUS } from './constants';

export const BrandStoreStatusBadge = ({ status }) => {
  const badgeStyle = {
    maxWidth: '100%',
    overflow: 'hidden',
    whiteSpace: 'wrap',
    cursor: 'default',
  };
  const getStatusProperties = (status) => {
    switch (status) {
      case BRAND_STORE_ORDER_STATUS.new.status:
        return { className: 'badge badge-secondary', text: BRAND_STORE_ORDER_STATUS.new.label, style: { backgroundColor: "#907BF3" } };
    case BRAND_STORE_ORDER_STATUS.draft.status:
        return { className: 'badge badge-secondary', text: BRAND_STORE_ORDER_STATUS.draft.label, style: {} };
    case BRAND_STORE_ORDER_STATUS.pending_approval.status:
        return { className: 'badge badge-warning', text: BRAND_STORE_ORDER_STATUS.pending_approval.label, style: {} };
    case BRAND_STORE_ORDER_STATUS.scheduled.status:
        return { className: 'badge badge-info', text: BRAND_STORE_ORDER_STATUS.scheduled.label, style: {} };
    case BRAND_STORE_ORDER_STATUS.live.status:
        return { className: 'badge badge-success', text: BRAND_STORE_ORDER_STATUS.live.label, style: {} };
    case BRAND_STORE_ORDER_STATUS.live_edits_saved.status:
        return { className: 'badge badge-secondary', text: BRAND_STORE_ORDER_STATUS.live_edits_saved.label, style: {} };
    case BRAND_STORE_ORDER_STATUS.live_edits_pending_approval.status:
        return { className: 'badge badge-warning', text: BRAND_STORE_ORDER_STATUS.live_edits_pending_approval.label, style: {} };
    case BRAND_STORE_ORDER_STATUS.expired.status:
        return { className: 'badge badge-danger', text: BRAND_STORE_ORDER_STATUS.expired.label, style: {} };
    default:
        return null;
    }

  }

  const { className, text, style } = getStatusProperties(status) || {};

  if (!className || !text) {
    return null;
  }

  return (
    <span className={className} style={{ ...badgeStyle, ...style }} title={text}>
      {text}
    </span>
  );
};
