import React from 'react';
import { Container } from 'react-bootstrap';
import moment from 'moment';
import LabelValue from './ScheduleTabComponents/LabelValue';
import styles from './ScheduleTabComponents/ScheduleTab.module.scss';
import PropTypes from 'prop-types';

const PERIODIC_TIME_UNIT_LABELS = {
  years: 'Yearly',
  months: 'Monthly',
  weeks: 'Weekly',
  days: 'Daily',
};

const SINGULAR_TIME_UNIT_LABELS = {
  years: 'year',
  months: 'month',
  weeks: 'week',
  days: 'day',
};

const ScheduleTab = ({ start_at, stop_at, total_cost, currency_symbol, wallet = {}, time_unit, cost_per_unit }) => {
  return (
    <Container className={styles.container}>
      <LabelValue
        label='Store Schedule'
        value={`${start_at ? moment(start_at).format('Do MMMM YYYY') : ''} - ${
          stop_at ? moment(stop_at).format('Do MMMM YYYY') : ''
        }`}
        description='These dates have have been set by your retailer. Please contact them for updates.'
      />
      {PERIODIC_TIME_UNIT_LABELS[time_unit] && (
        <LabelValue
          label={`${PERIODIC_TIME_UNIT_LABELS[time_unit]} Store Cost`}
          value={`${currency_symbol}${Number(cost_per_unit).toFixed(2)} per ${SINGULAR_TIME_UNIT_LABELS[time_unit]}`}
        />
      )}
      <LabelValue
        label='Total Cost'
        value={`${currency_symbol}${total_cost ? Number(total_cost).toFixed(2) : 0}`}
        description='This will be deducted from your chosen wallet once the store setup has been approved.'
        valueColor={true}
      />
      <LabelValue label='Wallet' value={`${wallet.name} (${currency_symbol}${wallet.balance} ${wallet.currency})`} />
    </Container>
  );
};

ScheduleTab.propTypes = {
  start_at: PropTypes.string,
  stop_at: PropTypes.string,
  total_cost: PropTypes.number,
  currency_symbol: PropTypes.string.isRequired,
  wallet: PropTypes.shape({
    name: PropTypes.string,
    balance: PropTypes.number,
    currency: PropTypes.string,
  }),
  time_unit: PropTypes.oneOf(['years', 'months', 'weeks', 'days']),
  cost_per_unit: PropTypes.string,
};

export default ScheduleTab;
