import {faProductHunt} from '@fortawesome/free-brands-svg-icons';
import {
  faArrowDown,
  faBook,
  faBullseye,
  faCog,
  faImage,
  faMoneyBill,
  faPhotoVideo,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import {faCalendar, faChartNetwork, faKey} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {sortBy} from 'lodash';
import moment from 'moment';
import Promise from 'promise-polyfill';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Alert, Button, Form, Modal, Tab} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import {getAvailableSlots, getTemplatePreview} from '../../lib/api/ownedSpaces';
import {NATIVE_BANNER, NATIVE_PRODUCT, OWNED_SPACE_PRODUCTS, OWNED_SPACE_SUBFORMAT_SEARCH} from '../../lib/webads';
import {
  selectAdTypesIncludingDisabled,
  selectSession,
  selectUser,
  selectUserOrganisationCurrency
} from '../session/sessionSlice';
import AdContainer from './AdContainer';
import Errors from './components/Errors';
import BannerAdsCategory from './components/Preview/BannerAdsCategory';
import SponsoredProductsCarousel from './components/Preview/SponsoredProductsCarousel';
import WebAdsDynamicField from './components/WebAdsDynamicField';
import {
  hasAnEditedLiveOrder,
  hasLiveOrderBeingViewed,
  selectAssets,
  selectAvailableWebSpaces,
  selectOrder,
  selectOrderAds,
  showBudget,
} from './orderFormSlice';
import styles from './owned_space_section.module.scss';
import AdGrid from './components/AdGrid';
import PreviewSection from './components/PreviewSection';
import PropertiesSection from './components/PropertiesSection';
import PropertiesBody from './components/PropertiesBody';
import {
  STATUS_APPROVED,
  STATUS_COMPLETED,
  STATUS_DRAFT,
  STATUS_LIVE,
  STATUS_PAUSED,
  STATUS_PENDING_APPROVAL,
} from '../../lib/orders';
import {packageBudgetRange, packageFieldHumanDateRangeText} from '../../lib/packages';
import SponsoredProductsIframePreview from './components/Preview/SponsoredProductsIframePreview';
import {ScheduleTab} from './owned_space_properties_tabs/ScheduleTab';
import {PlacementTab} from './owned_space_properties_tabs/PlacementTab';
import {AdDetailsTab} from './owned_space_properties_tabs/AdDetailsTab';
import {TemplateTab} from './owned_space_properties_tabs/TemplateTab';
import {KeywordsTab} from './owned_space_properties_tabs/KeywordsTab';
import {BudgetTab} from './owned_space_properties_tabs/BudgetTab';
import {FeedTab} from './owned_space_properties_tabs/FeedTab';
import {AdvancedTab} from './owned_space_properties_tabs/AdvancedTab';
import {MetricsTab} from './owned_space_properties_tabs/MetricsTab';
import Clipboard from '../../app/components/Clipboard';
import { FALLBACK_CURRENCY, currencySymbol } from '../../lib/financial';

/* NOTE: This is not final implementation, data eventually will come from backend like fields for each ad type  */
/* This code pushed to avoid delaying other stuff, refactor is already in place */

let propertiesTabs = [
  {
    id: 'schedule',
    title: 'Schedule',
    description: 'When do you want your ad to run?',
    icon: faCalendar,
    completedCheck: ({ ad, errors }) => ad.start_at && ad.stop_at && !errors.start_at && !errors.stop_at,
    optional: false,
  },
  {
    id: 'placement',
    title: 'Placement',
    description: 'Where do you want your ad to appear?',
    icon: faChartNetwork,
    completedCheck: ({ ad }) => ad.webspace_id,
    optional: false,
  },
  {
    id: 'ad-details',
    title: 'Content',
    description: 'Add the content that best delivers your ad',
    icon: faArrowDown,
    completedCheck: ({ ad, webSpace, errors }) => {
      let templates = [];
      if (webSpace) {
        templates = webSpace.owned_templates || [];
      }
      const template = templates.find((template) => template.id === ad.web_template_id);
      if (!template) {
        return false;
      }
      const templateFields = getTemplateFields(template);
      const productsCustomFields = getProductFields(templateFields);
      const noProductFields = templateFields.filter((field) => field.type !== 'products');

      const formFields = noProductFields.concat(productsCustomFields);
      const required = formFields.filter((field) => field.constraints?.required);
      const fieldIDs = formFields.map((field) => field.id);

      return (
        required.every((field) => ad?.template_data?.web?.[field.id]) &&
        !fieldIDs.some((id) => errors?.[id]?.length > 0)
      );
    },
    optional: false,
  },
  {
    id: 'template',
    title: 'Template',
    description: 'What do you want your ad to look like?',
    icon: faImage,
    completedCheck: ({ ad }) => ad.web_template_id,
    optional: false,
  },
  {
    id: 'keywords',
    title: 'Keywords',
    description: 'What are the keywords to trigger your ads?',
    icon: faKey,
    completedCheck: ({ ad }) => ad.keywords?.length > 0,
    optional: false,
  },
  {
    id: 'budget',
    title: 'Budget',
    description: 'How much would you like to spend?',
    icon: faMoneyBill,
    completedCheck: ({ ad, webSpace, errors }) =>
      ad.cost_strategy === 'fixed' ||
      (ad.spend_cap > 0 &&
        ad.bid_amount >= webSpace.slot_price &&
        !errors.spend_cap &&
        !errors.bid_amount &&
        !errors.budget),
    optional: false,
  },
  {
    id: 'audiences',
    title: 'Audiences',
    description: 'How are you targeting?',
    icon: faBullseye,
    completedCheck: ({ ad }) => false,
    optional: false,
  },
  {
    id: 'assets',
    title: 'Assets',
    description: null,
    icon: faPhotoVideo,
    completedCheck: ({ ad }) => false,
    optional: false,
  },
  {
    id: 'feed',
    title: 'Products',
    description: null,
    icon: faProductHunt,
    completedCheck: ({ ad }) => ad.feed?.products?.length > 0,
    optional: false,
  },
  {
    id: 'advanced',
    title: 'Advanced',
    description: 'Do you want to override the default dates for this ad type?',
    icon: faCog,
    completedCheck: ({ ad, errors }) => ad.weight && !errors.weight,
    optionalCheck: ({ ad, errors }) => !(ad?.weight && errors.weight),
  },
  {
    id: 'metrics',
    title: 'Metrics',
    description: 'How is the performance of your ad?',
    icon: faBook,
    completedCheck: ({ ad }) => false,
    optional: true,
  },
];

const getTemplateFields = (template) => {
  if (!template) {
    return [];
  }

  let fieldHeadings = Object.keys(template?.meta_fields?.variables);
  let fieldData = fieldHeadings.map((id, i) => {
    let arr = template?.meta_fields?.variables[id];
    arr = { ...arr, ...{ id: id } };
    return arr;
  });

  return fieldData;
};

const getProductFields = (templateFields) => {
  const productField = templateFields.find((field) => field.type === 'products') ?? {};
  let fields = [];

  if (productField.hasOwnProperty('custom_fields')) {
    const customFields = productField.custom_fields;
    Object.keys(customFields).forEach((key) => {
      fields.push({
        id: key,
        ...customFields[key],
      });
    });
  }

  return fields;
};

const populateFields = (ad, fields) => {
  if (!fields) {
    return '';
  }
  let data = {};
  let keys = Object.keys(fields);

  for (const key of keys) {
    if (key === 's3_bucket_path') {
      continue;
    }

    const templateData = ad?.template_data?.web;

    if (templateData?.[key]) {
      //check if the url contains a valid protocol like http or https
      if (fields[key].type === 'link') {
        let url = templateData[key];
        if (!/^https?:\/\//i.test(url)) {
          url = 'http://' + url;
        }
        data[key] = url;
      } else {
        data[key] = templateData[key];
      }

      continue;
    }

    if (!fields[key]?.constraints?.required) {
      continue;
    }

    if (fields[key].default) {
      data[key] = fields[key].default;
      continue;
    }

    if (fields[key].type === 'text' || fields[key].type === 'textarea') {
      data[key] = fields[key].placeholder
        ? fields[key].placeholder
        : fields[key].comment
        ? fields[key].comment
        : 'Example text';
    } else if (fields[key].type === 'color') {
      data[key] = '#ECECEC';
    } else if (fields[key].type === 'link') {
      data[key] = 'http://www.example.com';
    } else if (fields[key].type === 'currency') {
      data[key] = 0;
    } else if (fields[key].type === 'image' || fields[key].type === 'video') {
      data[key] = 'http://www.example.com';
    } else if (fields[key].type === 'products') {
      data[key] = [...(ad?.feed?.products || []), ...(ad?.feed?.deleted_products || [])];
    } else {
      data[key] = '';
    }
  }

  return data;
};

const SingleProductUpdateModal = ({
  productsCustomFields = [],
  productID = null,
  feed = {
    group: [],
    products: [],
  },
  handleUpdate = () => {},
  onHideHandler = () => {},
  readOnly,
}) => {
  const product = feed?.products?.find((product) => product.id === productID);
  const [productCustomFields, setProductCustomFields] = useState(() => {
    const fields = {};
    productsCustomFields
      .map((field) => field?.id)
      .forEach((key) => {
        if (product && product.hasOwnProperty(key)) {
          fields[key] = product[key];
        }
      });
    return fields;
  });

  const handleChange = (id, val) => {
    setProductCustomFields({
      ...productCustomFields,
      [id]: val,
    });
  };

  return (
    <Modal size='md' show={true} onHide={onHideHandler}>
      <Modal.Header closeButton>Update product properties:</Modal.Header>
      <Modal.Body>
        {productsCustomFields
          .filter((field) => !['asset', 'feed'].includes(field.type))
          .map((field) => (
            <div key={field.id} style={{ marginTop: 0, marginBottom: '25px' }}>
              <WebAdsDynamicField
                // ad={ad}
                key={field.id}
                controlId={`${field.id}`}
                placeholder={field?.placeholder}
                field={field}
                disabled={readOnly}
                readOnly={readOnly}
                value={productCustomFields?.[field.id]}
                onChange={(val) => {
                  handleChange(field.id, val);
                }}
              />
            </div>
          ))}
      </Modal.Body>

      <Modal.Footer>
        <Button
          className='float-right'
          variant='primary'
          disabled={readOnly}
          onClick={(e) => {
            handleUpdate(productCustomFields, product.id);
          }}
        >
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
const OwnedSpaceSection = React.forwardRef((props, ref) => {
  const {
    controlId,
    readOnly = false,
    ad = {},
    errors = {},
    onChange = () => {},
  } = props;
  const session = useSelector(selectSession);
  const defCurrency = useSelector(selectUserOrganisationCurrency) || FALLBACK_CURRENCY;
  const currency = currencySymbol(defCurrency);
  const order = useSelector(selectOrder);
  const user = useSelector(selectUser);

  const dispatch = useDispatch();
  const orderAssets = useSelector(selectAssets);
  const showAdBudget = useSelector(showBudget);
  const liveOrderViewed = useSelector(hasLiveOrderBeingViewed);
  const currentTimeBeforeLive = !ad.start_at && moment().isAfter(ad.start_at);
  const hasEditedLiveOrder = useSelector(hasAnEditedLiveOrder);
  const approvedReadOnly =
    liveOrderViewed || (!hasEditedLiveOrder && [STATUS_APPROVED, STATUS_LIVE].includes(order.status));

  const isOwner = order.owner_id === user.active_organisation_id;

  const [availableSlotsWarningMessage, setAvailableSlotsWarningMessage] = useState(null);

  const showMetrics = Boolean(
    readOnly && (order.status === STATUS_LIVE || order.status === STATUS_COMPLETED || order.status === STATUS_PAUSED)
  );

  const orderAds = useSelector(selectOrderAds);
  const adIndex = orderAds.findIndex((orderAd) => orderAd.id === ad.id);

  const adType = useSelector(selectAdTypesIncludingDisabled).find((adType) => adType.control_id === ad.type);

  const handleChange = (val) => onChange({ ...ad, ...val });
  const handleReset = (ad) => onChange(ad);

  const packageOfferField = props.packageOffer?.ad_format_templates?.find((field) => field.id === ad.package_item_id);
  const packageFieldDateRangeDescription = packageFieldHumanDateRangeText(packageOfferField);
  const packageMinMaxBudget = packageBudgetRange(packageOfferField, currency);

  const webSpaces = useSelector(selectAvailableWebSpaces);

  //If this is an approved order then overwrite the existing values with original values when the order was approved
  let webSpace = webSpaces.find((webSpace) => webSpace.id === ad.webspace_id) || {};

  if (ad?.webspace && (order.status !== STATUS_PENDING_APPROVAL || readOnly || approvedReadOnly)) {
    webSpace = {...webSpace, ...ad.webspace}
  }

  const handleTemplateSelection = (template) => handleChange({ web_template_id: template.id });

  let templates = [];
  if (webSpace) {
    templates = webSpace.owned_templates || [];
  }

  const template = templates.find((template) => template.id === ad.web_template_id);

  useEffect(() => {
    if (templates.length === 1 && (!ad.web_template_id || ad.web_template_id !== template?.id)) {
      handleTemplateSelection(templates[0]);
    }
  }, []);

  const templateFields = getTemplateFields(template);
  const productsCustomFields = getProductFields(templateFields);
  const showWeight = ad?.type === NATIVE_BANNER && isOwner;
  const showAdvanced = showWeight;

  const shouldIncludeAdDetailsTab = () => {
    const isAnyTemplateFieldValid = templateFields.some(
      (field) => !['hidden', 'products', 'feed'].includes(field.type)
    );
    const isAnyProductCustomFieldValid = productsCustomFields.some((field) => !['hidden'].includes(field.type));
    return isAnyTemplateFieldValid || isAnyProductCustomFieldValid;
  };

  const getTabIdsGivenAdWebSpaceDefined = () => {
    let tabIDs = [...adType.adtype_meta];

    if (webSpace.subformat === OWNED_SPACE_SUBFORMAT_SEARCH) {
      tabIDs.splice(2, 0, 'keywords');
    }

    if (showMetrics) {
      tabIDs.push('metrics');
    }

    if (!showAdvanced) {
      tabIDs = tabIDs.filter((id) => id !== 'advanced');
    }

    if (webSpace?.format !== 'product' || !ad.web_template_id || readOnly) {
      tabIDs = tabIDs.filter((id) => id !== 'feed');
    }

    return tabIDs;
  };

  const filterTabsByTabIds = (tabIds) => {
    return sortBy(propertiesTabs, (tab) => tabIds.indexOf(tab.id)).filter(
      (tab) => tabIds.includes(tab.id) || tab.id.startsWith('test')
    );
  };

  const tabs = useMemo(() => {
    let tabIDs;

    if (ad.webspace_id && webSpace) {
      tabIDs = getTabIdsGivenAdWebSpaceDefined();
    } else {
      tabIDs = ['schedule', 'placement'];
    }

    if (!shouldIncludeAdDetailsTab()) {
      tabIDs = tabIDs.filter((tab) => tab !== 'ad-details');
    }

    return filterTabsByTabIds(tabIDs);
  }, [
    adType.adtype_meta,
    showMetrics,
    ad.webspace_id,
    ad.web_template_id,
    webSpace,
    productsCustomFields,
    templateFields,
    showAdvanced,
  ]);

  //used for triggering template preview
  const [singleProductUpdateModalVisible, setShowSingleProductUpdateModal] = useState(false);
  const [selectedProductID, setSelectedProductID] = useState(null);

  const [previewMarkup, setPreviewMarkup] = useState('');

  const [tmpGoal] = useState();

  const loadAssets = useCallback((templateAssets) => {
    if (!templateAssets) {
      return;
    }

    document.querySelectorAll('[data-tp-web-template]').forEach((e) => e.remove());
    const scripts = templateAssets?.scripts ?? [];
    const styles = templateAssets?.styles ?? [];

    const loadScript = (url) => {
      return new Promise((resolve, reject) => {
        const currentScript = document.querySelector(`script[src="${url}"][data-tp-web-template]`);
        if (!!currentScript) {
          return resolve(url);
        }
        const script = document.createElement('script');
        script.src = url;
        script.async = false;
        script.setAttribute('data-tp-web-template', 'true');
        script.onload = () => {
          resolve(url);
        };
        script.onerror = () => {
          reject(url);
        };
        document.body.appendChild(script);
      });
    };

    // save all Promises as array
    let promises = [];
    scripts.forEach((script) => promises.push(loadScript(script.src)));

    Promise.all(promises)
      .then(function () {
        // All external scripts loaded
      })
      .catch((err) => {
        // Error while loading scripts. TODO: add retry functionality
      });

    styles.forEach((asset) => {
      const href = asset?.href;
      if (href) {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.href = href;
        link.media = 'all';
        link.setAttribute('data-tp-web-template', 'true');
        document.head.appendChild(link);
      }
    });
  }, []);

  const [showLoader, setShowLoader] = useState(false);

  const compileTemplate = useCallback(() => {
    if (!template) {
      return;
    }

    if (
      webSpace.format === OWNED_SPACE_PRODUCTS &&
      (ad?.feed?.products ?? []).length === 0 &&
      (ad?.feed?.deleted_products ?? []).length === 0 &&
      !isTemplateRenderModeIframe
    ) {
      return;
    }

    setShowLoader(true);

    getTemplatePreview(template.id, populateFields(ad, template.meta_fields?.variables))
      .then((response) => {
        setPreviewMarkup(response.data);
        setShowLoader(false);
      })
      .catch((err) => {
        setPreviewMarkup('');
        setShowLoader(false);
      });
  }, [ad, template]);

  useEffect(() => {
    const handler = setTimeout(() => compileTemplate(), 250); // TODO: Move 250 to constants
    return () => clearTimeout(handler);
  }, [ad, template]);

  useEffect(() => {
    setAvailableSlotsWarningMessage(null);
    const webspace_id = ad?.webspace_id;
    if (!webspace_id || !isOwner || ![STATUS_DRAFT, STATUS_PENDING_APPROVAL].includes(order.status)) {
      return;
    }

    if (!webSpace?.subformat || webSpace.subformat !== 'search') {
      getAvailableSlots(webspace_id).then((response) => {
        if (response?.data && response?.data?.available_slots <= 0) {
          setAvailableSlotsWarningMessage('Maximum number of orders on this space has reached.');
        }
      });
    }
  }, [ad?.webspace_id, order?.status]);

  useEffect(() => {
    loadAssets(template?.meta_fields?.assets);
  }, [template?.meta_fields]);

  const previewMarkupClickHandler = useCallback(
    (e) => {
      const productElement = e.target.closest('[data-tp-product-id]');
      if (!!productElement) {
        const feedProducts = ad?.feed?.products ?? [];
        const product = feedProducts.find((product) => product.id === productElement.getAttribute('data-tp-product-id'));
        if (product) {
          setSelectedProductID(product.id);
          if (productsCustomFields.filter((field) => !['hidden'].includes(field.type)).length > 0) {
            setShowSingleProductUpdateModal(true);
          }
        }
      }
    },
    [previewMarkup]
  );

  let allErrors = [];

  if (errors.goal) {
    allErrors = [...allErrors, ...errors.goal];
  }

  if (errors?.id) {
    allErrors = [...allErrors, ...errors.id];
  }

  if (errors?.products) {
    allErrors.push(errors?.products);
  }

  if (errors?.budget) {
    allErrors.push(errors.budget);
  }

  if (errors?.spend_cap) {
    allErrors.push(errors.spend_cap);
  }

  if (errors?.bid_amount) {
    allErrors.push(errors.bid_amount);
  }

  if (errors?.webspace_id) {
    allErrors.push(errors.webspace_id);
  }

  const handleWebAdsDynamicFieldChange = (val, field) => {
    handleChange({
      [field.id]: val,
    });
  };

  const isTemplateRenderModeIframe = useMemo(() => {
    return template?.meta_fields?.preview_render_mode === 'iframe';
  }, [template?.meta_fields]);

  const handleSponsoredProductSelection = useCallback((product) => {
    setSelectedProductID(product.id);
    setShowSingleProductUpdateModal(true);
  }, []);

  return (
    <AdContainer
      ref={ref}
      {...props}
      header={
        <>
          <span className='font-weight-bold'>
            Ad #{adIndex + 1}: {packageOfferField?.flat_discount ? `$${packageOfferField.flat_discount} OFF – ` : ''}{' '}
            {packageOfferField?.percentage_discount ? `${packageOfferField.percentage_discount}% OFF – ` : ''}{' '}
            {adType.label}&nbsp;
          </span>
          {ad.id && (
            <span style={{ color: '#707070' }}>
              (#{ad.id}) <Clipboard text={ad.id} color='#707070' />
            </span>
          )}
          {packageFieldDateRangeDescription && <div>{packageFieldDateRangeDescription}</div>}
          {packageMinMaxBudget && (
            <div>
              <span className={styles.budget + ' text-right'}>{packageMinMaxBudget}</span>
            </div>
          )}
          <Form.Control.Feedback type='invalid' className={allErrors.length > 0 ? 'd-block' : ''}>
            <Errors errors={allErrors} />
          </Form.Control.Feedback>
        </>
      }
    >
      {webSpace?.format === OWNED_SPACE_PRODUCTS && singleProductUpdateModalVisible && (
        <SingleProductUpdateModal
          productID={selectedProductID}
          feed={ad.feed}
          readOnly={readOnly}
          productsCustomFields={productsCustomFields}
          handleUpdate={(fields, productID) => {
            const feed = { ...ad?.feed };
            feed.products = feed?.products.map((p) => (p.id === productID ? { ...p, ...fields } : p));
            handleChange({ feed });
            setSelectedProductID(null);
            setShowSingleProductUpdateModal(false);
          }}
          onHideHandler={() => {
            setSelectedProductID(null);
            setShowSingleProductUpdateModal(false);
          }}
        />
      )}
      {availableSlotsWarningMessage && availableSlotsWarningMessage?.length > 0 && (
        <Alert variant='warning' className='mx-4'>
          {availableSlotsWarningMessage}
        </Alert>
      )}

      <AdGrid>
        <PreviewSection>
          {isTemplateRenderModeIframe && ad?.type === NATIVE_PRODUCT ? (
            <SponsoredProductsIframePreview
              content={previewMarkup}
              templateID={template?.id}
              products={[...(ad?.feed?.products || []), ...(ad?.feed?.deleted_products || [])]}
              handleSponsoredProductSelection={handleSponsoredProductSelection}
            />
          ) : (
            <>
              {previewMarkup !== '' && (
                <div onClick={previewMarkupClickHandler} dangerouslySetInnerHTML={{ __html: previewMarkup }}></div>
              )}
              {showLoader ? (
                <div className='text-center d-flex align-items-center justify-content-center'>
                  <FontAwesomeIcon
                    className='fa-spin'
                    style={{ cursor: 'pointer', marginTop: '50px' }}
                    icon={faSpinner}
                    size='4x'
                  />
                </div>
              ) : (
                <>
                  {ad?.type === NATIVE_BANNER && (!previewMarkup || previewMarkup === '') && (
                    <BannerAdsCategory enablementText='Your banner will appear below. Start by selecting an Ad placement over there' />
                  )}
                  {ad?.type === NATIVE_PRODUCT && (!previewMarkup || previewMarkup === '') && (
                    <SponsoredProductsCarousel
                      itemsToPreview={template?.meta_fields?.number_of_items_to_preview}
                      highlightPositions={template?.meta_fields?.variables?.products?.sponsored_product_positions}
                    />
                  )}
                </>
              )}
            </>
          )}
        </PreviewSection>

        <PropertiesSection>
          <PropertiesBody readOnly={readOnly} tabs={tabs} completeCheckContext={{ ad, webSpace, template, errors }}>
            <Tab.Pane eventKey='schedule'>
              <ScheduleTab
                readOnly={readOnly}
                startReadOnly={currentTimeBeforeLive && ad.status !== STATUS_PENDING_APPROVAL}
                ad={ad}
                controlId={controlId}
                errors={errors}
                handleChange={handleChange}
                session={session}
              />
            </Tab.Pane>
            <Tab.Pane eventKey='placement'>
              <PlacementTab
                readOnly={readOnly}
                approvedReadOnly={approvedReadOnly}
                ad={ad}
                handleReset={handleReset}
                tmpGoal={tmpGoal}
                setPreviewMarkup={setPreviewMarkup}
                dispatch={dispatch}
                webSpaces={webSpaces}
              />
            </Tab.Pane>
            <Tab.Pane eventKey='ad-details'>
              <AdDetailsTab
                readOnly={readOnly}
                templateFields={templateFields}
                ad={ad}
                controlId={controlId}
                adType={adType}
                errors={errors}
                orderAssets={orderAssets}
                handleChange={handleChange}
                productsCustomFields={productsCustomFields}
                handleWebAdsDynamicFieldChange={handleWebAdsDynamicFieldChange}
              />
            </Tab.Pane>
            <Tab.Pane eventKey='template'>
              <TemplateTab
                webSpace={webSpace}
                templates={templates}
                handleTemplateSelection={handleTemplateSelection}
                template={template}
              />
            </Tab.Pane>
            <Tab.Pane eventKey='keywords'>
              <KeywordsTab
                controlId={`${ad.id}-keywords`}
                value={ad.keywords}
                readOnly={readOnly}
                handleChange={(keywords) => handleChange({ keywords })}
                errors={errors?.keywords}
              />
            </Tab.Pane>
            <Tab.Pane eventKey='budget'>
              <BudgetTab
                liveOrderViewed={liveOrderViewed}
                controlId={`${controlId}.budget`}
                showAdBudget={showAdBudget}
                ad={ad}
                webSpace={webSpace}
                readOnly={readOnly}
                errors={errors}
                handleChange={(budgetChange) => handleChange(budgetChange)}
                currency={currency}
              />
            </Tab.Pane>
            <Tab.Pane eventKey='feed'>
              <FeedTab
                webSpace={webSpace}
                readOnly={readOnly}
                errors={errors}
                ad={ad}
                template={template}
                handleChange={handleChange}
                productsCustomFields={productsCustomFields}
              />
            </Tab.Pane>
            <Tab.Pane eventKey='advanced'>
              <AdvancedTab
                showWeight={showWeight}
                ad={ad}
                controlId={controlId}
                readOnly={readOnly}
                handleChange={handleChange}
                errors={errors}
              />
            </Tab.Pane>
            <Tab.Pane eventKey='metrics'>
              <MetricsTab ad={ad} currency={defCurrency} />
            </Tab.Pane>
          </PropertiesBody>
        </PropertiesSection>
      </AdGrid>
    </AdContainer>
  );
});

OwnedSpaceSection.propTypes = {
  controlId: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  ad: PropTypes.shape({
    type: PropTypes.string.isRequired,
  }).isRequired,
  assets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ),
  isNew: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  errors: PropTypes.object,
};

export default OwnedSpaceSection;
