import React, { useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledIframe = styled.iframe`
  width: 100%;
  border: none;
`;

const SponsoredProductsIframePreview = ({ templateID, content, products = [], handleSponsoredProductSelection }) => {
  const iframeRef = useRef();

  const updateIframe = useCallback(() => {
    const frame = iframeRef?.current;
    if (!frame) {
      return;
    }

    const doc = frame.contentDocument;
    doc.open();
    doc.write(content);
    doc.close();
  }, [content, iframeRef]);

  useEffect(() => {
    updateIframe();

    const handler = (event) => {
      const { message, productID } = event.data ?? {};
      if (message === `template_${templateID}_initialized`) {
        if (iframeRef && iframeRef.current) {
          iframeRef.current.style.height = iframeRef.current.contentWindow.document.body.offsetHeight + 20 + 'px';
        }
      } else if (message === `template_${templateID}_product_clicked`) {
        const feedProducts = products;
        const product = feedProducts.find((product) => product.id === productID);
        if (product) {
          handleSponsoredProductSelection(product);
        }
      }
    };

    window.addEventListener('message', handler);

    // clean up
    return () => window.removeEventListener('message', handler);
  }, [content]);

  return <StyledIframe ref={iframeRef} frameborder='0' scrolling='no' />;
};

SponsoredProductsIframePreview.propTypes = {
  templateID: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  products: PropTypes.array.isRequired,
  handleSponsoredProductSelection: PropTypes.func.isRequired,
};

export default SponsoredProductsIframePreview;
