import styled from "styled-components";

const ControlsContainer = styled.div`
  background-color: #fbfbfb;
  border-width: 1px 1px 0 1px;
  border-style: solid;
  border-color: #e0e0e0;
  padding: 32px 32px 20px;
  gap: 30px;
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
`;

export default ControlsContainer;