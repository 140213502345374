
import { connect } from 'react-redux'
import TextArea from '../../components/form_elements/TextArea'
import { selectPackageOutcomes, updateOutcomes, selectPackageOutcomesErrors } from '../../packageFormSlice'

const mapStateToProps = state => ({
    controlId: "package-outcomes",
    label: "Outcomes",
    description: "The value proposition for this package. Text over 82 characters may be truncated.",
    maxLengthSoft: 82,
    required: true,
    value: selectPackageOutcomes(state),
    errors: selectPackageOutcomesErrors(state),
})


const mapDispatchToProps = dispatch => ({
    onChange: outcomes => dispatch(updateOutcomes(outcomes)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TextArea)
