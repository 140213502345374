import React, { useEffect, useState } from 'react';
import { Container, Form } from 'react-bootstrap';
import properties_styles from '../brand_page_properties.module.scss';
import AddBlockSection from './ContentTabComponents/AddBlockSection';
import { ContentBlocks } from './ContentTabComponents/ContentBlocks';
import { AddBlockModal } from './ContentTabComponents/AddBlockModal';
import { VetoModal } from '../../../../app/components/VetoModal';
import { addBlock, removeBlock, reorderBlocks } from '../../state_management/BrandStoreSlice';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import Errors from '../../../order_form/components/Errors';

const SelectPageDropdown = ({ value, onChange, pages, brandStoreErrors }) => {
  const pagesWithErrors = [];
  for (let page of pages) {
    const pageErrors = brandStoreErrors?.pages?.[page.id];
    const pageHasContentBlockErrors = pageErrors?.blocks && Object.entries(pageErrors.blocks).length;
    if (pageHasContentBlockErrors) {
      pagesWithErrors.push(`Page '${page.page_name || 'No page name set'}' requires attention`);
    }
  }
  return (
    <>
      <Form.Group controlId='pageSelect'>
        <Form.Label className={properties_styles.dropdown_label}>Current Page Selected</Form.Label>
        <Form.Control as='select' value={value} onChange={onChange} isInvalid={pagesWithErrors.length}>
          {pages.map((page, index) => {
            const pageErrors = brandStoreErrors?.pages?.[page.id];
            const pageHasContentBlockErrors = pageErrors?.blocks && Object.entries(pageErrors.blocks).length > 0;
            return (
              <option key={index} value={page.id} className={properties_styles.option}>
                {pageHasContentBlockErrors && '⚠ '}
                {page.page_name || 'No page name set'}
              </option>
            );
          })}
        </Form.Control>
        <div className={properties_styles.dropdown_error_text}>
          <Errors errors={pagesWithErrors} />{' '}
        </div>
      </Form.Group>
    </>
  );
};

export const ContentTab = ({
  brandStore,
  brandStorePages,
  brandStoreErrors,
  currentPageId,
  setCurrentPageId,
  readOnly,
}) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [editingBlockId, setEditingBlockId] = useState(null);
  const [blockToRemove, setBlockToRemove] = useState(null);
  const [showVetoRemoveBlockModal, setShowVetoRemoveBlockModal] = useState(false);

  useEffect(() => {
    const pageIndex = brandStorePages.findIndex((page) => page.id === currentPageId);
    if (pageIndex === -1 && brandStorePages.length > 0) {
      setCurrentPageId(brandStorePages[0].id);
    }
  }, [brandStorePages, currentPageId, setCurrentPageId]);

  const blockTypes = brandStore.blockTypes;
  const currentPageContent = brandStorePages.find((page) => page.id === currentPageId)?.json_schema.blocks || [];

  const handleSaveBlock = () => {
    setEditingBlockId(null);
  };

  const handleEditBlock = (blockId) => {
    setEditingBlockId(blockId);
  };

  const handleAddBlock = (currentPageId, selectedBlockType) => {
    const newBlockId = uuidv4();
    dispatch(
      addBlock({
        pageId: currentPageId,
        newBlockId: newBlockId,
        blockType: selectedBlockType,
      })
    );
    setEditingBlockId(newBlockId);
  };

  const requestRemoveBlock = (blockId) => {
    if (editingBlockId === blockId) {
      setEditingBlockId(null);
    }
    setBlockToRemove(blockId);
    setShowVetoRemoveBlockModal(true);
  };

  const confirmRemoveBlock = () => {
    dispatch(
      removeBlock({
        blockId: blockToRemove,
      })
    );
    setBlockToRemove(null);
    setShowVetoRemoveBlockModal(false);
  };

  const handleRemoveBlock = (blockId) => {
    requestRemoveBlock(blockId);
  };

  const handleVetoRemoveBlockModalClose = () => {
    setBlockToRemove(null);
    setShowVetoRemoveBlockModal(false);
  };

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);
  const handlePageSelect = (event) => {
    setCurrentPageId(parseInt(event.target.value));
  };

  const moveBlock = (fromIndex, toIndex) => {
    let newBlocks = [...currentPageContent];
    const blockMoving = newBlocks[fromIndex];
    newBlocks.splice(fromIndex, 1);
    newBlocks.splice(toIndex, 0, blockMoving);

    dispatch(reorderBlocks({ pageId: currentPageId, newBlockOrder: newBlocks }));
  };
  return (
    <Container>
      <SelectPageDropdown
        value={currentPageId}
        onChange={handlePageSelect}
        pages={brandStorePages}
        brandStoreErrors={brandStoreErrors}
      />
      <ContentBlocks
        currentPageContent={currentPageContent}
        currentPageId={currentPageId}
        removeBlock={handleRemoveBlock}
        saveBlock={handleSaveBlock}
        editBlock={handleEditBlock}
        blockTypes={blockTypes}
        editingBlockId={editingBlockId}
        brandStorePages={brandStorePages}
        moveBlock={moveBlock}
        readOnly={readOnly}
        brandStoreErrors={brandStoreErrors}
        productSets={brandStore.permissions.product_sets}
      />
      <AddBlockSection
        displayEmptyStatePrompt={currentPageContent.length === 0}
        onClickAddBlock={handleShowModal}
        readOnly={readOnly}
      />
      <AddBlockModal
        showModal={showModal}
        handleClose={handleCloseModal}
        blockTypes={blockTypes}
        handleAddBlock={handleAddBlock}
        currentPageId={currentPageId}
        readOnly={readOnly}
      />
      <VetoModal
        show={showVetoRemoveBlockModal}
        handleClose={handleVetoRemoveBlockModalClose}
        confirmAction={confirmRemoveBlock}
        heading='Confirming you want to delete this block?'
        messageParagraphs={[
          {
            text:
              currentPageContent.find((contentBlock) => contentBlock.id === blockToRemove)?.name === 'Navigation'
                ? 'It is recommended to have a Navigation block on each page.'
                : ' ',
            bold: false,
          },
          { text: 'If you delete this block, all configured settings for this block will be removed.', bold: false },
          { text: 'This block will no longer be included in metrics reporting.', bold: false },
          { text: 'Are you sure you would like to continue?', bold: true },
        ]}
        confirmMessage={'Delete Block'}
        cancelMessage={'Cancel'}
      />
    </Container>
  );
};
