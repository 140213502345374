import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
// @ts-ignore
import React from "react";


export const Throbber = (): JSX.Element =>
    <div className="text-center d-flex align-items-center justify-content-center">
        <FontAwesomeIcon
            className="fa-spin"
            style={{cursor: "pointer", marginTop: "50px"}}
            icon={faSpinner}
            size="4x"
        />
    </div>