import PropTypes from "prop-types";
import { useState } from "react";
import { Form } from "react-bootstrap";
import styled from 'styled-components';
import { DescriptionContainer, ErrorFeedback, LabelText, TextInput } from "./Common";
import Errors from "./Errors";


const ActionContainer = styled.div`
    margin: 0 15px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
`

const CheckboxContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 13rem;
    overflow: scroll;
`

const FancyCheckbox = styled.input`
    accent-color: #7155FF;
    width: 15px;
    height: 15px;
`

const SelectCheckbox = styled(FancyCheckbox)`
    left: 35px;
    top: 7px;
`

const SelectLabel = styled(Form.Check.Label)`
    margin-left: 25px;
`

const SearchInput = styled(TextInput)`
    height: 32px;
    width: 40%;
    max-width: 100px;
    margin-left: auto;
`

const CheckContainer = styled(Form.Check)`
    background: #F8F8F8;;
    border-radius: 4px;
    padding: 8px 15px;
    display: block;
`

const MultiSelect = ({
    controlId,
    label,
    description,
    readOnly = false,
    disabled = false,
    required = false,
    value = [],
    onChange = () => { },
    options = [],
    errors = [],
}) => {
    const [search, setSearch] = useState('')

    const handleChange = (option, checked) => {
        if (readOnly) {
            return
        }

        let newValue = value.filter(opt => opt !== option.value)
        if (checked) {
            newValue.push(option.value)
        }

        onChange(newValue)
    }

    const handleToggleAll = (checked) => {
        if (readOnly) {
            return
        }

        if (!checked) {
            return onChange([])
        }
        onChange(options.map(option => option.value))
    }

    const handleSearch = value => {
        if (readOnly) {
            return
        }
        setSearch(value)
    }

    let filteredOptions = options
    if (search !== '') {
        filteredOptions = options.filter(option => option.label.toLowerCase().includes(search.toLowerCase()))
    }


    return (
        <Form.Group controlId={controlId}>
            <Form.Label className="d-flex align-self-center align-items-center font-weight-bold">
                <LabelText>
                    {label} {required && "*"}{" "}
                </LabelText>
            </Form.Label>
            <DescriptionContainer>
                {description}
            </DescriptionContainer>
            <ActionContainer>
                <Form.Check
                    as={FancyCheckbox}
                    type="checkbox"
                    name={controlId}
                    disabled={readOnly}
                    id="select-all-none"
                    label="Select All / None"
                    checked={value.length === options.length && options.every(opt => value.includes(opt.value))}
                    onChange={e => handleToggleAll(e.target.checked)}
                />
                <SearchInput
                    type="text"
                    disabled={disabled}
                    placeholder="Search"
                    value={search}
                    onChange={(e) => handleSearch(e.target.value)}
                />
            </ActionContainer>
            <CheckboxContainer>
                {filteredOptions.map((option) => (
                    <CheckContainer
                        name={controlId}
                        key={option.id}
                    >
                        <Form.Check.Input
                            as={SelectCheckbox}
                            id={option.id}
                            name={controlId}
                            checked={value.includes(option.value)}
                            type="checkbox"
                            onChange={e => handleChange(option, e.target.checked)}
                            required={required}
                            disabled={readOnly}
                        />
                        <SelectLabel>{option.label}</SelectLabel>
                    </CheckContainer>
                ))}
            </CheckboxContainer>
            <ErrorFeedback type="invalid" isInvalid={errors.length > 0}>
                <Errors errors={errors} />
            </ErrorFeedback>
        </Form.Group>
    );
};

MultiSelect.propTypes = {
    controlId: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    description: PropTypes.string,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    value: PropTypes.arrayOf(PropTypes.string),
    options: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
        })
    ),
    errors: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func,
};

export default MultiSelect;
