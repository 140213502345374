import { Col, Form, Row } from 'react-bootstrap';
import styles from './bespokeFields.module.scss';
import PropTypes from 'prop-types';

const RangeField = ({ field, index, updateLayoutData, layoutData, idHelper, errors }) => {
  return (
    <Row>
      <Col md={6}>
        <Form.Group className={styles.group_style} controlId={field.type + index + '-Name'}>
          <Form.Label>Name</Form.Label>
          <Form.Control
            type='text'
            value={layoutData[index]?.title ?? ''}
            onChange={(e) => updateLayoutData({ id: idHelper(e.target.value), title: e.target.value }, index)}
            isInvalid={!layoutData[index]?.title && errors.length > 0}
          />
        </Form.Group>
        <Form.Group className={styles.group_style} controlId={field.type + index + '-Default'}>
          <Form.Label>Description</Form.Label>
          <Form.Control
            type='text'
            value={layoutData[index]?.notice ?? ''}
            onChange={(e) => updateLayoutData({ notice: e.target.value }, index)}
          />
        </Form.Group>
        <Form.Group className={styles.group_style} controlId={field.type + index + '-Info'}>
          <Form.Label>Info</Form.Label>
          <Form.Control
            type='text'
            value={layoutData[index]?.info ?? ''}
            onChange={(e) => updateLayoutData({ info: e.target.value }, index)}
          />
        </Form.Group>
      </Col>
      <Col md={6}>
        <Form.Group className={styles.group_style} controlId={field.type + index + '-Min-Range'}>
          <Form.Label>Min Range</Form.Label>
          <Form.Control
            type='number'
            value={layoutData[index]?.minlen ?? ''}
            onChange={(e) => updateLayoutData({ minlen: parseInt(e.target.value) }, index)}
          />
        </Form.Group>
        <Form.Group className={styles.group_style} controlId={field.type + index + '-Max-Range'}>
          <Form.Label>Max Range</Form.Label>
          <Form.Control
            type='number'
            value={layoutData[index]?.maxlen ?? ''}
            onChange={(e) => updateLayoutData({ maxlen: parseInt(e.target.value) }, index)}
          />
        </Form.Group>
        <Form.Group className={styles.group_style} controlId={field.type + index + '-multiline'}>
          <Form.Label>Increment Step</Form.Label>
          <Form.Control
            type='number'
            min='1'
            value={layoutData[index]?.step ?? ''}
            onChange={(e) => updateLayoutData({ step: e.target.value }, index)}
          />
        </Form.Group>
      </Col>
    </Row>
  );
};

RangeField.propTypes = {
  field: PropTypes.object,
  index: PropTypes.number,
  updateLayoutData: PropTypes.func,
  layoutData: PropTypes.func,
  idHelper: PropTypes.func,
  errors: PropTypes.array,
};

export default RangeField;
