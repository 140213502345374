import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Checkbox from './generic/Checkbox';
import CurrencyAmount from './generic/CurrencyAmount';
import Dropdown from './generic/Dropdown';
import DynamicDropdown from './generic/DynamicDropdown';
import FormFilter from './generic/FormFilter';
import Radio from './generic/Radio';
import Text from './generic/Text';
import TextArea from './generic/TextArea';
import Url from './generic/Url';
import styles from './dynamic_field.module.scss';
import { GOOGLE_PERFORMANCE_MAX } from '../../../lib/webads';
import { faPlus } from '@fortawesome/pro-light-svg-icons';

const DynamicField = ({
  controlId,
  platform,
  field,
  readOnly = false,
  errors = [],
  onChange = () => {},
  runtimeData = [],
  value,
  meta = {},
}) => {
  const [dynamicRows, setDynamicRows] = useState({
    indexStart: 0,
    hiddenState: [],
  });

  const [currentDynamicRow, setCurrentDynamicRow] = useState(0);

  useEffect(() => {
    if (field.type === 'dynamic_row') {
      let hiddenStateArr = new Array(field.maxrows).fill(true);

      let showUntil = value !== undefined ? Object.keys(value) : null;

      if (showUntil !== null) {
        let lastRow = showUntil[showUntil.length - 1];
        showUntil = Number(lastRow.substr(lastRow.indexOf('-') + 1)) + 1;
        hiddenStateArr.fill(false, 0, showUntil - field.index_start);
        setCurrentDynamicRow(showUntil - field.index_start);
      }

      setDynamicRows({
        indexStart: 0,
        hiddenState: hiddenStateArr,
      });
    }
  }, []);

  if (field.type === 'text') {
    return (
      <Text
        controlId={controlId}
        header={field.title}
        readOnly={readOnly}
        required={field.required}
        minLength={field.minlen}
        maxLength={field.maxlen}
        maxLengthSoft={field.maxlensoft}
        notice={field.notice}
        info={field.info}
        errors={errors}
        value={value}
        onChange={onChange}
      />
    );
  }
  if (field.type === 'currency') {
    return (
      <CurrencyAmount
        controlId={controlId}
        header={field.title}
        readOnly={readOnly}
        required={field.required}
        notice={field.notice}
        errors={errors}
        value={value}
        onChange={onChange}
      />
    );
  }
  if (field.type === 'textarea') {
    return (
      <TextArea
        controlId={controlId}
        header={field.title}
        readOnly={readOnly}
        required={field.required}
        minLength={field.minlen}
        maxLength={field.maxlen}
        maxLengthSoft={field.maxlensoft}
        notice={field.notice}
        info={field.info}
        errors={errors}
        value={value}
        onChange={onChange}
      />
    );
  }

  if (field.type === 'url') {
    return (
      <Url
        controlId={controlId}
        header={field.title}
        readOnly={readOnly}
        required={field.required}
        maxLength={field.maxlen}
        maxLengthSoft={field.maxlensoft}
        notice={field.notice}
        errors={errors}
        value={value}
        onChange={onChange}
      />
    );
  }

  if (field.type === 'asset') {
    return '';
  }

  if (field.type === 'select_one') {
    if (field.variant === 'radio') {
      return (
        <Radio
          controlId={controlId}
          header={field.title}
          readOnly={readOnly}
          required={field.required}
          options={field.options}
          errors={errors}
          value={value}
          onChange={onChange}
        />
      );
    }
    if (field.variant === 'dropdown') {
      return (
        <Dropdown
          controlId={controlId}
          header={field.title}
          readOnly={readOnly}
          required={field.required}
          options={field.options}
          errors={errors}
          value={value}
          onChange={onChange}
        />
      );
    }
    if (field.variant === 'dynamic_dropdown') {
      return (
        <DynamicDropdown
          controlId={controlId}
          header={field.title}
          readOnly={readOnly}
          required={field.required}
          options={Object.keys(runtimeData).includes(field.options) ? runtimeData[field.options] : []}
          errors={errors}
          value={value}
          onChange={onChange}
        />
      );
    }
  }

  if (field.type === 'select_one_or_many') {
    if (field.variant === 'checkbox') {
      return (
        <Checkbox
          controlId={controlId}
          header={field.title}
          readOnly={readOnly}
          disabled={readOnly}
          required={field.required}
          options={field.options}
          errors={errors}
          value={value}
          onChange={onChange}
        />
      );
    }
  }

  if (field.type === 'feed') {
    return '';
  }

  if (
    field.type === 'dynamic_row' &&
    (meta?.ad?.type === GOOGLE_PERFORMANCE_MAX || meta?.ad?.format === GOOGLE_PERFORMANCE_MAX)
  ) {
    const getLargestNumberFromKeys = (obj) =>
      Math.max(
        ...Object.keys(obj)
          .filter((key) => key.match(/-\d+$/))
          .map((key) => parseInt(key.split('-')[1]))
      );
    const rowCount = Math.max(field.minrows, getLargestNumberFromKeys(value || {}));

    return (
      <div>
        {Array.from({ length: rowCount }).map((_, i) => (
          <Form.Group
            controlId={controlId}
            style={{
              padding: '5px 10px',
              background: '#edeef0',
              border: 'solid 1px #d4d4d4',
              borderLeft: 'solid 3px #d4d4d4',
            }}
          >
            {field.fields_template.map((f) => (
              <DynamicField
                key={`${f.id}-${field.index_start + i}`}
                field={{
                  ...f,
                  title: f.title + ' ' + (field.index_start + i),
                  id: f.id + '-' + i,
                  required: field.index_start + i <= field.minrows,
                }}
                controlId={`${controlId.substr(0, controlId.lastIndexOf('.') + 1)}-${f.id}-${field.index_start + i}`}
                readOnly={readOnly}
                errors={errors?.[`${f.id}-${field.index_start + i}`]}
                value={value?.[`${f.id}-${field.index_start + i}`]}
                onChange={(val) => {
                  let data = {
                    ...(value || {}),
                    [`${f.id}-${field.index_start + i}`]: val,
                  };
                  onChange(data);
                }}
              />
            ))}
          </Form.Group>
        ))}
        {rowCount < field.maxrows && (
          <div className='text-center'>
            <Button
              type='button'
              disabled={readOnly}
              variant='outline-secondary'
              hidden={!field?.collapsed}
              onClick={() => {
                const nextIndex = field.index_start + rowCount - 1;
                let addedValues = {};

                field.fields_template.forEach((f) => (addedValues[`${f.id}-${field.index_start + nextIndex}`] = ''));
                onChange({
                  ...(value || {}),
                  ...addedValues,
                });
              }}
            >
              <FontAwesomeIcon icon={faPlus} /> {field.title}
            </Button>
          </div>
        )}
      </div>
    );
  }

  if (field.type === 'dynamic_row') {
    let rowCount = field?.match_media
      ? meta?.ad?.media && meta.ad.media.length > 0
        ? meta.ad.media.length
        : 0
      : field.maxrows;

    // Remove any excess values that may occur when a media file is removed.
    if (value) {
      const availableFields = field.fields_template.flatMap((fld) =>
        [...Array(rowCount)].map((_, index) => `${fld.id}-${field.index_start + index}`)
      );
      const usedKeys = Object.keys(value).filter((key) => availableFields.includes(key));

      if (usedKeys.length < Object.keys(value).length) {
        const filtered = usedKeys.reduce((obj, key) => {
          obj[key] = value[key];
          return obj;
        }, {});
        onChange(filtered);
      }
    }
    return (
      <div>
        {[...Array(field.maxrows)].slice(0, rowCount).map((_, i) => (
          <Form.Group
            controlId={controlId}
            hidden={field?.collapsed ? false : dynamicRows.hiddenState[i]}
            style={{
              padding: '5px 10px',
              background: '#edeef0',
              border: 'solid 1px #d4d4d4',
              borderLeft: 'solid 3px #d4d4d4',
            }}
          >
            {field.fields_template.map((f) => (
              <DynamicField
                key={`${f.id}-${field.index_start + i}`}
                field={{
                  ...f,
                  title: f.title + ' ' + (field.index_start + i),
                  id: f.id + '-' + i,
                }}
                controlId={`${controlId.substr(0, controlId.lastIndexOf('.') + 1)}-${f.id}-${field.index_start + i}`}
                readOnly={readOnly}
                errors={errors?.[`${f.id}-${field.index_start + i}`]}
                value={value?.[`${f.id}-${field.index_start + i}`]}
                onChange={(val) => {
                  let data = {
                    ...(value || {}),
                    [`${f.id}-${field.index_start + i}`]: val,
                  };
                  onChange(data);
                }}
              />
            ))}
          </Form.Group>
        ))}
        <Button
          type='button'
          disabled={readOnly}
          hidden={field?.collapsed ? true : false}
          onClick={(e) => {
            e.preventDefault();
            let rowState = dynamicRows.hiddenState;
            rowState[currentDynamicRow] = false;
            setDynamicRows({
              hiddenState: rowState,
            });

            if (currentDynamicRow < field.maxrows - 1) {
              setCurrentDynamicRow(currentDynamicRow + 1);
            } else {
              e.target.hidden = true;
            }
          }}
        >
          {field.title}
        </Button>
      </div>
    );
  }

  if (field.type === 'group') {
    if (field.variant === 'multi') {
      return [...Array(field.maxlength)].map((_, i) => (
        <Form.Group controlId={controlId}>
          <Form.Label className='font-weight-bold'>{field.title}</Form.Label>
          {field.fields.map((f) => (
            <DynamicField
              key={`${f.id}-${i}`}
              field={f}
              controlId={`${controlId}-${f.id}-${i}`}
              readOnly={readOnly}
              errors={errors?.[`${f.id}-${i}`]}
              value={value?.[`${f.id}-${i}`]}
              onChange={(val) => {
                let data = {
                  ...(value || {}),
                  [`${f.id}-${i}`]: val,
                };
                onChange(data);
              }}
            />
          ))}
        </Form.Group>
      ));
    }

    if (field.variant === 'exclusive') {
      return [...Array(field.maxlength)].map((_) => (
        <Form.Group controlId={controlId}>
          <Form.Label className='font-weight-bold'>{field.title}</Form.Label>
          {field.fields.map((f) => (
            <DynamicField
              key={f.id}
              field={f}
              controlId={`${controlId}-${f.id}`}
              readOnly={readOnly}
              errors={errors?.[f.id]}
              value={f.id === value?.value ? value?.data : undefined}
              onChange={(val) => {
                onChange({
                  value: f.id,
                  data: val,
                });
              }}
            />
          ))}
        </Form.Group>
      ));
    }
  }

  if (field.type === 'filter') {
    return (
      <FormFilter
        controlId={controlId}
        header={field.title}
        readOnly={readOnly}
        required={field.required}
        options={field.options}
        fields={field.fields}
        errors={errors}
        value={value}
        onChange={onChange}
      />
    );
  }

  if (field.type === 'range') {
    return (
      <Form.Group controlId={controlId}>
        <Form.Label>{field.title}</Form.Label>
        {(field?.info || field?.notice) && (
          <span style={{ marginLeft: '5px' }}>
            {field?.info && (
              <OverlayTrigger
                placement='left'
                overlay={<Tooltip id={`${controlId}-info-popover`}>{field.info}</Tooltip>}
              >
                <FontAwesomeIcon icon={faQuestionCircle} size='sm' />
              </OverlayTrigger>
            )}
            {field?.notice && (
              <Form.Control.Feedback className='d-block text-body mb-2'>{field.notice}</Form.Control.Feedback>
            )}
          </span>
        )}
        <span className={styles.slider_start_label}>{field.minlen}</span>
        <Form.Control
          type='range'
          min={field.minlen}
          max={field.maxlen}
          errors={errors}
          value={value}
          className={styles.slider}
          step={field.step ?? 1}
          onChange={(e) => onChange(e.target.value)}
        />
        <span className={styles.slider_end_label}>{field.maxlen}</span>
      </Form.Group>
    );
  }

  return '';
};

DynamicField.propTypes = {
  controlId: PropTypes.string.isRequired,
  field: PropTypes.shape({
    type: PropTypes.oneOf([
      'text',
      'textarea',
      'asset',
      'select_one',
      'select_one_or_many',
      'group',
      'url',
      'filter',
      'feed',
      'dynamic_row',
      'range',
    ]).isRequired,
  }).isRequired,
  platform: PropTypes.oneOf(['facebook', 'google', 'youtube', 'bespoke_product']).isRequired,
  readOnly: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default DynamicField;
