import React from 'react';
import { Controller } from 'react-hook-form';
import DefaultFacebookCustomAudiences from '../../features/organisation_settings/containers/DefaultFacebookCustomAudiences';
import DefaultFacebookLookalikeAudiences from '../../features/organisation_settings/containers/DefaultFacebookLookalikeAudiences';
import DefaultFacebookSavedAudiences from '../../features/organisation_settings/containers/DefaultFacebookSavedAudiences';

export const FacebookPermissions = ({ control, adSettings, organisation }) => {
  return (
    <div className='rounded'>
      <div className='d-flex align-items-center mb-2'>
        <h5 className='flex-grow-1 mb-0'>Facebook Permissions</h5>
      </div>
      <Controller
        name='facebook_custom_audiences'
        control={control}
        defaultValue={adSettings?.facebook_custom_audiences}
        render={({ field }) => (
          <DefaultFacebookCustomAudiences
            controlId={`${organisation.id}-default-facebook-custom-audiences`}
            value={field.value}
            onChange={field.onChange}
            {...field}
          />
        )}
      />

      <Controller
        name='facebook_lookalike_audiences'
        control={control}
        defaultValue={adSettings?.facebook_lookalike_audiences}
        render={({ field }) => (
          <DefaultFacebookLookalikeAudiences
            controlId={`${organisation.id}-default-facebook-lookalike-audiences`}
            value={field.value}
            onChange={field.onChange}
            {...field}
          />
        )}
      />

      <Controller
        name='facebook_saved_audiences'
        control={control}
        defaultValue={adSettings?.facebook_saved_audiences}
        render={({ field }) => (
          <DefaultFacebookSavedAudiences
            controlId={`${organisation.id}-default-facebook-saved-audiences`}
            value={field.value}
            onChange={field.onChange}
            {...field}
          />
        )}
      />
    </div>
  );
};
