import PropTypes from 'prop-types'
import React from 'react'

export const Notification = ({ number }) => {
    return number > 0 ? <span
        className="position-absolute rounded-circle text-white font-weight-bold bg-dark"
        style={{
            top: '-0.3rem',
            right: '-0.3rem',
            width: '1.5rem',
            height: '1.5rem',
        }}
    >
        {number}
    </span> : <></>;
}

Notification.propTypes = {
    number: PropTypes.number.isRequired,
}
