import React, { forwardRef } from 'react';
import styled from 'styled-components';

import { DatePickerIcon } from './DatePickerIcon';
import { ChevronDownIcon } from './ChevronDownIcon';

const Input = styled.div`
  display: flex;
  align-items: center;

  padding: 12px 16px;
  min-width: 200px;
  max-width: 200px;
  border-radius: 4px;
  border: 1px solid #777785;

  :focus,
  :focus-within {
    border: 1px solid #7155ff;

    svg:first-child {
      fill: #00001E;
    }

    span {
      color: #00001E !important;
    }
  }
`;

const Value = styled.span`
  flex-grow: 1;
  white-space: nowrap;
  padding-left: 8px;
  padding-right: 8px;
  overflow: hidden;
  color: #777785;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 400;
`;

export const DatePickerInput = forwardRef(({ className, onClick, value }, ref) => {
  return (
    <Input className={className} onClick={onClick} ref={ref} tabIndex={-1}>
      <DatePickerIcon />
      <Value>{value ? value : 'Select date range'}</Value>
      <ChevronDownIcon />
    </Input>
  );
});
