import ProductsThrobber from "../../throbbers/ProductsThrobber";
import React from "react";
import styled from "styled-components";

const ThrobberContainer = styled.div`
  height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProductSelectorThrobber = (): JSX.Element =>
    <ThrobberContainer>
        <ProductsThrobber/>
    </ThrobberContainer>

export default ProductSelectorThrobber