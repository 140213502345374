import React, { useRef } from 'react';
import { Form } from 'react-bootstrap';
import styles from '../content_tab.module.scss';
import Errors from '../../../../../order_form/components/Errors';
import properties_styles from '../../../brand_page_properties.module.scss';

const MAX_URL_LENGTH = 2048;

export const LinkVariable = ({
  label,
  handleUpdateValue,
  constraints,
  brandStorePages,
  reduxValue,
  readOnly,
  variableErrors,
}) => {
  const dropdownRef = useRef();
  const textInputRef = useRef();

  const onLinkTypeChange = (e) => {
    handleUpdateValue({
      brandPageLink: reduxValue.brandPageLink,
      externalUrl: (e.target.value !== 'noLink') ? reduxValue.externalUrl : null,
      linkType: e.target.value,
    });
  };

  const onDropdownChange = (e) => {
    handleUpdateValue({
      brandPageLink: parseInt(e.target.value),
      externalUrl: reduxValue.externalUrl,
      linkType: reduxValue.linkType,
    });
  };

  const onTextInputChange = (e) => {
    handleUpdateValue({
      brandPageLink: parseInt(reduxValue.brandPageLink),
      externalUrl: e.target.value,
      linkType: reduxValue.linkType,
    });
  };

  return (
    <Form.Group controlId='link'>
      <Form.Label className={styles.variable_label}>{label}</Form.Label>
      <div className={styles.link_radio_options}>
        <Form.Check
          type='radio'
          label='Store link'
          value='brandPageLink'
          checked={reduxValue.linkType === 'brandPageLink'}
          onChange={onLinkTypeChange}
          className={styles.link_radio_option}
          disabled={readOnly}
        />
        <Form.Check
          type='radio'
          label='External URL'
          value='externalUrl'
          checked={reduxValue.linkType === 'externalUrl'}
          onChange={onLinkTypeChange}
          className={styles.link_radio_option}
          disabled={readOnly}
        />
        <Form.Check
          type='radio'
          label='No Link'
          value='noLink'
          checked={reduxValue.linkType === 'noLink'}
          onChange={onLinkTypeChange}
          className={styles.link_radio_option}
          disabled={readOnly}
        />
      </div>
      <div className={styles.link_input_container}>
        <div
          style={{
            display: reduxValue.linkType === 'brandPageLink' ? 'block' : 'none',
          }}
        >
          <Form.Control
            as='select'
            onChange={onDropdownChange}
            required={constraints.required}
            ref={dropdownRef}
            value={reduxValue.brandPageLink}
            disabled={readOnly}
            isInvalid={variableErrors?.length && (!reduxValue || reduxValue.brandPageLink === '')}
          >
            <option value=''>Select a Page</option>
            {brandStorePages.map((page, index) => (
              <option key={index} value={page.id}>
                {page.page_name || 'No page name set'}
              </option>
            ))}
          </Form.Control>
        </div>
        <div
          style={{
            display: reduxValue.linkType === 'externalUrl' ? 'block' : 'none',
          }}
        >
          <div className={styles.link_info_text}>Please enter a valid full URL, e.g. http://www.example.com</div>
          <Form.Control
            type='text'
            onChange={onTextInputChange}
            required={constraints.required}
            maxLength={MAX_URL_LENGTH}
            placeholder={'Link URL'}
            ref={textInputRef}
            value={reduxValue.externalUrl}
            disabled={readOnly}
            isInvalid={variableErrors?.length && (!reduxValue || reduxValue.externalUrl === '')}
            errors={variableErrors}
          />
        </div>
        <div className={properties_styles.form_error_text}>
          <Errors errors={variableErrors} />
        </div>
      </div>
    </Form.Group>
  );
};
