import React, { useState } from 'react';
import styled from 'styled-components';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

const KeyContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 69px;
  position: relative;
  width: 100%;
`;

const Frame = styled.div`
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6px;
  position: relative;
`;

const Heading = styled.div`
  color: ${(props) => (props.selected ? '#7155ff' : '#808080')};
  font-family: 'Roboto-SemiBold', Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  &:hover {
    color: #7155ff;
  }
`;

const StyledPopover = styled(Popover)`
  border-radius: 12px;
  background-color: #d0c6ff;
  min-width: 522px;
  & .arrow::after {
    border-top-color: #d0c6ff;
  }
`;

const StyledPopoverContent = styled(Popover.Content)`
  display: flex;
  justify-content: center;
  padding: 12px 16px;
`;

export const FilterHeadings = ({ onHeadingClick }) => {
  const [selectedHeading, setSelectedHeading] = useState('promoted_products');

  const headings = [
    { key: 'promoted_products', label: 'Promoted products' },
    {
      key: 'halo_products',
      label: 'Halo products',
      icon: faQuestionCircle,
      tooltip:
        'Product sales that have been indirectly impacted. For instance, products that were added to a customer’s cart and purchased in addition to promoted products.',
    },
    { key: 'all', label: 'All products' },
  ];

  const handleClick = (key) => {
    setSelectedHeading(key);
    onHeadingClick(key);
  };

  const renderPopover = (content, id) => (
    <StyledPopover id={id}>
      <StyledPopoverContent>{content}</StyledPopoverContent>
    </StyledPopover>
  );

  return (
    <KeyContainer>
      {headings.map(({ key, label, icon, tooltip }) => (
        <Frame key={key} onClick={() => handleClick(key)}>
          <Heading selected={selectedHeading === key}>{label}</Heading>
          {icon && (
            <OverlayTrigger
              trigger={['hover', 'focus']}
              placement='top'
              overlay={renderPopover(tooltip, `popover-${key}`)}
            >
              <span>
                <FontAwesomeIcon icon={icon} />
              </span>
            </OverlayTrigger>
          )}
        </Frame>
      ))}
    </KeyContainer>
  );
};
