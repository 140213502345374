import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { faChevronUp, faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Spinner } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';

import { ScreensTable } from './ScreensTable';
import { NoScreensAvailable } from './NoScreensAvailable';
import { BootstrapDataTable } from '../../../common/data/BootstrapDataTable';
import { FALLBACK_CURRENCY } from '../../../../lib/financial';

const StyledBadge = styled(Badge)`
  color: black;
  padding: 6px;

  ${({ variant }) => {
    if (variant === 'success') {
      return `background-color: #87F5C0 !important;`;
    } else if (variant === 'danger') {
      return `background-color: #EF767A !important;`;
    }
  }}
`;

const StyledExpandIcon = styled(FontAwesomeIcon)`
  margin-right: 8px;
`;

const Container = styled.div`
  padding: 16px;
  margin-top: 16px;

  #screen-availability-table {
    border: none;

    .table-header {
      background-color: #3a3b4f;
      color: white;
      text-overflow: ellipsis;
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
    }

    td {
      border-left: none;
      border-right: none;
      color: #00001e;
      font-weight: 700;
    }

    tr.disabled > td {
      color: #777785;
    }

    td.reset-expansion-style {
      padding: 0;
    }
  }
`;

const SpinnerWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const isWeekAvailable = (row) => {
  return row.screens?.length > 0 && row.available;
};

export const isWeekUnavailable = (row) => {
  return !isWeekAvailable(row);
};

export const areAllRowsSelected = (allRows, selectedRows) => {
  if (allRows.length === 0) {
    return false;
  }

  for (let row of allRows) {
    if (!selectedRows.includes(row.id) && isWeekAvailable(row)) {
      return false;
    }
  }
  return true;
};

export const shouldCheckboxBeDisabled = (row, rowIndex, selected, allRows) => {
  if (isWeekUnavailable(row)) {
    return true;
  }

  const selectedCount = selected.reduce((sum, id) => sum + (id !== undefined ? 1 : 0), 0);
  const isCurrentChecked = selected[rowIndex] !== undefined;

  if (selectedCount === 0) {
    return false;
  }

  if (selectedCount === 1 && isCurrentChecked) {
    return false;
  }

  // look for gaps in reverse direction
  let previousUnchecked = false;
  let previousGapDetected = false;
  let previousChecked = false;
  for (let index = rowIndex - 1; index >= 0; index -= 1) {
    const selectedId = selected[index];
    if (isWeekUnavailable(allRows[index])) {
      previousUnchecked = true; // Treat "Not Available" as an unchecked week
      continue;
    }
    if (selectedId === undefined) {
      previousUnchecked = true;
      continue;
    }
    previousChecked = true;
    if (previousUnchecked) {
      previousGapDetected = true;
      break;
    }
  }
  if (previousGapDetected) {
    return true;
  }

  // look for gaps in forward direction
  let nextUnchecked = false;
  let nextGapDetected = false;
  let nextChecked = false;
  for (let index = rowIndex + 1; index < allRows.length; index += 1) {
    const selectedId = selected[index];
    if (isWeekUnavailable(allRows[index])) {
      nextUnchecked = true; // Treat "Not Available" as an unchecked week
      continue;
    }
    if (selectedId === undefined) {
      nextUnchecked = true;
      continue;
    }
    nextChecked = true;
    if (nextUnchecked) {
      nextGapDetected = true;
      break;
    }
  }
  if (nextGapDetected) {
    return true;
  }

  return isCurrentChecked && previousChecked && nextChecked;
};

const getScreenAvailabilityTableColumns = ({
  allRows,
  expanded,
  onChangeSelected,
  onExpand,
  onSelectAll,
  selected,
  selectAllChecked,
}) => [
    {
      dataField: 'select',
      headerStyle: { width: '56px', textAlign: 'center' },
      style: { textAlign: 'center' },
      formatExtraData: { allRows, onChangeSelected, onSelectAll, selectAllChecked, selected },
      headerFormatter: (_column) => {
        const anyWeekUnavailable = allRows.some(isWeekUnavailable);
        return (
          <Form.Check type='checkbox' checked={selectAllChecked} onChange={onSelectAll} disabled={anyWeekUnavailable} />
        );
      },
      formatter: (_cell, row, rowIndex, formatExtraData) => {
        const disabled = shouldCheckboxBeDisabled(row, rowIndex, formatExtraData.selected, formatExtraData.allRows);

        return (
          <Form.Check
            type='checkbox'
            checked={formatExtraData.selected.includes(row.id)}
            disabled={disabled}
            onChange={(event) => {
              formatExtraData.onChangeSelected(row.id, rowIndex, event.currentTarget.checked);
            }}
          />
        );
      },
    },
    {
      dataField: 'date_and_location',
      text: 'Date and Location',
      formatExtraData: { expanded, onExpand },
      headerFormatter: (column) => {
        return <div>{column.text}</div>;
      },
      formatter: (_cell, row, _rowIndex, formatExtraData) => {
        const startDate = moment(row.start_at);
        const endDate = moment(row.stop_at);

        const icon = formatExtraData.expanded.includes(row.id) ? (
          <StyledExpandIcon icon={faChevronUp} />
        ) : (
          <StyledExpandIcon icon={faChevronDown} />
        );

        const handleClick = () => {
          if (row.screens?.length > 0) {
            formatExtraData.onExpand(row);
          }
        };

        const dateRangeText = startDate.isSame(endDate, 'year')
          ? `${startDate.format('D MMMM')}-${endDate.format('D MMMM YYYY')}`
          : `${startDate.format('D MMMM YYYY')}-${endDate.format('D MMMM YYYY')}`;

        return (
          <div onClick={handleClick}>
            {icon}
            {dateRangeText}
          </div>
        );
      },
    },
    {
      dataField: 'name',
      text: 'Name',
      headerStyle: { width: '340px' },
      headerFormatter: (column) => {
        return <div>{column.text}</div>;
      },
      formatter: (_cell, _row) => {
        return '';
      },
    },
    {
      dataField: 'screen_type',
      text: 'Screen Type',
      headerStyle: { width: '160px' },
      headerFormatter: (column) => {
        return <div>{column.text}</div>;
      },
      formatter: (_cell, _row) => {
        return '';
      },
    },
    {
      dataField: 'price',
      text: 'Price',
      headerStyle: { width: '160px' },
      headerFormatter: (column) => {
        return <div>{column.text}</div>;
      },
      formatter: (_cell, row) => {
        if (row.screens?.length > 0) {
        return new Intl.NumberFormat(undefined, { style: 'currency', currency: FALLBACK_CURRENCY })
            .format(row.price)
            .replace('A$', '$');
        }
        return '-';
      },
    },
    {
      dataField: 'availability',
      text: 'Availability',
      headerStyle: { width: '120px' },
      headerFormatter: (column) => {
        return <div>{column.text}</div>;
      },
      formatter: (_cell, row) => {
        if (isWeekAvailable(row)) {
          return <StyledBadge variant='success'>Available</StyledBadge>;
        }
        return <StyledBadge variant='danger'>Not Available</StyledBadge>;
      },
    },
  ];

export const ScreenAvailabilityTable = ({ availabilityData, requestStatus, selected, onChangeSelected }) => {
  const [expanded, setExpanded] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(areAllRowsSelected(availabilityData, selected));

  // In order to correctly handle checkbox selectability of rows (only adjacent weeks can be selected, no gaps),
  // we need the id of selected rows plus the index within the array of rows.
  //
  // We handle that here by converting an array of id's to a kind of sparse array where the ids are stored at
  // their original index.
  //
  // NOTE: in javascript any gaps in arrays will be reported as undefined, and Array.length will include those gaps
  const sparseSelected = useMemo(() => {
    return availabilityData.reduce((newlySelected, row, rowIndex) => {
      const id = `${row.start_at}_${row.stop_at}`;
      if (selected.includes(id)) {
        newlySelected[rowIndex] = id;
      }
      return newlySelected;
    }, []);
  }, [availabilityData, selected]);

  const handleExpand = (row) => {
    if (expanded.includes(row.id)) {
      setExpanded((prev) => prev.filter((id) => id !== row.id));
    } else {
      setExpanded((prev) => prev.concat(row.id));
    }
  };

  const handleChangeSelected = (id, _rowIndex, checked) => {
    const newlySelected = [...selected];
    if (checked && !selected.includes(id)) {
      newlySelected.push(id);
      onChangeSelected(newlySelected);
      const allSelected = areAllRowsSelected(availabilityData, newlySelected);
      setSelectAllChecked(allSelected);
    } else if (!checked && selected.includes(id)) {
      onChangeSelected(newlySelected.filter((existingId) => existingId !== id));
      setSelectAllChecked(false);
    }
  };

  const handleSelectAll = () => {
    if (selectAllChecked) {
      onChangeSelected([]);
      setSelectAllChecked(false);
    } else {
      const newlySelected = [];
      for (let row of availabilityData) {
        if (row.screens?.length > 0) {
          newlySelected.push(row.id);
        }
      }
      onChangeSelected(newlySelected);
      if (areAllRowsSelected(availabilityData, newlySelected)) {
        setSelectAllChecked(true);
      }
    }
  };

  return (
    <Container className='broadsign-schedule-table-container'>
      {requestStatus === 'loading' && (
        <SpinnerWrapper>
          <Spinner animation='border' />
        </SpinnerWrapper>
      )}

      {requestStatus === 'completed' && (availabilityData?.length || 0) === 0 && <NoScreensAvailable />}

      {requestStatus === 'completed' && availabilityData?.length > 0 && (
        <BootstrapDataTable
          columns={getScreenAvailabilityTableColumns({
            allRows: availabilityData,
            expanded,
            onChangeSelected: handleChangeSelected,
            onExpand: handleExpand,
            onSelectAll: handleSelectAll,
            selected: [...sparseSelected],
            selectAllChecked,
          })}
          data={availabilityData || []}
          expandRow={{
            expanded,
            expandByColumnOnly: true,
            renderer: (row) => <ScreensTable key={row.id} row={row} />,
          }}
          headerWrapperClasses='table-header'
          id='screen-availability-table'
          key={selected.join('')} // table won't rerender when selection changes, force render here
          keyField='id'
          rowClasses={(row, _rowIndex) => {
            if ((row.screens?.length || 0) === 0) {
              return 'disabled';
            }
            return '';
          }}
          paginationEnable={false}
        />
      )}
    </Container>
  );
};

ScreenAvailabilityTable.propTypes = {
  availabilityData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      start_at: PropTypes.string.isRequired,
      stop_at: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
      screens: PropTypes.array.isRequired,
    })
  ).isRequired,
  onChangeSelected: PropTypes.func,
  requestStatus: PropTypes.oneOf(['idle', 'loading', 'completed']).isRequired,
  selected: PropTypes.arrayOf(PropTypes.string),
};
