import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import styles from './brandstores_controls.module.scss';
import { BrandStoresDropdown } from './BrandStoresDropdown';
import { BRAND_STORE_ORDER_STATUS } from '../constants';

const StyledSearch = styled.div`
  display: flex;
  align-items: center;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: #777785;
`;

const FilterResultsBar = (props) => {
  return (
    <div className='input-group' style={{ maxWidth: '15em', flexGrow: 1 }}>
      <input
        type='text'
        size={20}
        className={`border-right-0 ${styles.brandstore_controls_input}`}
        id='search'
        placeholder='Search'
        onChange={props.onChange}
      />
      <StyledSearch>
        <FontAwesomeIcon icon={faSearch} />
      </StyledSearch>
    </div>
  );
};

const SupplierDropdown = (props) => {
  const options = [
    { value: 'all', label: 'All Suppliers' },
    ...props.suppliers.map((supplier) => ({ value: supplier.id, label: supplier.name })),
  ];
  return <BrandStoresDropdown options={options} onChange={props.onChange} maxWidth='9em' defaultValue='all' />;
};

const RetailerDropdown = (props) => {
  const options = [
    { value: 'all', label: 'All Retailers' },
    ...props.retailers.map((retailer) => ({ value: retailer.id, label: retailer.name })),
  ];
  return <BrandStoresDropdown options={options} onChange={props.onChange} maxWidth='9em' defaultValue='all' />;
};

const StatusDropdown = (props) => {
  // create the drop downs from BRAND_STORE_ORDER_STATUS constant
  const options = Object.keys(BRAND_STORE_ORDER_STATUS).map((key) => ({
    value: key,
    label: BRAND_STORE_ORDER_STATUS[key].label,
  }));
  options.unshift({ value: 'all', label: 'All Status' });
  return <BrandStoresDropdown options={options} onChange={props.onChange} defaultValue='all' />;
};

const EntriesInfo = ({ entryFrom, entryTo, totalEntries }) => {
  return '';
};

export const BrandStoresControls = (props) => {
  return (
    <>
      <div className={`${styles.top_controls_background}`}>
        <EntriesInfo
          entryFrom={props.tableEntryFrom}
          entryTo={props.tableEntryTo}
          totalEntries={props.tableEntryTotal}
        />
        <StatusDropdown onChange={props.onChangeStatusDropdown} />
        {props.isRetailer && <SupplierDropdown onChange={props.onChangeSupplierDropdown} suppliers={props.suppliers} />}
        {!props.isRetailer && (
          <RetailerDropdown onChange={props.onChangeRetailerDropdown} retailers={props.retailers} />
        )}
        <FilterResultsBar onChange={props.onChangeFilterTextBox} />
      </div>
    </>
  );
};

BrandStoresControls.propTypes = {
  onChangeStatusDropdown: PropTypes.func,
  onChangeSupplierDropdown: PropTypes.func,
  suppliers: PropTypes.any,
  retailers: PropTypes.any,
  onChangeFilterTextBox: PropTypes.func,
  brandPagesLoading: PropTypes.bool,
  isRetailer: PropTypes.bool,
};
