import axios from '../../axios'

export async function organisationRetailerBespokeProducts(retailerID = undefined) {
    let params = new URLSearchParams()
    if (retailerID) {
        params.append('retailer_id', retailerID)
    }
    return axios.get(`/bespoke/supplier?${params.toString()}`)
}

export async function ownedBespokeProducts(supplierID = undefined) {
    let params = new URLSearchParams()
    if (supplierID) {
        params.append('supplier_id', supplierID)
    }
    return axios.get(`/bespoke?${params.toString()}`)
}

export async function createBespokeProduct(form) {
    return axios.post(`/bespoke`, form);
}

export async function showBespokeProduct(bespokeID) {
    return axios.get(`/bespoke/${bespokeID}`);
}

export async function updateBespokeProduct(bespokeID, form) {
    return axios.put(`/bespoke/${bespokeID}`, form);
}