import axios from '../../axios';
import FormData from 'form-data';

const LOGIN_TIMEOUT = 180000;

export async function loginRequest(email, password) {
  const form = new FormData();
  form.set('email', email);
  form.set('password', password);

  return axios.post(`/user/login`, form, {
    timeout: LOGIN_TIMEOUT,
  });
}

export async function loginAs(email, password, loginAs) {
  const form = new FormData();
  form.set('email', email);
  form.set('password', password);
  form.set('login_as', loginAs);

  return axios.post(`/user/login-as`, form, {
    timeout: LOGIN_TIMEOUT,
  });
}

export async function loginAdminAs(token) {
  const form = new FormData();
  form.set('token', token);

  return axios.post(`/user/login-with-handoff`, form, {
    timeout: LOGIN_TIMEOUT,
  });
}

export async function linkOrg(token) {
  const form = new FormData();
  form.set('token', token);

  return axios.post(`/user/organisation/link`, form, {
    timeout: LOGIN_TIMEOUT,
  });
}

export async function linkUser(token) {
  const form = new FormData();
  form.set('token', token);

  return axios.post(`/organisation/user/link`, form, {
    timeout: LOGIN_TIMEOUT,
  });
}

export async function updateUser(user_id, first_name, last_name, preferences_json) {
  return axios.put(`/user/${user_id}`, {
    first_name,
    last_name,
    preferences_json,
  });
}

export async function inviteUserOrganisation(
  first_name,
  last_name,
  email,
  jobtitle,
  selected_org_id = null,
  organisation_name
) {
  const form = new FormData();
  form.set('first_name', first_name);
  form.set('last_name', last_name);
  form.set('email', email);
  form.set('jobtitle', jobtitle);
  form.set('organisation_name', organisation_name);

  if (!!selected_org_id) {
    form.set('selected_org_id', selected_org_id);
  }

  return axios.post(`/user/invite`, form);
}

export async function inviteUser(first_name, last_name, email, role, jobtitle) {
  const form = new FormData();
  form.set('first_name', first_name);
  form.set('last_name', last_name);
  form.set('email', email);
  form.set('jobtitle', jobtitle);
  form.set('role', role);

  return axios.post(`/user/invite/user`, form);
}

export async function registerUserOrganisation(
  first_name,
  last_name,
  jobtitle,
  email,
  password,
  password_confirmation,
  token,
  organisation
) {
  const form = new FormData();
  form.set('first_name', first_name);
  form.set('last_name', last_name);
  form.set('jobtitle', jobtitle);
  form.set('email', email);
  form.set('password', password);
  form.set('password_confirmation', password_confirmation);
  form.set('token', token);
  form.set('organisation', organisation);
  form.set('default_margin', 10);

  return axios.post(`/user/register`, form);
}

export async function registerUser(first_name, last_name, jobtitle, email, password, password_confirmation, token) {
  const form = new FormData();
  form.set('first_name', first_name);
  form.set('last_name', last_name);
  form.set('jobtitle', jobtitle);
  form.set('email', email);
  form.set('password', password);
  form.set('password_confirmation', password_confirmation);
  form.set('token', token);

  return axios.post(`/user/create`, form);
}

export async function updateUserDetails(userId, first_name, last_name, email, jobtitle, role, is_active) {
  const form = new FormData();
  form.set('first_name', first_name);
  form.set('last_name', last_name);
  if (email != null) form.set('email', email);
  form.set('jobtitle', jobtitle);
  form.set('is_active', is_active);
  form.set('role', role);

  return axios.post(`/user/${userId}/details`, form);
}

export async function updateUserPassword(userId, password, password_confirmation) {
  const form = new FormData();
  form.set('password', password);
  form.set('password_confirmation', password_confirmation);

  return axios.post(`/user/${userId}/password`, form);
}

export async function resetPasswordExternal(email) {
  return axios.post(`/user/reset-password`, {
    email,
  });
}

export async function resetPassword(email) {
  const form = new FormData();
  form.set('email', email);

  return axios.post(`/user/reset`, form);
}

export async function switchOrganisation(orgId) {
  return axios.post(`/user/switch-active-organisation/${orgId}`);
}

export async function resetPasswordConfirm(password, password_confirmation, token) {
  const form = new FormData();
  form.set('password', password);
  form.set('password_confirmation', password_confirmation);

  return axios.post(`/user/reset/password/${token}`, form);
}

export async function refreshUser(cache = true) {
  let url = cache ? `/user/refresh?cache=1` : `/user/refresh`;
  return axios.get(url, {
    timeout: LOGIN_TIMEOUT,
  });
}

export async function getDashbardStatistics(parameters) {
  let params = new URLSearchParams();
  Object.keys(parameters).forEach((key) => {
    if (['before', 'after'].includes(key)) {
      params.set(key, parameters[key]);
    } else {
      params.set(key, encodeURIComponent(JSON.stringify(parameters[key])));
    }
  });
  return axios.get(`/user/statistics/dashboard?${params.toString()}`);
}
