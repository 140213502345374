export function randomRange(min, max) {
    return Math.random() * (max - min) + min
}

// Between 1 and 11
export function randomCharacters(length = 1) {
    if (length > 11) {
        throw new Error('Character length must be less than 12')
    }
    return Math.random().toString(36).substring(2,2+length)
}