import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createBrowserRouter, RouterProvider, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import AllOrders from './app/AllOrders';
import { selectDialog } from './app/appSlice';
import { ToastContainer } from 'react-toastify';
import ArchivedOrders from './app/ArchivedOrders';
import CreateOrder from './app/CreateOrder';
import Dashboard from './app/Dashboard';
import AppDialog from './app/Dialog';
import EditOrder from './app/EditOrder';
import InviteSupplier from './app/InviteSupplier';
import InviteUser from './app/InviteUser';
import LinkOrg from './app/LinkOrg';
import LinkUser from './app/LinkUser';
import Login from './app/Login';
import LoginAdminAs from './app/LoginAdminAs';
import ProtectedRoutes from './app/ProtectedRoutes';
import EditOwnedSpace from './app/EditOwnedSpace';
import CreateOwnedSpace from './app/CreateOwnedSpace';
import Register from './app/Register';
import RegisterUser from './app/RegisterUser';
import ResetPassword from './app/ResetPassword';
import ResetPasswordConfirm from './app/ResetPasswordConfirm';
import SupplierSettings from './app/SupplierSettings';
import SuppliersGeneralSettings from './app/SuppliersGeneralSettings/SuppliersGeneralSettings';
import ViewOrder from './app/ViewOrder';
import { randomRange } from './lib/random';
import OrganisationSettings from './app/OrganisationSettings';
import OrganisationChannels from './app/OrganisationChannels';
import OrganisationWebSettings from './app/OrganisationWebSettings';
import OrganisationBespokeSettings from './app/OrganisationBespokeSettings';
import OrganisationInStoreScreensSettings from './app/OrganisationSettings/Broadsign/OrganisationInStoreScreensSettings';
import CreateBespokeProduct from './app/CreateBespokeProduct';
import EditBespokeProduct from './app/EditBespokeProduct';
import { clearSession, isLoggedIn, refreshSession } from './features/session/sessionSlice';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-toastify/dist/ReactToastify.css';
import OrganisationUserEdit from './app/OrganisationUserEdit';
import ListPackages from './app/ListPackages';
import CreatePackage from './app/CreatePackage';
import OrganisationRoleEdit from './app/OrganisationRoleEdit';
import RetailerWallets from './app/RetailerWallets';
import SingleWalletPage from './app/SingleWalletPage';
import EditPackage from './app/EditPackage';
import OrganisationInventoryHome from './features/inventory/OrganisationInventoryHome';
import OrganisationInventoryWebspaceDetails from './features/inventory/OrganisationInventoryWebspaceDetails';
import OrganisationInventoryAudienceDetails from './features/inventory/OrganisationInventoryAudienceDetails';
import { EditBrandStore } from './features/brand_stores/EditBrandStore';
import OrganisationBrandStoresHome from './features/brand_stores/OrganisationBrandStoresHome';
import BrandStoreConfigureForm from './features/brand_stores/create/BrandStoreConfigureForm';
import { trackPageView } from './helpers/segment';
import { HelmetProvider } from 'react-helmet-async';
import StyleSheetManagerProvider from './providers/StyleSheetManagerProvider';

const LogOut = () => {
  const dispatch = useDispatch();
  dispatch(clearSession());
  return <Navigate to='/login' replace />;
};

const PageView = () => {
  const location = useLocation();

  useEffect(() => {
    trackPageView(location.pathname);
  }, [location.pathname]);
  return <></>;
};

const AppRoutes = () => {
  const session = useSelector((state) => state.session);
  const dispatch = useDispatch();

  useEffect(() => {
    const interval = setInterval(() => {
      if (isLoggedIn(session)) {
        dispatch(refreshSession());
      }
    }, randomRange(3, 5) * 60 * 1000);
    return () => {
      clearInterval(interval);
    };
  }, [session]);

  return (
    <>
      <PageView />
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route path='/admin-login-as' element={<LoginAdminAs />} />
        <Route path='/register' element={<Register />} />
        <Route path='/register/user' element={<RegisterUser />} />
        <Route path='/organisation/link' element={<LinkOrg />} />
        <Route path='/user/link' element={<LinkUser />} />
        <Route path='/reset' element={<ResetPassword />} />
        <Route path='/reset/password/:token' element={<ResetPasswordConfirm />} />
        <Route path='/logout' element={<LogOut />} />
        <Route element={<ProtectedRoutes />}>
          <Route index element={<Dashboard />} />
          <Route path='/orders' element={<AllOrders />} />
          <Route path='/orders/archive' element={<ArchivedOrders />} />
          <Route path='/order/create' element={<CreateOrder />} />
          <Route path='/order/:order_id' element={<ViewOrder />} />
          <Route path='/order/edit/:order_id' element={<EditOrder />} />
          <Route path='/brandstores/configure' element={<BrandStoreConfigureForm />} />
          <Route path='/brandstores/configure/:order_id' element={<BrandStoreConfigureForm />} />
          <Route path='/brandstores/home' element={<OrganisationBrandStoresHome />} />
          <Route path='/brandstores/edit/:order_id' element={<EditBrandStore />} />
          <Route path='/organisations/invite' element={<InviteSupplier />} />
          <Route path='/inventory/home' element={<OrganisationInventoryHome />} />
          <Route path='/inventory/ownedspace/usage/:usage_id' element={<OrganisationInventoryWebspaceDetails />} />
          <Route path='/inventory/audience/usage/:audience_id' element={<OrganisationInventoryAudienceDetails />} />
          <Route path='/organisation/settings' element={<OrganisationSettings />} />
          <Route path='/organisation/settings/:id' element={<OrganisationUserEdit />} />
          <Route path='/organisation/channels' element={<OrganisationChannels />} />
          <Route path='/organisation/websettings' element={<OrganisationWebSettings />} />
          <Route path='/organisation/instorescreens-settings' element={<OrganisationInStoreScreensSettings />} />
          <Route path='/organisation/bespokesettings' element={<OrganisationBespokeSettings />} />
          <Route path='/organisation/role/:id' element={<OrganisationRoleEdit />} />
          <Route path='/wallets' element={<RetailerWallets />} />
          <Route path='/wallet/:wallet_id' element={<SingleWalletPage />} />
          <Route path='/organisation/websettings/edit/:owned_space_id' element={<EditOwnedSpace />} />
          <Route path='/organisation/websettings/create/:placement/:format/:subformat' element={<CreateOwnedSpace />} />
          <Route path='/settings/suppliers' element={<SuppliersGeneralSettings />} />
          <Route path='/settings/suppliers/:organisation_id' element={<SupplierSettings />} />
          <Route path='/packages' element={<ListPackages />} />
          <Route path='/packages/create' element={<CreatePackage />} />
          <Route path='/packages/:package_id/edit' element={<EditPackage />} />
          <Route path='/organisation/bespokesettings/create' element={<CreateBespokeProduct />} />
          <Route path='/organisation/bespokesettings/:bespoke_id/edit' element={<EditBespokeProduct />} />
          <Route path='/users/invite' element={<InviteUser />} />
        </Route>
      </Routes>
    </>
  );
};

const router = createBrowserRouter([{ path: '*', Component: AppRoutes }]);

const App = () => {
// Start: Handle auth token
const searchParams = new URLSearchParams(document.location.search);
const authToken = searchParams.get('authToken');
if (authToken) { 
// Set the cookie
document.cookie = `authToken=${authToken}`;

// Remove it from query params 
searchParams.delete('authToken');
document.location.search = searchParams;
}
// End: Handle auth token

const dialog = useSelector(selectDialog);
  return (
    <StyleSheetManagerProvider>
      <HelmetProvider>
        <RouterProvider router={router} />
        {!!dialog && <AppDialog dialog={dialog} />}
        <ToastContainer autoClose={5000} />
      </HelmetProvider>
    </StyleSheetManagerProvider>
  );
};

export default App;
