import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import Invite from '../features/organisations/Invite';
import { selectUser } from '../features/session/sessionSlice';
import Chrome from './layout/Chrome';
import { INVITE_SUPPLIERS } from '../lib/permissions';
import { Navigate } from 'react-router-dom';

const InviteSupplier = () => {
  const user = useSelector(selectUser);
  const userPermissions = user?.role?.permissions;
  const canInviteSuppliers = userPermissions ? userPermissions.find((p) => p.name === INVITE_SUPPLIERS) : false;

  if (!canInviteSuppliers) {
    return <Navigate to='/' replace />;
  }

  return (
    <>
      <Helmet>
        <title>InviteSupplier - {process.env.REACT_APP_NAME}</title>
      </Helmet>

      <Chrome>
        <Container className='pt-4'>
          <Row className='mb-3'>
            <Col lg='6'>
              <Invite />
            </Col>
          </Row>
        </Container>
      </Chrome>
    </>
  );
};

export default InviteSupplier;
