import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { Badge, Form, OverlayTrigger, Popover } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { selectTheme } from '../../../session/sessionSlice';
import Errors from '../Errors';

const TextArea = ({
  readOnly = false,
  disabled = false,
  required = false,
  header,
  placeholder = '',
  controlId,
  value = '',
  onChange = () => {},
  errors = [],
  minLength = 0,
  maxLength,
  maxLengthSoft,
  notice,
  info,
}) => {
  const theme = useSelector(selectTheme);

  return (
    <Form.Group controlId={controlId}>
      <Form.Label className='d-flex align-self-center align-items-center font-weight-bold'>
        <span>
          {header} {required && '*'}{' '}
          {info && (
            <OverlayTrigger
              placement='left'
              overlay={
                <Popover>
                  <Popover.Content id={`${controlId}-info-popover`}>{info}</Popover.Content>
                </Popover>
              }
            >
              <FontAwesomeIcon icon={faQuestionCircle} size='sm' />
            </OverlayTrigger>
          )}
        </span>
        {maxLengthSoft !== undefined && (
          <Badge className='ml-auto' variant={minLength <= value.length ? 'primary' : 'danger'}>
            {value.length} / {maxLengthSoft} characters
          </Badge>
        )}
      </Form.Label>
      {notice && <Form.Control.Feedback className='d-block text-body mb-2'>{notice}</Form.Control.Feedback>}
      <Form.Control
        as='textarea'
        rows='4'
        style={theme.components?.form_control}
        placeholder={placeholder}
        readOnly={readOnly}
        disabled={disabled}
        value={value}
        isInvalid={errors.length > 0}
        onKeyUp={(e) => {
          e.preventDefault();
          if (e.key === 'Enter') {
            onChange(value + '\n');
          }
        }}
        onChange={(e) => onChange(e.target.value)}
        required={required}
        minLength={minLength}
        maxLength={maxLength}
      />
      <Form.Control.Feedback type='invalid'>
        <Errors errors={errors} />
      </Form.Control.Feedback>
    </Form.Group>
  );
};

TextArea.propTypes = {
  header: PropTypes.string.isRequired,
  controlId: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  maxLength: PropTypes.number,
  maxLengthSoft: PropTypes.number,
  notice: PropTypes.string,
};

export default TextArea;
