import React from 'react';
import styles from './ToggleViewSwitch.module.scss';
import { faGrid2 } from '@fortawesome/pro-regular-svg-icons';
import { faList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Tab = ({ isSelected, icon }) => (
  <div className={`${styles.tab} ${isSelected ? styles.selected : ''}`}>
    <FontAwesomeIcon icon={icon} />
  </div>
);

export const ToggleViewSwitch = ({ isListView, toggleView }) => (
  <div className={styles.switch}>
    <div onClick={() => toggleView(true)}>
      <Tab isSelected={isListView} icon={faList} />
    </div>
    <div onClick={() => toggleView(false)}>
      <Tab isSelected={!isListView} icon={faGrid2} />
    </div>
  </div>
);
