import { isLive } from '../../lib/orders';
import { strcmp } from '../../lib/strings';

export const sortByFuncs = (session) => ({
  status: (a, b) => {
    let statuses = session.order_statuses;
    let ai = statuses.findIndex((e) => a.status === e.id);
    let bi = statuses.findIndex((e) => b.status === e.id);
    return ai - bi;
  },
  name: (a, b) => strcmp(a.name.toLowerCase(), b.name.toLowerCase()),
  start_date: (a, b) => new Date(b.start_at) - new Date(a.start_at),
  end_date: (a, b) => new Date(b.stop_at) - new Date(a.stop_at),
  created_date: (a, b) => new Date(b.created_at) - new Date(a.created_at),
  last_updated: (a, b) => new Date(b.updated_at) - new Date(a.updated_at),
  campaign_type: (a, b) => strcmp(a.format, b.format),
  supplier: (a, b) => strcmp(a.supplier_id, b.supplier_id),
  retailer: (a, b) => strcmp(a.owner_id, b.owner_id),
});

export const applyFilter = (orders, filterFields) =>
  orders
    .filter(
      (order) =>
        filterFields.organisation === '' ||
        order.supplier_id === filterFields.organisation ||
        order.owner_id === filterFields.organisation
    )
    .filter((order) => order.name.toLowerCase().includes(filterFields.text.toLowerCase()))
    .filter(
      (order) =>
        filterFields.order_status === '' ||
        filterFields.order_status === order.status ||
        (filterFields.order_status === 'campaign_live' && isLive(order))
    )
    .filter((order) => filterFields.format === '' || order.data_json.map((a) => a.format).includes(filterFields.format))
    .filter(
      (order) => filterFields.platform === '' || order.data_json.map((a) => a.platform).includes(filterFields.platform)
    );
