import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Col, Form, InputGroup, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import styled from 'styled-components';
import Datetime from 'react-datetime';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import PropTypes from 'prop-types';

const AvailabilityHeader = styled.span`
  font-weight: bold;
  font-size: 18px;
`;

const StyledOL = styled.ol`
  list-style: none;
`;

const StyledLI = styled.li`
  margin: 5px;
`;

const AvailabilitySection = ({
  values = { max_booking_date: '', orientation: [] },
  isSaving = false,
  onChange = (data) => {},
  formErrors = {},
}) => {
  const [landscapeChecked, setLandscapeChecked] = useState(values?.orientation.includes('Landscape'));
  const [portraitChecked, setPortraitChecked] = useState(values?.orientation.includes('Portrait'));
  const [bundledChecked, setBundledChecked] = useState(values?.orientation.includes('Landscape_&_Portrait'));
  const isValidUpToDate = (current) => current.isSameOrAfter(moment(), 'day');

  useEffect(() => {
    let selectedOrientations = [];

    if (landscapeChecked && portraitChecked) {
      setBundledChecked(true);
    }

    if (landscapeChecked) {
      selectedOrientations.push('Landscape');
    }

    if (portraitChecked) {
      selectedOrientations.push('Portrait');
    }

    if (bundledChecked) {
      selectedOrientations.push('Landscape_&_Portrait');
    }

    onChange({ orientation: selectedOrientations });
  }, [landscapeChecked, portraitChecked, bundledChecked]);

  return (
    <>
      <Row className='mb-3'>
        <Col>
          <h3>Availability</h3>
        </Col>
      </Row>
      <hr />
      <Row className='mb-3'>
        <Col>
          <AvailabilityHeader>Available Screen Types</AvailabilityHeader>
        </Col>
        <Col>
          <AvailabilityHeader>How far in advance suppliers can order</AvailabilityHeader>{' '}
          <OverlayTrigger
            placement='top'
            overlay={<Tooltip id='info-popover'>No screens beyond this date will be available.</Tooltip>}
          >
            <FontAwesomeIcon icon={faCircleInfo} size='sm' />
          </OverlayTrigger>
        </Col>
      </Row>
      <Row>
        <Col>
          <StyledOL>
            <StyledLI>
              <Form.Check
                checked={landscapeChecked}
                value={'Landscape'}
                onChange={() => setLandscapeChecked(!landscapeChecked)}
                disabled={isSaving}
                inline
                name='orientation'
                label='Landscape'
              />
            </StyledLI>
            <StyledLI>
              <Form.Check
                checked={portraitChecked}
                value={'Portrait'}
                onChange={() => setPortraitChecked(!portraitChecked)}
                disabled={isSaving}
                inline
                name='orientation'
                label='Portrait'
              />
            </StyledLI>
            <StyledLI>
              <Form.Check
                checked={bundledChecked}
                value={'Landscape_&_Portrait'}
                onChange={() => setBundledChecked(!bundledChecked)}
                disabled={isSaving}
                inline
                name='orientation'
                label='Bundled (landscape and portrait)'
              />
            </StyledLI>
          </StyledOL>
          {formErrors?.orientation && (
            <Form.Text className='text-danger'>
              {formErrors.orientation.toString().replace('orientation', 'available screen types')}
            </Form.Text>
          )}
        </Col>
        <Col>
          <InputGroup>
            <Datetime
              value={values.max_booking_date}
              dateFormat='YYYY-MM-DD'
              timeFormat={false}
              inputProps={{ placeholder: 'Up to YYYY-MM-DD', disabled: isSaving }}
              isValidDate={isValidUpToDate}
              onChange={(value) => {
                const date = moment.isMoment(value) ? moment(value.toString()).format('YYYY-MM-DD') : value;
                onChange({ max_booking_date: date });
              }}
            />
            <InputGroup.Append>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faCalendar} />
              </InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
          {formErrors?.max_booking_date && (
            <Form.Text className='text-danger'>
              {formErrors.max_booking_date
                .toString()
                .replace('max booking date', 'how far in advance suppliers can order')}
            </Form.Text>
          )}
        </Col>
      </Row>
    </>
  );
};

AvailabilitySection.propTypes = {
  values: PropTypes.shape({
    max_booking_date: PropTypes.string,
    orientation: PropTypes.array,
  }),
  isSaving: PropTypes.bool,
  onChange: PropTypes.func,
  FormErrors: PropTypes.object,
};

export default AvailabilitySection;
