import axios from '../../axios'

export async function organisationRetailerPackages(retailerID = undefined) {
    let params = new URLSearchParams()
    if (retailerID) {
        params.append('retailer_id', retailerID)
    }
    return axios.get(`/packages/supplier?${params.toString()}`)
}

export async function packages(ownerID, brandID) {
    let params = new URLSearchParams()
    params.append('brand_id', brandID)
    params.append('owner_id', ownerID)

    return axios.get(`/packages/list?${params.toString()}`)
}

export async function ownedPackages(supplierID = undefined) {
    let params = new URLSearchParams()
    if (supplierID) {
        params.append('supplier_id', supplierID)
    }

    return axios.get(`/packages?${params.toString()}`)
}

export async function createPackage(form) {
    return axios.post(`/packages`, form);
}

export async function showPackage(packageID) {
    return axios.get(`/packages/${packageID}`);
}

export async function updatePackage(packageID, form) {
    return axios.put(`/packages/${packageID}`, form);
}



export async function listPackages() {
    return axios.get(`/package_groups`)
}

export async function createPackageGroup(name) {
    return axios.post(`/package_groups`, { name })
}
