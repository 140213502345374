import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AdSection from './AdSection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCube } from '@fortawesome/pro-regular-svg-icons';
import { Alert, Button, Modal } from 'react-bootstrap';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ErrorFeedback } from '../packages/components/form_elements/Common';
import Errors from '../packages/components/form_elements/Errors';
import { discountDisplay } from '../../lib/financial';
import { useSelector } from 'react-redux';
import { selectSession } from '../session/sessionSlice';

const PackageContainer = styled.div`
  border: solid 1px #d4ccff;
  border-radius: 8px;
  padding: 32px;
  background-color: #fbfbff;
  margin-bottom: 60px;

  ${(props) => props.isInvalid && 'border: 1px solid #dc3545;'}
`;

const PackageHeaderContainer = styled.div`
  display: flex;
  align-items: center;
`;

const PackageTitleBarContainer = styled.div`
  margin-bottom: 10px;
`;

const PackageHeader = styled.h4`
  font-weight: bold;
  font-size: 18px;
  color: #3a3b4f;
  display: flex;
  align-items: center;
`;

const PackageHeaderLabel = styled.span`
  margin-left: 15px;
`;

const PackageSubHeader = styled.div`
  text-align: right;
  margin: 10px;
  span {
    color: #000;
    font-size: 18px;
  }
`;

const packageBudgetTotal = (pkg, currency) => {
  let text;

  if (pkg?.min_budget && pkg?.max_budget) {
    text = `Min ${currency}${pkg?.min_budget} - Max ${currency}${pkg?.max_budget}`;
  } else if (pkg?.min_budget) {
    text = `Min ${currency}${pkg?.min_budget}`;
  } else if (pkg?.max_budget) {
    text = `Max ${currency}${pkg?.max_budget}`;
  }

  return text;
};

const PackageSection = ({
  readOnly = false,
  isOwner = false,
  packageOffer = {},
  ads = [],
  assets = [],
  index = 0,
  onChange = () => {},
  onDelete = () => {},
  errors = {},
  extra = {},
}) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const packageErrors = errors[`data_json.${index}.package_id`] || [];
  const session = useSelector(selectSession);
  const defCurrency = session.user?.active_organisation?.settings?.['defaultCurrency'];
  const currency = session.currencies.find((s) => s.code === defCurrency)?.symbol;
  const totalBudget = packageBudgetTotal(packageOffer, currency);
  const handleDelete = () => {
    if (!readOnly) {
      ads.forEach((ad) => {
        onDelete(ad);
      });
    }

    setDeleteModal(false);
  };

  const packageDiscounts = discountDisplay(packageOffer?.ad_format_templates ?? [], currency);

  return (
    <PackageContainer isInvalid={packageErrors.length > 0}>
      <PackageTitleBarContainer>
        <PackageHeaderContainer>
          <PackageHeader>
            <FontAwesomeIcon icon={faCube} />
            <PackageHeaderLabel>{packageOffer.name}</PackageHeaderLabel>
          </PackageHeader>
          {!readOnly && (
            <Button className='ml-auto' variant='outline-muted' onClick={(e) => setDeleteModal(true)}>
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          )}
        </PackageHeaderContainer>
        {totalBudget && (
          <PackageSubHeader>
            <span>Package Budget: {totalBudget}</span>
            {packageDiscounts && (
              <>
                {' '}
                - <span>{packageDiscounts}</span>
              </>
            )}{' '}
          </PackageSubHeader>
        )}
        <ErrorFeedback type='invalid' isInvalid={packageErrors.length > 0}>
          <Errors errors={packageErrors} />
        </ErrorFeedback>
        {isOwner &&
          packageOffer.stock_availability > 0 &&
          packageOffer.stock_availability < packageOffer.stock_used && (
            <div>
              <Alert variant='warning'>
                There are currently {packageOffer.stock_used} orders with this package, exceeding the available stock of{' '}
                {packageOffer.stock_availability}.
              </Alert>
              <hr />
            </div>
          )}
      </PackageTitleBarContainer>

      {ads.map((ad, i) => (
        <div key={ad.id}>
          {packageOffer.ad_format_templates?.[i]?.notes && (
            <Alert variant='info'>{packageOffer.ad_format_templates[i].notes}</Alert>
          )}
          <AdSection
            key={ad.id}
            index={index + i}
            readOnly={readOnly}
            controlId={`data_json.${index + i}`}
            ad={ad}
            packageOffer={packageOffer}
            assets={assets}
            errors={errors}
            onChange={(ad) => onChange(ad)}
            onDelete={() => {}}
            extra={extra}
          />
        </div>
      ))}

      <Modal show={deleteModal} onHide={() => setDeleteModal(false)}>
        <Modal.Header closeButton>Remove package?</Modal.Header>
        <Modal.Body>Are you sure you want to remove this package? An unsaved ads will be unrecoverable.</Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setDeleteModal(false)}>
            Cancel
          </Button>
          <Button type='submit' variant='danger' className='ml-2' onClick={handleDelete}>
            Remove
          </Button>
        </Modal.Footer>
      </Modal>
    </PackageContainer>
  );
};

PackageSection.propTypes = {
  readOnly: PropTypes.bool,
  isOwner: PropTypes.bool,
  ads: PropTypes.array,
  packageOffer: PropTypes.object,
  assets: PropTypes.array,
  index: PropTypes.number,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  //Extra custom parameters
  extra: PropTypes.shape({ inStoreScreensTabIsReadOnly: PropTypes.bool }),
};

export default PackageSection;
