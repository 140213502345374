import React, { useState } from 'react';
import adSectionStyles from '../../../order_form/ad_section.module.scss';
import { PageList } from './PagesTabComponents/PageList';
import styles from '../brand_page_properties.module.scss';
import { addPage, removePage, reorderPages, addBlock } from '../../state_management/BrandStoreSlice';
import { useDispatch } from 'react-redux';
import { getRandomIntegerInRange } from '../../helpers';
import { VetoModal } from '../../../../app/components/VetoModal';
import { v4 as uuidv4 } from 'uuid';

const AddPageButtonPanel = ({ onAddPage }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <button className={`btn btn-outline-secondary ${styles.add_page_button}`} onClick={onAddPage}>
        + Add Page
      </button>
    </div>
  );
};

const PagesTab = ({ brandStoreOrder, brandStorePages, brandStoreErrors, readOnly, brandStoreBlockTypes }) => {
  const dispatch = useDispatch();
  const [editingPageId, setEditingPageId] = useState(brandStorePages[0]?.id);
  const [pageToRemove, setPageToRemove] = useState(null);
  const [showVetoRemoveModal, setShowVetoRemoveModal] = useState(false);

  const onAddPage = () => {
    const newPageId = getRandomIntegerInRange(Number.MIN_SAFE_INTEGER, -1);
    dispatch(addPage({ newPageId }));
    setEditingPageId(newPageId);

    //add navigation block to all pages by default
    const navigationBlockType = brandStoreBlockTypes.find((blockType) => blockType.name === 'Navigation');
    if (navigationBlockType) {
      const newBlockId = uuidv4();
      dispatch(
        addBlock({
          pageId: newPageId,
          newBlockId: newBlockId,
          blockType: navigationBlockType,
        })
      );
    }
  };

  const onPageSave = () => {
    setEditingPageId(null);
  };

  const onPageEdit = (pageId) => {
    setEditingPageId(pageId);
  };

  const onPageRemove = (pageId) => {
    if (editingPageId === pageId) {
      setEditingPageId(null);
    }
    setPageToRemove(pageId);
    setShowVetoRemoveModal(true);
  };

  const confirmPageRemove = () => {
    dispatch(removePage({ pageId: pageToRemove }));
    setPageToRemove(null);
    setShowVetoRemoveModal(false);
  };

  const handleModalClose = () => {
    setPageToRemove(null);
    setShowVetoRemoveModal(false);
  };

  const moveCard = (fromIndex, toIndex) => {
    // Adjust indices to account for the non-draggable first item
    fromIndex += 1;
    toIndex += 1;

    let newPages = [...brandStorePages];
    const itemMoving = newPages[fromIndex];
    newPages.splice(fromIndex, 1);
    newPages.splice(toIndex, 0, itemMoving);

    dispatch(reorderPages(newPages));
  };

  return (
    <div className={`rounded ${adSectionStyles.section}`} style={{ padding: '16px' }}>
      <PageList
        brandStoreOrder={brandStoreOrder}
        editingPageId={editingPageId}
        onPageSave={onPageSave}
        onPageEdit={onPageEdit}
        onPageRemove={onPageRemove}
        moveCard={moveCard}
        readOnly={readOnly}
        brandStoreErrors={brandStoreErrors}
      />
      {!readOnly && (
        <>
          <hr className={styles.blue_horizontal_rule}></hr>
          <AddPageButtonPanel onAddPage={onAddPage} readOnly={readOnly} />
        </>
      )}
      <VetoModal
        show={showVetoRemoveModal}
        handleClose={handleModalClose}
        confirmAction={confirmPageRemove}
        heading='Confirm Page Delete'
        messageParagraphs={[
          { text: 'If you delete this page, any configured content blocks on the page will also be deleted.' },
          { text: 'The page will no longer be included in the metrics tab reporting.' },
          { text: 'Are you sure you would like to continue?', bold: true },
        ]}
        confirmMessage={'Delete Page'}
        cancelMessage={'Cancel'}
      />
    </div>
  );
};

export default PagesTab;
