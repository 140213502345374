import PropTypes from "prop-types";
import React from "react";
import { Form } from "react-bootstrap";
import { DescriptionContainer, ErrorFeedback, LabelText, TextInput } from "./Common";
import Errors from './Errors';


const NumberComponent = ({
  controlId,
  label,
  description,
  placeholder = "",
  readOnly = false,
  disabled = false,
  required = false,
  value,
  onChange = () => { },
  errors = [],
  max,
  min,
}) => {
  const handleChange = e => {
    const newValue = e.target.value
    if (newValue === '') {
      return onChange(null)
    }

    const num = Number(newValue)
    onChange(num)
  }

  return <Form.Group controlId={controlId}>
    <Form.Label className="d-flex align-self-center align-items-center font-weight-bold">
      <LabelText>
        {label} {required && "*"}{" "}
      </LabelText>
    </Form.Label>
    <DescriptionContainer>
      {description}
    </DescriptionContainer>
    <TextInput
      type="number"
      readOnly={readOnly}
      disabled={disabled}
      placeholder={placeholder}
      value={value}
      isInvalid={errors.length > 0}
      onChange={handleChange}
      required={required}
      max={max}
      min={min}
    />
    <ErrorFeedback type="invalid" isInvalid={errors.length > 0}>
      <Errors errors={errors} />
    </ErrorFeedback>
  </Form.Group>
  }

  NumberComponent.propTypes = {
    controlId: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    description: PropTypes.string,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    errors: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func,
    min: PropTypes.number,
    max: PropTypes.number,
  };

  export default NumberComponent;
