import React from 'react'
import {Image} from "react-bootstrap";

type OrganisationLogoProps = {
    src: string,
}

const OrganisationLogo = ({src}: OrganisationLogoProps) =>
    <Image
        className="w-100"
        style={{maxWidth: '8rem', maxHeight: '3rem', objectFit: 'scale-down'}}
        src={src}
    />

export default OrganisationLogo