import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

const Suppliers = ({ suppliers = [], value = {}, onChange = () => {} }) => {
  const [checkedState, setCheckedState] = useState(new Array(suppliers.length).fill(false));
  const [allSelected, setAllSelected] = useState(false);
  const [selectedType, setSelectedType] = useState('whitelist');
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);

  const handleOnChange = (position, val) => {
    let suppliersList = [];
    setAllSelected(false);
    if (selectedType === 'all') {
      setSelectedType('whitelist');
    }

    const updatedCheckedState = checkedState.map((item, index) => (index === position ? !item : item));

    if (val) {
      setSelectedSuppliers([...selectedSuppliers, { organisation_id: suppliers[position].id }]);

      suppliersList = [...selectedSuppliers, { organisation_id: suppliers[position].id }];
    } else {
      let o = selectedSuppliers.filter((supplier, i) => supplier.organisation_id !== suppliers[position].id);
      suppliersList = o;
      setSelectedSuppliers(o);
    }

    setCheckedState(updatedCheckedState);

    var obj = {
      ...value,
      ...{
        type: selectedType,
        data: suppliersList,
      },
    };
    onChange(obj);
  };

  useEffect(() => {
    if (value?.type === 'all') {
      setCheckedState(new Array(suppliers.length).fill(true));
      setAllSelected(true);
      setSelectedType('all');
    } else if (value?.type === 'whitelist' || value?.type === 'blacklist') {
      //setting selected suppliers
      const slist = value.data.map((supplier) => supplier.organisation_id);
      const listToUpdate = suppliers.map((supplier) => slist.includes(supplier.id));
      setCheckedState(listToUpdate);
      setSelectedType(value.type);
      setAllSelected(false);
      setSelectedSuppliers(value.data);
    }
  }, [value]);

  const handleSelectAllOnChange = (val) => {
    var obj = {
      ...value,
      ...{ type: val ? 'all' : 'whitelist' },
    };
    if (val) {
      setSelectedType('all');
      setAllSelected(true);
      setCheckedState(new Array(suppliers.length).fill(true));
      setSelectedSuppliers([]);
    } else {
      setSelectedType('whitelist');
      setAllSelected(false);
      setCheckedState(new Array(suppliers.length).fill(false));
      setSelectedSuppliers([]);
    }
    onChange(obj);
  };

  return (
    <article>
      <div className='mb-2'>
        <p className='mb-0'>
          <b>Suppliers</b>
        </p>
        <small className='mb-2'>Choose Suppliers to include / exclude from this space.</small>
      </div>
      <div className='border rounded border-secondary p-3'>
        <span style={{ fontSize: '0.80rem' }}>
          <b className='text-sm'>
            <a
              onClick={(e) => {
                setSelectedType('whitelist');
                var obj = {
                  ...value,
                  ...{
                    type: 'whitelist',
                    data: selectedSuppliers,
                  },
                };
                onChange(obj);
              }}
            >
              <span
                className='mr-2'
                style={{
                  cursor: 'pointer',
                  borderBottom: selectedType === 'whitelist' || selectedType === 'all' ? '2px solid purple' : 'none',
                }}
              >
                Selected Only
              </span>
            </a>
            <a
              onClick={(e) => {
                setSelectedType('blacklist');
                var obj = {
                  ...value,
                  ...{
                    type: 'blacklist',
                    data: selectedSuppliers,
                  },
                };
                onChange(obj);
              }}
            >
              <span
                style={{
                  cursor: 'pointer',
                  borderBottom: selectedType === 'blacklist' ? '2px solid purple' : 'none',
                }}
              >
                Exclude Selected
              </span>
            </a>
          </b>
        </span>
        <hr />
        {suppliers.length > 0 ? (
          <Form.Group>
            {(selectedType === 'whitelist' || selectedType === 'all') && (
              <>
                <Form.Check
                  type='checkbox'
                  checked={allSelected}
                  onChange={(e) => handleSelectAllOnChange(e.target.checked)}
                  label='Select All'
                  id={suppliers}
                  htmlFor={suppliers.id}
                />
                <hr />
              </>
            )}
            {suppliers.map((supplier, i) => {
              return (
                <Form.Check
                  type='checkbox'
                  label={supplier.name}
                  checked={checkedState[i]}
                  key={supplier.id}
                  id={supplier.id}
                  onChange={(e) => handleOnChange(i, e.target.checked)}
                  className='mb-2'
                  htmlFor={supplier.id}
                />
              );
            })}
          </Form.Group>
        ) : (
          <span>There are no suppliers currently available.</span>
        )}
      </div>
    </article>
  );
};

Suppliers.propTypes = {};

export default Suppliers;
