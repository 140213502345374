import { connect } from "react-redux";
import { selectSession, selectUserOrganisation } from "../../session/sessionSlice";
import CustomAudiences from "../components/CustomAudiences";
import { googleRemarketingAudienceUpdate, selectReadPermissions } from "../organisationSettingsSlice";


const mapStateToProps = state => ({
        title: 'Remarketing Audiences',
        value: selectReadPermissions(state)?.google_remarketing_audiences,
        audiences: selectSession(state).google_remarketing_audiences,
        preselectedValues: selectUserOrganisation(state)?.settings?.ad_settings?.google_remarketing_audiences,
})

const mapDispatchToProps = dispatch => ({
    onChange: custom_audiences => dispatch(googleRemarketingAudienceUpdate(custom_audiences))
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomAudiences)
