import React from 'react'
import { Form } from 'react-bootstrap'


const Urls = ({ urls = [], onChange = () => {}}) => {
    const handleChange = (url, checked) => {
        let urlIndex = urls.findIndex(ur => ur.id === url.id)
        let newValue = urls.slice()
        newValue[urlIndex] = {...url, is_active: checked}
        onChange(newValue)
    }

    return <article>
        <div className="mb-2">
            <p className="mb-0"><b>Matching</b></p>
            <small className="mb-2">Select the URL of the page this space should appear on.</small>
        </div>
        <div className="border rounded border-secondary p-3">
            <div>
                <small><b>URLs</b></small>
                <hr />
                {urls.length > 0 ?
                <Form.Group>
                    {urls.map(url =>
                        <Form.Check
                            key={url.id}
                            type="checkbox"
                            label={url.match_pattern}
                            id={url.id}
                            className="mb-2"
                            checked={url.is_active}
                            onChange={e => handleChange(url, e.target.checked)}
                            htmlFor={url.id}
                        />
                    )}
                </Form.Group>
                :
                <span>No URLs have been created. <a href="mailto:support@zitcha.com">Please contact support.</a></span>}
            </div>
        </div>
    </article>
}

Urls.propTypes = {

}

export default Urls
