import {createAsyncThunk} from '@reduxjs/toolkit';
import {
  approveBrandStore,
  brandStoreBlocks,
  brandStorePreview,
  createBrandStoreConfigurationApi,
  getSavedBrandStoreOrder,
  provideRetailerFeedbackForBrandStore,
  saveBrandStore,
  submitBrandStore,
  updateBrandStoreConfigurationApi,
} from '../BrandStoreApi';
import {
  tranformOrderFromResponse,
  transformConfigureFormForRequest,
  transformOrderForRequest,
} from './BrandStoreStateHelpers';
import axios from '../../../axios/axiosMainInstance';
import {getOrganisationRelation} from '../../../lib/api';
import {getSingleWallet} from '../../../lib/api/wallets';

export const loadBrandStoreOrder = createAsyncThunk(
  'brand_stores/loadBrandStoreOrder',
  async ({ orderId }, { rejectWithValue }) => {
    try {
      const orderResponse = await getSavedBrandStoreOrder(orderId);
      const fetchedOrder = orderResponse.data.data;

      const [blockResponse, orgResponse, walletResponse, previewResponse] = await Promise.all([
        brandStoreBlocks(),
        getOrganisationRelation(fetchedOrder.owner_id, fetchedOrder.supplier_id),
        getSingleWallet(fetchedOrder.wallet.id),
        brandStorePreview(transformOrderForRequest(fetchedOrder)),
      ]);

      const blockTypes = blockResponse.data;
      const transformedOrder = tranformOrderFromResponse(fetchedOrder, blockTypes);
      const orgPermissions = orgResponse.data.brand.read_permissions;
      const wallet = walletResponse.data.data;
      const preview = previewResponse.data.data;

      return {
        order: transformedOrder,
        blockTypes: blockTypes,
        orgPermissions: orgPermissions,
        orderWallet: wallet,
        orderPreview: preview,
      };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const saveBrandStoreOrder = createAsyncThunk(
  'brand_stores/saveOrder',
  async ({ brandStoreOrder, organisationId }, { rejectWithValue }) => {
    try {
      const transformedOrder = transformOrderForRequest(brandStoreOrder);
      const response = await saveBrandStore(brandStoreOrder.id, transformedOrder);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const submitForReview = createAsyncThunk(
  'brand_stores/submitOrderForReview',
  async ({ brandStoreOrder, organisationId }, { rejectWithValue }) => {
    try {
      const transformedOrder = transformOrderForRequest(brandStoreOrder);
      const response = await submitBrandStore(brandStoreOrder.id, transformedOrder);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const onApproval = createAsyncThunk(
  'brand_stores/onApproval',
  async ({ brandStoreOrder, organisationId }, { rejectWithValue }) => {
    try {
      const transformedOrder = transformOrderForRequest(brandStoreOrder);
      const response = await approveBrandStore(brandStoreOrder.id, transformedOrder);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const onProvideFeedback = createAsyncThunk(
  'brand_stores/onProvideFeedback',
  async ({ brandStoreOrderId, feedback }, { rejectWithValue }) => {
    try {
      const response = await provideRetailerFeedbackForBrandStore(brandStoreOrderId, feedback);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const submitBrandStoreInvitation = createAsyncThunk(
  'brand_stores/submitBrandStoreInvitation',
  async (invitation, { rejectWithValue }) => {
    try {
      const response = await axios.post(`brand-stores/organisations/${invitation.retailer_id}`, invitation);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getPreview = createAsyncThunk('brand_stores/preview', async (order, { rejectWithValue }) => {
  //if there are no pages, do no make request, return empty preview state
  if (!order.pages?.length) return { data: {} };
  try {
    const transformedOrder = transformOrderForRequest(order);
    const response = await brandStorePreview(transformedOrder);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const loadBrandStoreConfigureForm = createAsyncThunk(
  'brand_stores/loadBrandStoreConfigurationForm',
  async ({ orderId }, { rejectWithValue }) => {
    try {
      const orderResponse = await getSavedBrandStoreOrder(orderId);
      const fetchedOrder = orderResponse.data.data;

      return { order: fetchedOrder };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const saveBrandStoreConfiguration = createAsyncThunk(
  'brand_stores/saveBrandStoreConfiguration',
  async (formData, { rejectWithValue, getState }) => {
    try {
      const transformedConfigureForm = transformConfigureFormForRequest(formData);
      const response = await createBrandStoreConfigurationApi(formData.owner_id, transformedConfigureForm);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateBrandStoreConfiguration = createAsyncThunk(
  'brand_stores/updateBrandStoreConfiguration',
  async (formData, { rejectWithValue }) => {
    try {
      const transformedConfigureForm = transformConfigureFormForRequest(formData);
      const response = await updateBrandStoreConfigurationApi(formData.id, transformedConfigureForm);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
