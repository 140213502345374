import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import PropTypes from 'prop-types';

const TIME_UNIT_LABELS = {
  years: 'Yearly',
  months: 'Monthly',
  weeks: 'Weekly',
  days: 'Daily',
};

const TimeUnitRadioGroup = ({ selectedValue, disabled, onChange }) => (
  <Form.Group controlId='time_unit' style={{ marginTop: '0.25rem' }}>
    {Object.entries(TIME_UNIT_LABELS).map(([value, label]) => (
      <Form.Check
        key={value}
        id={value}
        inline
        type='radio'
        label={label}
        name='time_unit'
        value={value}
        onChange={onChange}
        checked={selectedValue === value}
        disabled={disabled}
      />
    ))}
  </Form.Group>
);

TimeUnitRadioGroup.propTypes = {
  selectedValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

const CostInput = ({ value, onChange, onBlur, errors, selectedLabel, disabled }) => {
  const numberRegex = /^\d+\.?\d*$/;
  const invalidNumber = value && (!numberRegex.test(value) || value < 0);
  return (
    <Form.Group controlId='cost_per_unit'>
      <Form.Label>{`${selectedLabel} cost`}</Form.Label>
      <InputGroup className='mb-3'>
        <InputGroup.Text>$</InputGroup.Text>
        <Form.Control
          className={`${errors?.length > 0 || invalidNumber ? 'is-invalid' : ''}`}
          type='number'
          aria-label={`${selectedLabel} cost`}
          value={value || ''}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
        />
      </InputGroup>
      <Form.Text className='text-muted'>
        {invalidNumber && <div className='text-danger'>Enter a positive dollar amount</div>}
      </Form.Text>
      <Form.Text className='text-muted'>
        {errors?.length > 0 && <div className='text-danger'>{errors[0]}</div>}
      </Form.Text>
    </Form.Group>
  );
};

CostInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string),
  selectedLabel: PropTypes.string,
  disabled: PropTypes.bool,
};

const UnitCountInput = ({ value, onChange, errors, selectedLabel, disabled }) => {
  const integerRegex = /^\d+$/;
  const invalidNumber = value && (!integerRegex.test(value) || value < 1);
  return (
    <Form.Group controlId='time_unit'>
      <Form.Label>{`Number of ${selectedLabel}`}</Form.Label>
      <InputGroup className='mb-3'>
        <Form.Control
          className={`${errors?.length > 0 || invalidNumber ? 'is-invalid' : ''}`}
          type='number'
          aria-label={`Number of ${selectedLabel}`}
          value={value || ''}
          onChange={onChange}
          disabled={disabled}
        />
      </InputGroup>
      <Form.Text className='text-muted'>
        {errors?.length > 0 && <div className='text-danger'>{errors[0]}</div>}
      </Form.Text>
      <Form.Text className='text-muted'>
        {invalidNumber && <div className='text-danger'>Enter a positive whole number</div>}
      </Form.Text>
    </Form.Group>
  );
};

UnitCountInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string),
  selectedLabel: PropTypes.string,
  disabled: PropTypes.bool,
};

const BrandStoreCost = ({ formData, setFormData, errors }) => {
  const handleTimeUnitChange = (e) => {
    setFormData({ ...formData, time_unit: e.target.value });
  };

  const handleCostChange = (e) => {
    setFormData({ ...formData, cost_per_unit: e.target.value });
  };

  const handleCostBlur = (e) => {
    const value = e.target.value;
    const truncatedValue = parseFloat(value).toFixed(2);
    if (Number.parseFloat(truncatedValue)) setFormData({ ...formData, cost_per_unit: truncatedValue });
  };

  const handleUnitCountChange = (e) => {
    setFormData({ ...formData, unit_count: e.target.value });
  };

  const selectedLabel = TIME_UNIT_LABELS[formData.time_unit];

  return (
    <Form.Group controlId='brandStoreCost'>
      <Form.Label className='font-weight-bold mb-1'>Brand Store Cost</Form.Label>
      <TimeUnitRadioGroup selectedValue={formData.time_unit} disabled={formData?.is_already_approved} onChange={handleTimeUnitChange} />
      <CostInput
        value={formData.cost_per_unit}
        onChange={handleCostChange}
        onBlur={handleCostBlur}
        errors={errors?.cost_per_unit}
        selectedLabel={selectedLabel}
        disabled={formData?.is_already_approved}
      />
      <UnitCountInput
        value={formData.unit_count}
        onChange={handleUnitCountChange}
        errors={errors?.unit_count}
        selectedLabel={formData.time_unit}
        disabled={formData?.is_already_approved}
      />
    </Form.Group>
  );
};

BrandStoreCost.propTypes = {
  formData: PropTypes.shape({
    time_unit: PropTypes.string,
    cost_per_unit: PropTypes.string,
    unit_count: PropTypes.string,
    is_already_approved: PropTypes.bool,
  }).isRequired,
  setFormData: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    cost_per_unit: PropTypes.arrayOf(PropTypes.string),
    unit_count: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default BrandStoreCost;
