import { refreshUser } from './api'
import Cookies from 'js-cookie';

const AUTH_TOKEN_VALIDITY_HOURS = 1;

export const setAuthTokenCookie = token => {
    const tokenValidityHours = new Date(new Date().getTime() + 60 * 60 * 1000 * AUTH_TOKEN_VALIDITY_HOURS);
    Cookies.set('authToken', token, {
        expires: tokenValidityHours,
        secure: process.env.NODE_ENV === 'production',
    });
}

const auth = {
    loggedIn() {
        return sessionStorage.getItem('auth') !== null
    },

    clearSession() {
        sessionStorage.removeItem('auth')
    },

    updateSession(data) {
        sessionStorage.setItem('auth', JSON.stringify(data))
    },

    session() {
        return JSON.parse(sessionStorage.getItem('auth'))
    },

    refresh() {
        refreshUser()
            .then(resp => resp.data.data)
            .then(auth_data => {
                auth_data.token = this.session().token
                this.updateSession(auth_data)
            })
    },
}

export default auth
