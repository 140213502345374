import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React from 'react'
import { Figure } from 'react-bootstrap'
import styles from './product_square.module.scss'

const ProductSquare = ({ name, src, price, checked = false, onClick = () => { } }) => (
    <Figure className={`position-relative rounded bg-light ${styles.figure}`} onClick={onClick}>
        <span
            className={`position-absolute rounded-circle border border-secondary ${styles.check_hole}`}
        ></span>
        {checked && (
            <span
                className={`position-absolute rounded-circle bg-success text-white d-flex align-items-center ${styles.check_circle}`}
            >
                <FontAwesomeIcon icon={faCheck} size="xs" className="mx-auto" />
            </span>
        )}
        <Figure.Image className={`${styles.image} px-3 rounded d-flex mx-auto`} src={src} alt={name} />
        <Figure.Caption className={`${styles.caption} p-2 text-left`}>
            <div className="mb-2">{name}</div>
            <div className="mb-2 text-center">{price}</div>
        </Figure.Caption>
    </Figure>
)

ProductSquare.propTypes = {
    name: PropTypes.string.isRequired,
    src: PropTypes.string,
    price: PropTypes.string,
}

export default ProductSquare
