export const AdType = Object.freeze({
  FACEBOOK_DISPLAY: 'facebook_display',
  FACEBOOK_BOOSTED: 'facebook_boosted',
  FACEBOOK_ONLINE_CONVERSION_DPA: 'facebook_online_conversion_dpa',
  FACEBOOK_MANAGED_PARTNER_AD: 'facebook_managed_partner_ad',
  FACEBOOK_CAROUSEL: 'facebook_carousel',

  YOUTUBE_BUMPER: 'youtube_bumper',

  GOOGLE_RESPONSIVE_SEARCH: 'google_responsive_search',
  GOOGLE_EXPANDED_TEXT: 'google_expanded_text',
  GOOGLE_SMART_SHOPPING: 'google_smart_shopping',
  GOOGLE_SHORT_VIDEO: 'google_short_video',
  GOOGLE_PERFORMANCE_MAX: 'google_performance_max',

  NATIVE_BANNER: 'native_banner',
  NATIVE_PRODUCT: 'native_product',

  BRAND_STORE: 'brand_store',

  BROADSIGN: 'broadsign_in_store',
});

export const LIVE_EDITABLE_AD_TYPES = [AdType.NATIVE_BANNER, AdType.NATIVE_PRODUCT, AdType.BROADSIGN];
