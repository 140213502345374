import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Form } from 'react-bootstrap';
import styled from 'styled-components';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { COUNTRY_OPTIONS } from './countries';
import { META_VERTICALS } from './verticals';

const Container = styled.div`
  font-size: 14px;

  .alert svg {
    margin-right: 8px;
  }
`;

const SubmitHelpText = styled.div`
  margin-bottom: 12px;
  color: #00001e;

  svg {
    width: 14px;
    height: 14px;
  }

  span {
    margin-left: 6px;
    font-size: 12px;
  }
`;

export const statusMap = {
  'not-started': 'Not Started',
  new: 'Not Started',
  submitted: 'In Progress',
  failed: 'Unsuccessful',
  completed: 'Completed',
};

export const OnboardingForm = ({ errors, onSubmit, previousFormFields, readOnly, supplierId }) => {
  const [formData, setFormData] = useState(previousFormFields);
  useEffect(() => {
    setFormData(previousFormFields);
  }, [previousFormFields, supplierId]);

  const handleChange = (fieldName) => (event) => {
    const value = event.target.value;
    setFormData((prev) => ({ ...prev, [fieldName]: value }));
  };

  const handleChangeCountriesTargeted = (items) => {
    setFormData((prev) => ({ ...prev, countries_targeting: items.map((item) => item.value) }));
  };

  const handleChangeSupplierRegistrationCountry = (item) => {
    setFormData((prev) => ({ ...prev, registration_country: item.value }));
  };

  const handleChangeVertical = (item) => {
    setFormData((prev) => ({ ...prev, vertical: item.value }));
  };

  const handleClickSubmit = () => {
    onSubmit(formData);
  };

  return (
    <Container>
      <Form>
        <Form.Group className='mb-3' controlId='formSupplierWebsite'>
          <Form.Label className='font-weight-bold mb-1'>Website</Form.Label>
          <div className='mb-1'>
            This should be the location on the Retailer website where all Supplier products are displayed.
          </div>
          <Form.Control
            className={`mt-2 ${errors?.website?.length > 0 ? 'is-invalid' : ''}`}
            type='text'
            disabled={readOnly}
            placeholder='Example: https://www.retailer.com/supplier-products'
            value={formData.website || ''}
            onChange={handleChange('website')}
          />
          <Form.Text className='text-muted'>
            {errors?.website?.length > 0 && <div className='text-danger'>{errors?.website[0]}</div>}
          </Form.Text>
        </Form.Group>

        <Form.Group className='mb-3' controlId='formCountriesTargeted'>
          <Form.Label className='font-weight-bold mb-1'>Countries Targeted</Form.Label>
          <Select
            aria-invalid={errors?.countries_targeting?.length > 0}
            isMulti
            onChange={handleChangeCountriesTargeted}
            options={COUNTRY_OPTIONS}
            placeholder='Example: Australia, New Zealand, United Kingdom, United States of America'
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                ...(state.isDisabled ? { backgroundColor: '#eaecf4' } : {}),
              }),
            }}
            isDisabled={readOnly}
            value={COUNTRY_OPTIONS.filter((option) => {
              return (formData.countries_targeting || []).includes(option.value);
            })}
          />
          <Form.Text className='text-muted'>
            {errors?.countries_targeting?.length > 0 && (
              <div className='text-danger'>{errors?.countries_targeting[0]}</div>
            )}
          </Form.Text>
        </Form.Group>

        <Form.Group className='mb-3' controlId='formSupplierRegistrationCountry'>
          <Form.Label className='font-weight-bold mb-1'>Supplier Registration Country</Form.Label>
          <Select
            aria-invalid={errors?.registration_country?.length > 0}
            onChange={handleChangeSupplierRegistrationCountry}
            options={COUNTRY_OPTIONS}
            placeholder='Example: Australia'
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                ...(state.isDisabled ? { backgroundColor: '#eaecf4' } : {}),
              }),
            }}
            isDisabled={readOnly}
            value={COUNTRY_OPTIONS.find((option) => {
              return formData?.registration_country === option.value;
            })}
          />
          <Form.Text className='text-muted'>
            {errors?.registration_country?.length > 0 && (
              <div className='text-danger'>{errors?.registration_country[0]}</div>
            )}
          </Form.Text>
        </Form.Group>

        <Form.Group className='mb-3' controlId='formVertical'>
          <Form.Label className='font-weight-bold mb-1'>Vertical</Form.Label>
          <Select
            aria-invalid={errors?.vertical?.length > 0}
            onChange={handleChangeVertical}
            options={META_VERTICALS}
            placeholder='Example: Technology'
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                ...(state.isDisabled ? { backgroundColor: '#eaecf4' } : {}),
              }),
            }}
            isDisabled={readOnly}
            value={META_VERTICALS.find((option) => {
              return formData?.vertical === option.value;
            })}
          />
          <Form.Text className='text-muted'>
            {errors?.vertical?.length > 0 && <div className='text-danger'>{errors?.vertical[0]}</div>}
          </Form.Text>
        </Form.Group>

        <SubmitHelpText>
          <FontAwesomeIcon icon={faInfoCircle} />
          <span>
            Once a form is submitted and if the onboarding is successful, the form data cannot be modified again.
          </span>
        </SubmitHelpText>

        <Button disabled={readOnly} onClick={handleClickSubmit} variant='primary'>
          Submit
        </Button>
      </Form>
    </Container>
  );
};

OnboardingForm.propTypes = {
  errors: PropTypes.shape({
    website: PropTypes.arrayOf(PropTypes.string),
    countries_targeting: PropTypes.arrayOf(PropTypes.string),
    registration_country: PropTypes.arrayOf(PropTypes.string),
    vertical: PropTypes.arrayOf(PropTypes.string),
  }),
  onSubmit: PropTypes.func.isRequired,
  previousFormFields: PropTypes.shape({
    website: PropTypes.string,
    countries_targeting: PropTypes.string,
    registration_country: PropTypes.string,
    vertical: PropTypes.string,
  }),
  readOnly: PropTypes.bool,
  supplierId: PropTypes.string,
};
