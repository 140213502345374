import { Pagination } from 'react-bootstrap';
import { useMemo, forwardRef } from 'react';

/**
 * Pagination component for orders.
 * React function component
 * @param {number} totalItems - number of all orders
 * @param {number} itemsPerPage - number of orders displayed per page
 * @param {number } currentPage - the current page being viewed
 * @param {boolean} disabled - should the pagination be disabled
 * @param onClick - click handler
 * @param ref - parent container reference
 */

const OrderPagination = forwardRef(({ totalItems, itemsPerPage, currentPage, onClick = () => {}, disabled = false },ref) => {
  let items = [];
  const scrollToTop = () => {
    ref?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  };
  const pages = useMemo(() => {
    return totalItems > 0 && itemsPerPage > 0 ? Math.ceil(totalItems / itemsPerPage) : 1;
  }, [totalItems,itemsPerPage]);
  items.push(
    <Pagination.Prev
      key={`Pagination.Prev of page:${currentPage}`}
      disabled={currentPage === 1 || disabled}
      onClick={() => {
        onClick('prev');
        scrollToTop()
      }}
    />
  );
  items.push(
    <Pagination.Item key={`Pagination.Item:${currentPage}`} disabled={true}>
      Page {currentPage} of {pages}
    </Pagination.Item>
  );
  items.push(
    <Pagination.Next
      key={`Pagination.Next of page:${currentPage}`}
      disabled={currentPage === pages || disabled}
      onClick={() => {
        onClick('next');
        scrollToTop()
      }}
    />
  );

  return <Pagination className='d-flex justify-content-center'>{items}</Pagination>;
});

export default OrderPagination;
