import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { maxBy, minBy, uniq } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import {
  approveOrder as approveOrderRequest,
  archiveOrder as archiveOrderRequest,
  deleteAsset,
  deleteOrder as deleteOrderRequest,
  duplicateOrder as duplicateOrderRequest,
  getOrganisationRelation,
  listAdFormats,
  listMercatoDevices,
  organisationRetailerPackages,
  ownedPackages,
  packages,
  publishOrder as publishOrderRequest,
  rejectOrder as rejectOrderRequest,
  requestOrderReview as requestOrderReviewRequest,
  unarchiveOrder as unarchiveOrderRequest,
} from '../../lib/api';
import { ORDER_FORM_STATE, STATUS_APPROVED, STATUS_LIVE, STATUS_PENDING_APPROVAL } from '../../lib/orders';
import { orderDeleted, orderUpdated as ordersSliceOrderUpdated } from '../orders/ordersSlice';
import { selectSession, selectUser, selectUserOrganisation } from '../session/sessionSlice';
import { produce } from 'immer';
import { listOwnedSpaces } from '../../lib/api/ownedSpaces';
import { trackCreateOrder } from '../../helpers/segment';

const initialState = {
  order: {
    start_at: '',
    stop_at: '',
    status: STATUS_PENDING_APPROVAL,
    data_json: [],
    assets: [],
    audience: {
      facebook: [],
      google: [],
    },
    feed: {
      facebook: {
        group: {},
        products: [],
      },
      google: {
        group: {},
        products: [],
      },
    },
    loading_order_form_organisation: false,
  },
  available_packages: [],
  available_mercato_devices: [],
  available_web_spaces: [],
  edited_ads: [],
  live_order: null,
  status: 'idle',
  request: 'none',
  request_errors: {},
  ad_formats: [],
  permissions: {},
  settings: {},
  owner_organisation: {},
  brand_organisation: {},
  read_only: true,
  safe_quit: true,
  errors: {},
};

export const destroyAsset = createAsyncThunk('order_form/deleteAsset', async (arg, { getState }) => {
  await deleteAsset(arg);
});

export const approveOrder = createAsyncThunk(
  'order_form/approveOrder',
  async (arg, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await approveOrderRequest(selectOrder(getState()).id, hasLiveOrderBeingViewed(getState()));
      dispatch(orderUpdated(response.data.data));
      dispatch(ordersSliceOrderUpdated(response.data.data));
      return response.data.data;
    } catch (err) {
      if (!err.response?.data) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const rejectOrder = createAsyncThunk(
  'order_form/rejectOrder',
  async (arg, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await rejectOrderRequest(selectOrder(getState()).id, arg);
      dispatch(orderUpdated(response.data.data));
      dispatch(ordersSliceOrderUpdated(response.data.data));
      return response.data.data;
    } catch (err) {
      if (!err.response?.data) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const publishOrder = createAsyncThunk(
  'order_form/publishOrder',
  async (arg, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await publishOrderRequest(selectOrder(getState()).id);
      dispatch(orderUpdated(response.data.data));
      dispatch(ordersSliceOrderUpdated(response.data.data));
      return response.data.data;
    } catch (err) {
      if (!err.response?.data) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteOrder = createAsyncThunk(
  'order_form/deleteOrder',
  async (arg, { getState, dispatch, rejectWithValue }) => {
    try {
      const orderId = selectOrder(getState()).id;
      const response = await deleteOrderRequest(orderId);
      dispatch(orderDeleted(orderId));
      return response.data.data;
    } catch (err) {
      if (!err.response?.data) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const requestOrderReview = createAsyncThunk(
  'order_form/requestOrderReview',
  async (arg, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await requestOrderReviewRequest(selectOrder(getState()).id, arg.requestee_id, arg.message);
      dispatch(orderUpdated(response.data.data));
      dispatch(ordersSliceOrderUpdated(response.data.data));
      return response.data.data;
    } catch (err) {
      if (!err.response?.data) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const duplicateOrder = createAsyncThunk(
  'order_form/duplicateOrder',
  async (arg, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await duplicateOrderRequest(selectOrder(getState()).id, arg);
      dispatch(orderUpdated(response.data.data));
      dispatch(ordersSliceOrderUpdated(response.data.data));
      return response.data.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      throw rejectWithValue(err.response);
    }
  }
);

export const archiveOrder = createAsyncThunk(
  'order_form/archiveOrder',
  async (arg, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await archiveOrderRequest(selectOrder(getState()).id);
      dispatch(orderUpdated(response.data.data));
      dispatch(ordersSliceOrderUpdated(response.data.data));
      return response.data.data;
    } catch (err) {
      if (!err.response?.data) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const unarchiveOrder = createAsyncThunk(
  'order_form/unarchiveOrder',
  async (arg, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await unarchiveOrderRequest(selectOrder(getState()).id);
      dispatch(orderUpdated(response.data.data));
      dispatch(ordersSliceOrderUpdated(response.data.data));
      return response.data.data;
    } catch (err) {
      if (!err.response?.data) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const orderPackages = createAsyncThunk(
  'order_form/orderPackages',
  async (arg, { getState, dispatch, rejectWithValue }) => {
    try {
      let response;
      const userOrganisation = selectUserOrganisation(getState());

      if (userOrganisation.id === selectOrder(getState()).owner_id) {
        response = await ownedPackages(selectOrder(getState()).supplier_id);
      } else {
        response = await organisationRetailerPackages(selectOrder(getState()).owner_id);
      }

      dispatch(availablePackagesUpdate(response.data.data));
      return response.data.data;
    } catch (err) {
      if (!err.response?.data) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const availableAdFormats = createAsyncThunk(
  'order_form/availableAdFormats',
  async (arg, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await listAdFormats(selectOrder(getState()).owner_id, selectOrder(getState()).supplier_id);

      dispatch(availableAdFormatsUpdate(response.data.data));
      return response.data.data;
    } catch (err) {
      if (!err.response?.data) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const availablePackages = createAsyncThunk(
  'order_form/availablePackages',
  async (arg, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await packages(selectOrder(getState()).owner_id, selectOrder(getState()).supplier_id);

      dispatch(availableAdFormatsUpdate(response.data.data));
      return response.data.data;
    } catch (err) {
      if (!err.response?.data) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const availableMercatoDevices = createAsyncThunk(
  'order_form/availableMercatoDevices',
  async (arg, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await listMercatoDevices(selectOrder(getState()).owner_id, selectOrder(getState()).supplier_id);

      dispatch(availableMercatoDevicesUpdate(response.data.data));
      return response.data.data;
    } catch (err) {
      if (!err.response?.data) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const availableWebSpaces = createAsyncThunk(
  'order_form/availableWebSpaces',
  async (arg, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await listOwnedSpaces(selectOrder(getState()).owner_id, selectOrder(getState()).supplier_id);

      dispatch(availableWebSpacesUpdate(response.data.data));
      return response.data.data;
    } catch (err) {
      if (!err.response?.data) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateOrderFormOrganisations = createAsyncThunk(
  'order_form/orderFormOrganisations',
  async (arg, { getState, dispatch, rejectWithValue }) => {
    dispatch(setOrderFormOrganisationLoading(true)); // Start the loading
    try {
      const response = await getOrganisationRelation(
        selectOrder(getState()).owner_id,
        selectOrder(getState()).supplier_id
      );
      dispatch(organisationsUpdated(response.data));
      dispatch(permissionsUpdate(response.data.retailer.read_permissions));
      dispatch(settingsUpdate(response.data.retailer.settings));
      dispatch(setOrderFormOrganisationLoading(false)); // End the loading upon success
      return response.data;
    } catch (err) {
      dispatch(setOrderFormOrganisationLoading(false)); // End the loading upon an error
      if (!err.response?.data) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const orderFormSlice = createSlice({
  name: 'order_form',
  initialState,

  reducers: {
    setOrderFormOrganisationLoading: (state, action) => {
      state.order.loading_order_form_organisation = action.payload;
    },
    orderUpdated(state, action) {
      state.order = action.payload;
    },
    switchLiveOrderToEdited(state, action) {
      if ([STATUS_LIVE, STATUS_APPROVED].includes(state.order.status)) {
        state.live_order = state.order;

        if (state.order.edited_snapshot) {
          state.order = state.order.edited_snapshot;
        } else {
          state.order = produce(state.order, (order) => {
            order.status = STATUS_PENDING_APPROVAL;
          });
        }
      }
    },
    switchEditedToLiveOrder(state, action) {
      if (state.live_order) {
        state.order = state.live_order;
        state.live_order = null;
      }
    },
    clearLiveOrder(state, action) {
      state.live_order = null;
    },
    organisationsUpdated(state, action) {
      state.owner_organisation = action.payload.retailer;
      state.brand_organisation = action.payload.brand;
    },
    organisationUpdated(state, action) {
      state.order.feed = {
        facebook: {
          group: {},
          products: [],
        },
        google: {
          group: {},
          products: [],
        },
      };

      // state.order.data_json.forEach(ad => {
      // Remove custom audiences
      // delete ad.audience?.facebook_custom_audiences
      // delete ad.audience?.google_remarketing_audiences
      // })
    },
    adInsert(state, action) {
      let ad = action.payload;
      ad.id = uuidv4();
      state.order.data_json.push(ad);
    },
    adUpdated(state, action) {
      const ad = action.payload;
      const index = state.order.data_json.findIndex((a) => a.id === ad.id);

      state.order.data_json[index] = ad;

      const earliestStartDate = minBy(state.order.data_json, 'start_at')?.start_at;
      const latestEndDate = maxBy(state.order.data_json, 'stop_at')?.stop_at;

      state.order.start_at = earliestStartDate;
      state.order.stop_at = latestEndDate;

      if (state.live_order && !state.read_only) {
        state.edited_ads = uniq(state.edited_ads.concat(ad.id));
      }
    },
    adDelete(state, action) {
      state.order.data_json = state.order.data_json.filter((ad) => ad.id !== action.payload);

      const newErrorKeys = Object.keys(state.errors).filter(
        (errorKey) => !errorKey.startsWith('data_json.' + action.payload)
      );
      const newErrors = {};
      newErrorKeys.forEach((errorKey) => (newErrors[errorKey] = state.errors[errorKey]));
      state.errors = newErrors;
    },
    adDeleteAll(state) {
      state.order.data_json = [];
      const newErrors = {};
      state.errors = newErrors;
    },
    adClear(state, action) {
      const adId = action.payload?.id;
      const adDataToKeep = action.payload?.data;
      if (adId) {
        const adIdx = state.order.data_json.findIndex((ad) => ad.id === adId);
        if (adIdx >= 0) {
          if (adDataToKeep) {
            state.order.data_json[adIdx] = { ...adDataToKeep };
          } else {
            state.order.data_json[adIdx] = {};
          }
        }
      }

      const newErrors = {};
      state.errors = newErrors;
    },
    budgetUpdated(state) {
      const total = state.order.data_json.map((ad) => ad.budget || 0).reduce((a, b) => a + b, 0);
      state.order.budget = total === 0 ? null : total;
    },
    assetsUpdated(state, action) {
      const ids = action.payload.map((f) => f.id);
      state.order.assets = state.order.assets.filter((asset) => !ids.includes(asset.id)).concat(action.payload);
    },
    assetDelete(state, action) {
      const { file, adTypes } = action.payload;
      state.order.assets = state.order.assets.filter((a) => a.id !== file.id);
      state.order.data_json.forEach((ad) => {
        const mediaFields = adTypes
          .find((adType) => adType.control_id === ad.type)
          .fields.filter((field) => field.type === 'asset');
        mediaFields.forEach((mediaField) => {
          ad[mediaField.id] = ad[mediaField.id].filter((assetId) => assetId !== file.id);
        });
      });
    },
    facebookAudienceUpdate(state, action) {
      state.order.audience.facebook = action.payload;
    },
    facebookAudienceDelete(state, action) {
      state.order.audience.facebook = state.order.audience.facebook.filter(
        (audience) => audience.id !== action.payload.id
      );
      state.order.data_json.forEach((ad) => {
        if (ad.audience === action.payload.id) {
          ad.audience = undefined;
        }
      });
    },
    googleAudienceUpdate(state, action) {
      state.order.audience.google = action.payload;
    },
    googleAudienceDelete(state, action) {
      state.order.audience.google = state.order.audience.google.filter((audience) => audience.id !== action.payload.id);
      state.order.data_json.forEach((ad) => {
        if (ad.audience === action.payload.id) {
          ad.audience = undefined;
        }
      });
    },
    facebookProductGroupUpdate(state, action) {
      const { products } = action.payload;
      state.order.feed.facebook = products;
    },
    googleProductGroupUpdate(state, action) {
      const { products } = action.payload;
      state.order.feed.google = products;
    },
    availableAdFormatsUpdate(state, action) {
      state.ad_formats = action.payload;
    },
    availableMercatoDevicesUpdate(state, action) {
      state.available_mercato_devices = action.payload;
    },
    availableWebSpacesUpdate(state, action) {
      state.available_web_spaces = action.payload;
    },
    orderFieldUpdated(state, action) {
      state.order[action.payload.field] = action.payload.value;
    },
    orderFormReadOnly(state) {
      state.read_only = true;
    },
    orderFormEditable(state) {
      state.read_only = false;
      state.safe_quit = false;
    },
    availablePackagesUpdate(state, action) {
      state.available_packages = action.payload;
    },
    permissionsUpdate(state, action) {
      state.permissions = action.payload;
    },
    settingsUpdate(state, action) {
      state.settings = action.payload;
    },
    errorsUpdate(state, action) {
      state.errors = action.payload;
    },
    clearError(state, action) {
      let errors = Object.assign({}, state.errors);
      delete errors[action.payload];
      state.errors = errors;
    },
    clearOrder: () => initialState,
  },

  extraReducers: (builder) => {
    builder.addCase(approveOrder.pending, (state, action) => {
      state.status = 'loading';
      state.request = 'approve';
      state.request_errors = {};
    });

    builder.addCase(approveOrder.fulfilled, (state, action) => {
      if (state.status === 'loading') {
        state.order = action.payload;
        state.status = 'success';
      }
    });

    builder.addCase(approveOrder.rejected, (state, action) => {
      if (state.status === 'loading') {
        state.status = 'failed';
        state.request = 'none';
        state.request_errors = action.payload;
      }
    });

    builder.addCase(rejectOrder.pending, (state, action) => {
      state.status = 'loading';
      state.request = 'not_approved';
      state.request_errors = {};
    });

    builder.addCase(rejectOrder.fulfilled, (state, action) => {
      if (state.status === 'loading') {
        state.order = action.payload;
        state.status = 'success';
      }
    });

    builder.addCase(rejectOrder.rejected, (state, action) => {
      if (state.status === 'loading') {
        state.status = 'failed';
        state.request = 'none';
        state.request_errors = action.payload;
      }
    });

    builder.addCase(publishOrder.pending, (state, action) => {
      state.status = 'loading';
      state.request = 'publish';
      state.request_errors = {};
    });

    builder.addCase(publishOrder.fulfilled, (state, action) => {
      if (state.status === 'loading') {
        const order = action.payload;
        state.order = order;
        state.status = 'success';
        trackCreateOrder(order.id, order.owner_id, order.supplier_id);
      }
    });

    builder.addCase(publishOrder.rejected, (state, action) => {
      if (state.status === 'loading') {
        state.status = 'failed';
        state.request = 'none';
        state.request_errors = action.payload;
      }
    });

    builder.addCase(deleteOrder.pending, (state, action) => {
      state.status = 'loading';
      state.request = 'delete';
      state.request_errors = {};
    });

    builder.addCase(deleteOrder.fulfilled, (state, action) => {
      if (state.status === 'loading') {
        state.order = action.payload;
        state.status = 'success';
      }
    });

    builder.addCase(deleteOrder.rejected, (state, action) => {
      if (state.status === 'loading') {
        state.status = 'failed';
        state.request = 'none';
        state.request_errors = action.payload;
      }
    });

    builder.addCase(requestOrderReview.pending, (state, action) => {
      state.status = 'loading';
      state.request = 'request_review';
      state.request_errors = {};
    });

    builder.addCase(requestOrderReview.fulfilled, (state, action) => {
      if (state.status === 'loading') {
        state.order = action.payload;
        state.status = 'success';
      }
    });

    builder.addCase(requestOrderReview.rejected, (state, action) => {
      if (state.status === 'loading') {
        state.status = 'failed';
        state.request = 'none';
        state.request_errors = action.payload;
      }
    });

    builder.addCase(duplicateOrder.pending, (state, action) => {
      state.status = 'loading';
      state.request = 'duplicate';
      state.request_errors = {};
    });

    builder.addCase(duplicateOrder.fulfilled, (state, action) => {
      if (state.status === 'loading') {
        state.order = action.payload;
        state.status = 'success';
      }
    });

    builder.addCase(duplicateOrder.rejected, (state, action) => {
      if (state.status === 'loading') {
        state.status = 'failed';
        state.request = 'none';
        state.request_errors = action.payload.data;
      }
    });

    builder.addCase(archiveOrder.pending, (state, action) => {
      state.status = 'loading';
      state.request = 'archive';
      state.request_errors = {};
    });

    builder.addCase(archiveOrder.fulfilled, (state, action) => {
      if (state.status === 'loading') {
        state.order = action.payload;
        state.status = 'success';
      }
    });

    builder.addCase(archiveOrder.rejected, (state, action) => {
      if (state.status === 'loading') {
        state.status = 'failed';
        state.request = 'none';
        state.request_errors = action.payload;
      }
    });

    builder.addCase(unarchiveOrder.pending, (state, action) => {
      state.status = 'loading';
      state.request = 'unarchive';
      state.request_errors = {};
    });

    builder.addCase(unarchiveOrder.fulfilled, (state, action) => {
      if (state.status === 'loading') {
        state.order = action.payload;
        state.status = 'success';
      }
    });

    builder.addCase(unarchiveOrder.rejected, (state, action) => {
      if (state.status === 'loading') {
        state.status = 'failed';
        state.request = 'none';
        state.request_errors = action.payload;
      }
    });
  },
});

export const hasLiveOrderBeingViewed = (state) => Boolean(selectLiveOrder(state));
export const hasAnEditedLiveOrder = (state) => Boolean(selectOrder(state).edited_snapshot);

export const selectOrder = (state) => state.order_form.order;
export const selectLiveOrder = (state) => state.order_form.live_order;
export const selectOrderWithOnlyEditedAds = (state) =>
  produce(selectOrder(state), (order) => {
    order.data_json = order.data_json.filter((ad) => selectEditedAds(state).includes(ad.id));
  });
export const selectAssets = (state) => selectOrder(state).assets;
export const selectOrderAds = (state) => state.order_form.order.data_json;
export const selectFacebookAudiences = (state) => state.order_form.order.audience.facebook;
export const selectGoogleAudiences = (state) => state.order_form.order.audience.google;
export const selectFacebookProductGroup = (state) => state.order_form.order.feed.facebook;
export const selectGoogleProductGroup = (state) => state.order_form.order.feed.google;
export const selectErrors = (state) => state.order_form.errors;
export const selectReadOnly = (state) => state.order_form.read_only;
export const selectStatus = (state) => state.order_form.status;
export const selectRequest = (state) => state.order_form.request;
export const selectRequestErrors = (state) => state.order_form.request_errors;
export const selectAvailablePackages = (state) => state.order_form.available_packages;
export const selectAvailableMercatoDevices = (state) => state.order_form.available_mercato_devices;
export const selectAvailableWebSpaces = (state) => state.order_form.available_web_spaces;
export const selectEditedAds = (state) => state.order_form.edited_ads;
export const selectOrderFormState = (state) => {
  const { order, read_only } = state.order_form;

  if (!order.id) {
    return ORDER_FORM_STATE.CREATE;
  }
  if (read_only) {
    return ORDER_FORM_STATE.VIEW;
  }
  if (order.id && !read_only) {
    return ORDER_FORM_STATE.EDIT;
  }

  return null;
};
export const isOwnerOrganisation = (state) => selectOwnerOrganisation(state).id === selectUserOrganisation(state).id;

export const selectOwnerOrganisation = (state) => state.order_form.owner_organisation;
export const selectSupplierOrganisation = (state) => state.order_form.brand_organisation;

export const selectOrderFormPermissions = (state) => state.order_form.permissions;
export const selectOrderFormSettings = (state) => state.order_form.settings;

export const selectOwnerProductSets = (state) => selectOrderFormPermissions(state).product_sets || [];
export const selectOwnerProductCatalogues = (state) => selectOrderFormPermissions(state).product_catalogs || [];

export const selectImageAssets = (state) => {
  const imageExt = selectSession(state).file_formats.images;
  return selectAssets(state).filter((f) => imageExt.some((ext) => f.extension === ext));
};

export const selectVideoAssets = (state) => {
  const videoExt = selectSession(state).file_formats.videos;
  return selectAssets(state).filter((f) => videoExt.some((ext) => f.extension === ext));
};

export const selectVideoAndImageAssets = (state) => {
  const imageExt = selectSession(state).file_formats.images;
  const videoExt = selectSession(state).file_formats.videos;
  const selectedVideos = selectAssets(state).filter((f) => videoExt.some((ext) => f.extension === ext));
  return selectedVideos.concat(selectAssets(state).filter((f) => imageExt.some((ext) => f.extension === ext)));
};

export const selectAvailableAdTypes = (state) => state.order_form.ad_formats;

export const selectOtherOrganisation = (state) => {
  if (selectOrder(state).owner_id === selectSession(state).user.active_organisation_id) {
    return selectSupplierOrganisation(state);
  }

  return selectOwnerOrganisation(state);
};

export const showBudget = (state) =>
  selectOrder(state).owner_id === selectUser(state).active_organisation.id ||
  Boolean(selectOrderFormPermissions(state)?.metrics?.budget);

export const showBidAmount = (state) =>
  selectOrder(state).owner_id === selectUser(state).active_organisation.id ||
  Boolean(selectOrderFormPermissions(state)?.metrics?.bid_amount);

export const { setOrderFormOrganisationLoading } = orderFormSlice.actions;
export const selectOrderFormOrganisationLoading = (state) => selectOrder(state).loading_order_form_organisation;

export const {
  orderUpdated,
  switchLiveOrderToEdited,
  switchEditedToLiveOrder,
  clearLiveOrder,
  organisationUpdated,
  organisationsUpdated,
  adInsert,
  adUpdated,
  adDelete,
  adDeleteAll,
  adClear,
  budgetUpdated,
  assetsUpdated,
  assetDelete,
  facebookAudienceUpdate,
  facebookAudienceDelete,
  googleAudienceUpdate,
  googleAudienceDelete,
  facebookProductGroupUpdate,
  googleProductGroupUpdate,
  orderFieldUpdated,
  orderFormReadOnly,
  orderFormEditable,
  availablePackagesUpdate,
  permissionsUpdate,
  settingsUpdate,
  availableAdFormatsUpdate,
  availableMercatoDevicesUpdate,
  availableWebSpacesUpdate,
  errorsUpdate,
  clearError,
  clearOrder,
} = orderFormSlice.actions;

export default orderFormSlice.reducer;
