import React, {useState} from "react";
import styles from './inventory_controls.module.scss';
import PropTypes from "prop-types";

export const InventoryDropdown = (props) => {
    const [isDirty, setIsDirty] = useState(false);
    return (
        <div className="d-flex float-right align-items-center" style={{marginLeft: props.marginLeft}}>
            {props.label && <span className={`${styles.small_label_text}`}>{props.label}</span>}
            <select
                className={`${styles.inventory_controls_input} ${isDirty ? styles.inventory_dropdown_dirty : styles.inventory_dropdown_clean}`}
                style={{maxWidth: props.maxWidth}}
                id="inlineFormCustomSelectPref"
                onChange={e => {
                    props.onChange(e);
                    setIsDirty(true);
                }}
                defaultValue={props.defaultValue}
            >
                {props.options.map(option => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        </div>
    );
}

InventoryDropdown.propTypes = {
    label: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
                .isRequired,
            label: PropTypes.string.isRequired,
        })
    ).isRequired,
    maxWidth: PropTypes.string,
    marginLeft: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    defaultValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        ),
    ]),
};