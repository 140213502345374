import React from 'react';
import { BrandPageSettingsCard } from './BrandPageSettingsCard';
import styles from '../../brand_page_properties.module.scss';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

export const PageList = ({
  brandStoreOrder,
  editingPageId,
  onPageSave,
  onPageEdit,
  onPageRemove,
  moveCard,
  readOnly,
  brandStoreErrors,
}) => {
  const brandStorePages = brandStoreOrder.pages;
  const homePage = brandStorePages[0];
  return (
    <>
      {homePage && (
        <React.Fragment key={homePage.id}>
          <BrandPageSettingsCard
            key={homePage.id}
            editingPage={editingPageId === homePage.id}
            pageId={homePage.id}
            baseUrl={brandStoreOrder.base_url}
            pageName={homePage.page_name}
            pageUrl={homePage.page_url}
            scheduleDateFrom={homePage.page_start_date}
            scheduleDateTo={homePage.page_stop_date}
            dateInheritedFromParent={homePage.is_date_inherited_from_parent}
            onPageSave={onPageSave}
            onPageEdit={() => onPageEdit(homePage.id)}
            onPageRemove={() => onPageRemove(homePage.id)}
            homepageFlag={homePage.is_home_page}
            readOnly={readOnly}
            pageErrors={brandStoreErrors?.pages?.[homePage.id]?.errorMessages}
          />
          {brandStorePages.length > 1 && <hr className={styles.blue_horizontal_rule}></hr>}
        </React.Fragment>
      )}
      <DndProvider backend={HTML5Backend}>
        {brandStorePages.slice(1).map((page, index) => (
          <React.Fragment key={page.id}>
            <BrandPageSettingsCard
              key={page.id}
              editingPage={editingPageId === page.id}
              pageId={page.id}
              baseUrl={brandStoreOrder.base_url}
              pageName={page.page_name}
              pageUrl={page.page_url}
              scheduleDateFrom={page.page_start_date}
              scheduleDateTo={page.page_stop_date}
              dateInheritedFromParent={page.is_date_inherited_from_parent}
              onPageSave={onPageSave}
              onPageEdit={() => onPageEdit(page.id)}
              onPageRemove={() => onPageRemove(page.id)}
              homepageFlag={page.is_home_page}
              readOnly={readOnly}
              pageErrors={brandStoreErrors?.pages?.[page.id]?.errorMessages}
              moveCard={moveCard}
              index={index}
            />
            {index < brandStorePages.length - 2 && <hr className={styles.blue_horizontal_rule}></hr>}
          </React.Fragment>
        ))}
      </DndProvider>
    </>
  );
};
