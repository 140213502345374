import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown, faCheck } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

const CardContainer = styled.div`
  width: 100%;
  background-color: white;
  border-radius: 8px;
  border: 1px solid #d0c6ff;
  overflow: hidden;
  max-height: ${(props) => (props.expanded ? 'none' : '56px')};
  display: flex;
  flex-direction: column;
  position: relative;
`;

const CardHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  height: 100%;
  padding: 16px;
  cursor: pointer;

  .status-indicator {
    width: 24px;
    height: 24px;
    padding: 4px;
    border-radius: 100px;
    border: 1px solid ${(props) => (props.completed ? 'white' : '#b9b9c0')};
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => (props.completed ? '#87F5C0' : 'white')};
    color: white;
  }

  .title {
    flex: 1 1 0;
    font-size: 14px;
    font-weight: bold;
    line-height: 16.1px;
    word-wrap: break-word;
  }

  .optional {
    font-size: 14px;
    font-weight: 300;
    line-height: 16.1px;
    word-wrap: break-word;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #d0c6ff;
  margin: -2px auto 15px;
`;

const CardContent = styled.div`
  max-height: ${(props) => (props.expanded ? '100%' : '0')};
  overflow: hidden;
`;

const ContentWrapper = styled.div`
  width: 100%;
  padding: 0 16px 16px;
`;

export const AssetUploadAccordionCard = ({ title, children, expanded, onToggle, optional, completed }) => {
  const key = title.replace(/ /g, '-').toLowerCase();

  const handleHeaderClick = (event) => {
    onToggle();
    event.stopPropagation();
  };

  return (
    <CardContainer id={'card-container-' + key} expanded={expanded}>
      <CardHeader id={'card-header-' + key} completed={completed} onClick={handleHeaderClick}>
        <div className='status-indicator'>{completed && <FontAwesomeIcon icon={faCheck} />}</div>
        <div className='title'>
          {title}
          {!optional && '*'}
          <span className='optional'> ({optional ? 'optional' : 'required'})</span>
        </div>
        {expanded ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
      </CardHeader>
      <ContentWrapper id={'content-wrapper-' + key} onClick={(e) => e.stopPropagation()}>
        <Divider />
        <CardContent expanded={expanded}>{children}</CardContent>
      </ContentWrapper>
    </CardContainer>
  );
};
