import PropTypes from 'prop-types'
import React from 'react'
import styles from './youtube_video.module.scss'

const YoutubeVideo = ({ src, header, link, callToAction }) => {
    return (
        <div className="p-3 bg-white">
            <div style={{ color: "#808080", fontSize: "10px", textAlign: "center", width: "100%" }}>Not actual display of the ad on the device</div>
            <div className="embed-responsive embed-responsive-16by9">
                <video
                    width="100%"
                    key={src}
                    className="embed-responsive-item"
                    controls
                    style={{ backgroundColor: 'black' }}
                >
                    <source src={src} />
                </video>
            </div>
            <div className={styles.info}>
                <div className={styles.ad_background}>
                    <div className={styles.ad_logo_container}>
                        <img src="/ad_preview/logo.png" alt="" className={styles.ad_logo} />
                    </div>
                    <div>
                        <div>{header}</div>
                        <div className={styles.ad_link_container}>
                            <span className={styles.ad_badge}>Ad</span>
                            <div className={styles.ad_link}>{link}</div>
                        </div>
                    </div>
                    <div className="ml-auto">
                        <div className={styles.ad_button}>
                            {callToAction}
                        </div>
                    </div>
                </div>
                {/* <div className={styles.info_contents}>
                    <div className={`mb-2 ${styles.primary_info}`}>
                        <h1 className={styles.primary_info_title}>{header}</h1>
                        <div className={styles.info_menu}>
                            <div className={styles.info_subtext}>
                                <div className={styles.info_subtext_text}>
                                    <div className={styles.count}>233,103</div>
                                    <div className={styles.date}>
                                        <span className={styles.dot}>•</span>
                                        <span>19 Jan 2020</span>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.separator}></div>
                            <div>
                                <div className={styles.menu}>
                                    <div className={styles.menu_renderer}>
                                        <span className={styles.button}>
                                            <span className={styles.icon}>
                                                <svg
                                                    viewBox="0 0 24 24"
                                                    preserveAspectRatio="xMidYMid meet"
                                                    focusable="false"
                                                    className="style-scope yt-icon"
                                                    style={{
                                                        pointerEvents: 'none',
                                                        display: 'inline-block',
                                                        width: '20px',
                                                        height: '20px',
                                                        color: 'rgb(144, 144, 144)',
                                                    }}
                                                >
                                                    <g className="style-scope yt-icon">
                                                        <path
                                                            d="M1 21h4V9H1v12zm22-11c0-1.1-.9-2-2-2h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L14.17 1 7.59 7.59C7.22 7.95 7 8.45 7 9v10c0 1.1.9 2 2 2h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73v-1.91l-.01-.01L23 10z"
                                                            className="style-scope yt-icon"
                                                        ></path>
                                                    </g>
                                                </svg>
                                            </span>
                                            <span className={styles.label}>101k</span>
                                        </span>

                                        <span className={styles.button}>
                                            <span className={styles.icon}>
                                                <svg
                                                    viewBox="0 0 24 24"
                                                    preserveAspectRatio="xMidYMid meet"
                                                    focusable="false"
                                                    className="style-scope yt-icon"
                                                    style={{
                                                        pointerEvents: 'none',
                                                        display: 'inline-block',
                                                        width: '20px',
                                                        height: '20px',
                                                        color: 'rgb(144, 144, 144)',
                                                    }}
                                                >
                                                    <g className="style-scope yt-icon">
                                                        <path
                                                            d="M15 3H6c-.83 0-1.54.5-1.84 1.22l-3.02 7.05c-.09.23-.14.47-.14.73v1.91l.01.01L1 14c0 1.1.9 2 2 2h6.31l-.95 4.57-.03.32c0 .41.17.79.44 1.06L9.83 23l6.59-6.59c.36-.36.58-.86.58-1.41V5c0-1.1-.9-2-2-2zm4 0v12h4V3h-4z"
                                                            className="style-scope yt-icon"
                                                        ></path>
                                                    </g>
                                                </svg>
                                            </span>
                                            <span className={styles.label}>432</span>
                                        </span>

                                        <span className={styles.button}>
                                            <span className={styles.icon}>
                                                <svg
                                                    viewBox="0 0 24 24"
                                                    preserveAspectRatio="xMidYMid meet"
                                                    focusable="false"
                                                    className="style-scope yt-icon"
                                                    style={{
                                                        pointerEvents: 'none',
                                                        display: 'inline-block',
                                                        width: '20px',
                                                        height: '20px',
                                                        color: 'rgb(144, 144, 144)',
                                                    }}
                                                >
                                                    <g className="style-scope yt-icon">
                                                        <path
                                                            d="M11.7333 8.26667V4L19.2 11.4667L11.7333 18.9333V14.56C6.4 14.56 2.66667 16.2667 0 20C1.06667 14.6667 4.26667 9.33333 11.7333 8.26667Z"
                                                            className="style-scope yt-icon"
                                                        ></path>
                                                    </g>
                                                </svg>
                                            </span>
                                            <span className={styles.label}>SHARE</span>
                                        </span>

                                        <span className={styles.button}>
                                            <span className={styles.icon}>
                                                <svg
                                                    viewBox="0 0 24 24"
                                                    preserveAspectRatio="xMidYMid meet"
                                                    focusable="false"
                                                    className="style-scope yt-icon"
                                                    style={{
                                                        pointerEvents: 'none',
                                                        display: 'inline-block',
                                                        width: '20px',
                                                        height: '20px',
                                                        color: 'rgb(144, 144, 144)',
                                                    }}
                                                >
                                                    <g className="style-scope yt-icon">
                                                        <path
                                                            d="M14 10H2v2h12v-2zm0-4H2v2h12V6zm4 8v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zM2 16h8v-2H2v2z"
                                                            className="style-scope yt-icon"
                                                        ></path>
                                                    </g>
                                                </svg>
                                            </span>
                                            <span className={styles.label}>SAVE</span>
                                        </span>

                                        <span className={`${styles.button} ${styles.last}`}>
                                            <span className={`${styles.icon} ${styles.last}`}>
                                                <svg
                                                    viewBox="0 0 24 24"
                                                    preserveAspectRatio="xMidYMid meet"
                                                    focusable="false"
                                                    className="style-scope yt-icon"
                                                    style={{
                                                        pointerEvents: 'none',
                                                        display: 'inline-block',
                                                        width: '20px',
                                                        height: '20px',
                                                        color: 'rgb(144, 144, 144)',
                                                    }}
                                                >
                                                    <g className="style-scope yt-icon">
                                                        <circle
                                                            cx="5"
                                                            cy="12"
                                                            r="2"
                                                            className="style-scope yt-icon"
                                                        ></circle>
                                                        <circle
                                                            cx="12"
                                                            cy="12"
                                                            r="2"
                                                            className="style-scope yt-icon"
                                                        ></circle>
                                                        <circle
                                                            cx="19"
                                                            cy="12"
                                                            r="2"
                                                            className="style-scope yt-icon"
                                                        ></circle>
                                                    </g>
                                                </svg>
                                            </span>
                                        </span>
                                    </div>
                                    <div className={styles.like_bar}>
                                        <div className={styles.like_container}>
                                            <div className={styles.like_capacity}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className={styles.top_row}>
                        <span className={styles.profile_img}>
                            <img src="/ad_preview/default_profile.jpg" alt="" />
                        </span>
                        <span className={styles.upload_info}>
                            <span className={styles.channel_name}>{channelName}</span>
                            <div className={styles.subscriber_count}>123k subscribers</div>
                        </span>
                        <span className={styles.subscribe_container}>
                            <span className={styles.subscribe_button}>Subscribe</span>
                        </span>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

YoutubeVideo.propTypes = {
    src: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    callToAction: PropTypes.string.isRequired,
}

export default YoutubeVideo
