import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './filesquare.module.scss'
import { truncate } from '../../../../lib/strings'

const ImgMedia = ({ src, alt }) => (
    <img
        className={`p-1 w-100 h-100 rounded ${styles.media_square}`}
        alt={alt}
        src={src}
    />
)

const VideoMedia = ({ src }) => (
    <video
        controls=""
        width="100%"
        className={`p-1 w-100 h-100 rounded ${styles.media_square}`}
    >
        <source src={src} />
    </video>
)

const FileSquare = ({ file, onDelete = () => {}, readOnly = false }) => {
    let media = <></>
    if (file.assettype === 'image') {
      media = <ImgMedia src={file.url} alt={file.name} />;
    } else if (file.assettype === 'video') {
      media = <VideoMedia src={file.url} />;
    } else {
      media = <div className={`p-1 w-100 h-100 rounded ${styles.text_square}`}>{truncate(file.name, 30)}</div>;
    }

    return (
        <div className={`m-4 rounded ${styles.container}`} >
            {media}
            {!readOnly && (
                <button
                    className={`ml-2 border-0 p-0 ${styles.delete_button}`}
                    onClick={e => {
                        e.preventDefault()
                        onDelete(file)
                    }}
                >
                    <FontAwesomeIcon icon={faTimesCircle} /> 
                </button>
            )}
        </div>
    )
}

FileSquare.propTypes = {
    file: PropTypes.shape({
        assettype: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
    }),
    readOnly: PropTypes.bool,
    onDelete: PropTypes.func,
}

export default FileSquare
