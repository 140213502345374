import { faPercentage } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import { Form, InputGroup } from 'react-bootstrap'
import { DescriptionContainer, ErrorFeedback, LabelText, TextInput } from './Common'
import Errors from './Errors'

const Percentage = ({
    controlId,
    readOnly = false,
    required = false,
    label,
    description,
    value,
    onChange = () => { },
    errors = [],
}) => {
    const handlePercentChange = (e) => {
        const val = parseFloat(e.target.value)

        if (val > 100) {
            return onChange(100)
        }

        if (val < 0) {
            return onChange(0)
        }

        onChange(val)
    }


    return (
        <Form.Group controlId={controlId}>
            <LabelText>
                {label} {required && label && "*"}{" "}
            </LabelText>
            <DescriptionContainer>
                {description}
            </DescriptionContainer>
            <InputGroup>
                <TextInput
                    type="number"
                    className="rounded-right"
                    max={100}
                    min={0}
                    required={required}
                    readOnly={readOnly}
                    disabled={readOnly}
                    value={value}
                    isInvalid={errors.length}
                    onChange={handlePercentChange}
                />
                <InputGroup.Append>
                    <InputGroup.Text>
                        <FontAwesomeIcon icon={faPercentage} />
                    </InputGroup.Text>
                </InputGroup.Append>
            </InputGroup>
            <ErrorFeedback type="invalid" isInvalid={errors.length > 0}>
                <Errors errors={errors} />
            </ErrorFeedback>
        </Form.Group>)
}

Percentage.propTypes = {
    controlId: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    value: PropTypes.number,
    label: PropTypes.string,
    description: PropTypes.string,
    errors: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func,
}

export default Percentage
