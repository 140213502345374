import PropTypes from 'prop-types'
import React from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import { formatNumber } from '../../../lib/strings'
import styles from './budget.module.scss'
import Errors from './Errors'
import { FALLBACK_CURRENCY, currencySymbol } from '../../../lib/financial'

const Budget = ({
  readOnly = false,
  required = false,
  currencies,
  label,
  value = { currency: FALLBACK_CURRENCY, budget: undefined },
  onChange = () => { },
  errors = { budget: undefined, currency: undefined },
}) => {
  // const session = useSelector(selectSession)
  // Note: It looks like value isnt passed through to this component
  // so the currency is always AUD so it might need to be looked at
  // when Mercato is implemented

  const handleChange = val => onChange({ ...value, ...val })

  const handleBudgetChange = e => {
    if (readOnly) {
      return
    }

    let val = e.target.value.replace(/[^\d]/g, '')
    if (val === '') {
      e.target.value = undefined
      handleChange({ budget: undefined })
      return
    }

    let num = Number(val)
    if (!isNaN(num)) {
      handleChange({ budget: num })
    }
  }

  return (
    <div>
      <Form.Label>
        {label} {required && '*'}
      </Form.Label>
      <InputGroup className="mb-2">
        <InputGroup.Prepend>
          <Form.Control
            as="select"
            readOnly={readOnly}
            disabled={readOnly}
            value={value.currency}
            // style={session?.theme?.components?.form_control}
            onChange={e => handleChange({ currency: e.target.value })}
            isInvalid={errors.currency}
            className={styles.currency}
            required={required}
          >
            {currencies.map(c => (
              <option key={c} value={c}>{c}</option>
            ))}
          </Form.Control>

          <InputGroup.Text
          // style={session?.theme?.components?.input_group_text}
          >
            {currencySymbol(value.currency)}
          </InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control
          type="text"
          className="rounded-right"
          // style={session?.theme?.components?.form_control}
          maxLength={6}
          min={0}
          required={required}
          readOnly={readOnly}
          disabled={readOnly}
          value={formatNumber(value.budget ?? '')}
          isInvalid={errors.budget || errors.currency}
          onChange={handleBudgetChange}
        />
        <Form.Control.Feedback type="invalid">
          <Errors errors={errors.budget} />
          <Errors errors={errors.currency} />
        </Form.Control.Feedback>
      </InputGroup>
    </div>)
}

Budget.propTypes = {
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  currencies: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  value: PropTypes.shape({
    budget: PropTypes.number,
    currency: PropTypes.string,
  }),
  label: PropTypes.string.isRequired,
  errors: PropTypes.shape({
    budget: PropTypes.arrayOf(PropTypes.string),
    currency: PropTypes.arrayOf(PropTypes.string),
  }),
  onChange: PropTypes.func,
}

export default Budget
