import moment from 'moment'
import React from 'react'
import {Col, Container, Form, OverlayTrigger, Popover, Row} from 'react-bootstrap'
import {useDispatch} from 'react-redux'
import styled from 'styled-components'
import {budgetUpdated, clearError} from '../../orderFormSlice'
import AdBudget from '../AdBudget'
import {faQuestionCircle,} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const SubHeader = styled.h6`
  font-weight: bold;
  margin-bottom: 0;
`

const PaymentModelBody = styled.p`
    font-size: 23px;
    text-transform: ${props => props.slotPricingMode === 'fixed' ? 'capitalize' : 'uppercase'};
`

const Budget = ({ controlId = '', startDate, stopDate, ad = {}, readOnly = true, showBudget = false, webSpace = {}, onChange = () => { }, errors = {}, currency, }) => {
    const dispatch = useDispatch()
    const start = moment(startDate);
    const end = moment(stopDate);
    let duration = Math.floor(moment.duration(end.diff(start)).asDays() + 1);
    if (duration < 0 || !startDate || !stopDate) {
        duration = 0;
    }
    const slotPrice = parseFloat(webSpace.slot_price || 0).toFixed(2)
    const fixedPrice = duration * slotPrice

    const controlIDPrefix = controlId.split('.').slice(0, 2).join('.')

    let spendCapErrors = []
    if (webSpace.slot_pricing_mode === 'bid' && ad.bid_amount * 10 > ad.spend_cap && errors.spend_cap?.length === 0) {
        spendCapErrors.push(`Spend cap needs to be greater than ${ad.bid_amount * 10}.`)
    }

    if (webSpace.slot_pricing_mode === 'fixed' && fixedPrice !== ad.budget && !readOnly) {
        onChange({
            budget: fixedPrice,
        });
        dispatch(budgetUpdated());
    }

    if (webSpace.slot_pricing_mode === 'fixed' && slotPrice !== ad.spend_amount && !readOnly) {
        onChange({
            spend_amount: slotPrice,
        });
    }

    return <Container>
        <Row>
            <Col>
                <SubHeader>Monetisation Model</SubHeader>
                <PaymentModelBody slotPricingMode={webSpace.slot_pricing_mode}>
                    {webSpace.slot_pricing_mode === 'fixed'
                        ? webSpace.slot_pricing_mode
                        : webSpace.bidmode}
                    {webSpace.bidmode === 'cpc' &&
                        <OverlayTrigger
                            placement="right"
                            overlay={
                                <Popover>
                                    <Popover.Content id={`${webSpace.bidmode}-info-popover`}>
                                        Spend cap needs to be &gt;=10x bid amount
                                    </Popover.Content>
                                </Popover>
                            }
                        >
                            <FontAwesomeIcon icon={faQuestionCircle} size="sm" className="pl-1" />
                        </OverlayTrigger>
                    }
                </PaymentModelBody>
            </Col>
        </Row>
        {webSpace.slot_pricing_mode === 'fixed'
            ? <Row>
                <Col className="font-weight-bold">
                    Total spend: <span className="text-primary">
                        {duration && showBudget && webSpace.slot_price
                            ? `${currency + parseFloat(fixedPrice).toFixed(2)}`
                            : currency + " \u2014"}
                    </span> {webSpace.slot_price && (
                        <span className="text-secondary">
                            ({currency + slotPrice} per day)
                        </span>
                    )}
                </Col>
            </Row>
            : <>
                <Row>
                    <Col>
                        <Form.Group controlId={controlId}>
                            <Form.Label>
                                <SubHeader>Bidding Amount</SubHeader>
                            </Form.Label>
                            <AdBudget
                                key={webSpace.id}
                                controlId={`${controlId}.bid_amount`}
                                readOnly={readOnly}
                                label=""
                                adType={ad.type}
                                value={ad.bid_amount}
                                placeholder={webSpace.slot_price}
                                onChange={budget => {
                                    if (budget !== ad.bid_amount) {
                                        dispatch(clearError(`${controlIDPrefix}.bid_amount`))
                                    }

                                    onChange({
                                        bid_amount: budget,
                                    });
                                    dispatch(budgetUpdated());
                                }}
                                errors={errors.bid_amount}
                                currency={currency}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId={controlId}>
                            <Form.Label>
                                <SubHeader>Spend Cap</SubHeader>
                            </Form.Label>
                            <AdBudget
                                key={webSpace.id}
                                controlId={`${controlId}.spend_cap`}
                                readOnly={readOnly}
                                label=""
                                adType={ad.type}
                                value={ad.spend_cap}
                                onChange={budget => {
                                    if (budget !== ad.spend_cap) {
                                        dispatch(clearError(`${controlIDPrefix}.spend_cap`))
                                    }

                                    onChange({
                                        spend_cap: budget,
                                        budget: budget,
                                    });
                                    dispatch(budgetUpdated());
                                }}
                                errors={[...(errors.spend_cap ?? []), ...(errors.budget ?? []), ...spendCapErrors]}
                                currency={currency}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </>}
    </Container>
}

export default Budget
