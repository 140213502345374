import {useMemo} from "react";
import {debounce} from "lodash";
import {DEFAULT_DEBOUNCE_TIME} from "../defaults";


const useDebounce = (request: (...any) => any, wait: number = DEFAULT_DEBOUNCE_TIME) => {
    return useMemo(() => debounce(request, wait), [request,wait]);
};


export default useDebounce;
