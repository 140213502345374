import { connect } from 'react-redux'
import { selectSession } from '../../session/sessionSlice'
import Monetisation from '../components/Monetisation'
import { ownedSpaceFormUpdated, selectOwnedSpace, selectOwnedSpaceErrors } from '../ownedSpaceFormSlice'

const mapStateToProps = state => ({
    category: selectSession(state).webcategories.find(category => category.id === selectOwnedSpace(state).owned_categories_id),
    ownedSpace: selectOwnedSpace(state),
    errors: selectOwnedSpaceErrors(state),
})


const mapDispatchToProps = dispatch => ({
    onChange: value => dispatch(ownedSpaceFormUpdated(value)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Monetisation)
