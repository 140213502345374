import React from 'react';
import styled from 'styled-components';


const Container = styled.div`
  color: #5a5c69;
  justify-content: center;
  align-items: center;
`;

const Button = styled.button`
  background: transparent;
  font-weight: bold;
  padding: 5px 10px;
  color: #5a5c69;
  border-width: 2px !important;
  border-color: #5a5c69 !important;
`;

type CallToActionProps = {
    onClick: () => void,
}

const CallToAction = ({onClick}: CallToActionProps) => {
    const handleClick = e => {
        e.preventDefault();
        onClick();
    }

    return <Container>
        <p className="d-inline">Drag and drop files or </p>
        <Button
            className="d-line border rounded"
            onClick={handleClick}
        >
            Upload
        </Button>
    </Container>
}

export default CallToAction
