import React from 'react';
import TextThrobber from "./TextThrobber";

const prompts = [
    'Preparing perfect stage for your masterpiece.',
    'Equipping you with everything you need.',
    'Bringing your ad vision to life.',
    'Laying groundwork for your marketing masterpiece.',
    'Fine-tuning engines for seamless ad creation.',
    'Ensuring pixels are in perfect place.',
    'Conjuring up mix of creativity and strategy.',
    'Distilling inspiration into powerful ad experience.',
    'Gathering elements for eye-catching advertisement.',
    'Merging your imagination with powerful tools.',
    'Gathering latest products for ad creation.',
    'Preparing selection for your advertising needs.',
    'Curating diverse range to inspire creativity.',
    'Arranging shelves with enticing products.',
    'Presenting delightful assortment for ad campaign.',
    'Gathering supply to fuel advertising ideas.',
    'Syncing inventory to showcase extensive collection.',
    'Unlocking universe of products for captivating ads.',
    'Connecting you to world of retail products.',
    'Preparing retail playground for crafting ads.',
];


const ProductsThrobber = () => <TextThrobber prompts={prompts}/>;

export default ProductsThrobber;
