import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

const StoreName = ({ formData, readOnly = false, setFormData, errors }) => {
  const handleNameChange = (e) => {
    setFormData({ ...formData, name: e.target.value });
  };

  return (
    <Form.Group className='mb-3' controlId='formStoreName'>
      <Form.Label className='font-weight-bold mb-1'>Store Name</Form.Label>
      <Form.Control
        type='text'
        placeholder=''
        readOnly={readOnly}
        value={formData.name}
        onChange={handleNameChange}
        className={errors?.name?.length > 0 ? 'is-invalid' : ''}
      />
      <Form.Text className='text-muted'>
        {errors?.name?.length > 0 && <div className='text-danger'>{errors?.name[0]}</div>}
      </Form.Text>
    </Form.Group>
  );
};

StoreName.propTypes = {
  formData: PropTypes.object,
  setFormData: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    name: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default StoreName;
