import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

const CheckHole = styled.span`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: 1px solid #bdc2c7;
`;

const CheckCircle = styled.span`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  color: #fff;
  background-color: #4FAD80;

  display: flex;
  align-items: center;
`;


type CheckProps = {
    checked: boolean,
};

const Check = ({checked = false, ...props}: CheckProps): JSX.Element => {
    if (!checked) {
        return <Unchecked {...props} />;
    }

    return <Checked  {...props} />;
};

const Unchecked = (): JSX.Element =>
    <Container>
        <CheckHole className="check-hole"></CheckHole>
    </Container>;

const Checked = (): JSX.Element =>
    <Container>
        <CheckCircle className="check-circle">
            <FontAwesomeIcon icon={faCheck} size="1x" className="mx-auto"/>
        </CheckCircle>
    </Container>;


export default Check;