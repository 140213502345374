import PropTypes from 'prop-types'
import React from 'react'
import { Form } from 'react-bootstrap'
import Errors from '../Errors'

const percentiles = [
    { value: '0-50', label: '0-50' },
    { value: '50-60', label: '50-60' },
    { value: '60-70', label: '60-70' },
    { value: '70-80', label: '70-80' },
    { value: '80-90', label: '80-90' },
    { value: '90+', label: '90+' },
]

const IncomeRange = ({
    id,
    readOnly = false,
    required = false,
    value = [],
    errors = [],
    onChange = () => {},
}) => {
    const handleChange = (percentile, checked) => {
        let nval = value.filter(v => v !== percentile)
        if (checked) {
            nval.push(percentile)
        }
        onChange(nval)
    }
    return (
        <Form.Group controlId="percentile-group">
            <Form.Label>Income Range (Percentile)</Form.Label>
            <div className="d-flex flex-wrap justify-contents-center">
                {percentiles.map(percentile => (
                    <Form.Check
                        inline
                        custom
                        id={`${id}-income-range-${percentile.value}`}
                        key={percentile.value}
                        type="checkbox"
                        style={{
                            flex: '0 1 75px',
                        }}
                        readOnly={readOnly}
                        label={percentile.label}
                        checked={value.some(v => v === percentile.value)}
                        onChange={e => handleChange(percentile.value, e.target.checked)}
                    />
                ))}
            </div>
            <Form.Control.Feedback type="invalid">
                <Errors errors={errors} />
            </Form.Control.Feedback>
        </Form.Group>
    )
}

IncomeRange.propTypes = {
    id: PropTypes.string,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    value: PropTypes.arrayOf(PropTypes.string),
    errors: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func,
}

export default IncomeRange
