import React from 'react'
import styled from 'styled-components'
import AdFormatCard from './AdFormatCard'


const FormContainer = styled.section`
    background-color: #ECECEC;
    border-radius: 7px;
    padding: 20px;
    min-height: 1250px;
`

const AdFormatForm = ({ readOnly = false, adFormats = [], value = [], onChange = () => { }, errors = [] }) => {
    const handleChange = (val, index) => {
        let newVal = value.slice()
        newVal[index] = val
        onChange(newVal)
    }

    const handleMoveUp = index => {
        if (index === 0) {
            return;
        }

        let moveTo = index - 1
        let newVal = value.slice()

        let temp = newVal[index]
        newVal[index] = newVal[moveTo]
        newVal[moveTo] = temp

        onChange(newVal)
    }

    const handleMoveDown = index => {
        if (index === value.length - 1) {
            return;
        }

        let moveTo = index + 1
        let newVal = value.slice()

        let temp = newVal[index]
        newVal[index] = newVal[moveTo]
        newVal[moveTo] = temp

        onChange(newVal)
    }

    const handleDelete = (index) => {
        const newVal = value.filter((_, idx) => index !== idx)
        onChange(newVal)
    }

    return <FormContainer>
        {value.map((val, index) =>
            <AdFormatCard
                key={val.id}
                controlId={`${val.id}`}
                readOnly={readOnly}
                label={adFormats.find(adFormat => adFormat.control_id === val.adformat_control_id)?.label}
                platform={adFormats.find(adFormat => adFormat.control_id === val.adformat_control_id)?.platform}
                value={val}
                onMoveUp={index !== 0 ? () => handleMoveUp(index) : undefined}
                onMoveDown={index !== value.length - 1 ? () => handleMoveDown(index) : undefined}
                onChange={adformat => handleChange(adformat, index)}
                onDelete={() => handleDelete(index)}
                errors={errors?.[index]}
            />
        )}
    </FormContainer>
}

export default AdFormatForm
