import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import AdDateRange from '../components/AdDateRange';

export const ScheduleTab = ({ readOnly, startReadOnly, ad, controlId, errors, handleChange, session }) => (
  <Container>
    <Row>
      <Col sm='12'>
        <AdDateRange
          controlIdStartAt={`${controlId}.start_at`}
          controlIdStopAt={`${controlId}.stop_at`}
          readOnly={readOnly}
          startReadOnly={startReadOnly}
          required
          minStartDate={session.order_start_delay_days}
          minEndDate={session.order_end_delay_days}
          value={{
            start_datetime: ad.start_at,
            stop_datetime: ad.stop_at,
          }}
          errors={{
            start_datetime: errors.start_at,
            stop_datetime: errors.stop_at,
          }}
          onChange={({ start_datetime, stop_datetime }) => {
            handleChange({
              start_at: start_datetime,
              stop_at: stop_datetime,
            });
          }}
          dateTime={ad.platform !== 'google'}
        />
      </Col>
    </Row>
  </Container>
);
