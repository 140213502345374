import PropTypes from 'prop-types'
import React from 'react'
import Behaviours from './audience/Behaviours'
import GoogleGender from './audience/GoogleGender'
import GoogleAge from './audience/GoogleAge'
import IncomeRange from './audience/IncomeRange'
import Interests from './audience/Interests'
import Locations from './audience/Locations'
import ParentalStatus from './audience/ParentalStatus'

const GoogleAudienceTargetingRow = ({ controlId, readOnly = false, adType, audience = {}, filter = [], onChange = () => { }, errors = {} }) => {
    const handleChange = val => onChange({ ...audience, ...val })

    return <div>
        {(filter.length === 0 || filter.includes('age')) &&
        <GoogleAge
            controlId={`${controlId}-google-age`}
            onChange={age => handleChange({ age })}
            value={audience.age}
            readOnly={readOnly}
            errors={errors.age}
        />}
        {(filter.length === 0 || filter.includes('gender')) &&
        <GoogleGender
            id={`${controlId}-gender`}
            onChange={gender => handleChange({ gender })}
            value={audience.gender}
            readOnly={readOnly}
            errors={errors.gender}
        />}
        {(filter.length === 0 || filter.includes('locations')) &&
        <Locations
            onChange={locations => handleChange({ locations })}
            value={audience.locations}
            platform="google"
            readOnly={readOnly}
            errors={errors.locations}
        />}
        {(filter.length === 0 || filter.includes('interests')) &&
        <Interests
            onChange={interests => handleChange({ interests })}
            value={audience.interests}
            platform="google"
            readOnly={readOnly}
            errors={errors.interests}
        />}
        {(filter.length === 0 || filter.includes('behaviours')) &&
        <Behaviours
            adType={adType}
            onChange={behaviours => handleChange({ behaviours })}
            value={audience.behaviours}
            platform="google"
            readOnly={readOnly}
            errors={errors.behaviours}
        />}
        {(filter.length === 0 || filter.includes('income_range')) &&
        <IncomeRange
            id={`${controlId}-income-range`}
            onChange={income_range => handleChange({ income_range })}
            value={audience.income_range}
            readOnly={readOnly}
            errors={errors.income_range}
        />}
        {(filter.length === 0 || filter.includes('parental_status')) &&
        <ParentalStatus
            id={`${controlId}-parental-status`}
            onChange={parental_status => handleChange({ parental_status })}
            value={audience.parental_status}
            readOnly={readOnly}
            errors={errors.parental_status}
        />}
        {/* <Keywords
            id={`${controlId}-google-keywords`}
            onChange={keywords => handleChange({ keywords })}
            value={audience.keywords}
            readOnly={readOnly}
            errors={errors.keywords}
        /> */}
    </div>
}

GoogleAudienceTargetingRow.propTypes = {
    controlId: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
    audience: PropTypes.object,
    adType: PropTypes.string.isRequired,
    filter: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func,
    onDelete: PropTypes.func,
    errors: PropTypes.object,
}

const GoogleAudienceTargeting = ({ controlId, readOnly = false, adType, audience = {}, onChange = () => { }, errors = {}, filter = [] }) => {
    const handleChange = val => {
        if (!readOnly) {
            onChange(val)
        }
    }

    return <div>
        <GoogleAudienceTargetingRow
            key={audience.id}
            controlId={`${controlId}-${audience.id}`}
            audience={audience}
            adType={adType}
            filter={filter}
            onChange={val => handleChange(val)}
            errors={errors}
        />
    </div>
}

GoogleAudienceTargeting.propTypes = {
    controlId: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
    audiences: PropTypes.arrayOf(PropTypes.object),
    adType: PropTypes.string.isRequired,
    filter: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func,
    onDelete: PropTypes.func,
    errors: PropTypes.object,
}


export default GoogleAudienceTargeting
