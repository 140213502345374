import PropTypes from 'prop-types';
import React from 'react';
import { Form, Button } from 'react-bootstrap';
import Errors from '../Errors';
import { faPlus, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const GoogleGender = ({ id, readOnly = false, value = {}, errors = [], onChange = () => {} }) => {
  return (
    <Form.Group controlId='gender-group'>
      <div class='row'>
        <div class='col'>
          <Form.Label>Gender</Form.Label>
        </div>
      </div>
      <div class='row'>
        <div class='col'>
          <Button
            variant={value?.male ? 'outline-success' : 'outline-secondary'}
            readOnly={readOnly}
            style={{ marginRight: '10px' }}
            onClick={(e) =>
              value?.male ? onChange({ ...value, male: undefined }) : onChange({ ...value, male: true })
            }
          >
            <FontAwesomeIcon icon={value?.male ? faCheck : faPlus} /> Male
          </Button>
          <Button
            variant={value?.female ? 'outline-success' : 'outline-secondary'}
            readOnly={readOnly}
            style={{ marginRight: '10px' }}
            onClick={(e) =>
              value?.female ? onChange({ ...value, female: undefined }) : onChange({ ...value, female: true })
            }
          >
            <FontAwesomeIcon icon={value?.female ? faCheck : faPlus} /> Female
          </Button>
          <Button
            variant={value?.unknown ? 'outline-success' : 'outline-secondary'}
            readOnly={readOnly}
            style={{ marginRight: '10px' }}
            onClick={(e) =>
              value?.unknown ? onChange({ ...value, unknown: undefined }) : onChange({ ...value, unknown: true })
            }
          >
            <FontAwesomeIcon icon={value?.unknown ? faCheck : faPlus} /> Unknown
          </Button>
        </div>
      </div>
      <Form.Control.Feedback type='invalid'>
        <Errors errors={errors} />
      </Form.Control.Feedback>
    </Form.Group>
  );
};

GoogleGender.propTypes = {
  id: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  value: PropTypes.shape({ male: PropTypes.bool, female: PropTypes.bool }),
  errors: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};

export default GoogleGender;
