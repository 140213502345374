
import { connect } from 'react-redux'
import Currency from '../../components/form_elements/Currency'
import { selectPackageMaxBudget, selectPackageMaxBudgetErrors, updateMaxBudget } from '../../packageFormSlice'

const mapStateToProps = state => ({
    controlId: "package-max-budget",
    label: "Maximum Total Budget",
    description: "The maximum total budget for all ads in this package.",
    required: false,
    value: selectPackageMaxBudget(state),
    errors: selectPackageMaxBudgetErrors(state),
})


const mapDispatchToProps = dispatch => ({
    onChange: budget => dispatch(updateMaxBudget(budget)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Currency)
