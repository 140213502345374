import { maxBy, minBy } from "lodash"
import moment from "moment"

export function packageDateRangeText(pkg) {
    const earliestStartDate = minBy(pkg.ad_format_templates, 'start_date')?.start_date
    const latestEndDate = maxBy(pkg.ad_format_templates, 'end_date')?.end_date

    if (earliestStartDate && latestEndDate) {
        return `${moment(earliestStartDate).format('D MMMM YYYY')} to ${moment(latestEndDate).format('D MMMM YYYY')}`
    }

    if (earliestStartDate) {
        return moment(earliestStartDate).format('D MMMM YYYY')
    }

    if (latestEndDate) {
        return moment(latestEndDate).format('D MMMM YYYY')
    }

    return undefined
}


export function packageHumanDateRangeText(pkg) {
    const earliestStartDate = minBy(pkg.ad_format_templates, 'start_date')?.start_date
    const latestEndDate = maxBy(pkg.ad_format_templates, 'end_date')?.end_date

    if (earliestStartDate && latestEndDate) {
        return `Package ad availability from ${moment(earliestStartDate).format('D MMMM YYYY')} till ${moment(latestEndDate).format('D MMMM YYYY')}`
    }

    if (earliestStartDate) {
        return moment(earliestStartDate).format('D MMMM YYYY')
    }

    if (latestEndDate) {
        return moment(latestEndDate).format('D MMMM YYYY')
    }

    return undefined
}

export function packageFieldHumanDateRangeText(pkgField) {
    const earliestStartDate = pkgField?.start_date
    const latestEndDate = pkgField?.end_date

    if (earliestStartDate && latestEndDate) {
        return `Package ad availability from ${moment(earliestStartDate).format('D MMMM YYYY')} till ${moment(latestEndDate).format('D MMMM YYYY')}`
    }

    if (earliestStartDate) {
        return moment(earliestStartDate).format('D MMMM YYYY')
    }

    if (latestEndDate) {
        return moment(latestEndDate).format('D MMMM YYYY')
    }

    return undefined
}


export function packageBudgetRange(pkg, currency) {
    let text;

    if (pkg?.min_budget && pkg?.max_budget) {
        text = `Minimum Budget: ${currency ?? ""}${pkg?.min_budget} - Maximum Budget: ${currency ?? ""}${pkg?.max_budget}`
    }
    else if (pkg?.min_budget) {
        text = `Minimum Budget: ${currency ?? ""}${pkg?.min_budget}`
    }
    else if (pkg?.max_budget) {
        text = `Maximum Budget: ${currency ?? ""}${pkg?.max_budget}`
    }
    
    return text
}