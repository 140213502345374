import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import LogrocketFuzzySanitizer from 'logrocket-fuzzy-search-sanitizer';

const privateFieldNames = [
  'password',
  'token',
  'secret',
  'auth_id',
];

export const init = () => {
  const logRocketID = process.env.REACT_APP_LOGROCKET_ID;
  if(!logRocketID) {
    return;
  }

  const { requestSanitizer, responseSanitizer } = LogrocketFuzzySanitizer.setup(privateFieldNames);
  // TODO: make the project ID based on env value
  LogRocket.init(logRocketID, {
    network: {
      requestSanitizer: request => {
        if (request.headers['Authorization']) {
          request.headers['Authorization'] = '*';
        }
        return requestSanitizer(request);
      },
      responseSanitizer
    }
  });

  setupLogRocketReact(LogRocket);
}

export const identifyUser = user => {
  if(!user) {
    return;
  }
  const organisation = user.active_organisation;
  LogRocket.identify(user.id, {
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    organisation_id: organisation.id,
    organisation_name: organisation.name,
    role: user.role.name,
  });
}
