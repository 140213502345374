import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-bootstrap';
import Errors from '../../../../order_form/components/Errors';
import properties_styles from '../../brand_page_properties.module.scss';

export const PageNameText = ({ header, controlId, onChange = () => {}, errors = [], reduxValue, readOnly }) => {
  return (
    <Form.Group controlId={controlId}>
      <Form.Label className='d-flex align-self-center align-items-center font-weight-bold'>
        <span>{header}</span>
      </Form.Label>
      <Form.Control
        type='text'
        placeholder={'Page Name'}
        isInvalid={errors.length > 0}
        errors={errors}
        onChange={(e) => onChange(e.target.value)}
        required={true}
        minLength={0}
        maxLength={30}
        value={reduxValue}
        disabled={readOnly}
      />
      <div className={properties_styles.form_error_text}>
        <Errors errors={errors} />
      </div>
    </Form.Group>
  );
};

PageNameText.propTypes = {
  header: PropTypes.string.isRequired,
  controlId: PropTypes.string.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};
