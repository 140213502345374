export const PLATFORM_NATIVE = 'native';
export const PLATFORM_GOOGLE = 'google';
export const PLATFORM_FACEBOOK = 'facebook';
export const PLATFORM_MERCATO = 'mercato';
export const PLATFORM_DIGITAL_SCREEN = 'digital_screen';
export const PLATFORM_YOUTUBE = 'youtube';
export const PLATFORM_BESPOKE_PRODUCT = 'bespoke_product';
export const PLATFORM_LINKEDIN = 'LinkedIn';

export const FORMAT_FACEBOOK_REACH = 'facebook_reach';

export const FACEBOOK_FORMAT_IMAGE = 'image';
export const FACEBOOK_FORMAT_VIDEO = 'video';
export const FACEBOOK_FORMAT_CAROUSEL = 'carousel';
export const FACEBOOK_FORMAT_COLLECTION = 'collection';