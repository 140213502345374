import React from 'react';
import { Controller } from 'react-hook-form';
import DefaultGoogleRemarketingAudiences from '../../features/organisation_settings/containers/DefaultGoogleRemarketingAudiences';

export const GooglePermissions = ({ control, adSettings, organisation }) => {
  return (
    <div className='rounded'>
      <div className='d-flex align-items-center mb-2'>
        <h5 className='flex-grow-1 mb-0'>Google Permissions</h5>
      </div>
      <Controller
        name='google_remarketing_audiences'
        control={control}
        defaultValue={adSettings?.google_remarketing_audiences}
        render={({ field }) => (
          <DefaultGoogleRemarketingAudiences
            controlId={`${organisation.id}-default-google-remarketing-audiences`}
            value={field.value}
            onChange={field.onChange}
            {...field}
          />
        )}
      />
    </div>
  );
};

export default GooglePermissions;
