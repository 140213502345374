import React from 'react';
import isPropValid from '@emotion/is-prop-valid';
import { StyleSheetManager } from 'styled-components';

function StyleSheetManagerProvider({ children }: { children: React.ReactNode }) {
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp} enableVendorPrefixes>
      {children}
    </StyleSheetManager>
  );
}

// This implements the default behavior from styled-components v5
function shouldForwardProp(propName, target) {
  if (typeof target === 'string') {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName);
  }
  // For other elements, forward all props
  return true;
}
export default StyleSheetManagerProvider;
