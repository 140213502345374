import { connect } from 'react-redux';
import { selectSession } from '../../session/sessionSlice';
import CustomAudiences from '../components/CustomAudiences';

const mapStateToProps = (state) => ({
  title: 'Custom Audiences',
  audiences: selectSession(state).facebook_custom_audiences,
});

export default connect(mapStateToProps)(CustomAudiences);
