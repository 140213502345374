import React from 'react';
import styles from '../../brand_page_properties.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFloppyDisk,
  faPen,
  faTrash,
  faChevronUp,
  faChevronDown,
} from '@fortawesome/pro-regular-svg-icons';

export const ActionButton = ({ type, onClick }) => {
  const icons = {
    save: faFloppyDisk,
    hide: faChevronUp,
    edit: faPen,
    view: faChevronDown,
    remove: faTrash,
  };

  const labels = {
    save: 'Save',
    hide: '',
    edit: 'Edit',
    view: '',
    remove: 'Remove',
  };

  const classNames = {
    save: styles.save_button_icon,
    hide: styles.hide_button_icon,
    edit: styles.edit_button_icon,
    view: styles.view_button_icon,
    remove: styles.remove_button_icon,
  };

  return (
    <button className={styles.save_button} onClick={onClick}>
      <FontAwesomeIcon
        icon={icons[type]}
        className={classNames[type]}
        style={{ fontSize: '12px' }}
      />
      <div className={classNames[type]} style={{ fontSize: '12px' }}>
        {labels[type]}
      </div>
    </button>
  );
};
