import React from 'react';
import propTypes from 'prop-types';
import { Controller, useFormContext, useFormState } from 'react-hook-form';
import { Form, InputGroup } from 'react-bootstrap';
import { getCurrencySymbol } from '../../../../lib/wallets';
import StyledCurrencyBadge from './StyledCurrencyBadge';
const WalletMinBalance = ({ currency }) => {
  const methods = useFormContext();
  const { control } = methods;
  const { errors, isSubmitted, touchedFields } = useFormState();

  return (
    <Form.Group className='position-relative'>
      <StyledCurrencyBadge currency={currency} />
      <Form.Label>Minimum Balance</Form.Label>
      <InputGroup>
        <InputGroup.Text>{getCurrencySymbol(currency)}</InputGroup.Text>
        <Controller
          name='min_balance'
          control={control}
          render={({ field }) => (
            <Form.Control
              type='text'
              aria-label='Minimum balance'
              placeholder='0'
              {...field}
              isInvalid={(isSubmitted || touchedFields?.min_balance) && errors?.min_balance?.message}
            />
          )}
        />
        {(isSubmitted || touchedFields?.min_balance) && (
          <Form.Control.Feedback type='invalid'>{errors?.min_balance?.message}</Form.Control.Feedback>
        )}
      </InputGroup>
    </Form.Group>
  );
};

WalletMinBalance.prototype = {
  currency: propTypes.string.isRequired,
};

export default WalletMinBalance;
