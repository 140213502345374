import React, { useState } from 'react';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import productModalStyles from './ProductModalStyles.module.scss';
import { ProductPreviewList } from './ProductVariable';
import { ProductFeedSelector } from './ProductFeedSelector';

const ValidationMessage = ({ localListOfProducts, minProducts, maxProducts }) => {
  const overMax = localListOfProducts?.length - maxProducts;

  if (maxProducts === 0) {
    // Invisible span with the same style but no content
    return (
      <span className={productModalStyles.modal_warning_text} style={{ visibility: 'hidden' }}>
        &nbsp;
      </span>
    );
  }

  if (minProducts === maxProducts) {
    return (
      <span className={productModalStyles.modal_warning_text}>
        Please select exactly{' '}
        <b>
          {minProducts} product{minProducts > 1 ? 's' : ''}
        </b>{' '}
        to insert into Products Grid
      </span>
    );
  } else if (localListOfProducts?.length > maxProducts) {
    return (
      <span className={productModalStyles.modal_warning_text}>
        You've selected more than the maximum of{' '}
        <b>
          {maxProducts} product{maxProducts > 1 ? 's' : ''}
        </b>
        . Please remove {overMax} product{overMax > 1 ? 's' : ''}.
      </span>
    );
  } else if (localListOfProducts?.length < minProducts) {
    return (
      <span className={productModalStyles.modal_warning_text}>
        Please select{' '}
        <b>
          {minProducts} to {maxProducts} product{maxProducts > 1 ? 's' : ''}
        </b>{' '}
        to insert into Products Grid
      </span>
    );
  } else {
    // Invisible span with the same style but no content
    return (
      <span className={productModalStyles.modal_warning_text} style={{ visibility: 'hidden' }}>
        &nbsp;
      </span>
    );
  }
};

export const ProductSelectorModal = ({
  isOpen,
  handleClose,
  minProducts,
  maxProducts = 0,
  selectedValues,
  productSets,
  handleChange,
}) => {
  //localListOfProdcuts is the value local to the modal, to be saved on click of Save, or thrown away on cancel/close
  const [localListOfProducts, setLocalListOfProducts] = useState([...selectedValues]);

  // Handle local list of products
  const handleLocalProductListChange = (newList) => {
    setLocalListOfProducts(newList);
  };

  // Remove a product locally
  const removeProductLocally = (productId) => {
    setLocalListOfProducts(localListOfProducts.filter((product) => product.id !== productId));
  };

  // Move a product locally
  const moveProductLocally = (fromIndex, toIndex) => {
    let newOrder = [...localListOfProducts];
    const feedItemMoving = newOrder[fromIndex];
    newOrder.splice(fromIndex, 1);
    newOrder.splice(toIndex, 0, feedItemMoving);

    setLocalListOfProducts(newOrder);
  };

  // Handle the close of the modal and the saving of changes
  const handleSave = () => {
    handleChange(localListOfProducts);
    handleClose();
  };

  return (
    <Modal size='xl' show={isOpen} onHide={handleClose} className='xxl_modal'>
      <Modal.Header className={productModalStyles.product_selector_modal_header_area} closeButton={false}>
        <div className={productModalStyles.product_selector_modal_header_text}>Select products</div>
        <div className={productModalStyles.header_right}>
          <ValidationMessage
            localListOfProducts={localListOfProducts}
            minProducts={minProducts}
            maxProducts={maxProducts}
          />
          <button type='button' className='close' onClick={handleClose}>
            <span aria-hidden='true'>×</span>
          </button>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Row>
            <Col>
              <ProductFeedSelector
                value={localListOfProducts}
                productSets={productSets}
                onChange={handleLocalProductListChange}
              />
            </Col>
            <Col className='rounded bg-light' xs={4}>
              <div className={`${productModalStyles.products_selected_header}`}>
                <span>Selected Products ({localListOfProducts.length})</span>
              </div>
              <div style={{ margin: '10px' }}>
                <ProductPreviewList
                  products={localListOfProducts}
                  removeProduct={removeProductLocally}
                  moveProduct={moveProductLocally}
                  minHeight={true}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button className='float-right' variant='secondary' onClick={handleClose}>
          Cancel
        </Button>
        <Button
          className='float-right'
          variant='primary'
          disabled={maxProducts > 0 && localListOfProducts?.length > maxProducts}
          onClick={handleSave}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
