import React, {useEffect} from "react";
import {ProductFeed} from "../../types";
import {NoProductSets} from "./OtherStates";
import {Selector, SelectorProps} from "./Selector";


type ProductSelectorProps = SelectorProps & {
    value?: ProductFeed,
};

const ProductSelector = (props: ProductSelectorProps) => {
    const {
        productSets = [],
        value,
        onChange,
    } = props;

    useEffect(() => {
        if (!value) {
            onChange({
                ...value,
                group: productSets[0],
                products: [],
                deleted_products: [],
            });
        }
    }, [value, productSets, onChange]);

    if (productSets.length === 0) {
        return <NoProductSets/>;
    }

    if (!value) {
        return <NoProductSets/>;
    }

    return <Selector {...props} />
};

export default ProductSelector;


