import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { selectOwnerOrganisation } from '../../../orderFormSlice';

import styles from "./linkedin_image_ad.module.scss";

const LinkedInImageAd = ({
  introductoryText,
  headline,
  description,
  link,
  src,
  cta
}) => {
  const fallback_logo = "/ad_preview/logo.png";
  const ownerOrganisation = useSelector(selectOwnerOrganisation);
  const isValidHttpUrl = (string) => {
      let url;
      
      try {
        url = new URL(string);
      } catch (_) {
        return false;  
      }
    
      return true;
  }

  return (
    <div className={styles.container}>
      <p className={styles.note}>
        The display of your ad may vary from this view based on device, system preferences, and other factors. 
        Specifically, URLs will be shortened once displayed to members, hashtags will be linked, and image 
        resolutions could vary. 
      </p>
      <div className={styles.card}>
        <div className={styles.header}>
          <div className={styles.logo}>
            <img src={(ownerOrganisation?.settings?.logo?.url || ownerOrganisation?.logo) ?? fallback_logo} alt="" />
          </div>
          <div className={styles.organisation}>
            <a     
              href={isValidHttpUrl(link) ? new URL(link).protocol + "//" + new URL(link).hostname : link}
              target="_blank" rel="noreferrer"
            >{(ownerOrganisation?.name) ?? 'Organization Name'}</a>
            <div>12345 followers</div>
            <div>Promoted</div>
          </div>
        </div>
        <div className={styles.intro}>{introductoryText}</div>
        <img className={styles.media} src={src} alt="" />
        <a 
          className={styles.footer}     
          href={isValidHttpUrl(link) ? new URL(link).protocol + "//" + new URL(link).hostname : link}
          target="_blank" rel="noreferrer"
        >
          <div>
            <div className={styles.headline}>{headline}</div>
            <div className={styles.link}>{link.replace('https://', '')}</div>
          </div>
          <div>
            <div className={styles.cta}>{cta}</div>
          </div>
        </a>
      </div>
    </div>   
  )
}

LinkedInImageAd.propTypes = {
  introductoryText: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  cta: PropTypes.string.isRequired,
}

export default LinkedInImageAd