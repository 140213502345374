import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { faBrowser, faCalendar, faImage } from '@fortawesome/pro-light-svg-icons';
import PropertiesSection from '../order_form/components/PropertiesSection';
import PropertiesBody from '../order_form/components/PropertiesBody';
import { Tab } from 'react-bootstrap';
import PreviewSection from '../order_form/components/PreviewSection';
import AdGrid from '../order_form/components/AdGrid';
import {
  cancelEdit,
  clearBrandStore,
  editForm,
  getPreview,
  loadBrandStoreOrder,
  saveBrandStoreOrder,
  selectBrandStore,
  selectBrandStoreBlocks,
  selectBrandStoreOrder,
  setComment,
  submitForReview,
} from './state_management/BrandStoreSlice';
import ScheduleTab from './propertiesComponents/Tabs/ScheduleTab';
import PagesTab from './propertiesComponents/Tabs/PagesTab';
import { ContentTab } from './propertiesComponents/Tabs/ContentTab';
import { BrandStoreTopPanel } from './topPanelComponents/BrandStoreTopPanel';
import { PreviewContents } from './previewComponents/PreviewContents';
import {
  APPROVE_STATE,
  BRAND_STORE_ORDER_FORM_STATE,
  ORDER_LOADING_STATE,
  PREVIEW_STATE,
  PROVIDE_FEEDBACK_STATE,
  SAVE_STATE,
  SUBMIT_STATE,
} from './constants';
import { VetoModal } from '../../app/components/VetoModal';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { onApproval, onProvideFeedback } from './state_management/BrandStoreAsyncActions';
import CommentModal from './components/CommentModal';
import ApprovalConfirmationModal from './components/ApprovalConfirmationModal';
import { selectSession, selectUserOrganisation } from '../session/sessionSlice';
import { trackApproveBrandStore } from '../../helpers/segment';
import { selectBrandById } from '../brands/brandsSlice';
import { FALLBACK_CURRENCY } from '../../lib/financial';

const propertiesTabs = [
  {
    id: 'schedule',
    title: 'Schedule',
    description: undefined,
    icon: faCalendar,
    completedCheck: () => true,
    optional: false,
  },
  {
    id: 'pages',
    title: 'Pages',
    description: 'Manage the pages within your store.',
    icon: faBrowser,
    completedCheck: ({ brandStoreOrder, brandStoreErrors }) => {
      //check for serverside error messages
      const hasPageErrors =
        brandStoreErrors?.pages &&
        Object.values(brandStoreErrors.pages).some(
          (page) => page.errorMessages && Object.keys(page.errorMessages).length > 0
        );
      if (hasPageErrors) {
        return false;
      }

      // check that each page has pageUrl and pageName
      const pages = brandStoreOrder.pages;
      let allPageFieldsPopulated = true;
      let allUrlsUnique = true;
      let allNamesUnique = true;

      let pageUrls = [];
      let pageNames = [];
      pages.forEach((page) => {
        if (!page.page_name || !('page_url' in page) || (!page.is_home_page && page.page_url === '')) {
          allPageFieldsPopulated = false;
        } else {
          //Check if the url is already in the list
          if (
            pageUrls.length > 0 &&
            pageUrls.find((u) => u.toLowerCase() === page.page_url.toLowerCase()) !== undefined
          ) {
            allUrlsUnique = false;
          } else {
            pageUrls.push(page.page_url);
          }
          //Check if the name is already in the list
          if (
            pageNames.length > 0 &&
            pageNames.find((u) => u.toLowerCase() === page.page_name.toLowerCase()) !== undefined
          ) {
            allNamesUnique = false;
          } else {
            pageNames.push(page.page_name);
          }
        }
      });

      return allPageFieldsPopulated && allNamesUnique && allUrlsUnique;
    },
    optional: false,
  },
  {
    id: 'content',
    title: 'Content',
    description: undefined,
    icon: faImage,
    completedCheck: ({ brandStoreOrder, brandStoreBlockTypes = [], brandStoreErrors }) => {
      //check for serverside error messages
      const hasPageErrors =
        (brandStoreErrors?.pages &&
          Object.values(brandStoreErrors.pages)?.some((page) => {
            return Object.values(page.blocks).some((block) => {
              return block.errorMessages && Object.entries(block.errorMessages).length > 0;
            });
          })) ||
        false;

      if (hasPageErrors) {
        return false;
      }

      const pages = brandStoreOrder.pages;
      let allRequiredFieldsAreFilled = true;
      //check if all pages have content
      let allPagesHaveContent = true;
      let requiredFields = [];
      brandStoreBlockTypes.forEach((block) => {
        if (block.meta && block.meta.variables && block.meta.variables.length > 0) {
          let req = block.meta.variables.filter(
            (input) => input.type !== 'hidden' && input?.constraints && input.constraints?.required
          );
          requiredFields.push({ id: block.id, variables: req });
        }
      });

      pages.forEach((page) => {
        if (page.json_schema.blocks.length === 0) {
          allPagesHaveContent = false;
        }
      });

      if (brandStoreOrder?.pages && brandStoreOrder.pages.length > 0) {
        brandStoreOrder.pages.forEach((page) => {
          if (page?.json_schema && page?.json_schema?.blocks) {
            page?.json_schema?.blocks.forEach((b) => {
              //check if it has required variabled
              let reqBlock = requiredFields.filter((r) => r.id === b.block_id);
              if (reqBlock.length > 0) {
                if (b?.data) {
                  reqBlock[0].variables.forEach((v) => {
                    if (v?.key) {
                      switch (v.type) {
                        case 'image':
                          if (!(v.key in b.data) || !b.data[v.key]) {
                            allRequiredFieldsAreFilled = false;
                          }
                          break;
                        case 'video':
                          if (!(v.key in b.data) || !b.data[v.key]) {
                            allRequiredFieldsAreFilled = false;
                          }
                          break;
                        case 'link':
                          if (
                            b.data[v.key]['linkType'] === 'brandPageLink' &&
                            (b.data[v.key]['brandPageLink'] === '' || isNaN(b.data[v.key]['brandPageLink']))
                          ) {
                            allRequiredFieldsAreFilled = false;
                          } else if (
                            b.data[v.key]['linkType'] === 'externalUrl' &&
                            b.data[v.key]['externalUrl'] === ''
                          ) {
                            allRequiredFieldsAreFilled = false;
                          }
                          break;
                        case 'products':
                          if (!b.data[v.key] || b.data[v.key].length === 0) {
                            allRequiredFieldsAreFilled = false;
                          }
                          break;
                        default:
                      }
                    }
                  });
                } else {
                  allRequiredFieldsAreFilled = false;
                }
              }
            });
          }
        });
      }
      return allPagesHaveContent && allRequiredFieldsAreFilled;
    },
    optional: false,
  },
];

const StyledLoaderWrapper = styled.div`
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;

  p {
    font-size: 1.2em;
  }
`;

export const BrandStoreBuilder = ({ user }) => {
  const brandStore = useSelector(selectBrandStore);
  const brandStoreOrder = useSelector(selectBrandStoreOrder);
  const brandStoreBlockTypes = useSelector(selectBrandStoreBlocks);
  const brandStoreErrors = brandStore.errors;
  const dispatch = useDispatch();
  const {order_id} = useParams();
  // hook state initialization
  const [currentContentPageId, setCurrentContentPageId] = useState(undefined);
  const [showVetoCancelModal, setShowVetoCancelModal] = useState(false);
  const [showRequestApprovalModal, setShowRequestApprovalModal] = useState(false);
  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const session = useSelector(selectSession);
  const currency = session.user?.active_organisation?.settings?.['defaultCurrency'] || FALLBACK_CURRENCY;
  const [showFeedBackModal, setShowFeedBackModal] = useState(false);
  const [feedBackModalIsSubmitting, setFeedBackModalIsSubmitting] = useState(false);
  const brand = useSelector((state) => selectBrandById(state, brandStoreOrder.supplier_id));
  const retailer = useSelector(selectUserOrganisation)

  const getToastErrorMessages = (brandStoreErrors) => {
    if (!brandStoreErrors?.pages) {
      return [];
    }

    let errors = brandStoreErrors.pages;
    let hasBlockErrors = false;
    let hasPageErrors = false;
    let errorMessages = [];

    for (let key in errors) {
      let page = errors[key];
      if (page.errorMessages && Object.keys(page.errorMessages).length > 0) {
        hasPageErrors = true;
      }
      if (page.blocks) {
        for (let blockKey in page.blocks) {
          let block = page.blocks[blockKey];
          if (block.errorMessages && Object.keys(block.errorMessages).length > 0) {
            hasBlockErrors = true;
          }
        }
      }
    }

    if (hasBlockErrors) {
      errorMessages.push('Please complete content for each page and block.');
    }

    if (hasPageErrors) {
      errorMessages.push('Please complete settings for each page.');
    }

    return errorMessages;
  };

  // Handle Save state changes
  useEffect(() => {
    if (brandStore.save_state === SAVE_STATE.SAVED) {
      toast.success('Brand Store successfully saved');
    } else if (brandStore.save_state === SAVE_STATE.ERROR) {
      const errorMessages = getToastErrorMessages(brandStoreErrors);
      errorMessages.forEach((message) => toast.error(message));
    }
  }, [brandStore.save_state, brandStoreErrors]);

  // Handle Submit state changes
  useEffect(() => {
    if (brandStore.submit_state === SUBMIT_STATE.SUBMITTED) {
      toast.success('Brand Store successfully submitted');
    } else if (brandStore.submit_state === SUBMIT_STATE.ERROR) {
      const errorMessages = getToastErrorMessages(brandStoreErrors);
      errorMessages.forEach((message) => toast.error(message));
    }
  }, [brandStore.submit_state, brandStoreErrors]);

  // Handle approve state changes
  useEffect(() => {
    if (brandStore.approve_state === APPROVE_STATE.APPROVED) {
      toast.success('The brand store is now scheduled to go live on the scheduled date');
    }
    if (brandStore.approve_state === APPROVE_STATE.ERROR) {
      if (brandStore.errors.error) {
        toast.error(brandStore.errors.error);
      }
    }
  }, [brandStore.approve_state, brandStore.errors]);

  // Handle feedback state changes
  useEffect(() => {
    if (brandStore.provide_feedback_state === PROVIDE_FEEDBACK_STATE.PROVIDED_FEEDBACK) {
      toast.success('Feedback successfully submitted');
      setShowFeedBackModal(false);
    }
    if (
      brandStore.provide_feedback_state === PROVIDE_FEEDBACK_STATE.PROVIDED_FEEDBACK ||
      brandStore.provide_feedback_state === PROVIDE_FEEDBACK_STATE.ERROR
    ) {
      setFeedBackModalIsSubmitting(false);
    }
  }, [brandStore.provide_feedback_state]);

  // Load brand store order and other necessary state when the order form state changes to VIEW
  // (this handles initial load as well as loading saved state after save or cancel actions)
  useEffect(() => {
    if (brandStore.order_form_state === BRAND_STORE_ORDER_FORM_STATE.VIEW) {
      dispatch(clearBrandStore());
      dispatch(loadBrandStoreOrder({ orderId: order_id }));
    }
  }, [dispatch, order_id, brandStore.order_form_state]);

  //update preview on changes to any page
  useEffect(() => {
    dispatch(getPreview(brandStoreOrder));
  }, [brandStoreOrder, dispatch]);

  if ([ORDER_LOADING_STATE.LOADING, ORDER_LOADING_STATE.INITIAL].includes(brandStore.order_loading_state)) {
    return (
      <StyledLoaderWrapper>
        <p>Loading brand store, please wait.</p>
        <FontAwesomeIcon className='fa-spin' style={{ cursor: 'pointer' }} icon={faSpinner} size='4x' />
      </StyledLoaderWrapper>
    );
  } else if (brandStore.order_loading_state === ORDER_LOADING_STATE.ERROR) {
    return <>something went wrong</>;
  }

  const organisationId = user.active_organisation.id;
  const isRetailer = user.active_organisation?.is_retailer;

  const brandStorePages = brandStoreOrder.pages;
  const brandStorePreview = brandStore.preview;
  const brandStorePreviewState = brandStore.preview_state;
  const brandStoreDirty = brandStore.isDirty;

  const handleEditForm = () => {
    dispatch(editForm());
  };

  const handleOnSave = () => {
    dispatch(saveBrandStoreOrder({ brandStoreOrder, organisationId }));
  };

  const handleSubmitRequestApproval = async (comment) => {
    await dispatch(setComment(comment));
    dispatch(submitForReview({ brandStoreOrder: { ...brandStoreOrder, comment: comment }, organisationId }));
    setShowRequestApprovalModal(false);
  };

  const handleOnProvideFeedback = async (feedback) => {
    setFeedBackModalIsSubmitting(true);
    await dispatch(onProvideFeedback({ brandStoreOrderId: brandStoreOrder.id, feedback }));
  };

  const handleSubmitApproval = async () => {
    dispatch(onApproval({ brandStoreOrder, organisationId })).then((action) => {
      if (!action.error) {
        const brandStore = action.payload.data;
        const currency = user.active_organisation?.settings?.['defaultCurrency'] ?? FALLBACK_CURRENCY;
        trackApproveBrandStore(brandStore, currency, retailer, brand);
      }
    });
    setShowApprovalModal(false);
  };

  const handleCancelEdit = () => {
    if (brandStoreDirty) {
      setShowVetoCancelModal(true); // Show the veto modal if there are unsaved changes
    } else {
      dispatch(cancelEdit());
    }
  };

  const confirmCancelEdit = () => {
    setShowVetoCancelModal(false);
    dispatch(cancelEdit());
  };

  const readOnly = [
    BRAND_STORE_ORDER_FORM_STATE.VIEW,
    BRAND_STORE_ORDER_FORM_STATE.SUBMITTING,
    BRAND_STORE_ORDER_FORM_STATE.SAVING,
    BRAND_STORE_ORDER_FORM_STATE.APPROVING,
    BRAND_STORE_ORDER_FORM_STATE.PROVIDING_FEEDBACK,
  ].includes(brandStore.order_form_state);

  return (
    <>
      {/*<Prompt*/}
      {/*  when={brandStoreDirty}*/}
      {/*  message='If you navigate away from this page, your changes will not be saved. Do you wish to proceed?'*/}
      {/*/>*/}
      <ApprovalConfirmationModal
        show={showApprovalModal}
        title='Brand Store Approval'
        totalCost={brandStoreOrder?.total_cost}
        currency={currency}
        wallet={brandStoreOrder?.wallet}
        supplier={brandStoreOrder?.supplier_name}
        isAlreadyApproved={brandStoreOrder?.is_already_approved}
        submitButtonText='Confirm Approval'
        handleApprove={() => handleSubmitApproval()}
        handleCancel={() => setShowApprovalModal(false)}
      />
      <CommentModal
        show={showRequestApprovalModal}
        title='Request Approval'
        heading='Please provide comments'
        submitButtonText='Request Approval'
        handleSubmit={(comment) => handleSubmitRequestApproval(comment)}
        handleClose={() => setShowRequestApprovalModal(false)}
      />
      <CommentModal
        show={showFeedBackModal}
        title='Provide Feedback'
        heading='Please provide your feedback'
        submitButtonText='Provide Feedback'
        handleSubmit={(feedback) => handleOnProvideFeedback(feedback)}
        handleClose={() => setShowFeedBackModal(false)}
        isSubmitting={feedBackModalIsSubmitting}
        errors={brandStore.errors?.error?.feedback || []}
      />
      <VetoModal
        show={showVetoCancelModal}
        handleClose={() => setShowVetoCancelModal(false)}
        confirmAction={confirmCancelEdit}
        heading={'Discard Changes'}
        messageParagraphs={[
          { text: 'If you navigate away from this page, your changes will not be saved.', bold: false },
          { text: 'Do you wish to proceed?', bold: true },
        ]}
        confirmMessage={'Discard Changes'}
        cancelMessage={'Keep Editing'}
      />
      <div style={{ width: '100%', paddingBottom: '30px', paddingLeft: '30px', paddingRight: '30px' }}>
        <BrandStoreTopPanel
          brandStoreOrder={brandStoreOrder}
          formState={brandStore.order_form_state}
          isDirty={brandStoreDirty}
          isRetailer={isRetailer}
          onEdit={handleEditForm}
          onCancel={handleCancelEdit}
          onSubmitForReview={() => {
            setShowRequestApprovalModal(true);
          }}
          onApproval={() => {
            setShowApprovalModal(true);
          }}
          onProvideFeedback={() => {
            setShowFeedBackModal(true);
          }}
          onSave={handleOnSave}
        />
        <AdGrid>
          <PreviewSection
            maximisePreviewSpace={true}
            noScroll={true}
            loaderState={readOnly ? 'none' : brandStorePreviewState === PREVIEW_STATE.UPDATING ? 'loading' : 'none'}
          >
            <PreviewContents
              brandStorePreviewState={brandStorePreviewState}
              brandStorePreview={brandStorePreview}
              previewPageId={currentContentPageId}
              brandStorePages={brandStorePages}
            />
          </PreviewSection>
          <PropertiesSection>
            <PropertiesBody
              readOnly={false}
              tabs={propertiesTabs}
              completeCheckContext={{ brandStoreOrder, brandStoreBlockTypes, brandStoreErrors }}
            >
              <Tab.Pane eventKey='schedule'>
                <ScheduleTab
                  start_at={brandStoreOrder.start_at}
                  stop_at={brandStoreOrder.stop_at}
                  total_cost={brandStoreOrder.total_cost}
                  currency_symbol={'$'}
                  wallet={brandStoreOrder.wallet}
                  time_unit={brandStoreOrder.time_unit}
                  cost_per_unit={brandStoreOrder.cost_per_unit}
                />
              </Tab.Pane>
              <Tab.Pane eventKey='pages'>
                <PagesTab
                  brandStoreOrder={brandStoreOrder}
                  brandStorePages={brandStorePages}
                  brandStoreErrors={brandStoreErrors}
                  readOnly={readOnly}
                  brandStoreBlockTypes={brandStoreBlockTypes}
                />
              </Tab.Pane>
              <Tab.Pane eventKey='content'>
                <ContentTab
                  brandStore={brandStore}
                  brandStorePages={brandStorePages}
                  brandStoreErrors={brandStoreErrors}
                  brandStoreBlockTypes={brandStoreBlockTypes}
                  currentPageId={currentContentPageId}
                  setCurrentPageId={setCurrentContentPageId}
                  readOnly={readOnly}
                />
              </Tab.Pane>
            </PropertiesBody>
          </PropertiesSection>
        </AdGrid>
      </div>
    </>
  );
};
