import PropTypes from "prop-types";
import React from "react";
import { Form } from "react-bootstrap";
import { DescriptionContainer, LabelText, LengthBadge, TextInput } from "./Common";
import Errors from './Errors';


const Text = ({
  controlId,
  label,
  description,
  placeholder = "",
  readOnly = false,
  disabled = false,
  required = false,
  value = "",
  onChange = () => { },
  errors = [],
  maxLength,
  maxLengthSoft,
}) => {

  return (
    <Form.Group controlId={controlId}>
      <Form.Label className="d-flex align-self-center align-items-center font-weight-bold">
        <LabelText>
          {label} {required && "*"}{" "}
        </LabelText>
        {maxLengthSoft !== undefined && (
          <LengthBadge variant="primary">
            {value.length} / {maxLengthSoft} Characters
          </LengthBadge>
        )}
      </Form.Label>
      <DescriptionContainer>
        {description}
      </DescriptionContainer>
      <TextInput
        type="text"
        readOnly={readOnly}
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        isInvalid={errors.length > 0}
        onChange={(e) => onChange(e.target.value)}
        required={required}
        maxLength={maxLength}
      />
      <Form.Control.Feedback type="invalid">
        <Errors errors={errors} />
      </Form.Control.Feedback>
    </Form.Group>
  );
};

Text.propTypes = {
  controlId: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  maxLength: PropTypes.number,
  maxLengthSoft: PropTypes.number,
};

export default Text;
