import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import Datetime from 'react-datetime';
import moment from 'moment';
import PropTypes from 'prop-types';

const Schedule = ({ formData, setFormData, errors }) => {
  const handleStartDateChange = (value) => {
    setFormData({ ...formData, start_at: value.format('YYYY-MM-DD') });
  };

  const isValidStartDate = (current) => !current.isBefore(moment(), 'day');

  const isValidEndDate = (current) => current.isSameOrAfter(formData.start_at, 'day');

  return (
    <Form.Group className='mb-3' controlId='formStartDate'>
      <Form.Label className='font-weight-bold mb-1'>Schedule</Form.Label>
      <Form.Text className='text-muted'>Set the date the store will be live from.</Form.Text>
      <Row>
        <Col>
          <Datetime
            value={formData.start_at}
            dateFormat='YYYY-MM-DD'
            timeFormat={false}
            isValidDate={isValidStartDate}
            onChange={handleStartDateChange}
            inputProps={{ disabled: formData?.is_already_approved }}
          />
          {errors?.start_at && <Form.Text className='text-danger'>{errors.start_at}</Form.Text>}
        </Col>
        <Col md={1} className='py-2 text-nowrap text-center'>
          To
        </Col>
        <Col>
          <Datetime
            value={formData.stop_at}
            dateFormat='YYYY-MM-DD'
            timeFormat={false}
            isValidDate={isValidEndDate}
            inputProps={{ disabled: true }}
          />
          {errors?.stop_at && <Form.Text className='text-danger'>{errors.stop_at}</Form.Text>}
        </Col>
      </Row>
    </Form.Group>
  );
};

Schedule.propTypes = {
  formData: PropTypes.shape({
    start_at: PropTypes.string,
    stop_at: PropTypes.string,
    is_already_approved: PropTypes.bool,
  }).isRequired,
  setFormData: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    start_at: PropTypes.string,
    stop_at: PropTypes.string,
  }),
};

export default Schedule;
