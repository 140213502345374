import React from 'react';
import { Col, Row } from 'react-bootstrap';
import MetricPermissions from '../../features/organisation_settings/components/MetricPermissions';
import { FacebookPermissions } from './FacebookPermissions';
import { GooglePermissions } from './GooglePermissions';
import { MetaManagedPartnerAdSettings } from './MetaManagedPartnerAdSettings';
import AdSettings from '../../features/organisation_settings/components/AdSettings';
import { CHANNELS_MANAGEMENT_FACEBOOK, CHANNELS_MANAGEMENT_GOOGLE } from '../../lib/permissions';

export const GlobalSettingsTab = ({
  control,
  permissions,
  adSettings,
  adTypes,
  organisation,
  userPermissions,
  hasFacebookMPAEnabled,
  ENABLE_META_SETTINGS_FEATURE,
}) => {
  return (
    <>
      <Row className='mb-3'>
        <div className='rounded pl-3 d-flex'>
          To change settings for a specific supplier, click on the edit button next to the supplier’s name under Manage
          Supplier below.
        </div>
      </Row>

      <Row className='mb-4'>
        <Col lg='12' xs='12'>
          <MetricPermissions isGeneralSettings={true} permissionsData={permissions} />
        </Col>
      </Row>

      <Row>
        {userPermissions.includes(CHANNELS_MANAGEMENT_FACEBOOK) && (
          <Col>
            <FacebookPermissions control={control} adSettings={adSettings} organisation={organisation} />
          </Col>
        )}
        {userPermissions.includes(CHANNELS_MANAGEMENT_GOOGLE) && (
          <Col>
            <GooglePermissions control={control} adSettings={adSettings} organisation={organisation} />
          </Col>
        )}
      </Row>

      {ENABLE_META_SETTINGS_FEATURE && hasFacebookMPAEnabled && (
        <Row>
          <MetaManagedPartnerAdSettings control={control} adSettings={adSettings} />
        </Row>
      )}

      <Row className='mb-2'>
        <Col lg='12' xs='12'>
          <div className='d-sm-flex justify-content-between align-items-center my-3'>
            <h5 className='text-dark mb-0'>Ad Types</h5>
          </div>
          <hr />

          <AdSettings adTypes={adTypes} adSettings={adSettings} />
        </Col>
      </Row>
    </>
  );
};
