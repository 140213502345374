import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import styled from 'styled-components';

import broadsign_schedule_modal_illustration from './broadsign_shedule_modal_illustration.svg';

import 'react-datepicker/dist/react-datepicker.css';

import { DatePickerInput } from './DatePickerInput';
import { DatePickerHeader } from './DatePickerHeader';

const Container = styled.div`
  width: 100%;
  padding: 0 80px;
  background-color: white;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 32px;

  .illustration {
    min-width: 135px;
    min-height: 135px;
    position: relative;
    background-color: #eaecf0;
    border-radius: 50%;
    overflow: hidden;
  }

  .infoText {
    font-size: 16px;
    line-height: 1.4;
    color: black;
  }
`;

const DatePickerWrapper = styled.div`
  .react-datepicker {
    border-radius: 4px;
    border: none;
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.25);
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__header {
    background-color: white;
    border: none;
  }

  .react-datepicker__day--keyboard-selected {
    background-color: unset;
  }

  .react-datepicker__day--range-start,
  .react-datepicker__day--selecting-range-start {
    background-color: #7155ff;
    color: #fff;
  }

  .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--selecting-range-start) {
    background-color: #e8e3fe;
    color: rgba(0, 0, 0, 0.87);
  }

  .react-datepicker__day--in-range:not(.react-datepicker__day--range-start):not(.react-datepicker__day--range-end) {
    background-color: #d0c6ff;
    color: rgba(0, 0, 0, 0.87);
  }

  .react-datepicker__day--range-end {
    background-color: #7155ff;
  }

  .react-datepicker__day {
    border-radius: 50%;
  }
`;

const areDatesValid = (start, end) => {
  return start && end && start.getDay() === 3 && end.getDay() === 2;
};

export const DateRangeSection = ({
  startOfWeekDay = 3,
  startDate,
  endDate,
  onChangeDateRange,
  errorMessage,
  onError,
}) => {
  const isSelectableDate = (date) => {
    if (startDate && endDate) {
      const d = moment(date);
      return date.getDay() === startOfWeekDay && d.isAfter(moment(), 'day');
    } else if (startDate) {
      return date.getDay() === startOfWeekDay - 1 && moment(date).isAfter(moment(startDate), 'day');
    }
    return date.getDay() === startOfWeekDay;
  };

  const resetDates = () => {
    onChangeDateRange(null, null);
    onError('');
  };

  const handleChangeDateRange = ([start, end]) => {
    onError('');

    if (start && start.getDay() !== 3) {
      onError('Start date must be a Wednesday');
      onChangeDateRange(null, null);
      return;
    }
    if (end && end.getDay() !== 2) {
      onError('End date must be a Tuesday');
      onChangeDateRange(start, null);
      return;
    }

    onChangeDateRange(start, end);
  };

  return (
    <Container>
      <img className='illustration' src={broadsign_schedule_modal_illustration} alt='placeholder' />

      <div className='infoText'>
        To view availability, select a date range in the dropdown to the right.
        <br />
        Bookings are <span style={{ fontWeight: 700 }}>in week timeframes</span>, and <span style={{ fontWeight: 700 }}>if booking multiple weeks they must be consecutive.</span>
        <div style={{ marginBottom: '11px' }}></div>
        <span style={{ fontWeight: 700 }}>Select a date range on the right to get started.</span>
      </div>

      <DatePickerWrapper>
        <DatePicker
          selected={startDate}
          startDate={startDate}
          endDate={endDate}
          dateFormat='d MMMM'
          selectsRange
          selectsDisabledDaysInRange
          minDate={new Date()}
          customInput={<DatePickerInput />}
          filterDate={isSelectableDate}
          onCalendarOpen={() => {
            if (!areDatesValid(startDate, endDate)) {
              resetDates();
            }
          }}
          onChange={handleChangeDateRange}
          renderCustomHeader={(props) => <DatePickerHeader {...props} />}
        />
        <div style={{ color: 'red', marginTop: '10px', minHeight: '20px' }}>{errorMessage}</div>
      </DatePickerWrapper>
    </Container>
  );
};

DateRangeSection.propTypes = {
  startOfWeekDay: PropTypes.number,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  onChangeDateRange: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
};
