import React, {useCallback, useEffect, useRef, useState} from "react";

export const useInfiniteScroll = (action: () => void, deps: React.DependencyList=[]) => {
    const ref = useRef<HTMLDivElement>(null);
    // The running attribute fixes the issue of triggering the scroll multiple times. This ensures
    // that the scroll action can't be trigger while an action is already running.
    const [running, setRunning] = useState<boolean>(false);

    const handleScroll = useCallback(() => {
        const element = ref.current;
        if (
            element
            && Math.abs(element.scrollHeight - element.scrollTop - element.clientHeight) < 10
            && !running
        ) {
            setRunning(true);
            action();
            setRunning(false);
        }
    }, deps);

    useEffect(() => {
        const element = ref.current;
        if (element) {
            element.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (element) {
                element.removeEventListener('scroll', handleScroll);
            }
        };
    }, [handleScroll]);

    return ref;
}
