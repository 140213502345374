import React from 'react'
import OrganisationLogo from "./OrganisationLogo";
import OrganisationLabel from "./OrganisationLabel";

type OrganisationLogotypeProps = {
    organisation: {
        name: string,
        logo?: string,
    },
}

const OrganisationLogotype = ({organisation}: OrganisationLogotypeProps) => {
    if (organisation.logo !== undefined) {
        return <OrganisationLogo src={organisation.logo}/>;
    }

    return <OrganisationLabel label={organisation.name}/>
}

export default OrganisationLogotype