// @ts-ignore
import React from "react";
import {Form} from "react-bootstrap";
import {useSelector} from "react-redux";
import {selectOrderFormSettings} from "../orderFormSlice";
import Money from "../../common/inputs/Money";
import {Settings} from "../../organisations/organisations";


type AdBudgetProps = {
    controlId: string,
    readOnly: boolean,
    required: boolean,
    adType: string,
    label: string,
    value?: number,
    placeholder: string,
    onChange: (arg0?: number) => void,
    errors: string[],
    currency: string,
};

type BudgetLabelProps = {
    label: string,
};

const BudgetLabel = ({label}: BudgetLabelProps) => <div className="d-flex">
    <Form.Label className="font-weight-bold">
        {label}
    </Form.Label>
</div>;

const createBudgetLabel = (label: string, currency: string, minBudget?: number): JSX.Element => {
    if (label === '') {
        return <></>;
    }

    if (!minBudget) {
        return <BudgetLabel label={label}/>;
    }

    return <BudgetLabel label={`${label} (Min budget: ${currency + minBudget})`}/>;
};

const AdBudget = ({adType, label = "Budget", ...props}: AdBudgetProps): JSX.Element => {
    const settings: Settings = useSelector(selectOrderFormSettings);
    const {currency} = props;
    const minBudget: number | undefined = settings.min_budget?.[adType];
    const Label = createBudgetLabel(label, currency, minBudget);
    
    return <Money label={Label} {...props} />;
};

export default AdBudget;
