import React from 'react'

type ErrorsProps = {
    errors: string[],
};

const Errors = ({errors = []}: ErrorsProps) => <div>
    {errors.map((e, i) => <div key={i} style={{wordWrap: 'break-word'}}>{e}</div>)}
</div>

export default Errors
