import React from 'react'
import { Card } from 'react-bootstrap'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { useSelector } from 'react-redux'
import { selectOwnerOrganisation } from '../../orderFormSlice'
import styles from './google_carousel.module.scss'

const GoogleProductCarousel = ({ assets, isPreviewMode = true, logo }) => {
    let organisation = useSelector(selectOwnerOrganisation)

    return (
        <div className={styles.mw_85}>
            <div style={{ color: "#808080", fontSize: "10px", textAlign: "center", width: "100%" }}>Not actual display of the ad on the device</div>
            <div className="mb-2" style={{
                width: '100%',
                fontFamily: 'Arial',
            }}>
                <Carousel
                    additionalTransfrom={0}
                    arrows={true}
                    autoPlaySpeed={3000}
                    centerMode
                    className="w-100"
                    afterChange={(previousSlide, { currentSlide, onMove }) => {
                    }}
                    containerClass=""
                    dotListClass=""
                    draggable
                    focusOnSelect={true}
                    itemClass={styles.ad_item}
                    keyBoardControl
                    minimumTouchDrag={80}
                    renderButtonGroupOutside={false}
                    renderDotsOutside={false}
                    responsive={{
                        desktop: {
                            breakpoint: {
                                max: 3000,
                                min: 1024
                            },
                            items: 3,
                            partialVisibilityGutter: 40
                        },
                        mobile: {
                            breakpoint: {
                                max: 464,
                                min: 0
                            },
                            items: 1,
                            partialVisibilityGutter: 30
                        },
                        tablet: {
                            breakpoint: {
                                max: 1024,
                                min: 464
                            },
                            items: 2,
                            partialVisibilityGutter: 30
                        }
                    }}
                    showDots={false}
                    sliderClass=""
                    slidesToSlide={1}
                    swipeable
                >
                    {assets.map((media, i) => (
                        <div style={{ margin: '5px', width: '100%', height: '309px' }}>
                            <Card className="d-flex text-left mt-5" style={{ flex: 1, margin: '5px', height: '309px' }} key={i}>
                                <div className="card-block text-center" style={{ height: '168px', width: '100%' }}>
                                    <Card.Img variant="top" style={{ padding: '3px', objectFit: 'cover', height: '168px' }} className={(isPreviewMode) ? styles.ad_media_preview : ""} src={media.image_url} />
                                </div>
                                <Card.Body style={{ padding: '8px' }}>
                                    <a
                                        href={media.link}
                                        target="_blank"
                                        rel="noreferrer"
                                        title={media.name}
                                        style={{
                                            color: '#1a0dab',
                                            fontSize: '16px',
                                            lineHeight: '24px',
                                            height: '48px',
                                            display: '-webkit-box',
                                            overflow: 'hidden',
                                            WebkitBoxOrient: 'vertical',
                                            WebkitLineClamp: '2',
                                            whiteSpace: 'normal',
                                            paddingBottom: '4px',
                                        }}
                                    >
                                        {media.name}
                                    </a>
                                    <div style={{
                                        fontWeight: 'bold',
                                        fontSize: '16px',
                                        lineHeight: '24px',
                                        height: '24px',
                                        color: '#202124',
                                        letterSpacing: '0.2px',
                                    }}>
                                        ${media.price}
                                    </div>
                                    <div style={{
                                        fontSize: '16px',
                                        lineHeight: '24px',
                                        height: '24px',
                                        color: '#202124',
                                        letterSpacing: '0.2px',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                    }}>{organisation.name}</div>
                                    <div style={{
                                        fontSize: '15px',
                                        lineHeight: '24px',
                                        height: '24px',
                                        color: '#70757a',
                                        letterSpacing: '0.2px',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                    }}>Free delivery</div>
                                </Card.Body>
                            </Card>
                        </div>
                    ))}
                </Carousel>
            </div>
            {logo?.url &&
                <div className="text-center">
                    <img
                        style={{ maxWidth: '100%' }}
                        src={logo?.url}
                        alt=""
                    />
                </div>
            }
        </div>
    )
}

GoogleProductCarousel.propTypes = {
}

export default GoogleProductCarousel
