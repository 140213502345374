// TODO: We are using a hard coded list of verticals and labels until an API is added to the backend
export const META_VERTICALS = [
    {
        value: 'ADVERTISING',
        label: 'Advertising',
    },
    {
        value: 'AUTOMOTIVE',
        label: 'Automotive',
    },
    {
        value: 'CONSUMER_PACKAGED_GOODS',
        label: 'Consumer Packaged Goods',
    },
    {
        value: 'ECOMMERCE',
        label: 'Ecommerce',
    },
    {
        value: 'EDUCATION',
        label: 'Education',
    },
    {
        value: 'ENERGY_AND_UTILITIES',
        label: 'Energy And Utilities',
    },
    {
        value: 'ENTERTAINMENT_AND_MEDIA',
        label: 'Entertainment And Media',
    },
    {
        value: 'FINANCIAL_SERVICES',
        label: 'Financial Services',
    },
    {
        value: 'GAMING',
        label: 'Gaming',
    },
    {
        value: 'GOVERNMENT_AND_POLITICS',
        label: 'Government And Politics',
    },
    {
        value: 'MARKETING',
        label: 'Marketing',
    },
    {
        value: 'ORGANIZATIONS_AND_ASSOCIATIONS',
        label: 'Organizations And Associations',
    },
    {
        value: 'PROFESSIONAL_SERVICES',
        label: 'Professional Services',
    },
    {
        value: 'RETAIL',
        label: 'Retail',
    },
    {
        value: 'TECHNOLOGY',
        label: 'Technology',
    },
    {
        value: 'TELECOM',
        label: 'Telecom',
    },
    {
        value: 'TRAVEL',
        label: 'Travel',
    },
    {
        value: 'OTHER',
        label: 'Other',
    },
];
