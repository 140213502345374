import React from "react";
import propTypes from "prop-types";
import { Controller, useFormContext, useFormState } from "react-hook-form";
import { Form, InputGroup } from "react-bootstrap";
import { getCurrencySymbol } from "../../../../lib/wallets";
import StyledCurrencyBadge from "./StyledCurrencyBadge";

const WalletBalance = ({
  currency
}) => {
  
  const methods = useFormContext();
  const { control } = methods;
  const { errors, isSubmitted, touchedFields } = useFormState();

  return (
    <Form.Group className="position-relative">
      <StyledCurrencyBadge currency={currency} />
      <Form.Label>Initial Balance *</Form.Label>
      <InputGroup>
        <InputGroup.Text>{getCurrencySymbol(currency)}</InputGroup.Text>
        <Controller
          name="initial_balance"
          control={control}
          render={({ field, fieldState: { invalid } }) => (
            <Form.Control
              type="text"
              aria-label="Initial Balance"
              {...field}
              isInvalid={
                (isSubmitted || touchedFields?.initial_balance) &&
                errors?.initial_balance?.message
              }
            />
          )}
        />
        {(isSubmitted || touchedFields?.initial_balance) && (
          <Form.Control.Feedback type="invalid">
            {errors?.initial_balance?.message}
          </Form.Control.Feedback>
        )}
      </InputGroup>
    </Form.Group>
  );
};

WalletBalance.prototype = {
  currency: propTypes.string.isRequired,
};

export default WalletBalance;
