import axios from '../../axios'
import FormData from 'form-data'
import {filterKeys} from "../util/objects";

export async function postAsset(file, { onUploadProgress } = { onUploadProgress: () => { } }) {
    let form = new FormData()

    form.append('mediafile', file)

    return axios.post(`/orders/assets`, form, {
        onUploadProgress: onUploadProgress,
        timeout: 0,
    })
}

export async function postFacebookAsset(file, { onUploadProgress, placements, automatic, ratio } = { onUploadProgress: () => { } }) {
    let params = new URLSearchParams()
    let form = new FormData()

    if (automatic || automatic === undefined) {
        form.append('automatic', 1)
    }

    // Need to put placements in form since it can return a 414 error.
    if (placements !== undefined) {
        form.append('placements', JSON.stringify(placements))
    }

    if (ratio !== undefined) {
        form.append('ratio', ratio)
    }

    form.append('mediafile', file)

    return axios.post(`/orders/assets/facebook?${params.toString()}`, form, {
        onUploadProgress: onUploadProgress,
        timeout: 0,
    })
}

export async function deleteAsset(fid) {
    return axios.delete(`/orders/assets/${fid}`);
}

const facebookPreviewKeys = [
    'link',
    'body',
    'headline',
    'linkdesc',
    'cta',
    'media',
    'asset_ratios',
    'created_at',
];

export async function adPreview(owner, ad, placement, {cancelSignal}) {
    let previewFields = filterKeys(ad, facebookPreviewKeys);

    if (placement) {
        previewFields.placement = placement;
    }

    if (Array.isArray(previewFields.media)) {
        previewFields.media = previewFields.media?.[0];
    }

    return axios.post(`/orders/preview/${owner}`, previewFields,
        {
            signal: cancelSignal,
            timeout: 60000,
        });
}

export async function downloadPOE(file) {
    const fileUrl = `/bespoke/poe/${file.id}`
    return axios({
      url: fileUrl,
      method: 'GET',
      responseType: 'blob',
    });
  }

  export async function deletePOE(poeId,adId) {
    const fileUrl = `bespoke/poe/${adId}/${poeId}`
    return axios({
      url: fileUrl,
      method: 'DELETE',
      responseType: 'json',
    });
  }
