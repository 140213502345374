import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styles from '../owned_space_section.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

export const TemplateTab = ({ webSpace, templates, handleTemplateSelection, template }) => (
  <div className={styles.form_section}>
    <Container>
      <Row>
        <Col sm='12'>
          {webSpace && templates.length > 0 && (
            <div style={{ marginTop: '20px' }}>
              <p className='text-center'>{templates.length} Templates Available</p>
              <Row className='mx-0'>
                {templates.map((templ) => {
                  const isChecked = templ.id === template?.id;
                  const isProduct = templ.format === 'product';

                  return (
                    <Col
                      key={templ.id}
                      md={isProduct ? 6 : 12}
                      xs={12}
                      onClick={() => handleTemplateSelection(templ)}
                      className={`px-2 ${styles.template_selection_card} ${isChecked && 'checked'}`}
                    >
                      <div className='d-flex align-items-center'>
                        <div className={`${styles.template_selection_checkbox} ${isChecked && 'checked'}`}>
                          {isChecked && <FontAwesomeIcon icon={faCheck} color='#fff' />}
                        </div>
                        <h6 className='m-0 ml-2'>{templ.name}</h6>
                      </div>
                      {templ?.meta_fields?.preview_url && (
                        <img className='w-100 mt-2' src={templ?.meta_fields?.preview_url} alt={templ.name} />
                      )}
                    </Col>
                  );
                })}
              </Row>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  </div>
);
