import PropTypes from 'prop-types';
import { Button, Col } from 'react-bootstrap';
import AssetsPane from '../components/standard_ads/AssetsPane';

export const AssetsTab = ({
  readOnly,
  structure,
  ad,
  assetFieldNames,
  assetValues,
  handleChange,
  assetFieldStructures,
  assetErrors,
  execution,
  userActiveOrganisation,
  organisation,
  updateOrder,
  order,
  dispatch,
  clearOrder,
  orderUpdated,
  clearSession,
  errorsUpdate,
}) => {
  return (
    <>
      <AssetsPane
        readOnly={readOnly && !execution}
        adFormat={structure}
        ad={ad}
        fieldNames={assetFieldNames}
        value={assetValues}
        onChange={handleChange}
        assetFieldStructures={assetFieldStructures}
        errors={assetErrors}
      />
      {execution && (
        <Col>
          {userActiveOrganisation.id === organisation.id && (
            <Button
              className='ml-2'
              variant='outline-primary'
              onClick={(e) => {
                e.preventDefault();
                updateOrder(order, order.id)
                  .then((resp) => {
                    dispatch(clearOrder());
                    dispatch(orderUpdated(resp.data.data));
                  })
                  .catch((err) => {
                    if (err.response?.status === 403) {
                      dispatch(clearSession());
                    } else if (err.response?.data?.errors) {
                      dispatch(errorsUpdate(err.response.data.errors));
                    }
                  });
              }}
            >
              Update
            </Button>
          )}
        </Col>
      )}
    </>
  );
};

AssetsTab.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  structure: PropTypes.object.isRequired,
  ad: PropTypes.object.isRequired,
  assetFieldNames: PropTypes.array.isRequired,
  assetValues: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  assetFieldStructures: PropTypes.array.isRequired,
  assetErrors: PropTypes.array.isRequired,
  execution: PropTypes.bool,
  userActiveOrganisation: PropTypes.object.isRequired,
  organisation: PropTypes.object.isRequired,
  updateOrder: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  clearOrder: PropTypes.func.isRequired,
  orderUpdated: PropTypes.func.isRequired,
  clearSession: PropTypes.func.isRequired,
  errorsUpdate: PropTypes.func.isRequired,
};
