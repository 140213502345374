export const USER_MANAGEMENT = 'user management';
export const INVITE_SUPPLIERS = 'invite suppliers';
export const INVITE_EMPLOYEE = 'invite employee';
export const BULK_INVITE = 'bulk invite';
export const CREATE_ORDERS = 'create orders';
export const PUBLISH_ORDERS = 'publish orders';
export const APPROVE_OR_REJECT_ORDERS = 'approve or reject orders';
export const VIEW_ALL_ORDERS = 'view all orders';
export const ORDER_REQUEST_REVIEW = 'order request review';
export const ORDER_ARCHIVE = 'order archive';
export const EDIT_PENDING_ORDERS = 'edit pending orders';
export const EDIT_APPROVED_ORDERS = 'edit approved orders';
export const EDIT_LIVE_ORDERS = 'edit live orders';
export const VIEW_ORDER_METRICS = 'view order metrics';
export const CHANNELS_MANAGEMENT_FACEBOOK = 'channels management facebook';
export const CHANNELS_MANAGEMENT_GOOGLE = 'channels management google';
export const OWNED_CHANNELS_MANAGEMENT_WEB = 'owned channels management web';
export const ACCESS_REPORTS = 'access reports';
export const MANAGE_SUPPLIER_SETTINGS = 'manage supplier settings';
export const MANAGE_ORGANISATION_SETTINGS = 'manage organisation settings';
export const BUILD_PACKAGES = 'build packages';
export const WALLET_MANAGEMENT = 'wallet management';
