import styles from './select_block_type_modal.module.scss';
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import BlockTypeTile from './BlockTypeTile';

export const AddBlockModal = ({
  showModal,
  handleClose,
  blockTypes,
  handleAddBlock,
  currentPageId,
}) => {
  const [selectedBlockType, setSelectedBlockType] = useState(null);

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      dialogClassName={styles.modal_dialog}
      centered
    >
      <Modal.Header className={styles.header_footer_block} closeButton>
        <Modal.Title className={styles.heading_text}>
          Select your Block type
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={styles.block_type_tiles_container}>
          {blockTypes.map((blockType) => (
            <BlockTypeTile
              key={blockType.id}
              blockType={blockType}
              isSelected={selectedBlockType === blockType}
              onSelect={() => setSelectedBlockType(blockType)}
            />
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer className={styles.header_footer_block}>
        <Button
          disabled={!selectedBlockType}
          onClick={() => {
            handleAddBlock(currentPageId, selectedBlockType);
            setSelectedBlockType(null);
            handleClose();
          }}
          className={styles.add_button}
        >
          Add
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
