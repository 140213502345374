import React, { useCallback, useEffect, useState } from 'react';
import propTypes from 'prop-types';
import TransactionsView from './TransactionsView';
import WalletsView from './WalletsView';
import { getSupplierWallets } from '../../lib/api/wallets';
import { useRequest } from '../../lib/hooks/useRequest';
import { useSelector } from 'react-redux';
import { selectUserOrganisation } from '../session/sessionSlice';
import { Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const OrganisationWallets = ({ organisation, isSupplier = false }) => {
  const [view, setView] = useState('wallets');
  const [transactionWallet, setTransactionWallet] = useState(null);
  const [wallets, setWallets] = useState([]);
  const userActiveOrganisation = useSelector(selectUserOrganisation);
  const organisationDefaultCurrency = userActiveOrganisation?.settings?.defaultCurrency ?? '';

  const { response, loading, error, refresh } = useRequest(() => getSupplierWallets(organisation?.id));

  useEffect(() => {
    if (response !== null) {
      setWallets(response?.data);
    }
  }, [response]);

  const handleWalletSelection = useCallback((wallet) => {
    setTransactionWallet(wallet);
    setView('transactions');
  }, []);

  const changeView = useCallback(() => {
    setView('wallets');
    refresh();
  }, []);

  useEffect(() => {
    if (transactionWallet) {
      setTransactionWallet(wallets.find((w) => w.id === transactionWallet.id) ?? null);
    }
  }, [wallets]);

  if (organisationDefaultCurrency?.length === 0) {
    return (
      <Alert variant='danger'>
        Wallet requires a default organisation currency to be set. Please{' '}
        <Link to='/organisation/settings'>update</Link> your organisation's currency.
      </Alert>
    );
  }

  return (
    <>
      {view === 'wallets' ? (
        <WalletsView
          currency={organisationDefaultCurrency}
          organisation={organisation}
          wallets={wallets}
          handleWalletSelection={handleWalletSelection}
          refreshWallets={refresh}
          isSupplier={isSupplier}
          loading={loading}
          error={error}
        />
      ) : (
        <TransactionsView walletID={transactionWallet?.id} isSupplier={isSupplier} changeView={changeView} />
      )}
    </>
  );
};

OrganisationWallets.propTypes = {
  organisation: propTypes.shape({
    id: propTypes.isRequired,
    name: propTypes.string.isRequired,
  }),
  isSupplier: propTypes.bool,
};

export default OrganisationWallets;
