import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

const BaseUrlText = ({ formData, readOnly = false, setFormData, errors }) => {
  const handleBaseUrlChange = (e) => {
    setFormData({ ...formData, base_url: e.target.value });
  };

  return (
    <Form.Group className='mb-3' controlId='formBaseUrl'>
      <Form.Label className='font-weight-bold mb-1'>Base Url</Form.Label>
      <Form.Text className='text-muted'>
        Set the URL where this store will be hosted. This will need to be whitelisted in your website’s configuration.
        Supplier will be able to define sub-URLs for their pages using this base url.
      </Form.Text>
      <Form.Control
        className={`mt-2 ${errors?.base_url?.length > 0 ? 'is-invalid' : ''}`}
        type='text'
        readOnly={readOnly}
        placeholder=''
        value={formData.base_url}
        onChange={handleBaseUrlChange}
      />
      <Form.Text className='text-muted'>
        {errors?.base_url?.length > 0 && <div className='text-danger'>{errors?.base_url[0]}</div>}
      </Form.Text>
    </Form.Group>
  );
};

BaseUrlText.propTypes = {
  formData: PropTypes.shape({
    base_url: PropTypes.string,
  }),
  setFormData: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    base_url: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default BaseUrlText;
