import { connect } from "react-redux";
import { selectSession } from "../../session/sessionSlice";
import ProductPermissions from "../components/ProductPermissions";
import { productsUpdate, selectReadPermissions } from "../organisationSettingsSlice";

const mapStateToProps = state => {
    const productSetCatalogues = selectSession(state).platform_catalogs.google
    const catalogueIds = productSetCatalogues.map(catalogue => catalogue.id)

    return {
        title: 'Products',
        catalogueValue: selectReadPermissions(state)?.product_catalogs?.filter(catalogue => catalogue.platform === 'google'),
        productSetValue: selectReadPermissions(state)?.product_sets?.filter(productSet => productSet.platform === 'google' && catalogueIds.includes(productSet.catalog_id)),
        catalogues: selectSession(state).platform_catalogs.google,
        disableCatalogues: true,
    }
}

const mapDispatchToProps = dispatch => ({
    onChange: (catalogues, productSets) => dispatch((dispatch, getState) => {
        const permissions = selectReadPermissions(getState())
        const filteredCatalogues = []

        const nonGoogleProductSets = (permissions.product_sets || []).filter(productSet => productSet.platform !== 'google')
        const filteredProductSets =  nonGoogleProductSets.concat(productSets)
        dispatch(productsUpdate([filteredCatalogues, filteredProductSets]))
    })
})


export default connect(mapStateToProps, mapDispatchToProps)(ProductPermissions)
