import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ConnectedInsightsView from '../metrics/ConnectedInsightsView';

export const MetricsTab = ({ ad, currency}) => (
  <Container>
    <Row>
      <Col sm='12'>
        <ConnectedInsightsView insights={ad?.web_ad_campaign?.insights || {}} refreshedAt={ad?.web_ad_campaign?.refreshed_at} currency={currency} />
      </Col>
    </Row>
  </Container>
);
