import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { ProductSelectorModal } from './ProductSelectorModal';
import styles from '../../content_tab.module.scss';
import { ActionButton } from '../../../PagesTabComponents/ActionButton';
import { faBars } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { DragDropCard } from '../../../../DragDropComponents/DragDropCard';
import properties_styles from '../../../../brand_page_properties.module.scss';
import Errors from '../../../../../../order_form/components/Errors';

const ProductPreview = ({ product, onClickRemove, readOnly }) => {
  return (
    <>
      <div className={styles.block_header}>
        <div className={styles.left_side}>
          <FontAwesomeIcon
            icon={faBars}
            className={styles.summary_bars_icon}
            style={readOnly && { color: 'transparent' }}
          />
          <img src={product.image_url} alt={product.name} className={styles.product_image} />
          <div className={styles.product_name}>{product.name}</div>
        </div>
        <div className={styles.right_side}>{!readOnly && <ActionButton type='remove' onClick={onClickRemove} />}</div>
      </div>
    </>
  );
};

export const ProductPreviewList = ({ products, removeProduct, moveProduct, readOnly, variableErrors, minHeight }) => {
  if (variableErrors?.length > 0) {
    return (
      <div className={properties_styles.form_error_text_products}>
        <Errors errors={variableErrors} />
      </div>
    );
  }
  if (!products || products.length === 0) {
    return <div className={styles.products_preview_empty}>No products have been selected.</div>;
  }
  return (
    <div className={`${styles.scrollable_products_preview_list} ${minHeight ? styles.min_products_area_height : ''}`}>
      {products.length > 0 && products.map((product, index) => {
        const ProductComponent = (
          <React.Fragment key={index}>
            <ProductPreview
              key={product.id}
              product={product}
              className={styles.product_preview_item}
              onClickRemove={() => {
                removeProduct(product.id);
              }}
              readOnly={readOnly}
            />
            {index !== products.length - 1 && <hr className={styles.product_preview_hr} />}
          </React.Fragment>
        );

        return readOnly ? (
          ProductComponent
        ) : (
          <DragDropCard key={product.id} id={product.id} index={index} moveCard={moveProduct} type={'productCard'}>
            {ProductComponent}
          </DragDropCard>
        );
      })}
    </div>
  );
};

export const ProductVariable = ({ handleChange, selectedValues, readOnly, constraints, variableErrors, productSets, info = null }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalOpen = () => setIsModalOpen(true);
  const handleModalClose = () => setIsModalOpen(false);
  const removeProduct = (productId) => {
    const newFeed = selectedValues.filter((product) => product.id !== productId);
    handleChange(newFeed);
  };

  const moveProduct = (fromIndex, toIndex) => {
    let newFeed = [...selectedValues];
    const feedItemMoving = newFeed[fromIndex];
    newFeed.splice(fromIndex, 1);
    newFeed.splice(toIndex, 0, feedItemMoving);

    handleChange(newFeed);
  };

  return (
    <>
      {info && <Form.Label className={styles.product_info_text}>
        <FontAwesomeIcon icon={faInfoCircle} /> {info}
      </Form.Label>}

      <div className={`${styles.products_preview_area} ${!selectedValues?.length ? styles.center_items : ''}`}>
        <ProductPreviewList
          products={selectedValues}
          removeProduct={removeProduct}
          moveProduct={moveProduct}
          readOnly={readOnly}
          constraints={constraints}
          variableErrors={variableErrors}
          minHeight={false}
        />
        {!readOnly && (
          <>
            <hr className={styles.product_preview_hr} />
            <Button variant='outline-secondary' className={`btn btn-outline-secondary ${styles.attach_products_button}`} onClick={handleModalOpen}>
              + Attach Products
            </Button>
            {isModalOpen && (
              <ProductSelectorModal
                isOpen={isModalOpen}
                handleClose={handleModalClose}
                readOnly={readOnly}
                selectedValues={selectedValues}
                productSets={productSets}
                minProducts={constraints?.min || 1}
                maxProducts={constraints?.max ?? 128}
                handleChange={handleChange}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};
