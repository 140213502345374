import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Col, Container, Figure, Form, Row } from "react-bootstrap";
import styled from "styled-components";
import {
  OWNED_SPACE_BANNERS,
  OWNED_SPACE_PRODUCTS,
  NATIVE_BANNER,
  NATIVE_PRODUCT,
} from "../../../../lib/webads";
import Accordian from "../common/Accordian";



const PlacementTypeGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: center;
`;

const PlacementFigure = styled(Figure)`
  border: 3px solid ${(props) => (props.selected ? "#7155FF" : "transparent")};
  border-radius: 8px;
  cursor: pointer;
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
`;

export const SuccessIcon = styled.span`
  color: #7ee79b;
  margin-right: 10px;
`;

const PlacementTile = ({
  imgUrl = "",
  label = "",
  selected = false,
  onClick = () => {},
  disabled = false,
  readOnly = true,
}) => {
  return (
    <div>
      <PlacementFigure
        selected={selected}
        disabled={disabled || readOnly}
        onClick={(e) => !readOnly && !disabled && onClick(e)}
      >
        <Figure.Image src={imgUrl} />
        <Figure.Caption>
          <b>{label}</b>
        </Figure.Caption>
      </PlacementFigure>
    </div>
  );
};

const filterSpaceFormat = (ad, space) => {
  if (ad.type === NATIVE_BANNER) {
    return space.format === OWNED_SPACE_BANNERS;
  }

  if (ad.type === NATIVE_PRODUCT) {
    return space.format === OWNED_SPACE_PRODUCTS;
  }

  return true;
};

const Placement = ({
  readOnly = true,
  ad = {},
  placementTypes = [],
  webSpaces = [],
  onChange = () => {},
}) => {
  const webSpace = webSpaces.find((webSpace) => webSpace.id === ad.webspace_id);
  const [placementType, setPlacementType] = useState(
    placementTypes.find(
      (placementType) => placementType.id === webSpace?.subformat
    )
  );
  const [stage, setStage] = useState(
    placementType ? "ad_space" : "placement_type"
  );

  const subformatSpaces = placementType
    ? webSpaces.filter(
        (space) =>
          placementType.id === space.subformat && filterSpaceFormat(ad, space)
      )
    : [];

  const filteredPlacements = placementTypes.filter(placement =>
   !(webSpaces.filter(space => placement.id === space.subformat && filterSpaceFormat(ad, space)).length === 0)
  )

  const handlePlacementClick = (placement) => {
    setPlacementType(placement);
    setStage("ad_space");
  };
  const handleAdSpaceClick = (webSpace) => {
    onChange(webSpace);
    setStage(undefined);
  };

  const handleCollapse = (curStage) =>
    curStage === stage ? setStage("") : setStage(curStage);

  if (filteredPlacements.length === 1 && filteredPlacements[0].id !== placementType?.id) {
    handlePlacementClick(filteredPlacements[0])
  }

  useEffect(() => {
    if (subformatSpaces.length === 1 && subformatSpaces[0].id !== ad.webspace_id) {
      handleAdSpaceClick(subformatSpaces[0])
    }
  }, [ad.webspace_id, subformatSpaces])

  return (
    <Container>
      <Row className="mb-4">
        <Col>
          <Accordian
            onCollapse={(e) => handleCollapse("placement_type")}
            open={stage === "placement_type"}
            header={
              <span>
                {placementType && (
                  <SuccessIcon>
                    <FontAwesomeIcon icon={faCheckCircle} size="lg" />
                  </SuccessIcon>
                )}
                <span>
                  1. Placement Type{" "}
                  {placementType && ": " + placementType.label}
                </span>
              </span>
            }
          >
            <PlacementTypeGrid>
              {placementTypes.map((placement) => (
                <PlacementTile
                  key={placement.id}
                  label={placement.label}
                  selected={placementType?.id === placement.id}
                  imgUrl={placement.icon_url}
                  onClick={(e) => handlePlacementClick(placement)}
                  disabled={
                    webSpaces.filter(
                      (space) =>
                        placement.id === space.subformat &&
                        filterSpaceFormat(ad, space)
                    ).length === 0
                  }
                  readOnly={readOnly}
                />
              ))}
            </PlacementTypeGrid>
          </Accordian>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <Accordian
            onCollapse={(e) => handleCollapse("ad_space")}
            open={stage === "ad_space"}
            header={
              <span>
                {ad.webspace_id &&
                  placementType &&
                  subformatSpaces.find(
                    (webSpace) => ad.webspace_id === webSpace.id
                  ) && (
                    <SuccessIcon>
                      <FontAwesomeIcon icon={faCheckCircle} size="lg" />
                    </SuccessIcon>
                  )}
                <span>2. Ad Space {webSpace && ": " + webSpace.name}</span>
              </span>
            }
          >
            <div>
              {placementType &&
                subformatSpaces.map((webSpace) => (
                  <Form.Check
                    type="radio"
                    key={webSpace.id}
                    label={webSpace.name}
                    checked={ad.webspace_id === webSpace.id}
                    onChange={(e) => handleAdSpaceClick(webSpace)}
                    id={ad.id + '-' + webSpace.id}
                    readOnly={readOnly}
                    disabled={readOnly}
                  />
                ))}
            </div>
          </Accordian>
        </Col>
      </Row>
    </Container>
  );
};

export default Placement;
