import PropTypes from 'prop-types'
import AdSection from '../AdSection'
import PackageSection from '../PackageSection'

const AdForms = ({ readOnly = false, isOwner = false, packages = [], ads = [], bespoke = [], assets = [], errors = {}, onChange = () => { }, onDelete = () => { }, extra = {} }) => {

    const packageGroups = ads.reduce((finalVal, ad) => {
        if (finalVal.length === 0) {
            return [[ad]]
        }

        let lastVal = finalVal[finalVal.length - 1]

        if (lastVal[0].package_collection_id && lastVal[0].package_collection_id === ad.package_collection_id) {
            lastVal.push(ad)
            finalVal[finalVal.length - 1] = lastVal
            return finalVal
        }

        return [...finalVal, [ad]]
    }, [])

    return <div>
        {packageGroups.map((adsArray, i) => {
            const ad = adsArray[0]
            const index = ads.findIndex(a => a.id === ad.id)

            if (adsArray.length === 1 && !ad.package_id) {

                return <AdSection
                    key={ad.id}
                    readOnly={readOnly}
                    index={index}
                    controlId={`data_json.${index}`}
                    ad={ad}
                    bespoke={bespoke}
                    assets={assets}
                    errors={errors}
                    onChange={ad => onChange(ad)}
                    onDelete={_ => onDelete(ad)}
                    extra={extra}
                />
            }

            return <PackageSection
                key={ad.package_collection_id}
                readOnly={readOnly}
                index={index}
                isOwner={isOwner}
                packageOffer={packages.find(pkg => ad.package_id === pkg.id)}
                ads={adsArray}
                assets={assets}
                onChange={(result) => onChange(result)}
                onDelete={(index) => onDelete(index)}
                errors={errors}
                extra={extra}
            />
        })}
    </div>
}

AdForms.propTypes = {
    readOnly: PropTypes.bool,
    isOwner: PropTypes.bool,
    ads: PropTypes.array,
    package: PropTypes.array,
    assets: PropTypes.array,
    onChange: PropTypes.func,
    onDelete: PropTypes.func,
    extra: PropTypes.shape({ inStoreScreensTabIsReadOnly: PropTypes.bool }),
}

export default AdForms
