import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Badge, Form, OverlayTrigger, Tooltip, Modal, Button, Row, Col, Popover } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { snakeCaseToTitleCase, titleCase, trimEllipses } from '../../../lib/strings';
import FacebookAudienceTargeting from './FacebookAudienceTargeting';
import GoogleAudienceTargeting from './GoogleAudienceTargeting';
import styles from './select_audience.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { selectOwnerOrganisation } from '../orderFormSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/pro-light-svg-icons';
import { getAudienceReachEstimate, createStandardAudienceTemplate } from '../../../lib/api';
import { useEffect } from 'react';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { refreshSession, selectStandardAudienceTemplates } from '../../session/sessionSlice';
import ErrorsFormFeedback from '../../common/ErrorsFormFeedback';
import useDebounce from '../../../lib/hooks/useDebounce';

const REACH_ESTIMATE_DEBOUNCE_WAIT = 300;

const valueText = (type, value) => {
  if (type === 'gender') {
    return Object.keys(value)
      .filter((k) => value[k])
      .map((k) => titleCase(k))
      .join(', ');
  } else if (type === 'age') {
    return value.join(', ');
  } else if (type === 'min_age' || type === 'max_age') {
    return value;
  } else if (type === 'locations') {
    return value.map((v) => v.label).join(', ');
  } else if (type === 'interests') {
    return value.map((v) => v.label).join(', ');
  } else if (type === 'behaviours') {
    return value.map((v) => v.label).join(', ');
  } else if (type === 'income_range') {
    return `${value.join(', ')} percentile`;
  } else if (type === 'parental_status') {
    return value;
  } else if (type === 'keywords') {
    return value.map((v) => `${v.phrase} (${v.type})`).join(', ');
  }

  return '';
};

const extractAudienceFromModal = (dbAudience) => {
  let audience = { ...dbAudience.audience };
  audience.id = dbAudience.id;
  audience.name = dbAudience.name;
  return audience;
};

const AudienceBadget = ({ id, audience, targetingType, adAudiences }) => {
  if (!valueText(targetingType, audience[targetingType])) {
    return '';
  }

  if (adAudiences.includes(targetingType)) {
    const text = valueText(targetingType, audience[targetingType]);

    return (
      <Badge pill variant='info' title={text}>
        {snakeCaseToTitleCase(targetingType)}: {trimEllipses(text, 25)}
      </Badge>
    );
  }

  // TODO: Fix for some reason tooltip not showing
  return (
    <OverlayTrigger
      placement='top'
      overlay={<Tooltip id={`${id}-tooltip`}>Audience targeting type not available for this ad type.</Tooltip>}
    >
      <Badge pill variant='dark' className='d-flex align-items-center'>
        {snakeCaseToTitleCase(targetingType)}: {valueText(targetingType, audience[targetingType])}
      </Badge>
    </OverlayTrigger>
  );
};

const AudienceTargetingModal = ({
  controlId,
  readOnly = false,
  as: As,
  adType,
  audience = {},
  onChange = () => {},
  filter = [],
  errors = {},
  platform = '',
  order,
}) => {
  const [value, setValue] = useState(audience);
  const [audienceSize, setAudienceSize] = useState('Unavailable');
  const [requestErrors, setRequestErrors] = useState([]);
  const [requestStatus, setRequestStatus] = useState('idle');
  const [selectionChanged, setSelectionChanged] = useState(true);
  const [audienceSelectionCategory, setAudienceSelectionCategory] = useState('broad');
  const [isDirty, setIsDirty] = useState(false);
  const [showTemplateCreate, setShowTemplateCreate] = useState(false);
  const [showTemplateSelect, setShowTemplateSelect] = useState(false);
  const [audienceTemplateName, setAudienceTemplateName] = useState('');
  const [templateSearchFilter, setTemplateSearchFilter] = useState('');
  const [showConfirmModal, setShowConfirmModal] = useState(null);
  const dispatch = useDispatch();
  const audienceTemplates = useSelector(selectStandardAudienceTemplates).filter(
    (audience) => audience.platform === platform
  );

  const handleChange = (newValue) => {
    setValue(newValue);
    setIsDirty(true);
  };

  const onDelete = (obj, id) => {
    let newValue = Object.keys(value).reduce((result, key) => {
      if (id !== null) {
        let tmp = null;
        if (key === obj) {
          tmp = value[key].filter((e, i) => i !== id);
        }
        result[key] = tmp ?? value[key];
      } else {
        if (Array.isArray(obj) && !obj.includes(key) && id === null) {
          result[key] = value[key];
        } else if (!Array.isArray(obj) && key !== obj) {
          result[key] = value[key];
        }
      }

      return result;
    }, {});
    setValue(newValue);
    setSelectionChanged(true);
    handleChange(newValue);
  };

  const debounceHandler = useDebounce((platform, order, value, selectionChanged) => {
    if (platform === 'facebook' && selectionChanged && order?.owner_id) {
      setSelectionChanged(false);
      setRequestStatus('loading');
      setRequestErrors([]);
      getAudienceReachEstimate(platform, order.owner_id, value)
        .then((res) => {
          if (res.data.data?.users_lower_bound && res.data.data?.users_upper_bound) {
            setAudienceSize(
              res.data.data.users_lower_bound.toLocaleString() +
                ' - ' +
                res.data.data.users_upper_bound.toLocaleString()
            );

            //assign specific selection type
            if (parseInt(res.data.data.users_lower_bound) < 2000) {
              setAudienceSelectionCategory('specific');
            } else if (
              parseInt(res.data.data.users_lower_bound) >= 2000 &&
              parseInt(res.data.data.users_lower_bound) < 50000
            ) {
              setAudienceSelectionCategory('mid');
            } else if (parseInt(res.data.data.users_lower_bound) >= 50000) {
              setAudienceSelectionCategory('broad');
            }
          } else {
            setAudienceSize('Unavailable');
          }
          setRequestStatus('success');
        })
        .catch((err) => {
          if (err.response.status === 400) {
            setRequestErrors(err.response.data.errors);
          }
          setRequestStatus('failed');
          setAudienceSize('Unavailable');
        });
    }
  }, REACH_ESTIMATE_DEBOUNCE_WAIT);

  useEffect(() => {
    debounceHandler(platform, order, value, selectionChanged);
  }, [selectionChanged, value]);

  const handleCreateTemplate = () => {
    createStandardAudienceTemplate(audienceTemplateName, platform, value).then((resp) => {
      let payload = resp.data.data;
      if (payload) {
        let audience = extractAudienceFromModal(payload);
        handleChange(audience);
        setIsDirty(false);
      }
      setAudienceTemplateName('');
      dispatch(refreshSession());
      setShowTemplateCreate(false);
    });
  };

  return (
    <>
      <div
        style={{
          height: 'auto',
          backgroundColor: '#f4f4f4',
          'padding-bottom': '15px',
          minHeight: '60px',
        }}
      >
        <div style={{ fontSize: '12px', marginTop: '5px', marginLeft: '15px' }}>
          Audience Summary {value.name && !isDirty && `for template audience ${value.name}`}
        </div>
        {platform && platform === 'facebook' && (
          <div>
            <Row>
              <Col sm='8' lg='10'>
                {value &&
                  value.locations &&
                  Array.isArray(value.locations) &&
                  value.locations.map((location, i) => (
                    <div className={styles.chip}>
                      Lives in {location.name} {location?.radius ? ',Radius ' + location.radius : ''}
                      <span
                        className={styles.closebtn}
                        onClick={(e) => {
                          onDelete('locations', i);
                        }}
                      >
                        &times;
                      </span>
                    </div>
                  ))}
                {value && value.gender && (value.gender.male || value.gender.female) && (
                  <div className={styles.chip}>
                    {value.gender.male ? (value.gender.female ? 'Male and Female' : 'Is Male') : 'Is Female'}
                    <span
                      className={styles.closebtn}
                      onClick={(e) => {
                        onDelete('gender', null);
                      }}
                    >
                      &times;
                    </span>
                  </div>
                )}
                {value && value.min_age && value.max_age && (
                  <div className={styles.chip}>
                    Age is between {value.min_age} and {value.max_age === 65 ? '65+' : value.max_age}
                    <span
                      className={styles.closebtn}
                      onClick={(e) => {
                        onDelete(['min_age', 'max_age'], null);
                      }}
                    >
                      &times;
                    </span>
                  </div>
                )}
                {value && value.min_age && !value.max_age && (
                  <div className={styles.chip}>
                    Age is {value.min_age} or over
                    <span
                      className={styles.closebtn}
                      onClick={(e) => {
                        onDelete(['min_age', 'max_age'], null);
                      }}
                      style={{ display: value.min_age === 18 ? 'none' : 'inline' }}
                    >
                      &times;
                    </span>
                  </div>
                )}
                {value &&
                  value.interests &&
                  value.interests.length > 0 &&
                  value.interests.map((interest, i) => (
                    <div className={styles.chip}>
                      Interested in {interest.name}
                      <span
                        className={styles.closebtn}
                        onClick={(e) => {
                          onDelete('interests', i);
                        }}
                      >
                        &times;
                      </span>
                    </div>
                  ))}
                {value &&
                  value.behaviours &&
                  value.behaviours.length > 0 &&
                  value.behaviours.map((behaviour, i) => (
                    <div className={styles.chip}>
                      Behaviour: {behaviour.name}
                      <span
                        className={styles.closebtn}
                        onClick={(e) => {
                          onDelete('behaviours', i);
                        }}
                      >
                        &times;
                      </span>
                    </div>
                  ))}
              </Col>
              <Col sm='4' lg='2' style={{ height: '110px' }}>
                <Row className='justify-content-md-center'>
                  <Col xs={12} sm={4} md={7}>
                    {!selectionChanged &&
                      audienceSize !== 'Unavailable' &&
                      (audienceSelectionCategory === 'broad' || audienceSelectionCategory === 'mid') && (
                        <div>
                          <div style={{ width: '80px' }}>
                            <img src='/center.svg' alt='Broad or defined selection' />
                          </div>
                        </div>
                      )}
                    {!selectionChanged &&
                      audienceSize !== 'Unavailable' &&
                      audienceSelectionCategory === 'specific' && (
                        <div>
                          <div style={{ width: '80px' }}>
                            <img src='/left.svg' alt='Specific selection' />
                          </div>
                        </div>
                      )}
                  </Col>
                </Row>
                <Row className='justify-content-md-center'>
                  <Col xs={12} sm={8} md={10}>
                    <div className={styles.reachDiv}>
                      {!selectionChanged && audienceSize !== 'Unavailable' && (
                        <>
                          <div className={styles.reachDivLabel}>Audience Reach</div>
                          <span>{audienceSize}</span>
                        </>
                      )}
                      {selectionChanged && (
                        <FontAwesomeIcon
                          className='fa-spin'
                          icon={faSpinner}
                          size='2x'
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '110px',
                            margin: '0 auto',
                          }}
                        />
                      )}
                      {!selectionChanged &&
                        audienceSize !== 'Unavailable' &&
                        (audienceSelectionCategory === 'broad' || audienceSelectionCategory === 'mid') && (
                          <div>(Selection is fairly broad)</div>
                        )}
                      {!selectionChanged &&
                        audienceSize !== 'Unavailable' &&
                        audienceSelectionCategory === 'specific' && <div>(Selection is specific)</div>}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        )}
        {platform && (platform === 'google' || platform === 'youtube') && (
          <div>
            {value &&
              value.locations &&
              value.locations.map((location, i) => (
                <div className={styles.chip}>
                  Lives in {location?.name} {location?.radius ? 'and Radius is ' + location.radius + ' Km(s)' : ''}
                  <span
                    className={styles.closebtn}
                    onClick={(e) => {
                      onDelete('locations', i);
                    }}
                  >
                    &times;
                  </span>
                </div>
              ))}
            {value && value.gender && (value.gender.male || value.gender.female || value.gender.unknown) && (
              <div className={styles.chip}>
                {value.gender.male
                  ? value.gender.female
                    ? 'Male and Female'
                    : 'Is Male'
                  : value.gender.female
                  ? 'Is Female'
                  : ''}
                {(value.gender.male || value.gender.female) && value.gender.unknown
                  ? ' and Unknown'
                  : !value.gender.male && !value.gender.female && value.gender.unknown
                  ? 'Gender Unknown'
                  : ''}
                <span
                  className={styles.closebtn}
                  onClick={(e) => {
                    onDelete('gender', null);
                  }}
                >
                  &times;
                </span>
              </div>
            )}
            {value && value.min_age && value.max_age && (
              <div className={styles.chip}>
                Age is between {value.min_age} and {value.max_age === 65 ? '65+' : value.max_age}
                <span
                  className={styles.closebtn}
                  onClick={(e) => {
                    onDelete(['min_age', 'max_age'], null);
                  }}
                >
                  &times;
                </span>
              </div>
            )}
            {value && value.min_age && !value.max_age && (
              <div className={styles.chip}>
                Age is {value.min_age} or over
                <span
                  className={styles.closebtn}
                  onClick={(e) => {
                    onDelete(['min_age', 'max_age'], null);
                  }}
                  style={{ display: value.min_age === 18 ? 'none' : 'inline' }}
                >
                  &times;
                </span>
              </div>
            )}
            {value && value.age && value.age.length > 0 && (
              <div className={styles.chip}>
                Age is {value.age.join()}
                <span
                  className={styles.closebtn}
                  onClick={(e) => {
                    onDelete('age', null);
                  }}
                >
                  &times;
                </span>
              </div>
            )}
            {value && value.income_range && value.income_range.length > 0 && (
              <div className={styles.chip}>
                Income range is {value.income_range.join()}
                <span
                  className={styles.closebtn}
                  onClick={(e) => {
                    onDelete('income_range', null);
                  }}
                >
                  &times;
                </span>
              </div>
            )}
            {value && value.parental_status && (
              <div className={styles.chip}>
                Parental status is{' '}
                {value.parental_status === 'parent'
                  ? 'parent'
                  : value.parental_status === 'not-parent'
                  ? 'not a parent'
                  : 'undetermined'}
                <span
                  className={styles.closebtn}
                  onClick={(e) => {
                    onDelete('parental_status', null);
                  }}
                >
                  &times;
                </span>
              </div>
            )}
            {value &&
              value.interests &&
              value.interests.length > 0 &&
              value.interests.map((interest, i) => (
                <div className={styles.chip}>
                  Interested in {interest.name}
                  <span
                    className={styles.closebtn}
                    onClick={(e) => {
                      onDelete('interests', i);
                    }}
                  >
                    &times;
                  </span>
                </div>
              ))}
            {value &&
              value.behaviours &&
              value.behaviours.length > 0 &&
              value.behaviours.map((behaviour, i) => (
                <div className={styles.chip}>
                  Affinity Audience: {behaviour.name}
                  <span
                    className={styles.closebtn}
                    onClick={(e) => {
                      onDelete('behaviours', i);
                    }}
                  >
                    &times;
                  </span>
                </div>
              ))}
          </div>
        )}
        <div style={{ marginTop: '5px', marginLeft: '15px' }}>
          <ErrorsFormFeedback errors={requestErrors} />
        </div>
      </div>
      <Modal.Body className='pb-0'>
        <As
          controlId={controlId}
          adType={adType}
          readOnly={readOnly}
          audience={value}
          filter={filter}
          onChange={(audience) => {
            if (!audience.id) {
              audience = { id: uuidv4(), ...audience };
            }
            setValue(audience);
            setSelectionChanged(true);
            handleChange(audience);
          }}
          errors={errors}
        />
      </Modal.Body>
      <Modal.Footer className='d-flex'>
        <OverlayTrigger
          trigger='click'
          show={showTemplateCreate}
          rootClose
          onToggle={(e) => setShowTemplateCreate(!showTemplateCreate)}
          placement='top'
          overlay={
            <Popover>
              <Popover.Title as='h3'>Input Template Name</Popover.Title>
              <Popover.Content className='d-flex'>
                <Form.Control
                  type='text'
                  inline
                  value={audienceTemplateName}
                  invalid={audienceTemplateName.length === 0}
                  onChange={(e) => setAudienceTemplateName(e.target.value)}
                />
                <Button variant='outline-primary' className='rounded-0 ml-2' size='sm' onClick={handleCreateTemplate}>
                  Save
                </Button>
              </Popover.Content>
            </Popover>
          }
        >
          <Button
            variant='outline-secondary'
            className={`rounded-0 ${styles.template_btn}`}
            size='sm'
            disabled={value.name && !isDirty}
            onClick={(_) => setShowTemplateCreate(true)}
          >
            Save as template
          </Button>
        </OverlayTrigger>
        <OverlayTrigger
          trigger='click'
          show={showTemplateSelect}
          rootClose
          onToggle={(e) => setShowTemplateSelect(!showTemplateSelect)}
          placement='top'
          overlay={
            <Popover>
              <Popover.Title as='h3'>Select Template</Popover.Title>
              <Popover.Content>
                <Form.Control
                  className='mb-3'
                  value={templateSearchFilter}
                  onChange={(e) => setTemplateSearchFilter(e.target.value)}
                />
                <hr />
                <div style={{ maxHeight: '10rem', overflowY: 'auto' }}>
                  {audienceTemplates.length > 0 ? (
                    audienceTemplates
                      .filter((audience) => audience.name.toLowerCase().includes(templateSearchFilter.toLowerCase()))
                      .map((audience) => (
                        <div className='mb-2' key={audience.id}>
                          <u
                            className='text-primary'
                            style={{ cursor: 'pointer' }}
                            onClick={(e) => {
                              let a = extractAudienceFromModal(audience);
                              if (value.name && !isDirty) {
                                handleChange(a);
                                setIsDirty(false);
                                setShowTemplateSelect(false);
                                setSelectionChanged(true);
                              } else {
                                setShowConfirmModal(a);
                              }
                            }}
                          >
                            {audience.name}
                          </u>
                        </div>
                      ))
                  ) : (
                    <span>No Audiences have been saved</span>
                  )}
                </div>
              </Popover.Content>
            </Popover>
          }
        >
          <Button
            variant='outline-secondary'
            className={`rounded-0 ${styles.template_btn}`}
            size='sm'
            onClick={(e) => setShowTemplateSelect(true)}
          >
            Load a template
          </Button>
        </OverlayTrigger>
        <Button
          className='ml-auto'
          variant='outline-primary'
          disabled={requestErrors.length > 0 || requestStatus === 'loading'}
          onClick={(e) => {
            e.preventDefault();
            onChange(value);
          }}
        >
          Save
        </Button>
      </Modal.Footer>

      <Modal show={showConfirmModal !== null} onHide={(e) => setShowConfirmModal(null)}>
        <Modal.Header>Confirm change.</Modal.Header>
        <Modal.Body>Loading this template will override your current audience.</Modal.Body>
        <Modal.Footer>
          <Button variant='outline-secondary rounded-0' onClick={(_) => setShowConfirmModal(null)}>
            Cancel
          </Button>
          <Button
            variant='outline-danger rounded-0'
            onClick={(_) => {
              handleChange(showConfirmModal);
              setIsDirty(false);
              setShowConfirmModal(null);
              setSelectionChanged(true);
            }}
          >
            Overwrite
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

AudienceTargetingModal.propTypes = {
  controlId: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  as: PropTypes.func.isRequired,
  adType: PropTypes.string.isRequired,
  audiences: PropTypes.object,
  filter: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  errors: PropTypes.object,
};

const SelectAudience = ({
  controlId,
  readOnly = false,
  adType,
  platform,
  order,
  audience = {},
  adAudiences,
  value = {},
  onChange = () => {},
  onSelect = () => {},
  errors = [],
}) => {
  const [modal, setModal] = useState('');
  const closeModal = () => setModal('');
  const [audienceErrors, setAudienceErrors] = useState({});
  const ownerOrg = useSelector(selectOwnerOrganisation);
  const [showTemplateSelect, setShowTemplateSelect] = useState(false);
  const [templateSearchFilter, setTemplateSearchFilter] = useState('');
  const audienceTemplates = useSelector(selectStandardAudienceTemplates).filter(
    (audience) => audience.platform === platform
  );

  const handleEdit = (e) => {
    if (readOnly) {
      return;
    }
    if (platform === 'google' || platform === 'youtube') {
      setModal('google_audiences');
    } else if (platform === 'facebook') {
      setModal('facebook_audiences');
    }
  };

  return (
    <section>
      <Form.Group controlId={controlId}>
        <Form.Label>
          <b>Standard Audience</b>
        </Form.Label>
        <div
          className='form-control d-flex align-items-center'
          style={{ minHeight: 'calc(1.5em + 0.75rem + 2px)', height: 'auto' }}
        >
          <span>
            {Object.keys(audience).length === 0 ? (
              !readOnly && (
                <>
                  <span onClick={handleEdit} className='text-primary' style={{ cursor: 'pointer', fontSize: '12px' }}>
                    <u>Build new audience</u>
                  </span>
                  {audienceTemplates.length > 0 && (
                    <>
                      <span className='text-muted'> | </span>
                      <OverlayTrigger
                        trigger='click'
                        show={showTemplateSelect}
                        rootClose
                        onToggle={(e) => setShowTemplateSelect(!showTemplateSelect)}
                        placement='top'
                        overlay={
                          <Popover>
                            <Popover.Title as='h3'>Select Template</Popover.Title>
                            <Popover.Content>
                              <Form.Control
                                className='mb-3'
                                value={templateSearchFilter}
                                onChange={(e) => setTemplateSearchFilter(e.target.value)}
                              />
                              <hr />
                              <div style={{ maxHeight: '10rem', overflowY: 'auto' }}>
                                {audienceTemplates.length > 0 ? (
                                  audienceTemplates
                                    .filter((audience) =>
                                      audience.name.toLowerCase().includes(templateSearchFilter.toLowerCase())
                                    )
                                    .map((audience) => (
                                      <div className='mb-2' key={audience.id}>
                                        <u
                                          className='text-primary'
                                          style={{ cursor: 'pointer' }}
                                          onClick={(e) => {
                                            let a = extractAudienceFromModal(audience);
                                            onChange(a);
                                            setShowTemplateSelect(false);
                                          }}
                                        >
                                          {audience.name}
                                        </u>
                                      </div>
                                    ))
                                ) : (
                                  <span>No Audiences have been saved</span>
                                )}
                              </div>
                            </Popover.Content>
                          </Popover>
                        }
                      >
                        <span
                          className='text-primary rounded-0'
                          size='sm'
                          onClick={(e) => setShowTemplateSelect(true)}
                          style={{ cursor: 'pointer', fontSize: '12px' }}
                        >
                          <u>Load from template</u>
                        </span>
                      </OverlayTrigger>
                    </>
                  )}
                </>
              )
            ) : (
              <div className='d-flex flex-wrap justify-contents-center' style={{ gap: '0.3rem' }} onClick={handleEdit}>
                {Object.keys(audience)
                  .filter((key) => key !== 'id')
                  .map((key) => (
                    <AudienceBadget
                      key={key}
                      id={`${controlId}-${audience.id}-${key}`}
                      audience={audience}
                      targetingType={key}
                      adAudiences={adAudiences}
                    />
                  ))
                  .filter((s) => s !== '')}
              </div>
            )}
          </span>
          {!readOnly && Object.keys(audience).length > 0 && (
            <span className='ml-auto' onClick={handleEdit} style={{ cursor: 'pointer' }}>
              <FontAwesomeIcon icon={faEdit} />
            </span>
          )}
        </div>

        {errors.map((error) => (
          <div style={{ color: '#dc3544', fontSize: '12px', marginTop: '5px' }}>{error}</div>
        ))}
      </Form.Group>

      <Modal size='xl' show={modal === 'facebook_audiences'} onHide={closeModal} enforceFocus={false}>
        <Modal.Header closeButton>Select Facebook audience targeting</Modal.Header>
        <AudienceTargetingModal
          controlId={`${controlId}-facebook-audience`}
          as={FacebookAudienceTargeting}
          platform='facebook'
          order={order}
          adType={adType}
          readOnly={readOnly}
          audience={audience}
          errors={audienceErrors}
          onChange={(audience) => {
            if (
              !ownerOrg?.settings?.defaultLocations?.facebook &&
              (!Array.isArray(audience?.locations) || audience.locations.length === 0)
            ) {
              setAudienceErrors({ locations: ['Location is required.'] });
              return;
            }
            setAudienceErrors({});
            onChange(audience);
            closeModal();
          }}
        />
      </Modal>

      <Modal size='xl' show={modal === 'google_audiences'} onHide={closeModal} enforceFocus={false}>
        <Modal.Header closeButton>Select Google audience targeting</Modal.Header>
        <AudienceTargetingModal
          controlId={`${controlId}-google-audience`}
          as={GoogleAudienceTargeting}
          platform='google'
          readOnly={readOnly}
          adType={adType}
          audience={audience}
          filter={adAudiences}
          errors={audienceErrors}
          onChange={(audience) => {
            if (
              !ownerOrg?.settings?.defaultLocations?.google &&
              (!Array.isArray(audience?.locations) || audience.locations.length === 0)
            ) {
              setAudienceErrors({ locations: ['Location is required.'] });
              return;
            }
            setAudienceErrors({});
            onChange(audience);
            closeModal();
          }}
        />
      </Modal>
    </section>
  );
};

SelectAudience.propTypes = {
  controlId: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  adType: PropTypes.string.isRequired,
  platform: PropTypes.string.isRequired,
  order: PropTypes.object.isRequired,
  audience: PropTypes.array,
  adAudiences: PropTypes.arrayOf(PropTypes.string).isRequired,
  value: PropTypes.object,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
};

export default SelectAudience;
