import React, { useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { strcmp } from '../../lib/strings';
import useOrdersLogic from '../../features/orders/hooks/useOrdersLogic';
import Orders from '../../features/orders/components/Orders';

const sortBy = [
  { value: 'status', label: 'Status' },
  { value: 'name', label: 'Name' },
  { value: 'start_date', label: 'Start Date' },
  { value: 'end_date', label: 'End Date' },
  { value: 'created_date', label: 'Created Date' },
  { value: 'last_updated', label: 'Last Updated' },
];

const sortByFuncs = {
  status: (a, b) => strcmp(a.status.toLowerCase(), b.status.toLowerCase()),
  name: (a, b) => strcmp(a.name.toLowerCase(), b.name.toLowerCase()),
  start_date: (a, b) => new Date(b.start_at) - new Date(a.start_at),
  end_date: (a, b) => new Date(b.stop_at) - new Date(a.stop_at),
  created_date: (a, b) => new Date(b.created_at) - new Date(a.created_at),
  last_updated: (a, b) => new Date(b.updated_at) - new Date(a.updated_at),
  campaign_type: (a, b) => strcmp(a.format, b.format),
  supplier: (a, b) => strcmp(a.supplier_id, b.supplier_id),
  retailer: (a, b) => strcmp(a.owner_id, b.owner_id),
};

const DashboardOrders = () => {
  const {
    data: orders,
    error,
    isLoading,
  } = useOrdersLogic({
    archived: false,
  });

  const [filter, setFilter] = useState({
    sort_by: 'start_date',
    status: 'pending_approval',
    platform: '',
  });

  const filteredAndSortedOrders = useMemo(
    () => orders?.data?.data.filter((order) => order.status === 'pending_approval').sort(sortByFuncs[filter.sort_by]),
    [orders, filter]
  );

  return (
    <div>
      <div className='d-flex align-items-center'>
        <h5 className='mb-0'>
          New Orders (Up to 20) <Link to='/orders'>(All Orders)</Link>
        </h5>
        <div className='ml-auto'>
          <Form.Group className='mb-0 d-flex align-items-center'>
            <Form.Label className='mb-0' style={{ width: '7rem' }}>
              Sort by:
            </Form.Label>
            <Form.Control
              as='select'
              value={filter.sort_by}
              onChange={(e) => setFilter({ ...filter, sort_by: e.target.value })}
            >
              {sortBy.map((sort) => (
                <option key={sort.value} value={sort.value}>
                  {sort.label}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </div>
      </div>
      <hr />
      <Orders data={filteredAndSortedOrders} error={error} isLoading={isLoading} />
    </div>
  );
};

export default DashboardOrders;
