import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Navigate, Outlet } from 'react-router-dom';
import { clearSession, refreshSession, selectUser, selectAuthToken } from '../features/session/sessionSlice';
import styled from 'styled-components';

const StyledLoaderWrapper = styled.div`
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;

  p {
    font-size: 1.2em;
  }
`;

const loadTheme = (theme) => {
    const existingThemeCss = document.getElementById("theme-css");
    if (existingThemeCss) { existingThemeCss.remove(); }
    const linkElement = document.createElement("link");
    linkElement.setAttribute("id","theme-css");
    linkElement.setAttribute("rel", "stylesheet");
    linkElement.setAttribute("type", "text/css");
    linkElement.setAttribute("href","/themes/" + theme + "/theme.css");
    document.head.appendChild(linkElement);    
}

const ProtectedRoutes = ({children}) => {
  const [loading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const authToken = useSelector(selectAuthToken);
  const user = useSelector(selectUser);
  const location = useLocation();
  const isLogoutRoute = location.pathname === '/logout';

  const refresh = useCallback(async () => {
    try {
      const res = await dispatch(refreshSession());

      const theme = res.payload?.user?.active_organisation?.settings?.theme;
      if (theme) {
        loadTheme(theme);
      }

      console.log(res);
      if(res && res.error) {
        dispatch(clearSession());
      }
    } catch (e) {
      dispatch(clearSession());
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!authToken || isLogoutRoute) {
      setIsLoading(false);
      return;
    }
    if (!user) {
      refresh();
    } else {
      setIsLoading(false);
    }
  }, []);

  if (!authToken) {
    return <Navigate to='/login' replace />;
  }

  return (
    <>
      {loading ? (
        <StyledLoaderWrapper>
          <p>The page is loading, please wait.</p>
          <FontAwesomeIcon className='fa-spin' style={{ cursor: 'pointer' }} icon={faSpinner} size='4x' />
        </StyledLoaderWrapper>
      ) : (
        <Outlet />
      )}
    </>
  );
};

export default ProtectedRoutes;
