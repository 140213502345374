import {Asset} from "../../types";
import {aspectRatio} from "../../../../lib/aspect_ratio";

export const reorderAssets = (value: Asset[]): Asset[] => {
  const landscapeAsset = value.find((f) => aspectRatio(f.metadata.width, f.metadata.height) === '1.91:1')
  const squareAsset = value.find(f => aspectRatio(f.metadata.width, f.metadata.height) === '1:1')
  const restOfAssets = value.filter(f => ![landscapeAsset?.id, squareAsset?.id].includes(f.id))

  return [landscapeAsset, squareAsset, ...restOfAssets].filter(f => f !== undefined);
}
