import { connect } from 'react-redux'
import { selectWebSubformats } from '../../../session/sessionSlice'
import Placement from '../../components/WebAds/Placement'

const mapStateToProps = state => ({
    placementTypes: selectWebSubformats(state),
})


export default connect(mapStateToProps)(Placement)
