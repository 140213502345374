import moment from 'moment';
import { DATE_FORMAT, STORE_DATE_FORMAT } from '../constants';

/** Get a copy of order state that is prepared for submit functions **/
export const transformOrderForRequest = (order) => {
  //if there are no pages in the order, there is nothing to transform
  if (!order?.pages.length) return order;

  // Create a deep copy of the order
  const orderCopy = JSON.parse(JSON.stringify(order));
  const baseUrl = orderCopy.base_url;

  // set start and end date for home page
  orderCopy.pages[0].page_start_date = order.start_at;
  orderCopy.pages[0].page_stop_date = order.stop_at;

  // map page ID to it's full URL for block links
  const pageUrlMap = new Map();
  orderCopy.pages.forEach((page) => {
    const urlSuffix = page.page_url && page.page_url.charAt(0) !== '/' ? '/' + page.page_url : page.page_url || '';
    const transformedUrl = baseUrl + urlSuffix;
    pageUrlMap.set(page.id, transformedUrl);
  });

  // resolve links to pages to their url string
  orderCopy.pages.forEach((page) => {
    page.json_schema.blocks.forEach((block) => {
      Object.entries(block.data).forEach(([key, value]) => {
        // If the value is a link, transform it from an object to a string url
        if (value && value.linkType) {
          switch (value.linkType) {
            case 'brandPageLink':
              block.data[key] = pageUrlMap.get(value.brandPageLink);
              break;
            default:
              block.data[key] = value.externalUrl;
              break;
          }
        }
      });
    });
  });
  return orderCopy;
};

export const tranformOrderFromResponse = (order, blockTypes) => {
  // Create a deep copy of the order
  const orderCopy = JSON.parse(JSON.stringify(order));
  const baseUrl = orderCopy.base_url;

  // map page URL to its ID for block links
  const pageIdMap = new Map();
  orderCopy.pages.forEach((page) => {
    const fullUrl = baseUrl + page.page_url;
    pageIdMap.set(fullUrl, page.id);
  });

  // Create a map for quick access to blockType information by id
  const blockTypeMap = new Map();
  blockTypes.forEach((blockType) => {
    blockTypeMap.set(blockType.id, blockType);
  });

  // Resolve string urls to links to pages or external links
  orderCopy.pages.forEach((page) => {
    page.json_schema.blocks.forEach((block) => {
      // Check if blockType exists for this block
      const blockType = blockTypeMap.get(block.block_id);
      if (blockType) {
        blockType.meta.variables.forEach((variable) => {
          if (!block.data.hasOwnProperty(variable.key)) {
            block.data[variable.key] = undefined;
          }
          // transform string link values into object
          if (variable.type === 'link') {
            if (pageIdMap.has(block.data[variable.key])) {
              block.data[variable.key] = {
                brandPageLink: pageIdMap.get(block.data[variable.key]),
                externalUrl: '',
                linkType: 'brandPageLink',
              };
            } else if (block.data[variable.key] === null || block.data[variable.key] === '') {
              block.data[variable.key] = {
                brandPageLink: '',
                externalUrl: null,
                linkType: 'noLink',
              };
            } else {
              block.data[variable.key] = {
                brandPageLink: '',
                externalUrl: block.data[variable.key],
                linkType: 'externalUrl',
              };
            }
          }
          // If the key is 'products' and value is undefined, set value to empty array
          if (variable.type === 'products') {
            block.data[variable.key] = block.data[variable.key] || [];
          }
        });
      }
    });
  });

  return orderCopy;
};

export const pageNameExists = (pages, name) => {
  return pages.findIndex((page) => page.page_name === name) !== -1;
};

export const getBlankPage = (defaultName, newPageId, storeStartDate, storeStopDate, isHomePage = false) => {
  return {
    id: newPageId,
    page_name: defaultName,
    page_url: '',
    is_home_page: isHomePage,
    is_date_inherited_from_parent: true,
    page_start_date: storeStartDate,
    page_stop_date: storeStopDate,
    json_schema: {
      schema_version: '1',
      blocks: [],
    },
  };
};

export const transformConfigureFormFromResponse = (configureForm) => {
  let configureFormCopy = JSON.parse(JSON.stringify(configureForm));
  configureFormCopy.start_at = moment(configureForm.start_at).format(DATE_FORMAT);
  configureFormCopy.stop_at = moment(configureForm.stop_at).format(DATE_FORMAT);

  return configureFormCopy;
};

export const transformConfigureFormForRequest = (configureForm) => {
  let configureFormCopy = JSON.parse(JSON.stringify(configureForm));
  configureFormCopy.start_at = moment(configureForm.start_at).format(STORE_DATE_FORMAT).toString();
  configureFormCopy.stop_at = moment(configureForm.stop_at).format(STORE_DATE_FORMAT).toString();
  configureFormCopy.wallet = {
    owner_id: configureFormCopy.supplier_id,
    id: configureFormCopy.wallet.id,
  };
  return configureFormCopy;
};
