import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'react-bootstrap';
import styled from 'styled-components';

export const statusMap = {
  'not-started': 'Not Started',
  new: 'Not Started',
  submitted: 'In Progress',
  failed: 'Unsuccessful',
  completed: 'Completed',
};

const Container = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 14px;

  .badge-not-started {
    background-color: #dadada;
  }

  .badge-new {
    background-color: #dadada;
  }

  .badge-submitted {
    color: #000000;
    background-color: #b5d0f7;
  }

  .badge-failed {
    color: #ffffff;
    background-color: #ef767a;
  }

  .badge-completed {
    background-color: #87f5c0;
  }
`;

const Label = styled.span`
  font-weight: 700;
`;

export const OnboardingStatus = ({ status = 'not-started' }) => {
  return (
    <Container>
      <Label>Onboarding Status:</Label>
      <Badge variant={status}>{statusMap[status]}</Badge>
    </Container>
  );
};

OnboardingStatus.propTypes = {
  status: PropTypes.oneOf(Object.keys(statusMap)),
};
