import {connect} from 'react-redux'
import {selectOrderFormPermissions, selectReadOnly} from '../orderFormSlice'
import ProductSelector from "../../common/products/ProductSelector";

const mapStateToProps = state => {
    const perms = selectOrderFormPermissions(state)

    const productSets = perms?.product_sets?.filter(set => set.platform === 'facebook') || [];

    return {
        readOnly: selectReadOnly(state),
        productSets: productSets,
    }
}

export default connect(mapStateToProps)(ProductSelector);
