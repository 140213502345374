import {createSlice} from "@reduxjs/toolkit";


const segmentSlice = createSlice({
  name: 'segment',
  initialState: {
    adBlockMessageViewed: false,
  },
  reducers: {
    markAdBlockMessageViewed(state) {
      state.adBlockMessageViewed = true;
    },
  },
});

export const {
  markAdBlockMessageViewed,
} = segmentSlice.actions

export const selectSegment = (state) => state.segment
export const selectAdBlockMessageViewed = (state) => selectSegment(state).adBlockMessageViewed;

export default segmentSlice.reducer
