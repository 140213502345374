import { AxiosError } from 'axios';
import React from 'react';
import { Spinner } from 'react-bootstrap';
import OrderSquare from './OrderSquare';

import styled from 'styled-components';
const Grid = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(4, 1fr);
`;
export default function Orders({ data, isLoading, error }: { data: any; isLoading: boolean; error: AxiosError }) {
  if (isLoading) {
    return (
      <>
        <div className='mb-4 text-center d-flex align-items-center justify-content-center'>
          <Spinner animation='border' />
          &nbsp; Loading orders, please wait...
        </div>
        <Grid>
          {/* {keeping the stale data visible while fetching new batch} */}
          {data?.length > 0 && data.map((order) => <OrderSquare key={order.id} order={order} />)}
        </Grid>
      </>
    );
  }
  if (error) {
    return (
      <div className='mb-4 text-center d-flex align-items-center justify-content-center'>
        Could not get orders! contact support.
      </div>
    );
  }
  return (
    <>
      {data.length > 0 ? (
        <Grid>
          {data.map((order) => (
            <OrderSquare key={order.id} order={order} />
          ))}
        </Grid>
      ) : (
        <div className='text-center'>
          <p>Sorry, but it doesn't seem like there are currently any orders.</p>
        </div>
      )}
    </>
  );
}
