import PropTypes from 'prop-types'
import React from 'react'
import { Form } from 'react-bootstrap'
import Dropdown from './Dropdown'
import DynamicField from '../DynamicField'
import Radio from './Radio'

const FormFilter = ({
  controlId,
  fields,
  type,
  readOnly = false,
  required = false,
  objective,
  options = [],
  errors = {},
  value = {},
  header,
  onChange = () => {},
}) => {
  const handleChange = val => {
    let data = value.data || {}
    onChange({ ...value, data: { ...data, ...val } })
  }
  return (
    <Form.Group controlId={controlId}>
      {type === 'radio' ? (
        <Radio
          controlId={`${controlId}-radio`}
          header={header}
          readOnly={readOnly}
          required={required}
          options={options}
          errors={errors}
          value={value.value}
          onChange={val => onChange({ ...value, value: val })}
        />
      ) : (
        <Dropdown
          controlId={`${controlId}-dropdown`}
          header={header}
          readOnly={readOnly}
          required={required}
          options={options}
          errors={errors}
          value={value.value}
          onChange={val => onChange({ value: val })}
        />
      )}
      <div>
        {fields
          .filter(f => f.option === value.value)
          .map(field => (
            <DynamicField
              key={field.id}
              controlId={`${controlId}-${field.id}`}
              field={field}
              required={field.required}
              objective={objective}
              readOnly={readOnly}
              value={value.data?.[field.id]}
              errors={errors?.[field.id]}
              onChange={val => handleChange({ [field.id]: val })}
            />
          ))}
      </div>
    </Form.Group>
  )
}

FormFilter.propTypes = {
  header: PropTypes.string.isRequired,
  controlId: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  objective: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  value: PropTypes.shape({
    value: PropTypes.string,
    data: PropTypes.object,
  }),
  errors: PropTypes.object,
  onChange: PropTypes.func,
  options: PropTypes.object.isRequired,
}

export default FormFilter
