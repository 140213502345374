import { connect } from "react-redux";
import { selectSession } from "../../session/sessionSlice";
import Categories from "../components/Categories";
import {
  selectOwnedSpace,
  selectOwnedSpaceErrors,
  updateOwnedCategoriesID,
} from "../ownedSpaceFormSlice";

const mapStateToProps = (state) => ({
  categories: selectSession(state).webcategories,
  value: selectOwnedSpace(state).owned_categories_id,
  errors: selectOwnedSpaceErrors(state).owned_categories_id,
});

const mapDispatchToProps = (dispatch) => ({
  onChange: (value) => dispatch(updateOwnedCategoriesID(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Categories);
