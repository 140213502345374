import React from 'react';
import { selectSession } from '../../../session/sessionSlice';
import { useSelector } from 'react-redux';
export default function PreviewNotification() {
  const session = useSelector(selectSession);
  return (
    <>
      {!session.facebook_live_preview_enabled ? (
        <div style={{ color: '#808080', fontSize: '10px', textAlign: 'center', width: '100%' }}>
          Not actual display of the ad on the device
        </div>
      ) : null}
    </>
  );
}
