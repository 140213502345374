import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getRequestReviewChanges } from '../../../lib/api';
import { selectSession } from '../../session/sessionSlice';
import { requestOrderReview } from '../orderFormSlice';
import Errors from './Errors';
import styles from './request_review_changes.module.scss';

const dateFormat = (date) =>
  date !== '' && moment(date, true).isValid() ? moment.utc(date).format('YYYY-MM-DD HH:mm:ss') : date;

const ChangesSection = ({ variant, label, original, changed }) => {
  let changeClass = styles.modification;
  if (variant === 'addition') {
    changeClass = styles.addition;
  } else if (variant === 'subtraction') {
    changeClass = styles.subtraction;
  }
  return (
    <div className={`bg-white mb-2 px-2 py-1 ${changeClass}`}>
      <b>{label}</b> changed from <span className='bg-light px-1'>{original}</span> to{' '}
      <span className='bg-light px-1'>{changed}</span>
    </div>
  );
};

// Handle styling for each change type
const changeClass = (original, changed) => {
  let changeClass = 'modification';
  if (!original && changed) {
    changeClass = 'addition';
  } else if (original && !changed) {
    changeClass = 'subtraction';
  }
  return changeClass;
};

const RequestReviewChanges = ({ orderID, supplierID, onFinish }) => {
  const [changes, setChanges] = useState({ order_changes: [], order_ad_changes: [] });
  const [requestState, setRequestState] = useState('idle');
  const [comment, setComment] = useState('');
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const adTypes = useSelector(selectSession).ad_types;

  useEffect(() => {
    setRequestState('fetching');
    getRequestReviewChanges(orderID)
      .then((response) => {
        setChanges({
          order_changes: response.data.data.order_changes,
          order_ad_changes: response.data.data.order_ad_changes,
        });
        setRequestState('done');
      })
      .catch((err) => {});
  }, [orderID]);

  return (
    <div>
      <div className='mb-5'>
        {requestState === 'fetching' && <span>Fetching changes...</span>}
        {requestState === 'done' && (
          <div>
            {Object.keys(changes.order_changes).length === 0 && Object.keys(changes.order_ad_changes).length === 0 ? (
              <span>No changes available.</span>
            ) : (
              <>
                <h5>Summary of Changes</h5>
                {Object.keys(changes.order_changes).length > 0 && (
                  <>
                    <h6>Order</h6>
                    <section className='bg-light px-3 py-2 mb-3'>
                      {changes.order_changes.map((field) => {
                        let oldValue = field.old;
                        let newValue = field.new;
                        if (field.type === 'date') {
                          oldValue = dateFormat(oldValue);
                          newValue = dateFormat(newValue);
                        }

                        return (
                          <ChangesSection
                            key={field.id}
                            variant={changeClass(oldValue, newValue)}
                            label={field.label}
                            original={oldValue}
                            changed={newValue}
                          />
                        );
                      })}
                    </section>
                  </>
                )}

                {changes.order_ad_changes.map((orderAd, i) => {
                  const adType = adTypes.find((adType) => adType.control_id === orderAd.format);
                  let title = `Ad #${orderAd.sequence}`;
                  if (adType) {
                    title += ` ${adType.label}`;
                  }
                  // Ad #1 Google Expanded Text Ad
                  return (
                    <section key={i} className='bg-light px-3 py-2 mb-3'>
                      <h6>{title}</h6>
                      <div>
                        {orderAd.changes.flatMap((field) => {
                          let oldValue = field.old;
                          let newValue = field.new;
                          if (field.type === 'date') {
                            oldValue = dateFormat(oldValue);
                            newValue = dateFormat(newValue);
                          }

                          return (
                            <ChangesSection
                              key={field.id}
                              variant={changeClass(oldValue, newValue)}
                              label={field.label}
                              original={oldValue}
                              changed={newValue}
                            />
                          );
                        })}
                      </div>
                    </section>
                  );
                })}
              </>
            )}
          </div>
        )}
      </div>
      <Form.Group className='mb-3'>
        <Form.Label className='d-flex align-self-center align-items-center'>
          Please provide reason for your order approval request *
        </Form.Label>
        <Form.Control
          as='textarea'
          rows={10}
          cols={48}
          value={comment}
          isInvalid={errors.message?.length > 0}
          onChange={(e) => setComment(e.target.value)}
          required={true}
        />
        <Form.Control.Feedback type='invalid'>
          <Errors errors={errors.message} />
        </Form.Control.Feedback>
      </Form.Group>
      <Modal.Footer>
        <Button
          disabled={requestState === 'fetching'}
          onClick={(e) => {
            setRequestState('fetching');
            dispatch(requestOrderReview({ requestee_id: supplierID, message: comment })).then((action) => {
              setRequestState('done');
              if (action.error) {
                setErrors(action.payload.errors);
              } else {
                onFinish(e);
              }
            });
          }}
        >
          Request Approval
        </Button>
      </Modal.Footer>
    </div>
  );
};

RequestReviewChanges.propTypes = {
  orderID: PropTypes.string.isRequired,
  supplierID: PropTypes.string.isRequired,
  onFinish: PropTypes.func.isRequired,
};

export default RequestReviewChanges;
