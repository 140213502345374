import React from 'react';
import styles from './ScheduleTab.module.scss';

const LabelValue = ({ label, value, description, valueColor }) => (
    <div className={styles.item}>
        <div className={styles.title}>{label}</div>
        <div className={valueColor ? styles.value : ''}>{value}</div>
        {description && <small className={styles.description}>{description}</small>}
    </div>
);
export default LabelValue;
