import React from 'react'
import { Form } from 'react-bootstrap'
import PropType from 'prop-types'
import Errors from '../../order_form/components/Errors'

const Name = ({ value = '', errors = [], onChange = () => { }, is_multitenancy = false}) => (
    <Form.Group>
        <div className="mb-2">
            <p><b>Order concurrency</b></p>
        </div>
        {
            is_multitenancy ? 
                <>
                    <Form.Control
                        value={value}
                        type="text"
                        isInvalid={errors.length > 0}
                        readonly="true"
                        onChange={e => onChange(e.target.value)}
                    />
                    <Form.Text className="mb-2">Choose how many ads can fit in this space at the same time. The value will be updated from the chosen template below </Form.Text>
                    <Form.Control.Feedback type="invalid">
                        <Errors errors={errors} />
                    </Form.Control.Feedback>
                </>
            :
                <>
                    <Form.Control
                        value={value}
                        type="number"
                        min="1"
                        isInvalid={errors.length > 0}
                        onChange={e => onChange(e.target.value)}
                    />
                    <Form.Text className="mb-2">Choose how many ads can fit in this space at the same time.</Form.Text>
                    <Form.Control.Feedback type="invalid">
                        <Errors errors={errors} />
                    </Form.Control.Feedback>
                </>
        }
    </Form.Group>
)

Name.propTypes = {
    value: PropType.number,
    errors: PropType.arrayOf(PropType.string),
    onChange: PropType.func,
    is_multitenancy: PropType.bool,
}

export default Name
