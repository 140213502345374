import React, { useCallback, useRef } from "react";
import {
  Controller,
  useFormContext,
  useFormState,
  useWatch,
} from "react-hook-form";
import propTypes from "prop-types";
import { Form, InputGroup } from "react-bootstrap";
import Datetime from "react-datetime";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { faClose } from "@fortawesome/pro-regular-svg-icons";

const StyledDateTimeInnerInput = styled.input`
  border: none;
  padding: 0;
  height: 0;
  visibility: hidden; /* This is important to add otherwise there will be an empty extra space */
`;

const StyledClearButton = styled(FontAwesomeIcon)`
  margin-left: 0.5rem;
  cursor: pointer;
`;

const WalletDateSelector = ({ control_name, label }) => {
  const methods = useFormContext();
  const { control, setValue } = methods;
  const { errors, isSubmitted } = useFormState();

  const dateInputRef = useRef();

  const value = useWatch({
    name: control_name,
  });

  const handleClear = useCallback(() => {
    setValue(control_name, null, {
      shouldValidate: true,
    });
  }, [setValue, control_name]);

  return (
    <Form.Group>
      <Form.Label>
        {label}
        {value && <StyledClearButton icon={faClose} onClick={handleClear} title='Clear' />}
      </Form.Label>
      <InputGroup>
        <InputGroup.Text>
          <FontAwesomeIcon icon={faCalendar} />
        </InputGroup.Text>
        <Controller
          name={control_name}
          control={control}
          render={({ field }) => (
            <>
              <Form.Control
                type='text'
                onClick={(e) => dateInputRef?.current?.click(e)}
                isInvalid={isSubmitted && errors[control_name]?.message}
                value={field?.value ? `${moment.utc(field.value).format('YYYY-MM-DD')}` : ''}
              />
              <Datetime
                autoComplete={false}
                timeFormat={false}
                utc={true}
                onChange={(val) => field.onChange(moment.utc(val))}
                renderInput={(props, openCalendar, closeCalendar) => (
                  <StyledDateTimeInnerInput ref={dateInputRef} onClick={openCalendar} {...props} />
                )}
              />
            </>
          )}
        />
      </InputGroup>
      {(isSubmitted || errors?.[control_name]) && (
        <Form.Control.Feedback type='invalid' className='d-block'>
          {errors?.[control_name]?.message}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

WalletDateSelector.propType = {
  control_name: propTypes.string.isRequired,
  label: propTypes.string.isRequired,
};

export default WalletDateSelector;
