import React from 'react';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus} from '@fortawesome/free-solid-svg-icons';
import {Button, Nav, OverlayTrigger, Popover} from 'react-bootstrap';
import {StyledPopMessage} from "./StyledPopMessage";
import styles from '../sidebar.module.scss'

//returns a styled Link or Button to represent actions in the Add popover
const AddActionSquare = ({action}) => {
    return action.to ? <Link
        to={action?.to}
        className="btn btn-light"
    >
        <FontAwesomeIcon icon={action.icon} size="4x"/>
        <div>{action.label}</div>
    </Link> : <Button
        variant="light"
        onClick={action?.onClick}
    >
        <FontAwesomeIcon icon={action.icon} size="4x"/>
        <div>{action.label}</div>
    </Button>;
}

const AddPopover = actions => {
    return <Popover id="add-popover-sidebar">
        <Popover.Content>
            <StyledPopMessage>
                {actions.map(action => <AddActionSquare action={action} key={action.label}/>)}
            </StyledPopMessage>
        </Popover.Content>
    </Popover>;
}

export const SidebarAddButton = ({actions}) => (
    <Nav.Item as="li" role="presentation" className={`mb-4 ${styles.sidebar_nav_item}`}>
        <OverlayTrigger
            trigger="click"
            rootClose
            placement="right"
            overlay={AddPopover(actions)}
        >
            <span className='sidebar_button'>
                <FontAwesomeIcon icon={faPlus} size="lg" className='sidebar_button'/>
                <label className={`${styles.sidebar_nav_label} sidebar_button`}>
                    Add
                </label>
            </span>
        </OverlayTrigger>
    </Nav.Item>);
