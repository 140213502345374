import styles from '../../brand_page_properties.module.scss';
import { PageUrlText } from './PageUrlText';
import { PageDateSettings } from './PageDateSettings';
import { PageSettingsSummary } from './PageSummary';
import { PageNameText } from './PageNameText';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectBrandStoreOrder,
  setPageName,
  setPageUrl,
  setDateInheritedFromParent,
  setPageStartDate,
  setPageStopDate,
} from '../../../state_management/BrandStoreSlice';
import { DragDropCard } from '../../DragDropComponents/DragDropCard';

const PAGE_DATE_SETTINGS_ENABLED = false;

const PageSettingsInputs = ({
  baseUrl,
  setInheritDate,
  setPageName,
  setPageUrl,
  setPageDateFrom,
  setPageDateTo,
  isHomepage,
  reduxPageValues,
  readOnly,
  pageErrors,
}) => {
  const pageNameErrors = pageErrors?.page_name;
  const pageUrlErrors = pageErrors?.page_url;
  return (
    <div className={styles.inputs_background}>
      <PageNameText
        controlId={'pageNameField'}
        header={'Page Name'}
        errors={pageNameErrors}
        onChange={(val) => {
          setPageName(val);
        }}
        reduxValue={reduxPageValues.pageName}
        readOnly={readOnly}
      />
      <PageUrlText
        controlId={'pageUrlField'}
        header={'Page URL'}
        minLength={3}
        maxLength={255}
        errors={pageUrlErrors}
        placeholder={'/sub-url'}
        baseUrl={baseUrl}
        onChange={(val) => {
          setPageUrl(val);
        }}
        reduxValue={reduxPageValues.pageUrl}
        isHomepage={isHomepage}
        readOnly={readOnly}
      />
      {PAGE_DATE_SETTINGS_ENABLED && (
        <PageDateSettings
          {...{
            inheritDate: reduxPageValues.inheritDate,
            setInheritDate,
            setPageDateFrom,
            setPageDateTo,
            storeStartDate: reduxPageValues.storeStartDate,
            storeStopDate: reduxPageValues.storeStopDate,
            pageStartDate: reduxPageValues.pageStartDate,
            pageStopDate: reduxPageValues.pageStopDate,
            readOnly: readOnly,
          }}
        />
      )}
    </div>
  );
};

export const BrandPageSettingsCard = ({
  baseUrl,
  editingPage,
  pageId,
  onPageSave,
  onPageEdit,
  onPageRemove,
  readOnly,
  moveCard,
  index,
  pageErrors,
}) => {
  //store form values in useState
  const dispatch = useDispatch();
  const brandStoreOrder = useSelector(selectBrandStoreOrder);
  const page = brandStoreOrder.pages.find((page) => page.id === pageId);

  const handleSetPageName = (pageName) => {
    dispatch(setPageName({ pageId, pageName }));
  };

  const handleSetPageUrl = (pageUrl) => {
    dispatch(setPageUrl({ pageId, pageUrl }));
  };

  const handleSetDateInheritedFromParent = (dateInheritedFromParent) => {
    dispatch(setDateInheritedFromParent({ pageId, dateInheritedFromParent }));
  };

  const handleSetPageStartDate = (pageStartDate) => {
    dispatch(setPageStartDate({ pageId, pageStartDate }));
  };

  const handleSetPageStopDate = (pageStopDate) => {
    dispatch(setPageStopDate({ pageId, pageStopDate }));
  };

  // Add slash if there is a page_url that doesn't have a slash
  const buildUrl = (baseUrl, pageUrl) => {
    if (!pageUrl || pageUrl.charAt(0) === '/') {
      return baseUrl + pageUrl;
    } else {
      return baseUrl + '/' + pageUrl;
    }
  };
  const summaryDisplayUrl = page.is_home_page ? baseUrl : buildUrl(baseUrl, page.page_url);

  return (
    <>
      {readOnly || !moveCard ? (
        <PageSettingsSummary
          {...{
            key: pageId,
            pageName: page.page_name,
            isHomepage: page.is_home_page,
            url: summaryDisplayUrl,
            onPageSave,
            onPageEdit,
            onPageRemove,
            editingPage,
            pageStartDate: page.page_start_date,
            pageStopDate: page.page_stop_date,
            brandStoreStartDate: brandStoreOrder.start_at,
            brandStoreStopDate: brandStoreOrder.stop_at,
            inheritDate: page.is_date_inherited_from_parent,
            readOnly: readOnly,
            pageHasErrors: pageErrors && Object.values(pageErrors).length > 0,
          }}
        />
      ) : (
        <DragDropCard id={pageId} index={index} moveCard={moveCard}>
          <PageSettingsSummary
            {...{
              key: pageId,
              pageName: page.page_name,
              isHomepage: page.is_home_page,
              url: summaryDisplayUrl,
              onPageSave,
              onPageEdit,
              onPageRemove,
              editingPage,
              pageStartDate: page.page_start_date,
              pageStopDate: page.page_stop_date,
              brandStoreStartDate: brandStoreOrder.start_at,
              brandStoreStopDate: brandStoreOrder.stop_at,
              inheritDate: page.is_date_inherited_from_parent,
              readOnly: readOnly,
              pageHasErrors: pageErrors && Object.values(pageErrors).length > 0,
            }}
          />
        </DragDropCard>
      )}
      <div style={{ display: editingPage ? 'block' : 'none' }}>
        <PageSettingsInputs
          {...{
            key: pageId,
            baseUrl,
            setInheritDate: handleSetDateInheritedFromParent,
            setPageName: handleSetPageName,
            setPageUrl: handleSetPageUrl,
            setPageDateFrom: handleSetPageStartDate,
            setPageDateTo: handleSetPageStopDate,
            isHomepage: page.is_home_page,
            reduxPageValues: {
              pageName: page.page_name,
              pageUrl: page.page_url,
              homepageFlag: page.is_home_page,
              pageStartDate: page.page_start_date,
              pageStopDate: page.page_stop_date,
              inheritDate: page.is_date_inherited_from_parent,
              storeStartDate: brandStoreOrder.start_at,
              storeStopDate: brandStoreOrder.stop_at,
            },
            readOnly: readOnly,
            pageErrors,
          }}
        />
      </div>
    </>
  );
};
