import { connect } from 'react-redux'
import { selectFacebookPlacements } from '../../session/sessionSlice'
import Placements from '../components/Placements'

const mapStateToProps = state => ({
    placements: selectFacebookPlacements(state).display,
})


export default connect(mapStateToProps)(Placements)
