import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Weight from '../components/WebAds/Weight';

export const AdvancedTab = ({ showWeight, ad, controlId, readOnly, handleChange, errors }) => (
  <Container>
    {showWeight && (
      <Row>
        <Col sm='12'>
          <Weight
            key={`${ad.id}-${ad.order_ad_id || 0}`}
            controlId={controlId}
            readOnly={readOnly}
            label='Weight'
            placeholder='0'
            adType={ad.type}
            value={ad.weight}
            onChange={(weight) => {
              handleChange({ weight });
            }}
            errors={errors.weight}
          />
        </Col>
      </Row>
    )}
  </Container>
);
