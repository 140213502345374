import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';

const StyledCurrencyBadge = styled.div`
  background-color: #E7F9F9;
  display: flex;
  color: #207172;
  font-size: 0.75rem;
  padding: 0.5rem;
  ${({ width }) => width && `width: ${width}px`}
`

const CurrencyBadge = ({
  currency,
  width,
}) => (
  <StyledCurrencyBadge width={width}>
    {currency}
  </StyledCurrencyBadge>
);


CurrencyBadge.propTypes = {
  currency: propTypes.string.isRequired,
  width: propTypes.number,
};

export default CurrencyBadge;