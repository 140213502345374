import React from 'react';
import gridTileStyles from './ProductGridTile.module.scss';
import listTileStyles from './ProductListTile.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { Form } from 'react-bootstrap';

export const ProductGridTile = ({ product, checked, onClick }) => {
  const { image_url, title, price, currency } = product;
  return (
    <div
      onClick={() => onClick(product)}
      className={`${gridTileStyles.product} ${checked ? gridTileStyles.selected : ''}`}
    >
      <div className={gridTileStyles.product_image_wrapper}>
        <img className={gridTileStyles.product_image} src={image_url} alt={title} />
      </div>
      <div className={gridTileStyles.product_body}>
        <div className={gridTileStyles.product_name_text_wrapper} title={title}>
          {title}
        </div>
        <div className={gridTileStyles.price_text_wrapper}>{`${currency} ${price}`}</div>
      </div>
      <div
        className={`${gridTileStyles.selected_check_box} ${
          checked ? gridTileStyles.selected : gridTileStyles.unselected
        }`}
      >
        {checked && <FontAwesomeIcon icon={faCheck} />}
      </div>
    </div>
  );
};

export const ProductListTile = ({ product, checked, onClick, index }) => {
  const { image_url, title, price, currency, id } = product;
  return (
    <div
      onClick={() => onClick(product)}
      className={`${listTileStyles.product_list_tile} ${index % 2 === 0 ? listTileStyles.even : listTileStyles.odd} ${
        checked ? listTileStyles.checked : ''
      }`}
    >
      <div className={listTileStyles.checkbox_wrapper}>
        <Form.Check type='checkbox' checked={checked} />
      </div>
      <div className={listTileStyles.box}>
        <img className={listTileStyles.product_image} src={image_url} alt={title} />
      </div>
      <div className={listTileStyles.title}>{title}</div>
      <div>
        {price} {currency}
      </div>
      <div>{id}</div>
    </div>
  );
};
