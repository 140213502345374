import React from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { AccordionHeader } from './AccordionHeader';
import { MediaEntry } from './MediaEntry';
import { AssetGroup } from './DigitalScreenMediaTypes';

interface PreviewAccordionProps {
  creativeAssetIndex: number;
  title: string;
  isOpen: boolean;
  onToggle: (key: string) => void;
  assetGroup: AssetGroup;
  accordionRef: React.RefObject<HTMLDivElement>;
  orderAssets: Array<any>;
  screenTypes: Array<string>;
}

export const PreviewAccordion: React.FC<PreviewAccordionProps> = ({
  creativeAssetIndex,
  title,
  isOpen,
  onToggle,
  assetGroup,
  accordionRef,
  orderAssets,
  screenTypes,
}) => {
  if (assetGroup) {
    return (
      <Accordion ref={accordionRef} activeKey={isOpen ? creativeAssetIndex.toString() : undefined}>
        <Card>
          <AccordionHeader
            eventKey={creativeAssetIndex.toString()}
            isOpen={isOpen}
            toggleOpen={() => onToggle(creativeAssetIndex.toString())}
          >
            {title}
          </AccordionHeader>
          <Accordion.Collapse eventKey={creativeAssetIndex.toString()}>
            <Card.Body>
              <MediaEntry assetGroup={assetGroup} orderAssets={orderAssets} screenTypes={screenTypes} />
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    );
  }
  return null;
};
