import { connect } from "react-redux";
import { selectSession, selectUserOrganisation } from "../../session/sessionSlice";
import CustomAudiences from "../components/CustomAudiences";
import { facebookLookalikeAudienceUpdate, selectReadPermissions } from "../organisationSettingsSlice";


const mapStateToProps = state => ({
    title: 'Lookalike Audiences',
    value: selectReadPermissions(state)?.facebook_lookalike_audiences,
    audiences: selectSession(state).facebook_lookalike_audiences,
    preselectedValues: selectUserOrganisation(state)?.settings?.ad_settings?.facebook_lookalike_audiences,
})

const mapDispatchToProps = dispatch => ({
    onChange: lookalike_audiences => dispatch(facebookLookalikeAudienceUpdate(lookalike_audiences))
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomAudiences)
