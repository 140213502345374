import {User} from "../features/common/types";
import {AnalyticsBrowser} from "@segment/analytics-next";

declare global {
  interface Window {
    pendo: {
      initialize: (object) => void,
      track: (string, object) => void,
    };
    segmentIsLoaded: boolean;
  }
}

type SegmentOrder = {
  id: string,
  name: string,
  budget: number,
  budget_currency: string,
};

type SegmentOrganisation = {
  id: string,
  name: string,
};

type SegmentBrandStore = {
  id: string,
  name: string,
  total_cost: number,
};

type SegmentSpace = {
  id?: string,
  name: string,
  format: 'product' | 'banners',
  placement: 'virtual' | 'embedded',
  subformat: 'web' | 'search',
}

const analytics = init();

export function init(): AnalyticsBrowser {
  window.segmentIsLoaded = false;
  const analyticsBrowser = AnalyticsBrowser.load({writeKey: process.env.REACT_APP_SEGMENT_WRITE_KEY})
  analyticsBrowser.ready(() => {
    window.segmentIsLoaded = true;
  });

  return analyticsBrowser;
}

export function isSegmentImplemented(): boolean {
  return window.segmentIsLoaded;
}

export function identifyUser(user: User): void {
  if (!isSegmentImplemented()) return;
  const organisation = user.active_organisation;

  analytics.identify(user.id, {
    firstName: user.first_name,
    lastName: user.last_name,
    email: user.email,
    title: user.role.name,
  });

  analytics.group(organisation.id, {
    name: organisation.name,
  });

  window.pendo.initialize({
    visitor: {
      id: user.id,
      email: user.email,
      full_name: `${user.first_name} ${user.last_name}`,
      role: user.role.name,
    },
    account: {
      id: organisation.id,
      name: organisation.name,
    }
  });
}

export function trackPageView(pathname: string): void {
  if (!isSegmentImplemented()) return;

  analytics.pageView(pathname);
}

export function trackApproveOrder(order: SegmentOrder, retailer: SegmentOrganisation, brand: SegmentOrganisation): void {
  if (!isSegmentImplemented()) return;

  analytics.track('Order Approved', {
    orderId: order.id,
    orderName: order.name,
    revenue: order.budget,
    currency: order.budget_currency,
    retailerId: retailer.id,
    retailerName: retailer.name,
    brandId: brand.id,
    brandName: brand.name,
  });

  window.pendo.track('order_approved', {
    orderId: order.id,
    orderName: order.name,
    revenue: order.budget,
    currency: order.budget_currency,
    retailerId: retailer.id,
    retailerName: retailer.name,
    brandId: brand.id,
    brandName: brand.name,
  });
}

export function trackCreateOrder(order: SegmentOrder, retailer: SegmentOrganisation, brand: SegmentOrganisation): void {
  if (!isSegmentImplemented()) return;

  analytics.track('Order Created', {
    orderId: order.id,
    orderName: order.name,
    retailerId: retailer.id,
    retailerName: retailer.name,
    brandId: brand.id,
    brandName: brand.name,
  });

  window.pendo.track('order_created', {
    orderId: order.id,
    orderName: order.name,
    retailerId: retailer.id,
    retailerName: retailer.name,
    brandId: brand.id,
    brandName: brand.name,
  });
}

export function trackCreateOrderByRetailer(order: SegmentOrder, retailer: SegmentOrganisation, brand: SegmentOrganisation): void {
  if (!isSegmentImplemented()) return;

  analytics.track('Order Created', {
    orderId: order.id,
    orderName: order.name,
    retailerId: retailer.id,
    retailerName: retailer.name,
    brandId: brand.id,
    brandName: brand.name,
  });

  window.pendo.track('order_created', {
    orderId: order.id,
    orderName: order.name,
    retailerId: retailer.id,
    retailerName: retailer.name,
    brandId: brand.id,
    brandName: brand.name,
  });
}

export function trackCreateOrderDraft(order: SegmentOrder, retailer: SegmentOrganisation, brand: SegmentOrganisation): void {
  if (!isSegmentImplemented()) return;

  analytics.track('Draft Order Created', {
    orderId: order.id,
    orderName: order.name,
    retailerId: retailer.id,
    retailerName: retailer.name,
    brandId: brand.id,
    brandName: brand.name,
  });

  window.pendo.track('draft_order_created', {
    orderId: order.id,
    orderName: order.name,
    retailerId: retailer.id,
    retailerName: retailer.name,
    brandId: brand.id,
    brandName: brand.name,
  });
}

export function trackInviteSupplier(firstName: string, lastName: string, email: string, title: string, organisationName: string): void {
  if (!isSegmentImplemented()) return;

  analytics.track('Supplier Invited', {
    invitee: {
      firstName,
      lastName,
      email,
      title,
    },
    company: {
      name: organisationName,
    },
  });

  window.pendo.track('supplier_invited', {
    firstName,
    lastName,
    email,
    title,
    organisationName,
  });
}

export function trackCreateSpace(space: SegmentSpace): void {
  if (!isSegmentImplemented()) return;

  analytics.track('Space Created', {
    spaceId: space.id,
    name: space.name,
    placement: space.placement,
    format: space.format,
    subformat: space.subformat,
  });

  window.pendo.track('space_created', {
    spaceId: space.id,
    name: space.name,
    placement: space.placement,
    format: space.format,
    subformat: space.subformat,
  });
}

export function trackApproveBrandStore(brandStore: SegmentBrandStore, currency: string, retailer: SegmentOrganisation, brand: SegmentOrganisation): void {
  if (!isSegmentImplemented()) return;

  analytics.track('Brand Store Approved', {
    brandStoreId: brandStore.id,
    brandStoreName: brandStore.name,
    revenue: brandStore.total_cost,
    currency,
    retailerId: retailer.id,
    retailerName: retailer.name,
    brandId: brand.id,
    brandName: brand.name,
  });

  window.pendo.track('brand_store_approved', {
    brandStoreId: brandStore.id,
    brandStoreName: brandStore.name,
    revenue: brandStore.total_cost,
    currency,
    retailerId: retailer.id,
    retailerName: retailer.name,
    brandId: brand.id,
    brandName: brand.name,
  });
}

export function trackCreateBrandStore(brandStore: SegmentBrandStore, retailer: SegmentOrganisation, brand: SegmentOrganisation): void {
  if (!isSegmentImplemented()) return;

  analytics.track('Brand Store Created', {
    brandStoreId: brandStore.id,
    brandStoreName: brandStore.name,
    retailerId: retailer.id,
    retailerName: retailer.name,
    brandId: brand.id,
    brandName: brand.name,
  });

  window.pendo.track('brand_store_created', {
    brandStoreId: brandStore.id,
    brandStoreName: brandStore.name,
    retailerId: retailer.id,
    retailerName: retailer.name,
    brandId: brand.id,
    brandName: brand.name,
  });
}

export function trackEnableChannel(adFormatId: string, platform?: string, brand?: SegmentOrganisation, margin?: number, minBudget?: number): void {
  if (!isSegmentImplemented()) return;

  analytics.track('Enable Channel', {
    adFormatId,
    platform,
    brandId: brand?.id,
    brandName: brand?.name,
    minBudget,
    margin,
  });

  window.pendo.track('enable_channel', {
    adFormatId,
    platform,
    brandId: brand?.id,
    brandName: brand?.name,
    minBudget,
    margin,
  });
}

export function trackCreateBespokeChannel(bespokeId: string, name: string): void {
  if (!isSegmentImplemented()) return;

  analytics.track('Bespoke Product Created', {
    bespokeId,
    name,
  });

  window.pendo.track('bespoke_product_created', {
    bespokeId,
    name,
  });
}

