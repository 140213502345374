import React from 'react';
import styles from './select_block_type_modal.module.scss';

const BlockTypeTile = ({ blockType, isSelected, onSelect }) => {
  const { preview_icon, name } = blockType;

  return (
    <div
      className={`${styles.block_type_tile} ${
        isSelected ? styles.selected_tile : ''
      }`}
      onClick={onSelect}
    >
      <img
        src={preview_icon}
        alt=''
        className={styles.preview_icon}
      />
      <div className={styles.block_name}>{name}</div>
    </div>
  );
};

export default BlockTypeTile;
