import React from 'react';
import WebPlacement from '../containers/WebAds/WebPlacement';
import { PLATFORM_NATIVE } from '../../../lib/platforms';
import { budgetUpdated } from '../orderFormSlice';

export const PlacementTab = ({
  readOnly,
  approvedReadOnly,
  ad,
  handleReset,
  tmpGoal,
  setPreviewMarkup,
  dispatch,
  webSpaces,
}) => {
  const handleWebSpaceSelection = (webSpace) => {
    handleReset({
      id: ad.id,
      platform: PLATFORM_NATIVE,
      type: ad.type,
      webspace_id: webSpace.id,
      cost_strategy: webSpace.slot_pricing_mode === 'bid' ? webSpace.bidmode : webSpace.slot_pricing_mode,
      goal: tmpGoal,
      feed: undefined,
      package_id: ad.package_id,
      package_collection_id: ad.package_collection_id,
      package_item_id: ad.package_item_id,
      start_at: ad?.start_at,
      stop_at: ad?.stop_at,
    });

    setPreviewMarkup('');
    dispatch(budgetUpdated());
  };
  return (
    <WebPlacement
      readOnly={readOnly || approvedReadOnly}
      ad={ad}
      webSpaces={webSpaces}
      onChange={(webSpace) => handleWebSpaceSelection(webSpace)}
    />
  );
};
