import PropTypes from 'prop-types'
import React from 'react'
import { Form } from 'react-bootstrap'
import Errors from '../Errors'

const Radio = ({
    header,
    controlId,
    readOnly = false,
    disabled = false,
    required = false,
    value = '',
    onChange = () => {},
    errors = [],
    options,
}) => {
    return (
        <Form.Group controlId={controlId}>
            <Form.Label className="font-weight-bold">
                {header} {required && '*'}
            </Form.Label>
            {Object.keys(options).map(opt => (
                <Form.Check
                    type="radio"
                    name={controlId}
                    id={opt}
                    key={opt}
                    label={options[opt]}
                    disabled={disabled}
                    checked={value === opt}
                    isInvalid={errors.length > 0}
                    onChange={e => {
                        if (!readOnly) {
                            onChange(e.target.value)
                        }
                    }}
                    required={required}
                    value={opt}
                />
            ))}
            <Form.Control.Feedback type="invalid">
                <Errors errors={errors} />
            </Form.Control.Feedback>
        </Form.Group>
    )
}

Radio.propTypes = {
    header: PropTypes.string.isRequired,
    controlId: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    value: PropTypes.string,
    errors: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func,
    options: PropTypes.object.isRequired,
}

export default Radio
