import { useEffect } from 'react'
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import { Helmet } from 'react-helmet-async'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import OrganisationPackageList from '../features/packages/containers/OrganisationPackageList'
import { fetchPackages, selectPackagesRequestStatus } from '../features/packages/packagesSlice'
import Chrome from './layout/Chrome'

const Header = styled.h1`
    margin-bottom: 100px;
`

const ListPackages = () => {
    const dispatch = useDispatch()
    const status = useSelector(selectPackagesRequestStatus)

    useEffect(() => {
        dispatch(fetchPackages())
    }, [dispatch])

    return <>
        <Helmet>
            <title>
                Packages - {process.env.REACT_APP_NAME}
            </title>
        </Helmet>

        <Chrome>
            <Container className="pt-4">
                <Row className="mb-3">
                    <Col>
                        <Header>Packages</Header>
                        {status === 'loading'
                            ? <Row className="text-center d-flex align-items-center justify-content-center">
                                <Spinner animation="border" />&nbsp;
                            </Row>
                            : <OrganisationPackageList />
                        }
                    </Col>
                </Row>
            </Container>
        </Chrome>
    </>
}

export default ListPackages
