import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { getThemes } from '../../lib/api/themes';

const initialState = {
  ids: [],
  entities: [],
  status: 'idle',
};

export const fetchThemes = createAsyncThunk(
  'themes/fetchThemes',
  async (arg, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await getThemes();
      return response.data;
    } catch (err) {
      if (!err.response?.data) {
        throw err;
      }
      throw rejectWithValue(err.response.data);
    }
  }
);

export const cachedFetchThemes = createAsyncThunk('themes/cachedFetchThemes', async (arg, { getState, dispatch }) => {
  if (getState().themes.status === 'idle') {
    const action = await dispatch(fetchThemes());
    return action.payload;
  }
});

const themesAdapter = createEntityAdapter({
  // Sort ASC by name
  sortComparer: (a, b) => (a.name < b.name ? -1 : +(a.name > b.name)),
});

export const { selectAll: selectAllThemes } = themesAdapter.getSelectors((state) => state.themes);

const themesSlice = createSlice({
  name: 'themes',
  initialState,
  reducers: {
    setThemes(themes) {
      return themes;
    },
  },
  extraReducers: {
    [fetchThemes.pending]: (state, action) => {
      state.status = 'loading';
    },
    [fetchThemes.fulfilled]: (state, action) => {
      if (state.status === 'loading') {
        themesAdapter.setAll(state, action.payload);
        state.status = 'succeeded';
      }
    },
    [fetchThemes.rejected]: (state, action) => {
      if (state.status === 'loading') {
        state.status = 'failed';
      }
    },
  },
});

export default themesSlice.reducer;
