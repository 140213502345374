import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

const ActionContainer = styled.div`
  background: #F7F5FF;
  padding: 16px;
  .recommended {
    float: right;
    background: #FFE787;
    border-radius: 4px;
    font-size: 10px;
    padding: 4px 8px;
  }
`;

const Action = ({ title, link, description, recommended = false }) => <ActionContainer className="mb-3">
  <div>
    <span className="link"><Link to={link}>{title} <FontAwesomeIcon icon={faChevronRight} /></Link></span>
    {recommended && <span className="recommended">Recommended</span>}
    
  </div>
  <p className="mb-0">{description}</p>
</ActionContainer>

const InventorySpaceActions = ({ ownedSpace, errors = {} }) => {

  return (
    <div className="border rounded p-3">
    <article>
      <div className="mb-2">
        <h5>Actions</h5>
      </div>
      <div>
        <div>
          <Action 
            title={'Modify Price'}
            link={`/organisation/websettings/edit/${ownedSpace}`}
            description={'Change the price point for this space.'}
          />
          <Action
            title={'Modify Monetisation Model'}
            link={`/organisation/websettings/edit/${ownedSpace}`}
            description={'Change between fixed price or bidding monetisation methods.'}
          />
          <Action
            title={'Modify Slots'}
            link={`/organisation/websettings/edit/${ownedSpace}`}
            description={'Increase or decrease available space slots.'}
          />
          <Action
            title={'Promote Space'}
            link={`/organisation/websettings/edit/${ownedSpace}`}
            description={'Advertise this space to increase visibility and drive higher utilisation.'}
          />
        </div>
      </div>
    </article>
    </div>
  );
};

InventorySpaceActions.propTypes = {
  ownedSpace: PropTypes.object,
  errors: PropTypes.object,
};

export default InventorySpaceActions;
