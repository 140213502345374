import { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet-async'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import PackageForm from '../features/packages/components/PackageForm'
import { clearPackage, fetchPackageGroups } from '../features/packages/packageFormSlice'
import Chrome from './layout/Chrome'

const Header = styled.h1`
    margin-bottom: 70px;
`

const CreatePackage = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(clearPackage())
        dispatch(fetchPackageGroups())
    }, [dispatch])

    return <>
        <Helmet>
            <title>
                New Package - {process.env.REACT_APP_NAME}
            </title>
        </Helmet>

        <Chrome>
            <Container className="pt-4 px-5" fluid>
                <Row className="mb-3">
                    <Col>
                        <Header>Package Editor</Header>
                        <PackageForm />
                    </Col>
                </Row>
            </Container>
        </Chrome>
    </>
}

export default CreatePackage
