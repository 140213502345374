import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Form } from 'react-bootstrap';
import styles from '../ad_section.module.scss';

export const AdGoalsTab = ({ ad, errors, handleChange, structure, session, readOnly }) => {
  return (
    <div className={styles.form_section}>
      <Container>
        <Row className='mb-5'>
          <Col>
            <p>
              <strong>Objective</strong>
            </p>
            {structure.goals.map((goal, index) => (
              <div
                key={goal}
                onClick={function () {
                  !readOnly && handleChange({ goal: goal });
                }}
                className={styles.goals}
              >
                <Form.Check
                  name={`goals-${ad.id}`}
                  disabled={readOnly}
                  type={'radio'}
                  label={goal}
                  value={goal}
                  id={`${goal}-${index}-${ad.id}`}
                  onChange={function (e) {
                    handleChange({ goal: e.target.value });
                  }}
                  isInvalid={errors?.goal}
                  aria-describedby={`${goal}-${index}-${ad.id}-muted`}
                  checked={ad.goal === goal}
                />
                <Form.Text className={styles.goals_muted} id={`${goal}-${index}-${ad.id}-muted`} muted>
                  {session.goals.find((each) => each.id === goal).description}
                </Form.Text>
              </div>
            ))}{' '}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

AdGoalsTab.propTypes = {
  ad: PropTypes.object.isRequired,
  errors: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  structure: PropTypes.object.isRequired,
  session: PropTypes.object.isRequired,
  readOnly: PropTypes.bool.isRequired,
};
