import { connect } from 'react-redux';
import { selectSession } from '../../session/sessionSlice';
import SavedAudiences from '../components/SavedAudiences';

const mapStateToProps = (state) => ({
  title: 'Saved Audiences',
  audiences: selectSession(state).facebook_saved_audiences,
});

export default connect(mapStateToProps)(SavedAudiences);
