
import { connect } from 'react-redux'
import MultiSelect from '../../components/form_elements/MultiSelect'
import { selectOrganisationWhiteList, selectPackageOrganisationWhitelistErrors, updateOrganisationWhiteList } from '../../packageFormSlice'

const mapStateToProps = state => ({
    controlId: "package-organisation-whitelist",
    label: "Suppliers",
    value: selectOrganisationWhiteList(state),
    required: true,
    errors: selectPackageOrganisationWhitelistErrors(state),
})


const mapDispatchToProps = dispatch => ({
    onChange: whitelist => dispatch(updateOrganisationWhiteList(whitelist)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MultiSelect)
