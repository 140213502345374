import { connect } from "react-redux";
import { selectSession } from "../../session/sessionSlice";
import Templates from "../components/Templates";
import { selectOwnedSpace, updateTemplate } from "../ownedSpaceFormSlice";

const mapStateToProps = (state) => ({
  format: selectOwnedSpace(state).format,
  templates: selectSession(state)?.webtemplates?.filter(template => template.format === selectOwnedSpace(state)?.format),
  value: selectOwnedSpace(state)?.owned_templates,
  is_multitenancy: selectOwnedSpace(state).is_multitenancy,
});


const mapDispatchToProps = (dispatch) => ({
  onChange: (value) => dispatch(updateTemplate(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Templates);
