import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { ownedPackages } from '../../lib/api';

export const fetchPackages = createAsyncThunk(
  'packages/fetchPackages',
  async (_arg, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await ownedPackages();
      dispatch(packagesUpdated(response.data.data));
      return response.data.data;
    } catch (err) {
      if (!err.response?.data) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const packagesAdapter = createEntityAdapter({
  // Sort chronologically
  sortComparer: (a, b) => b.updated_at.localeCompare(a.updated_at),
});

export const {
  selectAll: selectAllPackages,
  selectIds: selectPackageIds,
  selectById: selectPackageById,
} = packagesAdapter.getSelectors((state) => state.packages);

const packagesSlice = createSlice({
  name: 'packages',

  initialState: packagesAdapter.getInitialState({
    packages: [],
    status: 'idle',
    error: {},
  }),

  reducers: {
    packagesUpdated(state, action) {
      state.packages = action.payload;
    },
    packageUpdated(state, action) {
      packagesAdapter.upsertOne(state, action.payload);
    },
    packageDeleted: packagesAdapter.removeOne,
    packagesCleared: packagesAdapter.removeAll,
  },

  extraReducers: (builder) => {
    builder.addCase(fetchPackages.pending, (state, action) => {
      state.status = 'loading';
      state.error = {};
    });

    builder.addCase(fetchPackages.fulfilled, (state, action) => {
      if (state.status === 'loading') {
        packagesAdapter.upsertMany(state, action);
        state.status = 'succeeded';
      }
    });

    builder.addCase(fetchPackages.rejected, (state, action) => {
      if (state.status === 'loading') {
        state.status = 'failed';
        state.error = action.payload;
      }
    });
  },
});

export const selectPackagesRequestStatus = (state) => state.packages.status;

export const { packagesUpdated, packageUpdated, packageDeleted, packagesCleared } = packagesSlice.actions;

export default packagesSlice.reducer;
