import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { debounce } from 'debounce';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Chrome from '../../app/layout/Chrome';
import { selectSession, selectUser } from '../session/sessionSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faPlus, faSpinner } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import styles from '../../app/organisation_web_settings.module.scss';
import brandstore_styles from './brandstore_controls.module.scss';
import { BrandStoresControls } from './home/BrandStoresControls';
import { getBrandStores } from '../../lib/api/brandstores';
import OrganisationSelectionModal from '../order_form/OrganisationSelectionModal';
import { selectAllBrands } from '../brands/brandsSlice';
import { selectAllRetailers } from '../retailers/retailersSlice';
import { BrandStoreStatusBadge } from './BrandStoreStatusBadge';
import { BootstrapDataTable } from '../common/data/BootstrapDataTable';

const LinkButton = ({ url, text, icon }) => {
  return (
    <div>
      <Link variant='link' className='mr-auto px-3 small d-flex align-items-center' to={url} relative={'path'}>
        <FontAwesomeIcon icon={icon} aria-hidden='true' className='mr-2' />
        <div>{text}</div>
      </Link>
    </div>
  )
};

const getRetailerBrandStoreColumns = ({ navigate, suppliers }) => {
  return [
    {
      dataField: 'supplier_id',
      text: 'Brand',
      headerFormatter: (column) => {
        return <div style={{ minWidth: '250px' }}>{column.text}</div>;
      },
      formatter: (cell, row) => {
        let supplier = suppliers?.find((s) => s.id === row.supplier_id);
        return supplier?.name;
      },
    },
    {
      dataField: 'name',
      text: 'Store Name',
      style: {
        whiteSpace: 'nowrap',
      },
      headerFormatter: (column) => {
        return <div style={{ minWidth: '300px' }}>{column.text}</div>;
      },
    },
    {
      dataField: 'status',
      text: 'Status',
      headerFormatter: (column) => {
        return <div style={{ minWidth: '100px' }}>{column.text}</div>;
      },
      formatter: (cell, row) => (
        <div style={{ minWidth: '180px' }}>
          <BrandStoreStatusBadge status={row?.status} />
        </div>
      ),
    },
    {
      dataField: 'start_at',
      text: 'Start Date',
      headerFormatter: (column) => {
        return <div style={{ minWidth: '100px' }}>{column.text}</div>;
      },
      formatter: (cell, row) => {
        return moment(row.start_at).format('YYYY-MM-DD');
      },
    },
    {
      dataField: 'stop_at',
      text: 'End Date',
      headerFormatter: (column) => {
        return <div style={{ minWidth: '100px' }}>{column.text}</div>;
      },
      formatter: (cell, row) => {
        return moment(row.stop_at).format('YYYY-MM-DD');
      },
    },
    {
      dataField: 'pages',
      text: 'Pages',
      headerFormatter: (column) => {
        return <div style={{ minWidth: '80px' }}>{column.text}</div>;
      },
      formatter: (cell, row) => {
        var pages = row?.pages ?? [];

        return <span>{pages.length} pages</span>;
      },
    },
    {
      dataField: 'actions',
      text: 'Actions',
      formatter: (cell, row) => {
        return (
          <div className='d-flex'>
            <LinkButton url={`../edit/${row.id}`} text='View Store' icon={faCog} />
            <LinkButton url={`../configure/${row.id}`} text='Edit Settings' icon={faCog} />
          </div>
        );
      },
    },
  ];
};

const getSupplierBrandStoreColumns = ({ navigate, retailers }) => {
  return [
    {
      dataField: 'owner_id',
      text: 'Retailer',
      headerFormatter: (column) => {
        return <div style={{ minWidth: '250px' }}>{column.text}</div>;
      },
      formatter: (cell, row) => {
        let retailer = retailers?.find((s) => s.id === row.owner_id);
        return retailer?.name;
      },
    },
    {
      dataField: 'name',
      text: 'Store Name',
      style: {
        whiteSpace: 'nowrap',
      },
      headerFormatter: (column) => {
        return <div style={{ minWidth: '300px' }}>{column.text}</div>;
      },
    },
    {
      dataField: 'status',
      text: 'Status',
      headerFormatter: (column) => {
        return <div style={{ minWidth: '100px' }}>{column.text}</div>;
      },
      formatter: (cell, row) => (
        <div style={{ minWidth: '180px' }}>
          <BrandStoreStatusBadge status={row?.status} />
        </div>
      ),
    },
    {
      dataField: 'start_at',
      text: 'Start Date',
      headerFormatter: (column) => {
        return <div style={{ minWidth: '100px' }}>{column.text}</div>;
      },
      formatter: (cell, row) => {
        return moment(row.start_at).format('YYYY-MM-DD');
      },
    },
    {
      dataField: 'stop_at',
      text: 'End Date',
      headerFormatter: (column) => {
        return <div style={{ minWidth: '100px' }}>{column.text}</div>;
      },
      formatter: (cell, row) => {
        return moment(row.stop_at).format('YYYY-MM-DD');
      },
    },
    {
      dataField: 'pages',
      text: 'Pages',
      headerFormatter: (column) => {
        return <div style={{ minWidth: '80px' }}>{column.text}</div>;
      },
      formatter: (cell, row) => {
        var pages = row?.pages ?? [];

        return <span>{pages.length} pages</span>;
      },
    },
    {
      dataField: 'actions',
      text: 'Actions',
      formatter: (cell, row) => {
        return (
          <div>
            <LinkButton url={`../edit/${row.id}`} text='View Store' icon={faCog} />
          </div>
        );
      },
    },
  ];
};

const OrganisationBrandStoresHome = () => {
  const user = useSelector(selectUser);
  const session = useSelector(selectSession);
  const navigate = useNavigate();
  const isRetailer = user.active_organisation?.is_retailer;
  const [filterByBrandStoreSupplier, setFilterByBrandStoreSupplier] = useState([]);
  const [filterByBrandStoreRetailer, setFilterByBrandStoreRetailer] = useState([]);
  const [filterByBrandStoreName, setFilterByBrandStoreName] = useState(null);
  const [filterByBrandStoreStatus, setFilterByBrandStoreStatus] = useState('all');

  const [isBrandStoresLoading, setIsBrandStoresLoading] = useState(true);
  const [brandStoresData, setBrandStoresData] = useState([]);
  let filter = {};
  const listTopRef = useRef(null);
  const [showOrganisationsModal, setOrganisationsModal] = useState(false);
  const suppliers = useSelector(selectAllBrands);
  const retailers = useSelector(selectAllRetailers);

  useEffect(() => {
    filter = {};

    if (filterByBrandStoreSupplier.length > 0) {
      filter = { ...filter, supplier: filterByBrandStoreSupplier };
    }
    if (filterByBrandStoreRetailer.length > 0) {
      filter = { ...filter, retailer: filterByBrandStoreRetailer };
    }
    if (filterByBrandStoreName && filterByBrandStoreName.length > 0) {
      filter = { ...filter, name: filterByBrandStoreName };
    }
    if (filterByBrandStoreStatus && filterByBrandStoreStatus.length > 0) {
      filter = { ...filter, status: filterByBrandStoreStatus };
    }

    setIsBrandStoresLoading(true);

    getBrandStores(user.active_organisation.id, filter)
      .then((res) => {
        var data = res.data?.data;

        if (data) {
          setBrandStoresData(data);
        }

        setIsBrandStoresLoading(false);
      })
      .catch(() => {
        setIsBrandStoresLoading(false);
      });
  }, [filterByBrandStoreSupplier, filterByBrandStoreRetailer, filterByBrandStoreName, filterByBrandStoreStatus]);

  const brandStoreNoDataForFilter = () => {
    return <div className={styles.webSpaceNoDataForFilter}>There are no brand stores pages available</div>;
  };

  const handleFilterBrandStoreNameValue = debounce((val) => {
    filterByBrandStoreNameValue(val);
  }, 500);

  const filterByBrandStoreStatusValue = (status) => {
    setFilterByBrandStoreStatus(status);
  };

  const filterByBrandStoreNameValue = (val) => {
    setFilterByBrandStoreName(val);
  };

  const filterBrandStoreBySupplier = (selection) => {
    if (selection === 'all') {
      setFilterByBrandStoreSupplier('');
    } else {
      setFilterByBrandStoreSupplier(selection);
    }
  };

  const filterBrandStoreByRetailer = (selection) => {
    if (selection === 'all') {
      setFilterByBrandStoreRetailer('');
    } else {
      setFilterByBrandStoreRetailer(selection);
    }
  };

  return (
    <>
      <Helmet>
        <title>
          Brand Stores for Organisation {session.user?.active_organisation?.name} -{process.env.REACT_APP_NAME}
        </title>
      </Helmet>
      <Chrome>
        <div style={{ width: '100%', padding: '50px' }}>
          <Form ref={listTopRef}>
            <Row className='mb-3'>
              <Col>
                <div className='rounded my-1 d-flex'>
                  <div className='d-inline-block'>
                    <h3 className={styles.topHeading}>Brand Stores</h3>
                    <div>Showcase your brands and products in a multi-page, immersive shopping experience.</div>
                  </div>
                </div>
              </Col>
              <Col>
                {isRetailer && (
                  <Button
                    className='p-2 float-right'
                    style={{ width: '200px' }}
                    onClick={() => setOrganisationsModal(true)}
                  >
                    <FontAwesomeIcon className='mr-2 pt-0' style={{ height: '12px' }} icon={faPlus} />
                    <span>
                      <small>Create New Brand Store</small>
                    </span>
                  </Button>
                )}
              </Col>
            </Row>
            <div>
              <div>
                <BrandStoresControls
                  onChangeStatusDropdown={(e) => filterByBrandStoreStatusValue(e.target.value)}
                  onChangeSupplierDropdown={(e) => filterBrandStoreBySupplier(e.target.value)}
                  onChangeRetailerDropdown={(e) => filterBrandStoreByRetailer(e.target.value)}
                  onChangeFilterTextBox={(e) => handleFilterBrandStoreNameValue(e.target.value)}
                  brandPagesLoading={isBrandStoresLoading}
                  isRetailer={isRetailer}
                  suppliers={suppliers}
                  retailers={retailers}
                />
                <Row>
                  <Col>
                    {isBrandStoresLoading && (
                      <div
                        className='text-center d-flex align-items-center justify-content-center'
                        style={{ height: '300px' }}
                      >
                        <FontAwesomeIcon
                          className={`fa-spin ${brandstore_styles.loading_spinner}`}
                          icon={faSpinner}
                          size='4x'
                        />
                      </div>
                    )}
                    {!isBrandStoresLoading && (
                      <>
                        <div className={brandstore_styles.brandstore_home_table_container}>
                          <BootstrapDataTable
                            id='brandstore-home-table'
                            keyField='id'
                            headerWrapperClasses={styles.webspacesTemplatesHeader}
                            data={brandStoresData}
                            columns={
                              isRetailer
                                ? getRetailerBrandStoreColumns({
                                  navigate,
                                  suppliers,
                                })
                                : getSupplierBrandStoreColumns({
                                  navigate,
                                  retailers,
                                })
                            }
                            noDataIndication={brandStoreNoDataForFilter}
                            rowStyle={(row, rowIndex) => {
                              const backgroundColor = rowIndex % 2 === 0 ? '#f4f2ff' : '#fff';
                              return { backgroundColor };
                            }}
                            paginationEnable={false}
                          />
                        </div>
                      </>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div>&nbsp;</div>
                  </Col>
                </Row>
              </div>
            </div>
          </Form>
          {/* Modal to select the organization */}
          <OrganisationSelectionModal
            show={showOrganisationsModal}
            onHide={() => setOrganisationsModal(false)}
            navPath={'/brandstores/configure'}
          />
        </div>
      </Chrome>
    </>
  );
};

export default OrganisationBrandStoresHome;
