import axios from '../../axios'


export async function getOwnedSpaces(retailerId, filter, limit, skip) {
    filter = encodeURIComponent(JSON.stringify(filter));
    return axios.get(`/organisations/${retailerId}/webspaces?filter=${filter}&limit=${limit}&skip=${skip}`);
}

export async function getOwnedSpacesForSupplier(retailerId, supplierId) {
    return axios.get(`/organisations/${retailerId}/supplier/${supplierId}/webspaces`);
}

export async function getInventorySpaceDetails(retailerId, spaceId, range) {
    return axios.get(`/organisations/${retailerId}/report/inventory/webspaces/${spaceId}?range=${range}`);
}

export async function getOwnedSpaceDetails(retailerId, spaceId) {
    return axios.get(`/organisations/${retailerId}/webspaces/${spaceId}`);
}

export async function listOwnedSpaces(ownerID, brandID) {
    let params = new URLSearchParams()
    params.append('brand_id', brandID)
    params.append('owner_id', ownerID)

    return axios.get(`/owned_spaces?${params.toString()}`)
}

export async function updateOwnedSpace(form, owned_space_id) {
    return axios.put(`/owned_spaces/${owned_space_id}`, form);
}

export async function createOwnedSpace(form, owned_space_id) {
    return axios.post(`/owned_spaces`, form);
}

export async function setActiveStateOfOwnedSpace(state, owned_space_id) {
    return axios.post(`/owned_spaces/${owned_space_id}/status`, state);
}

export async function getActiveStateOfOwnedSpace(owned_space_id) {
    return axios.get(`/owned_spaces/${owned_space_id}/status`);
}

export async function getTemplatePreview(templateId, vars) {
    
    return axios.post(`/collab-web/compile-template`, {
        web_template_id: templateId,
        user_data: vars,
        preview: true,
    }, { headers: {
        'Content-Type': 'application/json'
    }})
}

export async function getAvailableSlots(owned_space_id) {
    return axios.get(`/owned_spaces/${owned_space_id}/get-available-slots`);
}

export async function getEmbeddableTag(owned_space_id) {
    return axios.get(`/owned_spaces/${owned_space_id}/embed-tag`);
}
