
import { connect } from 'react-redux'
import CreatableDropdown from '../../components/form_elements/CreatableDropdown'
import { createPackageGroup, selectAvaiablePackageGroups, selectPackageGroup, selectPackageGroupErrors, updateGroup } from '../../packageFormSlice'

const mapStateToProps = state => ({
    controlId: "package-group",
    label: "Group",
    description: "Attach this package to make it easier to find later.",
    value: selectPackageGroup(state),
    options: selectAvaiablePackageGroups(state).map(group => ({id: group.id, label: group.name, value: group.id})),
    errors: selectPackageGroupErrors(state),
})


const mapDispatchToProps = dispatch => ({
    onChange: group => dispatch(updateGroup(group.value)),
    onCreate: groupName => {
        dispatch(createPackageGroup(groupName))
            .then(action => dispatch(updateGroup(action.payload.id)))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(CreatableDropdown)
