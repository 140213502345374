import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { selectTheme } from '../../../session/sessionSlice';
import { formatNumber } from '../../../../lib/strings';
import Errors from '../Errors';

const CurrencyAmount = ({
  controlId,
  readOnly = false,
  disabled = false,
  required = true,
  metaEdit = false,
  metadata = [],
  header = 'Currency amount',
  value,
  onChange = () => {},
  onMetaChange = () => {},
  errors = [],
}) => {
  const theme = useSelector(selectTheme);

  const handleValueChange = (e) => {
    let val = e.target.value.replace(/[^\d]/g, '');
    if (val === '') {
      e.target.value = null;
      onChange(null);
      return;
    }

    let num = Number(val);
    if (!isNaN(num)) {
      onChange(num);
    }
  };

  return (
    <Form.Group controlId={controlId}>
      <div className='d-flex'>
        <Form.Label className='font-weight-bold'>
          {header} {required && '*'}
        </Form.Label>
      </div>
      <Form.Control
        type='text'
        className='rounded-right'
        style={theme.components?.form_control}
        maxLength={6}
        min={0}
        required={required}
        readOnly={readOnly}
        disabled={disabled}
        value={formatNumber(value ?? '')}
        isInvalid={errors.length}
        onChange={handleValueChange}
      />
      <Form.Control.Feedback type='invalid'>
        <Errors errors={errors} />
      </Form.Control.Feedback>
    </Form.Group>
  );
};

CurrencyAmount.propTypes = {
  controlId: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  metaEdit: PropTypes.bool,
  metadata: PropTypes.array,
  value: PropTypes.number,
  title: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  onMetaChange: PropTypes.func,
};

export default CurrencyAmount;
