import React from 'react';
import NoEventsImage from './no_events.svg';
import styled from 'styled-components';

const NoEventsText = styled.div`
  font-size: 23px;
`;
const NoEventsSubText = styled.div`
  font-size: 14px;
`;

export const NoEventsPlaceholder = () => {
  return (
    <tr>
      <td colSpan='8' className='text-center'>
        <img src={NoEventsImage} alt={'No Events Placeholder Image'} />
        <NoEventsText>No events have been created.</NoEventsText>
        <NoEventsSubText>Add events by clicking 'Add event'.</NoEventsSubText>
      </td>
    </tr>
  );
};
