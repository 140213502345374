import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment-business-days';
import React from 'react';
import { Form } from 'react-bootstrap';
import Datetime from 'react-datetime';
import Errors from './Errors';
import styled from 'styled-components';

const DateContainer = styled.div`
  ${(props) =>
    props.inline &&
    `
    display: flex;
    gap: 1rem;
    width: 100%;
  `}
`;

const DateTimeInput = styled(Datetime)`
  ${(props) =>
    props.directionUp &&
    `
    .rdtPicker {
      top: -270px;
    }
  `}
`;

const AdDateRange = ({
  controlIdStartAt,
  controlIdStopAt,
  readOnly = false,
  startReadOnly = false,
  required = false,
  startLabel = 'Start date',
  stopLabel = 'Stop date',
  minStartDays = 0,
  placeholder = { start_datetime: undefined, stop_datetime: undefined },
  value = { start_datetime: undefined, stop_datetime: undefined },
  onChange = () => {},
  errors = { start_datetime: undefined, stop_datetime: undefined },
  dateTime = true,
  closeOnSelect = false,
}) => {
  const dateFormat = dateTime ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD';

  const handleChange = (key, val) => {
    if (readOnly) {
      return;
    }

    if (moment.isMoment(val) && val.isValid()) {
      return onChange({ ...value, [key]: val.format(dateFormat) });
    }

    onChange({ ...value, [key]: val });
  };

  const validDateStart = (current) => {
    if (
      value.stop_datetime &&
      current.isAfter(moment.utc(value.stop_datetime))
    ) {
      return false;
    }

    return current.isAfter(moment().add(minStartDays, 'day'));
  };

  const validDateEnd = (current) => {
    if (
      value.start_datetime &&
      current.isBefore(moment.utc(value.start_datetime))
    ) {
      return false;
    }

    return current.isAfter(moment());
  };

  return (
    <DateContainer>
      <Form.Group controlId={controlIdStartAt}>
        <Form.Label className='font-weight-bold'>{startLabel}</Form.Label>
        <DateTimeInput
          directionUp
          value={
            value.start_datetime ? moment.utc(value.start_datetime) : undefined
          }
          closeOnSelect={closeOnSelect}
          renderInput={(props, openCalendar) => (
            <Form.Control
              type='text'
              onClick={() => (readOnly || startReadOnly ? '' : openCalendar())}
              readOnly={readOnly || startReadOnly}
              required={required}
              placeholder={
                placeholder.start_datetime
                  ? `${moment
                      .utc(placeholder.start_datetime)
                      .format('YYYY-MM-DD')} ${
                      dateTime
                        ? moment
                            .utc(placeholder.start_datetime)
                            .format('hh:mm A')
                        : ''
                    }`
                  : ''
              }
              value={
                props.value
                  ? `${moment.utc(props.value).format('YYYY-MM-DD')} ${
                      dateTime ? moment.utc(props.value).format('hh:mm A') : ''
                    }`
                  : ''
              }
              onChange={() => {
                //noop to prevent console warning - onChange handled in DateTimeInput
              }}
            />
          )}
          autoComplete={false}
          isValidDate={validDateStart}
          timeConstraints={{
            hours: { min: 0, max: 23 },
            minutes: { min: 0, max: 59, step: 1 },
          }}
          initialViewDate={moment().startOf('day')}
          onChange={(v) => {
            handleChange('start_datetime', v);
          }}
        />
        <Form.Control.Feedback
          type='invalid'
          className={errors.start_datetime ? 'd-block' : ''}
        >
          <Errors errors={errors.start_datetime} />
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId={controlIdStopAt}>
        <Form.Label className='font-weight-bold'>{stopLabel}</Form.Label>
        <DateTimeInput
          directionUp
          value={
            value.stop_datetime ? moment.utc(value.stop_datetime) : undefined
          }
          closeOnSelect={closeOnSelect}
          renderInput={(props, openCalendar) => (
            <Form.Control
              type='text'
              required={required}
              onClick={() => (readOnly ? '' : openCalendar())}
              placeholder={
                placeholder.stop_datetime
                  ? `${moment
                      .utc(placeholder.stop_datetime)
                      .format('YYYY-MM-DD')} ${
                      dateTime
                        ? moment
                            .utc(placeholder.stop_datetime)
                            .format('hh:mm A')
                        : ''
                    }`
                  : ''
              }
              readOnly={readOnly}
              value={
                props.value
                  ? `${moment.utc(props.value).format('YYYY-MM-DD')} ${
                      dateTime ? moment.utc(props.value).format('hh:mm A') : ''
                    }`
                  : ''
              }
              onChange={() => {
                //noop to prevent console warning - onChange handled in DateTimeInput
              }}
            />
          )}
          autoComplete={false}
          isValidDate={validDateEnd}
          timeConstraints={{
            hours: { min: 0, max: 23 },
            minutes: { min: 0, max: 59, step: 1 },
          }}
          initialViewDate={moment().endOf('day')}
          onChange={(v) => handleChange('stop_datetime', v)}
        />
        <Form.Control.Feedback
          type='invalid'
          className={errors.stop_datetime ? 'd-block' : ''}
        >
          <Errors errors={errors.stop_datetime} />
        </Form.Control.Feedback>
      </Form.Group>
    </DateContainer>
  );
};

AdDateRange.propTypes = {
  controlIdStartAt: PropTypes.string.isRequired,
  controlIdStopAt: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  placeholder: PropTypes.shape({
    start_datetime: PropTypes.string,
    stop_datetime: PropTypes.string,
  }),
  value: PropTypes.shape({
    start_datetime: PropTypes.string,
    stop_datetime: PropTypes.string,
  }),
  minStartDays: PropTypes.number,
  onChange: PropTypes.func,
  errors: PropTypes.shape({
    start_datetime: PropTypes.arrayOf(PropTypes.string),
    stop_datetime: PropTypes.arrayOf(PropTypes.string),
  }),
  dateTime: PropTypes.bool,
  closeOnSelect: PropTypes.bool,
};

export default AdDateRange;
