import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import { AsyncTypeahead, Token } from 'react-bootstrap-typeahead';
import { useSelector } from 'react-redux';
import { getAudienceLocations } from '../../../../lib/api';
import { selectOrder } from '../../orderFormSlice';
import { useRef, useEffect } from 'react';

const locationLabel = (platform, loc) => {
  if (platform === 'facebook') {
    if (loc.type === 'city') {
      return `${loc.name}, ${loc.region}, ${loc.country_name}`;
    } else if (loc.type === 'zip') {
      return `${loc.name} (${loc.primary_city}, ${loc.country_code})`;
    }
  } else if (platform === 'google' || platform === 'youtube') {
    if (loc.street) {
      return loc.name;
    }
    return loc.label;
  }

  return loc.name;
};

const LocationRow = ({
  id,
  readOnly = false,
  required = false,
  platform,
  onSearch = async () => {},
  value = {},
  key,
  onChange = () => {},
  onAddClick = () => {},
}) => {
  const order = useSelector(selectOrder);
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [radius, setRadius] = useState({
    radius: null,
    distance_unit: 'kilometer',
  });
  const handleChange = (obj) => onChange({ ...value, ...obj });
  const typeaheadRef = useRef(null);

  useEffect(() => {
    if ((platform === 'google' || platform === 'youtube') && value.type === 'address') {
      setRadius({
        radius: 20,
        distance_unit: 'kilometer',
      });
    }
  }, []);

  const onLocationSearch = (query) => {
    setIsLoading(true);
    getAudienceLocations(platform, value.type, order.owner_id, query)
      .then((resp) => resp.data.data)
      .then((data) => setOptions(data))
      .catch(() => {
        console.error('An unexpected error occurred while searching for the location.');
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <div>
      <Row className='mb-2'>
        <Col className='d-flex justify-content-around'>
          <InputGroup className='mt-2'>
            <InputGroup.Prepend>
              <InputGroup.Text id='basic-addon1'>
                <FontAwesomeIcon icon={faPlus} />
              </InputGroup.Text>
            </InputGroup.Prepend>
            {readOnly ? (
              <Form.Control readOnly={readOnly} value={locationLabel(platform, value)} />
            ) : (
              <>
                <AsyncTypeahead
                  id={id}
                  isLoading={isLoading}
                  style={
                    (platform === 'google' || platform === 'youtube') && value.type === 'address'
                      ? {
                          width: '350px',
                          float: 'left',
                        }
                      : {
                          flex: '1 0',
                        }
                  }
                  renderMenuItemChildren={(val) => {
                    return (
                      <Token active disabled={false}>
                        {locationLabel(platform, val)}
                      </Token>
                    );
                  }}
                  ref={typeaheadRef}
                  // className="ml-1"
                  placeholder={value.name}
                  disabled={readOnly || value.type === ''}
                  onSearch={onLocationSearch}
                  onChange={(selected) => {
                    if (!radius || radius?.radius !== null) {
                      handleChange({ ...selected[0], ...radius });
                    } else {
                      handleChange(selected[0]);
                    }
                    typeaheadRef.current.clear();
                  }}
                  options={options.map((loc, i) => ({
                    id: i,
                    ...loc,
                    label: locationLabel(platform, loc),
                  }))}
                />

                {(platform === 'facebook' && (value.type === 'zip' || value.type === 'city')) ||
                  ((platform === 'google' || platform === 'youtube') && value.type === 'address' && (
                    <>
                      <Form.Control
                        className='form-control'
                        type='number'
                        style={{
                          width: '100px',
                          float: 'left',
                          borderLeft: 'none',
                        }}
                        placeholder={'Radius'}
                        min={platform === 'facebook' ? 1 : 5}
                        max={platform === 'facebook' ? 80 : 65}
                        value={radius?.radius ? radius.radius : ''}
                        onChange={(e) => {
                          let value = parseInt(e.target.value);
                          let min = parseInt(e.target.min);
                          let max = parseInt(e.target.max);
                          if (value < min) {
                            value = min;
                          } else if (value > max) {
                            value = max;
                          }
                          setRadius({
                            radius: value,
                            distance_unit: 'kilometer',
                          });
                        }}
                      ></Form.Control>
                      <InputGroup.Append>
                        <InputGroup.Text className=''>Km</InputGroup.Text>
                      </InputGroup.Append>
                    </>
                  ))}
              </>
            )}
          </InputGroup>
        </Col>
      </Row>
      <Row className='mb-2'>
        <Col></Col>
      </Row>
    </div>
  );
};

LocationRow.propTypes = {
  id: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  platform: PropTypes.string.isRequired,
  value: PropTypes.shape({ name: PropTypes.string, type: PropTypes.string }),
  onChange: PropTypes.func,
};
export default LocationRow;
