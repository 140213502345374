import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';
import Chrome from '../app/layout/Chrome';
import ConnectedOrderFormChrome from '../features/order_form/ConnectedOrderFormChrome';
import OrderAdForm from '../features/order_form/containers/OrderAdForm';
import {
  availableAdFormats,
  availableMercatoDevices,
  availableWebSpaces,
  clearOrder,
  hasLiveOrderBeingViewed,
  orderFormEditable,
  orderPackages,
  orderUpdated,
  switchLiveOrderToEdited,
  updateOrderFormOrganisations,
} from '../features/order_form/orderFormSlice';
import { STATUS_DRAFT, STATUS_PENDING_APPROVAL, isEditingState } from '../lib/orders';
import axios from '../axios';
import useSWR from 'swr';
import { selectOrder } from '../features/order_form/orderFormSlice';
const getOrder = async (order_id) => {
  return axios.get(`/order/${order_id}`);
};

// fetch order by id
const fetcher = (...args) => {
  //args is [[]]
  const params = args[0][0];
  return getOrder(params).then((res) => res);
};

const EditOrder = () => {
  const dispatch = useDispatch();
  const { order_id } = useParams();
  const orderFromRedux = useSelector(selectOrder);
  const liveOrderViewed = useSelector(hasLiveOrderBeingViewed);
  const [extraParams, setExtraParams] = useState({});
  const {
    data: order,
    isLoading,
    error,
  } = useSWR([order_id], fetcher, {
    keepPreviousData: true,
  });

  const canEdit = isEditingState(orderFromRedux) || liveOrderViewed;

  useEffect(() => {
    if (order_id !== orderFromRedux.id) {
      dispatch(clearOrder()); //<- reset the order to the initialState if the in the url is different from what we have in redux.
    }
    if (!isLoading && !error && order) {
      dispatch(orderUpdated(order?.data?.data));
      dispatch(orderFormEditable());
      dispatch(switchLiveOrderToEdited());
      let inStoreScreensTabIsReadOnly = true;
      dispatch(updateOrderFormOrganisations());
      dispatch(availableAdFormats());
      dispatch(availableMercatoDevices());
      dispatch(availableWebSpaces());
      dispatch(orderPackages());
      if (
        !liveOrderViewed &&
        (order?.data?.data?.status === STATUS_PENDING_APPROVAL || order?.data?.data?.status === STATUS_DRAFT)
      ) {
        inStoreScreensTabIsReadOnly = false;
      }
      setExtraParams({ ...extraParams, inStoreScreensTabIsReadOnly: inStoreScreensTabIsReadOnly });
    }
  }, [dispatch, isLoading, error, order_id]);


  if (!canEdit) {
    return <Navigate to={`/order/${order_id}`} />;
  }

  return (
    <>
      <Helmet>
        <title>Edit Order - {process.env.REACT_APP_NAME}</title>
      </Helmet>

      <Chrome>
        <Container fluid className='pt-4'>
          <Row>
            <Col>
              <ConnectedOrderFormChrome>
                <OrderAdForm extra={extraParams} />
              </ConnectedOrderFormChrome>
            </Col>
          </Row>
        </Container>
      </Chrome>
    </>
  );
};

export default EditOrder;
