import React from 'react';
import styled from 'styled-components';
import { AssetGroup } from './DigitalScreenMediaTypes';

const AssetsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AssetContainer = styled.div`
  &:not(:first-of-type) {
    padding-top: 20px;
  }
  width: 100%;
  max-width: 800px;
`;

const AssetLabel = styled.div`
  padding-bottom: 20px;
  font-size: 18px;
  font-weight: 300;
`;

interface MediaEntryProps {
  assetGroup: AssetGroup;
  orderAssets: Array<any>;
  screenTypes: Array<string>;
}

export const MediaEntry: React.FC<MediaEntryProps> = ({ assetGroup, orderAssets, screenTypes }) => {
  return (
    <AssetsContainer>
      {assetGroup.map((asset, index) => {
        const mediaType = asset.media_type;
        const mediaId = asset[mediaType];
        const mediaURL = orderAssets.find((a) => a.id === mediaId)?.url;

        if (mediaType && mediaURL && screenTypes.includes(asset.screen_type)) {
          const AssetComponent = mediaType === 'image' ? 'img' : 'video';
          const label = `${asset.screen_type} - ${mediaType.charAt(0).toUpperCase() + mediaType.slice(1)}`;
          const aspectRatio = asset.screen_type === 'Landscape' ? '16by9' : '1by1';

          return (
            <AssetContainer key={index}>
              <AssetLabel>{label}</AssetLabel>
              <div className={`embed-responsive embed-responsive-${aspectRatio}`}>
                <AssetComponent
                  alt={`${label} preview`}
                  className='embed-responsive-item'
                  src={mediaURL}
                  {...(mediaType === 'video'
                    ? { controls: true, style: { backgroundColor: 'black' } }
                    : { style: { objectFit: 'contain' } })}
                />
              </div>
            </AssetContainer>
          );
        }
        return null; // If the conditions aren't met, don't render anything for this asset.
      })}
    </AssetsContainer>
  );
};
