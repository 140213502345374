export function aspectRatio(width: number, height: number): string {
  // Calculate the greatest common divisor (GCD) using the Euclidean algorithm
  const gcd = (a: number, b: number): number => (b === 0 ? a : gcd(b, a % b));

  const aspectRatioGCD = gcd(width, height);
  const calculatedWidth = width / aspectRatioGCD;
  const calculatedHeight = height / aspectRatioGCD;
  const targetAspectRatio = 191 / 100; // Target aspect ratio 1.91:1

  // Calculate the aspect ratio with the margin of error
  const calculatedAspectRatio = calculatedWidth / calculatedHeight;
  const upperLimit = targetAspectRatio * 1.02;
  const lowerLimit = targetAspectRatio * 0.98;

  // Check if the calculated aspect ratio is within the acceptable range
  if (calculatedAspectRatio >= lowerLimit && calculatedAspectRatio <= upperLimit) {
    return '1.91:1';
  }

  return `${calculatedWidth}:${calculatedHeight}`;
}
