import { Col, Form, Row } from 'react-bootstrap';
import styles from './bespokeFields.module.scss';
import PropTypes from 'prop-types';

const VideoField = ({ field, index, updateLayoutData, layoutData, idHelper, errors }) => {
  return (
    <Row>
      <Col md={6}>
        <Form.Group className={styles.group_style} controlId={field.type + index + '-Name'}>
          <Form.Label>Name</Form.Label>
          <Form.Control
            type='text'
            value={layoutData[index]?.title ?? ''}
            onChange={(e) =>
              updateLayoutData({ id: idHelper('video_' + e.target.value), title: e.target.value }, index)
            }
            isInvalid={!layoutData[index]?.title && errors.length > 0}
          />
        </Form.Group>
        <Form.Group className={styles.group_style} controlId={field.type + index + '-Default'}>
          <Form.Label>Description</Form.Label>
          <Form.Control
            type='text'
            value={layoutData[index]?.notice ?? ''}
            onChange={(e) => updateLayoutData({ notice: e.target.value }, index)}
          />
        </Form.Group>
        <Form.Group className={styles.group_style} controlId={field.type + index + '-Min-Height'}>
          <Form.Label>Min Height</Form.Label>
          <Form.Control
            type='number'
            value={layoutData[index]?.min_height ?? ''}
            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
            onChange={(e) => updateLayoutData({ min_height: parseInt(e.target.value) }, index)}
          />
        </Form.Group>
        <Form.Group className={styles.group_style} controlId={field.type + index + '-Max-Height'}>
          <Form.Label>Max Height</Form.Label>
          <Form.Control
            type='number'
            value={layoutData[index]?.max_height ?? ''}
            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
            onChange={(e) => updateLayoutData({ max_height: parseInt(e.target.value) }, index)}
          />
        </Form.Group>
        <Form.Group className={styles.group_style} controlId={field.type + index + '-Min-Width'}>
          <Form.Label>Min Width</Form.Label>
          <Form.Control
            type='number'
            value={layoutData[index]?.min_width ?? ''}
            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
            onChange={(e) => updateLayoutData({ min_width: parseInt(e.target.value.replace(/eE/, '')) }, index)}
          />
        </Form.Group>
      </Col>
      <Col md={6}>
        <Form.Group className={styles.group_style} controlId={field.type + index + '-Max-Width'}>
          <Form.Label>Max Width</Form.Label>
          <Form.Control
            type='number'
            value={layoutData[index]?.max_width ?? ''}
            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
            onChange={(e) => updateLayoutData({ max_width: parseInt(e.target.value) }, index)}
          />
        </Form.Group>
        <Form.Group className={styles.group_style} controlId={field.type + index + '-Max-Length'}>
          <Form.Label>Min Length</Form.Label>
          <Form.Control
            type='number'
            value={layoutData[index]?.min_length ?? ''}
            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
            onChange={(e) => updateLayoutData({ min_length: parseInt(e.target.value) }, index)}
          />
        </Form.Group>
        <Form.Group className={styles.group_style} controlId={field.type + index + '-Min-Length'}>
          <Form.Label>Max Length</Form.Label>
          <Form.Control
            type='number'
            value={layoutData[index]?.max_length ?? ''}
            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
            onChange={(e) => updateLayoutData({ max_length: parseInt(e.target.value) }, index)}
          />
        </Form.Group>
        <Form.Group className={styles.group_style} controlId={field.type + index + '-Max-Attachments'}>
          <Form.Label>Max Attachments</Form.Label>
          <Form.Control
            type='number'
            value={layoutData[index]?.max_videos ?? ''}
            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
            onChange={(e) =>
              updateLayoutData(
                {
                  max_videos: parseInt(e.target.value),
                  max_attachments: parseInt(e.target.value),
                },
                index
              )
            }
          />
        </Form.Group>
        <Form.Group className={styles.group_style} controlId={field.type + index + '-Info'}>
          <Form.Label>Info</Form.Label>
          <Form.Control
            type='text'
            value={layoutData[index]?.info ?? ''}
            onChange={(e) => updateLayoutData({ info: e.target.value }, index)}
          />
        </Form.Group>
        {/* <p>Aspect Ratio</p>
                <Form.Group className={styles.group_style} controlId={type + index + '-multiline'}>
                    <Form.Label>X:</Form.Label>
                    <Form.Check className={styles.form_check} type="radio" label="'1.1'" name={type + index + '-ar-X'} />
                    <Form.Check className={styles.form_check} type="radio" label="'1.9:1'" name={type + index + '-ar-X'} />
                </Form.Group>
                <Form.Group className={styles.group_style} controlId={type + index + '-multiline'}>
                    <Form.Label>Y:</Form.Label>
                    <Form.Check className={styles.form_check} type="radio" label="'1.1'" name={type + index + '-ar-Y'} />
                    <Form.Check className={styles.form_check} type="radio" label="'1.9:1'" name={type + index + '-ar-Y'} />
                </Form.Group> */}
      </Col>
    </Row>
  );
};

VideoField.propTypes = {
  field: PropTypes.object,
  index: PropTypes.number,
  updateLayoutData: PropTypes.func,
  layoutData: PropTypes.func,
  idHelper: PropTypes.func,
  errors: PropTypes.array,
};

export default VideoField;
