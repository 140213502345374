import moment from 'moment';

export function validVideo(field, file) {
    return (field.max_video_duration || Number.POSITIVE_INFINITY) >= file.metadata.duration
}

export function videoErrors(field, file) {
    let errors = []

    if ((field.max_video_duration || Number.POSITIVE_INFINITY) < file.metadata.duration) {
        errors.push(`Video is too long: needs to be at most ${moment.duration((field.max_video_duration), 'seconds').humanize()}`)
    }

    return errors
}
