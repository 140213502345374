import axios from '../../axios'

export const getAudienceLocationFields = (platform) => {
    return axios.get(`/audience/${platform}/location/fields`);
}

export const getAudienceLocations = (platform, type, ownerId, phrase) => {
    return axios.get(`/audience/${platform}/${ownerId}/locations/${type}?phrase=${phrase}`);
}

export const getAudienceInterests = (platform, ownerId, phrase) => {
    return axios.get(`/audience/${platform}/${ownerId}/interests?phrase=${phrase}`);
}

export const getAudienceBehaviours = (platform, ownerId, phrase, ad_control_id) => {
    return axios.get(`/audience/${platform}/${ownerId}/behaviours?phrase=${phrase}&ad_control_id=${ad_control_id}`);
}

export const getAudienceDescription = (platform, ownerId, query) => {
    const form = new FormData();
    form.set('q', JSON.stringify(query));
    return axios.post(`/audience/${platform}/${ownerId}/description`, form);
}

export const getAudienceReachEstimate = (platform, ownerId, data) => {
    return axios.post(`/audience/${platform}/${ownerId}/reach-estimate`, data);
}
