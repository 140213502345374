import React from 'react';
import styles from './circular_badge.module.scss';

const CircularBadge = () => (
    <div className={styles.circle_badge}>
      <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 10 10"
          width="10px"
          height="10px"
      >
          <circle
              cx="5"
              cy="5"
              r="5"
              className={styles.circle_icon}
              fillOpacity="1.0"
          />
      </svg>
      <div className={styles.ringring}></div>
  </div>
);

export default CircularBadge;