import { connect } from 'react-redux';
import Feed from '../components/Feed';
import { selectOrderFormPermissions, selectReadOnly } from '../orderFormSlice';
import { AdType } from '../../../lib/enums/ad_types';
import { PRODUCT_SET_TYPE_FACEBOOK_MPA } from '../../../lib/constants';

const mapStateToProps = (state, ownProps) => {
  const perms = selectOrderFormPermissions(state);
  const ad = ownProps?.ad || null;
  let productSets = [];
  let productCatalogs = [];

  if (ad && ad?.type === AdType.FACEBOOK_MANAGED_PARTNER_AD) {
    productSets = perms?.product_sets?.filter((set) => set?.type && set.type === PRODUCT_SET_TYPE_FACEBOOK_MPA && set.platform === 'facebook');
  } else {
    productSets = perms?.product_sets?.filter((set) => set.platform === 'facebook');
  }

  productCatalogs = perms?.product_catalogs?.filter((catalog) => catalog.platform === 'facebook');

  return {
    controlId: 'facebook-catalogs',
    required: false,
    readOnly: !ownProps?.type ? selectReadOnly(state) : false,
    productSets: productSets,
    productCatalogs: productCatalogs,
  };
};

export default connect(mapStateToProps)(Feed);
