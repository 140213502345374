import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import styles from './bespokeFields.module.scss';
import PropTypes from 'prop-types';

const TextField = ({ field, index, updateLayoutData, layoutData, idHelper, errors }) => {
  return (
    <Row>
      <Col md={6}>
        <Form.Group className={styles.group_style} controlId={field.type + index + '-Name'}>
          <Form.Label>Name</Form.Label>
          <Form.Control
            type='text'
            value={layoutData[index]?.title ?? ''}
            onChange={(e) => updateLayoutData({ id: idHelper(e.target.value), title: e.target.value }, index)}
            isInvalid={!layoutData[index]?.title && errors.length > 0}
          />
        </Form.Group>
        <Form.Group className={styles.group_style} controlId={field.type + index + '-Default'}>
          <Form.Label>Description</Form.Label>
          <Form.Control
            type='text'
            value={layoutData[index]?.notice ?? ''}
            onChange={(e) => updateLayoutData({ notice: e.target.value }, index)}
          />
        </Form.Group>
        <Form.Group className={styles.group_style} controlId={field.type + index + '-Info'}>
          <Form.Label>
            Info{' '}
            <OverlayTrigger
              placement='right'
              overlay={
                <Tooltip id='info-popover'>This will be used as info text for your field, just like this popup</Tooltip>
              }
            >
              <FontAwesomeIcon icon={faQuestionCircle} size='sm' />
            </OverlayTrigger>
          </Form.Label>
          <Form.Control
            type='text'
            value={layoutData[index]?.info ?? ''}
            onChange={(e) => updateLayoutData({ info: e.target.value }, index)}
          />
        </Form.Group>
      </Col>
      <Col md={6}>
        <Form.Group className={styles.group_style} controlId={field.type + index + '-Max-Length'}>
          <Form.Label>Min Length</Form.Label>
          <Form.Control
            type='number'
            value={layoutData[index]?.minlen ?? ''}
            onChange={(e) => updateLayoutData({ minlen: parseInt(e.target.value) }, index)}
          />
        </Form.Group>
        <Form.Group className={styles.group_style} controlId={field.type + index + '-Min-Length'}>
          <Form.Label>Max Length</Form.Label>
          <Form.Control
            type='number'
            value={layoutData[index]?.maxlen ?? ''}
            onChange={(e) =>
              updateLayoutData({ maxlen: parseInt(e.target.value), maxlensoft: parseInt(e.target.value) }, index)
            }
          />
        </Form.Group>
        <Form.Group className={styles.group_style} controlId={field.type + index + '-multiline'}>
          <Form.Label>Multiline</Form.Label>
          <Form.Check
            className={styles.form_check}
            type='radio'
            label='Yes'
            id={field.type + index + '-yes'}
            checked={layoutData[index]?.type === 'textarea'}
            onChange={(e) => updateLayoutData({ type: e.target.checked && 'textarea' }, index)}
          />
          <Form.Check
            className={styles.form_check}
            type='radio'
            label='No'
            id={field.type + index + '-no'}
            checked={layoutData[index]?.type === 'text'}
            onChange={(e) => updateLayoutData({ type: e.target.checked && 'text' }, index)}
          />
        </Form.Group>
      </Col>
    </Row>
  );
};

TextField.propTypes = {
  field: PropTypes.object,
  index: PropTypes.number,
  updateLayoutData: PropTypes.func,
  layoutData: PropTypes.func,
  idHelper: PropTypes.func,
  errors: PropTypes.array,
};

export default TextField;
