import { faInfoCircle, faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Alert, Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { getBrandingAssets, resetPasswordExternal } from '../lib/api';
import Footer from './layout/Footer';

const LocalAlert = styled.div`
  margin-top: 2.25em;
  background-color: ${(props) => props.$bgColor || '#b5d0f7'};
  padding: 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 10px;
  min-height: 44px;
  svg {
    color: #00001e;
  }
  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 19.4px;
    color: #00001e;
    margin: 0;
  }
`;
const Logo = styled.img`
  display: block;
  height: 128px;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
`;
const ResetPassword = () => {
  let location = useLocation();
  let params = new URLSearchParams(location.search);
  const [theme, setTheme] = useState({});

  useEffect(() => {
    if (params.has('id')) {
      getBrandingAssets(params.get('id')).then((resp) => {
        if (resp?.data?.data) {
          setTheme(resp.data.data);
        }
      });
    }
  }, []);

  const {
    getValues,
    register,
    handleSubmit,
    formState: { isSubmitting, errors, isSubmitSuccessful },
  } = useForm({
    mode: 'onSubmit',
  });

  const submitResetRequest = async ({ email }) => {
    try {
      await resetPasswordExternal(email);
    } catch (ex) {
      // there is a global error handler in the main axios instance
      // >src/axios/axiosMainInstance.js
    }
  };

  return (
    <>
      <Helmet>
        <title>Reset Password - {process.env.REACT_APP_NAME || ''}</title>
      </Helmet>
      <main className='py-4' style={{ backgroundColor: theme?.styles?.background }}>
        <Container>
          <Row className='justify-content-center'>
            <Col md={8}>
              <Card>
                {theme?.logo?.url && <Logo src={theme.logo.url} alt='Branding' />}
                <Card.Body>
                  <Alert variant='primary' className='text-center'>
                    Enter your account email to reset
                  </Alert>
                  <Form onSubmit={handleSubmit(submitResetRequest)}>
                    <Form.Group controlId='email'>
                      <Row>
                        <Col lg={{ offset: 3, span: 6 }}>
                          <Form.Control
                            type='email'
                            placeholder='Email'
                            {...register('email', {
                              required: {
                                message: 'Please enter your email address to reset your password',
                                value: true,
                              },
                              pattern: {
                                message: 'Please enter a valid email address to reset your password',
                                value: /\S+@\S+\.\S+/,
                              },
                            })}
                            aria-invalid={errors.email ? 'true' : 'false'}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Row>
                      <Col className='text-center mb-2'>
                        <Button type='submit' disabled={isSubmitting}>
                          Reset Password
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
              {errors.email && (
                <LocalAlert $bgColor='#EF767A'>
                  <FontAwesomeIcon icon={faExclamationTriangle} />
                  <p>{errors.email.message}</p>
                </LocalAlert>
              )}
              {isSubmitSuccessful && !errors.email && (
                <LocalAlert>
                  <FontAwesomeIcon icon={faInfoCircle} />
                  <p>
                    {`If this email exists in Zitcha, we will send instructions to ${getValues(
                      'email'
                    )} to reset your password`}
                  </p>
                </LocalAlert>
              )}
            </Col>
          </Row>
        </Container>
      </main>
      <Footer />
    </>
  );
};

export default ResetPassword;
