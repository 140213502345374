
import { connect } from 'react-redux'
import Text from '../../components/form_elements/Text'
import { selectPackageName, selectPackageNameErrors, updateName } from '../../packageFormSlice'

const mapStateToProps = state => ({
    controlId: "package-name",
    label: "Name",
    description: "Headlines over 30 characters may be truncated.",
    maxLengthSoft: 30,
    required: true,
    value: selectPackageName(state),
    errors: selectPackageNameErrors(state),
})


const mapDispatchToProps = dispatch => ({
    onChange: name => dispatch(updateName(name)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Text)
