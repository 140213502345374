import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Chart from 'react-google-charts';
import moment from 'moment/moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { useSelector } from 'react-redux';
import { selectSession } from '../../session/sessionSlice';

const options_util = {
  tooltip: { isHtml: true },
  allowHTML: true,
  legend: { position: 'top' },
  chartArea: { width: '80%', height: '80%' },
  // legend: "none",
  animation: {
    startup: true,
    easing: 'linear',
    duration: 300,
  },
  hAxix: {
    title: 'Period',
  },
  vAxis: {
    title: 'Utilisation (%)',
    viewWindow: {
      min: 0,
      max: 100,
    },
  },
  isStacked: true,
  seriesType: 'steppedArea',
  series: {
    0: { type: 'bars', visibleInLegend: false, color: '#777785' },
    1: { color: '#FAD1D3' },
    2: { color: '#FFF5CC' },
    3: { color: '#E3FDF0' },
  },
  axes: {
    y: {
      all: {
        range: {
          max: 100,
          min: 0,
        },
      },
    },
  },
};

const options_rev = {
  tooltip: { isHtml: true },
  allowHTML: true,
  legend: { position: 'top' },
  chartArea: { width: '80%', height: '80%' },
  // legend: "none",
  animation: {
    startup: true,
    easing: 'linear',
    duration: 300,
  },
  hAxix: {
    title: 'Period',
  },
  vAxis: {
    title: 'Revenue (%)',
    viewWindow: {
      min: 0,
      max: 100,
    },
  },
  isStacked: true,
  seriesType: 'steppedArea',
  series: {
    0: { type: 'bars', visibleInLegend: false, color: '#777785' },
    1: { color: '#FAD1D3' },
    2: { color: '#FFF5CC' },
    3: { color: '#E3FDF0' },
  },
  axes: {
    y: {
      all: {
        range: {
          min: 0,
        },
      },
    },
  },
};

const StyledTab = styled.span`
  padding: 0 15px;
  transition: 0.3s;
  ${(props) => props.active && 'border-bottom: 3px solid #7155FF;'}
`;

const StyledIcon = styled(FontAwesomeIcon)`
  font-size: 12px;
`;

const Overlay = ({ tooltip, text }) => (
  <OverlayTrigger
    trigger={['hover', 'focus']}
    rootClose
    placement='right'
    overlay={<Tooltip id={`tooltip-${text}`}>{tooltip}</Tooltip>}
  >
    <StyledIcon icon={faCircleInfo} />
  </OverlayTrigger>
);

const TextTab = ({ setTab, text, tooltip, active }) => (
  <StyledTab active={active === text} style={{ cursor: 'pointer' }} onClick={(e) => setTab(text)}>
    {text} <Overlay tooltip={tooltip} text={text} />
  </StyledTab>
);

const getToolTipForUtilisation = (percentage, date) => {
  return `<div style="width: 190px; margin: 10px">
   <p><strong>Utilisation:</strong> ${percentage}%</p>
   <p><strong>Date:</strong> ${moment(date).format('YYYY-MM-DD')}</p>
  </div>`;
};

const getToolTipForRevenue = (percentage, revenue, currency, date) => {
  return `<div style="width: 190px; margin: 10px">
   <p><strong>Revenue:</strong> ${percentage}%</p>
   <p><strong>Amount (${currency}):</strong> ${revenue}</p>
   <p><strong>Date:</strong> ${moment(date).format('YYYY-MM-DD')}</p>
  </div>`;
};

const InventorySpacePerformance = ({ ownedSpace, errors = {} }) => {
  const session = useSelector(selectSession);
  const [organisation] = useState(session.user.active_organisation);
  const [tabs, setTab] = useState('Utilisation');

  let lables_util = [
    '',
    'Utilisation (%)',
    { type: 'string', role: 'tooltip', p: { html: true } },
    'Low',
    'Medium',
    'High',
  ];
  let lables_rev = ['', 'Revenue (%)', { type: 'string', role: 'tooltip', p: { html: true } }, 'Low', 'Medium', 'High'];
  const util = (ownedSpace?.[0]?.data ?? []).map((d) => [
    moment(d?.date).format('D MMM'),
    d?.percent - 0,
    getToolTipForUtilisation(d?.percent, d?.date),
    30,
    30,
    40,
  ]);
  const rev = (ownedSpace?.[0]?.data ?? []).map((d) => [
    moment(d?.date).format('D MMM'),
    d?.revenue_percent - 0,
    getToolTipForRevenue(d?.revenue_percent, d?.revenue, organisation?.settings?.defaultCurrency, d?.date),
    30,
    30,
    40,
  ]);

  return (
    <div className='border rounded p-3'>
      <article>
        <div className='mb-2'>
          <h5>Performance Over Time</h5>
        </div>
        <div>
          <TextTab
            text={'Utilisation'}
            tooltip={'Utilisation shown as a percentage according to available slots.'}
            active={tabs}
            setTab={setTab}
          />
          <TextTab
            text={'Revenue'}
            tooltip={
              'The revenue percentage is comparative to the revenue generated by the top performing space within the selected time period.'
            }
            active={tabs}
            setTab={setTab}
          />
        </div>
        <div>
          <div>
            {tabs === 'Utilisation' ? (
              util.length > 0 ? (
                <Chart
                  key={'Utilisation-chart'}
                  chartType='ComboChart'
                  data={[lables_util, ...util]}
                  width='100%'
                  height='400px'
                  options={options_util}
                  legendToggle
                />
              ) : (
                <p className='text-center m-5'>No Data Available</p>
              )
            ) : tabs === 'Revenue' && rev.length > 0 ? (
              <Chart
                key={'Revenue-chart'}
                chartType='ComboChart'
                data={[lables_rev, ...rev]}
                width='100%'
                height='400px'
                options={options_rev}
                legendToggle
              />
            ) : (
              <p className='text-center m-5'>No Data Available</p>
            )}
          </div>
        </div>
      </article>
    </div>
  );
};

InventorySpacePerformance.propTypes = {
  ownedSpace: PropTypes.array,
  errors: PropTypes.object,
};

export default InventorySpacePerformance;
