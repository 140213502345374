import React from 'react';
import { Controller } from 'react-hook-form';
import { Col } from 'react-bootstrap';
import { RadioOption } from '../../features/order_form/components/RadioOption';
import styled from 'styled-components';

const StyledSubHeading = styled.h5`
  color: #5a5c69;
  font-size: 16px;
  font-weight: 700;
  line-height: 140%;
`;

const StyledDefaultPageSelector = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  gap: 20px;
`;

export const MetaManagedPartnerAdSettings = ({ control, adSettings }) => {
  return (
    <Col md={12} className='my-4'>
      <h5>Meta Managed Partner Ad Settings</h5>
      <p>This can be overridden on a specific Supplier’s settings page.</p>
      <StyledSubHeading>Default page to display ads.</StyledSubHeading>
      <Controller
        control={control}
        name='mpa_default_page_setting'
        defaultValue={adSettings?.mpa_default_page_setting || 'retailer'}
        render={({ field: { onChange, value } }) => (
          <StyledDefaultPageSelector>
            <RadioOption
              name={'Retailer Page'}
              value='retailer'
              checked={value === 'retailer'}
              onChange={onChange}
              description='Retailer Page'
              label=''
            />
            <RadioOption
              name={'Supplier Page'}
              value='supplier'
              checked={value === 'supplier'}
              onChange={onChange}
              description='Supplier Page'
              label=''
            />
          </StyledDefaultPageSelector>
        )}
      />
    </Col>
  );
};
