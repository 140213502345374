import React from 'react';
import PropTypes from 'prop-types';
import styles from '../ad_section.module.scss';
import MercatoDevicePlacement from '../containers/MercatoDevices/MercatoDevicePlacement';

export const PlacementTab = ({ ad, readOnly, mercatoDevices, handleChange }) => {
  return (
    <div className={styles.form_section}>
      <MercatoDevicePlacement
        ad={ad}
        readOnly={readOnly}
        value={ad?.selectedDevices ?? []}
        ownedDevices={mercatoDevices}
        onChange={(device) => {
          handleChange({ selectedDevices: device });
        }}
      />
    </div>
  );
};

PlacementTab.propTypes = {
  ad: PropTypes.object.isRequired,
  readOnly: PropTypes.bool.isRequired,
  mercatoDevices: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
};
