import { faFacebook, faGoogle, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faCaretDown, faCaretUp, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { faTimes } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Currency from '../../form_elements/Currency'
import Date from '../../form_elements/Date'
import TextArea from '../../form_elements/TextArea'
import Percentage from '../../form_elements/Percentage'
import ChoiceAmount from '../../form_elements/ChoiceAmount'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { PLATFORM_NATIVE } from '../../../../../lib/platforms'

const FLAT = 'flat'
const PERC = 'percentage'

const FormContainer = styled.article`
    margin-bottom: 25px;
`

const CardContainer = styled.div`
    border: 1px solid #D0C6FF;
    background: #fff;
    padding: 24px;
`

const ActionRow = styled.div`
    display: flex;
`

const Header = styled.h5`
    font-size: 23px;
`

const CardControls = styled.span`
    margin-left: auto;
    gap: 8px;
    display: flex;
`

const ControlButton = styled.span`
    cursor: pointer;
`

const FormRow = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 18px;

    ${props => props.isBig && 'align-items: start;'}
`

const InlineFormControl = styled.span`
    margin-left: auto;
    width: 13.75rem;
`

const StyledFacebook = styled(FontAwesomeIcon)`
    color: #5995ED;
`;
const StyledYoutube = styled(FontAwesomeIcon)`
    color: #FF0000;
`;
const StyledGoogle = styled(FontAwesomeIcon)`
    color: #4285F4;
`;

const DateController = styled(InlineFormControl)`
    display: flex;
    align-items: start;
    button {
        margin-bottom: 1rem;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
`;

const AdFormatCard = ({ controlId, readOnly = false, platform, label, value = {}, onChange = () => { }, onMoveUp, onMoveDown, onDelete = () => { }, errors = {} }) => {
    const handleMinBudgetChange = min_budget => onChange({ ...value, min_budget })
    const handleMaxBudgetChange = max_budget => onChange({ ...value, max_budget })
    const handleStartDateChange = start_date => onChange({ ...value, start_date })
    const handleEndDateChange = end_date => onChange({ ...value, end_date })
    const handleNotesChange = notes => onChange({ ...value, notes })
    const handleDiscountPercChange = percentage_discount => onChange({ ...value, percentage_discount, flat_discount: 0 })
    const handleDiscountPriceChange = flat_discount => onChange({ ...value, flat_discount, percentage_discount: 0 })

    const iconLookup = (name) => {
        switch (name) {
            case 'facebook':
                return <StyledFacebook icon={faFacebook} className="mr-1" />
            case 'youtube':
                return <StyledYoutube icon={faYoutube} className="mr-1" />
            case 'google':
                return <StyledGoogle icon={faGoogle} className="mr-1" />
            default:
                break;
        }
    }

    return (<FormContainer readOnly={readOnly}>
        <ActionRow>
            <Header>
                {iconLookup(platform)}{label}
            </Header>
            {!readOnly &&
                <CardControls>
                    {onMoveUp &&
                        <ControlButton>
                            <FontAwesomeIcon icon={faCaretUp} onClick={onMoveUp} />
                        </ControlButton>}
                    {onMoveDown &&
                        <ControlButton>
                            <FontAwesomeIcon className="cursor-pointer" icon={faCaretDown} onClick={onMoveDown} />
                        </ControlButton>}
                    <ControlButton>
                        <FontAwesomeIcon className="cursor-pointer" icon={faTimes} onClick={onDelete} />
                    </ControlButton>
                </CardControls>
            }
        </ActionRow>
        <CardContainer>
            <FormRow>
                Minimum Budget
                {(platform === PLATFORM_NATIVE) && <OverlayTrigger
                    trigger={["hover", "focus"]}
                    rootClose
                    placement="right"
                    overlay={
                        <Tooltip>Minimum budget applies for fixed monetisation Ad Types</Tooltip>
                    }
                >
                    <FontAwesomeIcon icon={faQuestionCircle} />
                </OverlayTrigger>}
                <InlineFormControl>
                    <Currency
                        controlId={`${controlId}-currency`}
                        readOnly={readOnly}
                        required
                        value={value.min_budget}
                        onChange={handleMinBudgetChange}
                        errors={errors.min_budget}
                    />
                </InlineFormControl>
            </FormRow>
            <FormRow>
                Maximum Budget
                {(platform === PLATFORM_NATIVE) && <OverlayTrigger
                    trigger={["hover", "focus"]}
                    rootClose
                    placement="right"
                    overlay={
                        <Tooltip>Maximum budget applies for CPM/CPC monetisation Ad Types</Tooltip>
                    }
                >
                    <FontAwesomeIcon icon={faQuestionCircle} />
                </OverlayTrigger>}
                <InlineFormControl>
                    <Currency
                        controlId={`${controlId}-currency`}
                        readOnly={readOnly}
                        value={value.max_budget}
                        onChange={handleMaxBudgetChange}
                        errors={errors.max_budget}
                    />
                </InlineFormControl>
            </FormRow>
            {platform !== 'bespoke_product' && <>
            <FormRow>
                Start Date
                <DateController>
                    <Date
                        key={value.start_date}
                        controlId={`${controlId}-start_at`}
                        readOnly={readOnly}
                        value={value.start_date}
                        onChange={handleStartDateChange}
                        errors={errors.start_date}
                    />
                    <Button onClick={(e) => handleStartDateChange()} >
                        <FontAwesomeIcon icon={faTimes} />
                    </Button>
                </DateController>
            </FormRow>
            <FormRow>
                End Date
                <DateController>
                    <Date
                        key={value.end_date}
                        controlId={`${controlId}-stop_at`}
                        readOnly={readOnly}
                        value={value.end_date}
                        fromDate={value.start_date}
                        onChange={handleEndDateChange}
                        errors={errors.end_date}
                    />
                    <Button onClick={(e) => handleEndDateChange()}>
                        <FontAwesomeIcon icon={faTimes} />
                    </Button>
                </DateController>
            </FormRow>
            </>}
            <FormRow isBig>
                Notes
                <InlineFormControl>
                    <TextArea
                        controlId={`${controlId}-notes`}
                        value={value.notes}
                        onChange={handleNotesChange}
                        errors={errors.notes}
                    />
                </InlineFormControl>
            </FormRow>
            <FormRow>
                Discount
                <InlineFormControl>
                    <ChoiceAmount
                        controlId={`${controlId}-percentage-discount`}
                        description="Amount to discount off this package."
                        onChange={({ selection, amount }) => selection === FLAT ? handleDiscountPriceChange(amount) : handleDiscountPercChange(amount)}
                        options={[
                            {
                                id: FLAT,
                                value: FLAT,
                                label: 'Flat',
                                input: Currency,
                            },
                            {
                                id: PERC,
                                value: PERC,
                                label: 'Percentage',
                                input: Percentage,
                            },
                        ]}
                        defaultOption={value.percentage_discount === 0 ? FLAT : PERC}
                        value={value.percentage_discount === 0 ? value.flat_discount : value.percentage_discount}
                        errors={errors.percentage_discount || errors.flat_discount}
                    />
                </InlineFormControl>
            </FormRow>
        </CardContainer>
    </FormContainer>);
}

AdFormatCard.propTypes = {
    controlId: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    platform: PropTypes.string,
    value: PropTypes.shape({
        min_budget: PropTypes.number,
        start_date: PropTypes.string,
        end_date: PropTypes.string,
    }).isRequired,
    onChange: PropTypes.func,
    onMoveUp: PropTypes.func,
    onMoveDown: PropTypes.func,
    onDelete: PropTypes.func,
    errors: PropTypes.shape({
        min_budget: PropTypes.arrayOf(PropTypes.string),
        start_date: PropTypes.arrayOf(PropTypes.string),
        end_date: PropTypes.arrayOf(PropTypes.string),
    }),
}

export default AdFormatCard
