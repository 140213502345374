export function filterKeys(object, filterKeys) {
    return Object.fromEntries(Object.entries(object).filter(([key]) => filterKeys.includes(key)));
}

export function findDifferentFalseyKeys(objA, objB, trueValue = true) {
  const falseyKeysInA = Object.keys(objA).filter(key => objA[key] !== trueValue);
  const trueKeysInB = Object.keys(objB).filter(key => objB[key] === trueValue);

  return trueKeysInB.filter(key => falseyKeysInA.includes(key) || !Object.keys(objA).includes(key));
}

