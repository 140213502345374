import styled from 'styled-components';
import React from 'react';
import emptyPreview from './BrandStorePagePreviewEmptyState.svg';

const StyledIframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`;

const CenteredDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 33%;
`;

export const BrandStorePreviewIFrame = ({ brandStorePreview, previewPageId, previewPageContent }) => {
  const previewHtml = brandStorePreview[previewPageId]?.pageContent;
  const isEmptyPage = !previewPageContent || previewPageContent.length === 0;

  if (!previewHtml || isEmptyPage) {
    return (
        <CenteredDiv>
          <img src={emptyPreview} alt='Empty Preview' />
        </CenteredDiv>
    );
  }

  // Add global font styles to the preview iframe
  const css = '<style>body {font-family: Arial, sans-serif;}a {pointer-events: none;} </style>';
  const updatedPreviewHtml = css + previewHtml;

  return <StyledIframe srcDoc={updatedPreviewHtml} />;
};
