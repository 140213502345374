import moment from 'moment';
import React from 'react';
import { Form } from 'react-bootstrap';
import Datetime from "react-datetime";
import styled from 'styled-components';
import { ErrorFeedback, LabelText } from './Common';
import Errors from './Errors';

const DateTimeInput = styled(Datetime)`
  ${props => props.directionUp && `
    .rdtPicker {
      top: -253px;
    }
  `}
`

const Date = ({ controlId, required = false, readOnly, label, value, fromDate, onChange = () => { }, errors = [] }) => {
    const validDate = current => current.isAfter(fromDate ? moment(fromDate).subtract(1, 'day') : moment())
    const handleChange = val => {
        if (readOnly) {
            return;
        }
        onChange(val.format("YYYY-MM-DD"))
    }

    return <Form.Group controlId={controlId}>
        <LabelText>
            {label} {required && label && "*"}{" "}
        </LabelText>
        <DateTimeInput
            directionUp
            value={value ? moment.utc(value) : value}
            renderInput={(props, openCalendar, closeCalendar) => (
                <Form.Control
                    type="text"
                    onClick={openCalendar}
                    readOnly={readOnly}
                    value={props.value ? moment.utc(props.value).format("YYYY-MM-DD") : ''}
                    onChange={() => {}}
                />
            )}
            autoComplete={false}
            isValidDate={validDate}
            timeFormat={false}
            onChange={handleChange}
        />
        <ErrorFeedback type="invalid" isInvalid={errors.length > 0}>
            <Errors errors={errors} />
        </ErrorFeedback>
    </Form.Group>
}

export default Date
