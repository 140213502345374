import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faGoogle, faYoutube } from '@fortawesome/free-brands-svg-icons';

const StyledAudience = styled.div`
  width: 100%;
  margin: 10px;
  padding: 10px;
  border-radius: 8px;
  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
  span {
    font-size: 22px;
    font-size: 22px;
    color: #777;
  }
  p {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
  }
  ${(props) => {
    if (props.count > 50)
      return `background: #421cff;
                color: #fff;
                span {color: #fff;}`;
    if (props.count > 25)
      return `background: #7352ff;
                color: #fff;
                span {color: #fff;}`;
    if (props.count > 10)
      return `background: #987cff;
                color: #fff;
                span {color: #fff;}`;
    if (props.count > 5)
      return `background: #b9a5ff;
                color: #fff;
                span {color: #fff;}`;
    if (props.count > 0)
      return `background: #d8cdff;
            color: #000;
            span {color: #000;}`;
    return `background: #FAFAFA; color: #000;
            span {color: #000;}`;
  }}
`;

const StyledAudienceAttributes = styled.div`
  width: 100%;
  background: #fafafa;
  margin: 10px;
  padding: 10px;
  border-radius: 8px;
  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
  span {
    font-size: 11px;
  }
  p {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
  }
`;

const Audience = ({ title, category }) => (
  <>
    <StyledAudience count={Number.isInteger(title - 0) ? title : 0}>
      <div>
        <div>
          <span>{title}</span>
        </div>
        <p>{category}</p>
      </div>
    </StyledAudience>
  </>
);

const AudienceAttributes = ({ attributes, title }) => (
  <>
    <StyledAudienceAttributes>
      <div>
        <div>
          <span>{attributes}</span>
        </div>
        <p>{title}</p>
      </div>
    </StyledAudienceAttributes>
  </>
);

const InventoryAudienceDetails = ({ audience, errors = {} }) => {
  return (
    <div className='border rounded p-3'>
      <article>
        <div className='mb-2'>
          <h5>Audience Details</h5>
        </div>
        <div className='d-flex'>
          <Audience
            title={audience?.platforms?.map(function (p) {
              if (p === 'facebook') {
                return <FontAwesomeIcon className='fa-facebook' size='2x' icon={faFacebook} />;
              } else if (p === 'google') {
                return <FontAwesomeIcon className='fa-google' size='2x' icon={faGoogle} />;
              } else if (p === 'youtube') {
                return <FontAwesomeIcon className='fa-youtube' size='2x' icon={faYoutube} />;
              }
              return <div>{p}</div>;
            })}
            category={'Platforms'}
          />
          <Audience title={audience?.type?.label} category={'Type'} />
          <Audience title={audience?.size} category={'Estimated Size'} />
        </div>
        <div className='d-flex'>
          <AudienceAttributes
            attributes={audience?.attributes?.map((a) => (
              <span style={{ marginRight: '5px' }} class='badge badge-light'>
                {a}
              </span>
            ))}
            title={'Attributes'}
          />
        </div>
      </article>
    </div>
  );
};

InventoryAudienceDetails.propTypes = {
  audience: PropTypes.object,
  errors: PropTypes.object,
};

export default InventoryAudienceDetails;
