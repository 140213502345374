import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { Form, InputGroup, Popover, OverlayTrigger } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import Datetime from 'react-datetime';
import { selectSession } from '../../session/sessionSlice';
import Errors from '../../order_form/components/Errors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const TimeSelect = ({ controlId, label, placeholder, value, errors = [], onChange = () => {}, popoverText = null }) => {
  const session = useSelector(selectSession);

  return (
    <Form.Group controlId={controlId}>
      {label && (
        <Form.Label>
          {label}
          {popoverText && (
            <OverlayTrigger
              placement='right'
              trigger='click'
              overlay={
                <Popover>
                  <Popover.Content style={{ textAlign: 'justify' }}>{popoverText}</Popover.Content>
                </Popover>
              }
            >
              <a style={{ marginLeft: '5px' }}>
                <FontAwesomeIcon icon={faInfoCircle} />
              </a>
            </OverlayTrigger>
          )}
        </Form.Label>
      )}
      <InputGroup>
        <Datetime
          value={value}
          inputProps={{ id: controlId }}
          dateFormat={false}
          timeFormat={'hh:mm A'}
          autoComplete={false}
          timeCaption='time'
          timeConstraints={{
            hours: { min: 0, max: 23 },
            minutes: { min: 0, max: 59, step: 15 },
            seconds: { min: 0, max: 59 },
          }}
          onChange={(v) => (moment(v).isValid() ? onChange(controlId, moment(v).format('hh:mm A')) : '')}
          style={session?.theme?.components?.form_control}
        />
      </InputGroup>
      <Form.Control.Feedback type='invalid' className={errors.length && 'd-block'}>
        <Errors errors={errors} />
      </Form.Control.Feedback>
    </Form.Group>
  );
};

TimeSelect.propTypes = {
  controlId: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default TimeSelect;
