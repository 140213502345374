import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import styled from 'styled-components';

const CollapseContainer = styled.div`
  display: ${(props) => (props.isVisible ? 'block' : 'none')};
  background-color: ${(props) => props.bgColor};
  clear: both;
`;

const ToggleContainer = styled.div`
  background-color: ${(props) => props.bgColor};
  cursor: pointer;
  display: ${(props) => (props.isVisible ? 'inline' : 'none')};
`;

const HeadingAndLabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Heading = styled.span`
  font-size: 14px;
  font-weight: ${(props) => (props.bold ? 'bold' : '500')};
  color: #000000;
  float: left;
  clear: both; // added this to ensure the heading is on its own line
`;

const CollapseableTooltipBox = ({ label, heading, children, reducedSpacing = false, bgColor, isBoldHeading }) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  return (
    <div className={reducedSpacing ? '' : 'mb-4'}>
      <HeadingAndLabelContainer>
        <Heading bold={isBoldHeading}>{heading}</Heading>
        <ToggleContainer
          bgColor={bgColor}
          className='small float-right px-2 mr-2 rounded-bottom'
          onClick={handleOpen}
          isVisible={!open}
        >
          <span className='mr-2'>{label}</span>
          <FontAwesomeIcon icon={faArrowDown} size='xs' />
        </ToggleContainer>
      </HeadingAndLabelContainer>
      <CollapseContainer
        bgColor={bgColor}
        className={reducedSpacing ? 'rounded-bottom' : 'px-3 py-1 rounded-bottom'}
        isVisible={open}
      >
        {children}
        <ToggleContainer
          bgColor={bgColor}
          className='small float-right px-2 mr-2 rounded-bottom'
          onClick={handleClose}
          isVisible
        >
          <span className='mr-2'>{label}</span>
          <FontAwesomeIcon icon={faArrowUp} size='xs' />
        </ToggleContainer>
      </CollapseContainer>
    </div>
  );
};

CollapseableTooltipBox.defaultProps = {
  bgColor: '#f8f9fa',
  isBoldHeading: false,
};

export default CollapseableTooltipBox;
