import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Col, Form, Row, Spinner } from 'react-bootstrap';
import { getProducts } from '../../../../../../../lib/api';
import { ProductGridTile, ProductListTile } from './ProductTile';
import styles from './ProductFeedSelector.module.scss';
import { ToggleViewSwitch } from './ToggleViewSwitch';

export const ProductFeedSelector = ({
  productSets = [],
  value = [],
  onChange,
  controlId = 'product_feed_selector',
}) => {
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [selectedProductSetId, setSelectedProductSetId] = useState(
    value && value.length > 0 && value[0].product_set_id ? value[0].product_set_id : ''
  );
  const [filterTerm, setFilterTerm] = useState('');
  const [status, setStatus] = useState('idle');
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [isListView, setIsListView] = useState(false);

  useEffect(() => {
    if (selectedProductSetId) {
      fetchProducts(selectedProductSetId).then((products) => setAllProducts(products || []));
    } else {
      setFilteredProducts([]);
    }
  }, [selectedProductSetId]);

  //filter products on change of filter term or product set
  useEffect(() => {
    const filteredProducts = allProducts.filter(
      (product) =>
        product.name.toLowerCase().includes(filterTerm.toLowerCase()) ||
        product.id.toLowerCase().includes(filterTerm.toLowerCase())
    );
    setFilteredProducts(filteredProducts);
  }, [filterTerm, allProducts]);

  //handle selection state of 'select all' check when products of value changes
  useEffect(() => {
    if (filteredProducts.length === 0) {
      setSelectAllChecked(false);
    } else {
      const allSelected = filteredProducts.every((product) =>
        value.some((selectedProduct) => selectedProduct.id === product.id)
      );
      setSelectAllChecked(allSelected);
    }
  }, [filteredProducts, value]);

  const toggleView = () => setIsListView((prevState) => !prevState);

  const handleProductSetChange = (e) => {
    const id = e.target.value;
    setSelectedProductSetId(id);
  };

  const fetchProducts = async (id) => {
    setStatus('loading');
    try {
      const resp = await getProducts(id, undefined, undefined, 1000);
      setStatus('succeeded');
      return resp.data.data;
    } catch (error) {
      setStatus('failed');
    }
  };

  const handleSelectAllChange = (event) => {
    const newValue = event.target.checked;
    setSelectAllChecked(newValue);
    if (newValue) {
      const newlySelectedProducts = filteredProducts.filter(
        (product) => !value.some((p) => p.id === product.id) // check product isn't already selected
      );
      onChange([...value, ...newlySelectedProducts]);
    } else {
      const remainingValues = value.filter((v) => !filteredProducts.some((product) => product.id === v.id));
      onChange(remainingValues); // if 'select all' is unchecked, clear only current product set selections
    }
  };

  const handleProductClick = (product) => {
    // Check if product is already selected
    const productExists = value.find((p) => p.id === product.id);

    if (productExists) {
      // If exists, filter it out
      const newValue = value.filter((p) => p.id !== product.id);
      onChange(newValue);
    } else {
      // If not, add it
      const newValue = [...value, product];
      onChange(newValue);
    }
  };

  const ProductsContent = () => {
    let textContent, productContent;

    switch (status) {
      case 'loading':
        textContent = (
          <Spinner animation='border' role='status'>
            <span className='sr-only'>Loading...</span>
          </Spinner>
        );
        break;
      case 'failed':
        textContent = 'Error loading products';
        break;
      case 'idle':
      case 'succeeded':
        if (filteredProducts.length === 0) {
          textContent = 'No products available';
        } else {
          productContent = (
            <>
              <Row className={styles.results_info_section}>
                <Col>
                  <div className='d-flex justify-content-between align-items-center'>
                    <div>
                      <Form.Check
                        type='checkbox'
                        label='Select all'
                        checked={selectAllChecked}
                        onChange={handleSelectAllChange}
                        className={styles.select_all_margin_left}
                      />
                    </div>
                    <div className='d-flex align-items-center'>
                      <div className={styles.results_text}>{filteredProducts.length} Results</div>
                      <ToggleViewSwitch isListView={isListView} toggleView={toggleView} />
                    </div>
                  </div>
                </Col>
              </Row>
              <div className={`${isListView ? styles.products_selector_list : styles.products_selector_grid}`}>
                {filteredProducts.map((product, index) =>
                  isListView ? (
                    <ProductListTile
                      key={product.id}
                      checked={value.some((p) => p.id === product.id)}
                      product={product}
                      onClick={handleProductClick}
                      index={index}
                    />
                  ) : (
                    <ProductGridTile
                      key={product.id}
                      checked={value.some((p) => p.id === product.id)}
                      product={product}
                      onClick={handleProductClick}
                    />
                  )
                )}
              </div>
            </>
          );
        }
        break;
      default:
        textContent = null;
    }

    return { textContent, productContent };
  };

  // Prepare content
  const { textContent, productContent } = ProductsContent();

  return (
    <Form.Group controlId={controlId} style={{ marginBottom: 0 }}>
      <Row className='mb-2'>
        <Col md='6'>
          <Form.Label className={styles.product_selector_label}>Product Catalog</Form.Label>
          <Form.Control
            as='select'
            className={styles.product_selector_filter}
            value={selectedProductSetId}
            onChange={handleProductSetChange}
          >
            <option value=''>None</option>
            {productSets.length > 0 && productSets.map((set) => (
              <option value={set.id} key={set.id}>
                {set.name}
              </option>
            ))}
          </Form.Control>
        </Col>
        <Col md='6'>
          <Form.Label className={styles.product_selector_label}>Filter by Product Name</Form.Label>
          <Form.Control
            type='text'
            placeholder={'Enter Product Name'}
            onChange={(e) => setFilterTerm(e.target.value)}
            value={filterTerm}
            className={styles.product_selector_filter}
          />
        </Col>
      </Row>
      {textContent && (
        <div className='text-center' style={{ lineHeight: '10rem' }}>
          {textContent}
        </div>
      )}
      {productContent}
    </Form.Group>
  );
};

ProductFeedSelector.propTypes = {
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  manualInput: PropTypes.bool,
  value: PropTypes.arrayOf(PropTypes.object),
  errors: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};
