import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const RadioContainer = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  color: black;
`;

const RadioInput = styled.input`
  margin-right: 12px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const LabelText = styled.span`
  font-weight: bold;
`;

const DescriptionText = styled.span`
  font-weight: normal;
`;

export const RadioOption = ({ name, value, checked, onChange, label, description, readOnly }) => {
  const handleChange = (e) => {
    if (!readOnly) {
      onChange(e.target.value);
    }
  };

  return (
    <RadioContainer>
      <RadioInput
        type='radio'
        name={name}
        value={value}
        checked={checked}
        onChange={handleChange}
        disabled={readOnly}
      />
      <TextContainer onClick={handleChange}>
        <LabelText>{label}</LabelText>
        <DescriptionText>{description}</DescriptionText>
      </TextContainer>
    </RadioContainer>
  );
};

RadioOption.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
};
