import React from 'react';
import styled from 'styled-components';
import { BootstrapDataTable } from '../../../common/data/BootstrapDataTable';

const Container = styled.div`
  && #screens-table {
    border: none;
    margin-bottom: unset;
    background-color: #80808010;

    td {
      font-weight: 400;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    td:not(:last-child) {
      border-bottom: none;
    }
  }
`;

const screensTableColumns = [
  {
    dataField: 'select',
    headerAttrs: { hidden: true },
    headerStyle: { width: '56px', textAlign: 'center' },
    style: { width: '56px', textAlign: 'center' },
    formatter: (cell, row) => {
      return '';
    },
  },
  {
    dataField: 'address',
    headerAttrs: { hidden: true },
    style: { paddingLeft: '38px' },
    formatter: (cell, row) => {
      return row.address;
    },
  },
  {
    dataField: 'name',
    headerAttrs: { hidden: true },
    style: { width: '340px', maxWidth: '340px' },
    formatter: (cell, row) => {
      return row.name;
    },
  },
  {
    dataField: 'orientation',
    headerAttrs: { hidden: true },
    style: { width: '160px' },
    formatter: (cell, row) => {
      return row.orientation;
    },
  },
  {
    dataField: 'price',
    headerAttrs: { hidden: true },
    style: { width: '160px' },
    formatter: (cell, row) => {
      return '';
    },
  },
  {
    dataField: 'availability',
    headerAttrs: { hidden: true },
    style: { width: '120px' },
    formatter: (cell, row) => {
      return '';
    },
  },
];

export const ScreensTable = ({ row }) => {
  return (
    <Container>
      <BootstrapDataTable id='screens-table' keyField='id' data={row.screens || []} columns={screensTableColumns} paginationEnable={false} />
    </Container>
  );
};
