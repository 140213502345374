import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const LabelHeader = styled.h6`
    margin-top: 0.5rem;
    font-weight: bold;
`

const Spinner = ({ label='', ...props }) =>
    <div {...props}>
        <FontAwesomeIcon
            className="fa-spin"
            style={{ cursor: "pointer" }}
            icon={faSpinner}
            size="4x"
        />
        <LabelHeader>{label}</LabelHeader>
    </div>

Spinner.propTypes = {
    hidden: PropTypes.bool,
}

export default Spinner
