import { connect } from 'react-redux'
import Name from '../components/Name'
import { selectOwnedSpace, selectOwnedSpaceErrors, updateName } from '../ownedSpaceFormSlice'

const mapStateToProps = state => ({
    value: selectOwnedSpace(state).name,
    errors: selectOwnedSpaceErrors(state).name
})


const mapDispatchToProps = dispatch => ({
    onChange: value => dispatch(updateName(value)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Name)
