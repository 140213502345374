import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-bootstrap';
import $style from './audience.module.scss';

const GoogleRemarketingAudiences = ({
    controlId,
    value = [],
    readOnly = false,
    disabled = false,
    audiences = [],
    onChange = () => {},
}) => {
    const handleChange = (audience, checked) => {
        if (checked) {
            onChange(audience, true)
        } else {
            onChange(audience, false)
        }
    }
    return (
        <Form.Group controlId={controlId}>
            <Form.Label className="font-weight-bold">Remarketing Audience</Form.Label>
            <div>
                {audiences.length > 0 ? (
                    audiences.map(audience => (
                        <div key={audience.id} className={audience?.deleted ? $style.deleteItem: ''}>
                            <Form.Check
                                inline
                                id={`${controlId}-google-remarketing-audience-${audience.id}`}
                                className="mr-2"
                                label={audience.name}
                                disabled={disabled || audience.deleted}
                                readOnly={readOnly || audience.deleted} 
                                checked={!!value.find(e => e.id === audience.id)}
                                onChange={e => handleChange(audience, e.target.checked)}
                            />
                        </div>
                    ))
                ) : (
                    <p>There are no remarketing audiences available.</p>
                )}
            </div>
        </Form.Group>
    )
}

GoogleRemarketingAudiences.propTypes = {
    controlId: PropTypes.string.isRequired,
    value: PropTypes.object,
    audience: PropTypes.array,
    onChange: PropTypes.func,
    readOnly: PropTypes.bool,
    disabled: PropTypes.bool,
}

export default GoogleRemarketingAudiences
