import React from 'react';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import styled from 'styled-components';

const CheckContainer = styled.div`
  border: 1px solid #d4ccff;
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  margin-bottom: 1.25rem;
`;

const DescriptionContainer = styled.p`
  font-size: 12px;
`;

const InvalidInstagramPlacementFormLabel = styled(Form.Label)`
  border-bottom: 1px dotted red;
`;

const placementLabelFactory = (placement, instagramEnabled, isOwnerOrganisation) => {
  if (placement.network === 'Instagram' && !instagramEnabled && isOwnerOrganisation) {
    return (
      <OverlayTrigger
        placement='top'
        overlay={
          <Tooltip id={`tooltip-${placement.id}`}>
            You cannot have an instagram placement without an instagram page ID. Please contact support.
          </Tooltip>
        }
      >
        <InvalidInstagramPlacementFormLabel>{placement.name}</InvalidInstagramPlacementFormLabel>
      </OverlayTrigger>
    );
  }

  return <Form.Label>{placement.name}</Form.Label>;
};

const Placements = ({
  controlId,
  readOnly,
  instagramEnabled = false,
  isOwnerOrganisation = false,
  availablePlacements = [],
  placements = [],
  automatic = undefined,
  value = undefined,
  onChange = () => {},
}) => {
  const handleSinglePlacementsChange = (placement, checked) => {
    let nval = value.filter((placementID) => placementID !== placement.id);

    if (checked) {
      nval.push(placement.id);
    }

    onChange({ automatic, placements: nval });
  };

  const availablePlacementsIDs = availablePlacements.map((placement) => placement.id);

  const handleAutomaticChange = (automatic) => onChange({ automatic, placements: value });
  const handlePlacementsChange = (placements) => onChange({ automatic, placements: placements });

  if (!readOnly) {
    if (value === undefined) {
      handlePlacementsChange(availablePlacementsIDs);
    }
  }
 
  return (
    <div>
      <Form.Group controlId={`${controlId}.facebook_placement_select`}>
        <Form.Label className='font-weight-bold'>Selection</Form.Label>
        <CheckContainer>
          <Form.Check
            type='radio'
            name={`${controlId}.facebook_placement_select`}
            // id={opt}
            label='Automatic'
            disabled={readOnly}
            checked={automatic}
            onChange={(e) => {
              if (!readOnly) {
                handleAutomaticChange(true);
              }
            }}
            required
          />
          <DescriptionContainer>
            Use automatic placements to maximise your budget and help show your ads to more people. Facebook's delivery
            system will allocate your ad set's budget across multiple placements based on where they're likely to
            perform best.
          </DescriptionContainer>
        </CheckContainer>
        <CheckContainer>
          <Form.Check
            type='radio'
            name={`${controlId}.facebook_placement_select`}
            // id={opt}
            label='Manual'
            disabled={readOnly}
            checked={!automatic }
            onChange={(e) => {
              if (!readOnly) {
                handleAutomaticChange(false);
              }
            }}
            required
          />
          <DescriptionContainer>
            Manually choose the places to show your ad. The more placements you select, the more opportunities you'll
            have to reach your target audience and achieve your business goals.
          </DescriptionContainer>

          {placements.map((placement) => (
            <Form.Check id={placement.id} key={placement.id}>
              <Form.Check.Input
                checked={
                  availablePlacementsIDs.includes(placement.id) &&
                  (value === undefined || value.some((placementID) => placementID === placement.id))
                }
                onChange={(e) => handleSinglePlacementsChange(placement, e.target.checked)}
                disabled={
                  !availablePlacementsIDs.includes(placement.id) ||
                  automatic ||
                  readOnly ||
                  (value?.length === 1 && value[0] === placement.id)
                }
                readOnly={readOnly}
              />
              {placementLabelFactory(placement, instagramEnabled, isOwnerOrganisation)}
            </Form.Check>
          ))}
        </CheckContainer>
      </Form.Group>
    </div>
  );
};

export default Placements;
