import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

const ActionContainer = styled.div`
  background: #f7f5ff;
  padding: 16px;
  .recommended {
    float: right;
    background: #ffe787;
    border-radius: 4px;
    font-size: 10px;
    padding: 4px 8px;
  }
`;

const Action = ({ title, link, description, recommended = false }) => (
  <ActionContainer className='mb-3'>
    <div>
      <span className='link'>
        <Link to={link}>
          {title} <FontAwesomeIcon icon={faChevronRight} />
        </Link>
      </span>
      {recommended && <span className='recommended'>Recommended</span>}
    </div>
    <p className='mb-0'>{description}</p>
  </ActionContainer>
);

const InventoryAudienceActions = ({ audience, errors = {} }) => {
  return (
    <div className='border rounded p-3'>
      <article>
        <div className='mb-2'>
          <h5>Actions</h5>
        </div>
        <div>
          <div>
            <Action
              title={'Edit Attributes'}
              link={`javascript:void(0)`}
              description={'Add or remove targeting attributes for this audience.'}
            />
            <Action
              title={'Promote Audience'}
              link={`javascript:void(0)`}
              description={'Advertise this audience to increase visibility and drive higher utilisation.'}
            />
          </div>
        </div>
      </article>
    </div>
  );
};

InventoryAudienceActions.propTypes = {
  audience: PropTypes.object,
  errors: PropTypes.object,
};

export default InventoryAudienceActions;
