import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { SkuMetricsTable } from './SkuMetricsTable';
import styled from 'styled-components';
import { SkuOrderDetails } from './SkuOrderDetails';
import { FilterHeadings } from './FilterHeadings';
import { useSelector } from 'react-redux';
import { selectOrder, selectOwnerOrganisation } from '../../orderFormSlice';
import AdsThrobber from '../../../common/throbbers/AdsThrobber';
import moment from 'moment';

const StyledModalBody = styled(Modal.Body)`
  max-height: 500px; // todo - adjust size
  overflow-y: auto;
`;

export const SkuMetricsModal = ({ isOpen, handleClose, skuMetrics, isLoading, error }) => {
  const [displayedMetrics, setDisplayedMetrics] = useState([]);
  const order = useSelector(selectOrder);
  const owner = useSelector(selectOwnerOrganisation)
  const currency = owner?.settings?.['defaultCurrency'] ?? 'AUD';

  const handleTabChange = (tabName) => {
    if (tabName === 'all') {
      setDisplayedMetrics(skuMetrics['promoted_products'].concat(skuMetrics['halo_products']));
    } else {
      setDisplayedMetrics(skuMetrics[tabName]);
    }
  };

  useEffect(() => {
    if (skuMetrics) {
      setDisplayedMetrics(skuMetrics['promoted_products'] ?? []);
    }
  }, [skuMetrics]);

  return (
    <Modal size='xl' show={isOpen} onHide={handleClose} className='xxl_modal' centered>
      <Modal.Header closeButton>
        <Modal.Title>Product Metrics</Modal.Title>
      </Modal.Header>
      <StyledModalBody>
        {isLoading ? (
          <div className='text-center d-flex align-items-center justify-content-center' style={{ minHeight: '45vh' }}>
            <AdsThrobber />
          </div>
        ) : error ? (
          <div>Error: {error.message}</div>
        ) : (
          <>
            <SkuOrderDetails
              name={order?.name}
              orderNumber={order?.id}
              dates={`${moment(order?.start_at).format('DD/MM/YYYY')} to
              ${moment(order?.stop_at).format('DD/MM/YYYY')}`}
            />
            <FilterHeadings onHeadingClick={handleTabChange} />
            <SkuMetricsTable skuMetrics={displayedMetrics} currency={currency} />
          </>
        )}
      </StyledModalBody>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

SkuMetricsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  skuMetrics: PropTypes.array,
};
