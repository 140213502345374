
import React  from "react";
import {Pagination} from "react-bootstrap";

const ChannelPagination = (
    itemsPerPage,
    totalRecords,
    activePageNumber,
    setActive,
) => {

    let allItems = [];
    let addbetween = false;
   
    if (totalRecords && totalRecords > 0) {
        let count = Math.ceil(totalRecords / itemsPerPage);

        for (let number = 1; number <= count; number++) {

            if (number <= 3 || number >= count - 2) {
                allItems.push(
                    <Pagination.Item key={number} active={number === activePageNumber} onClick={() => {
                        setActive(number)
                    }}>
                        {number}
                    </Pagination.Item>,
                );
            } else if (!addbetween) {
                addbetween = true;
                let opts = [];
                for (let o = 1; o <= count; o++) {
                    opts.push(<option key={o} value={o}>Page: {o}</option>)
                }
                allItems.push(
                    <select
                        className="custom-select mb-2 w-auto"
                        id="inlineFormCustomSelectPref"
                        onChange={(e) => setActive(e.target.value)}
                        value={activePageNumber}
                    >
                        {opts}
                    </select>
                );
            }
        }
    } else {
        allItems.push(
            <Pagination.Item key={1} active={true}>
                1
            </Pagination.Item>,
        );
    }

    const firstItem = (activePageNumber - 1) * itemsPerPage + 1
    const lastItem = Math.min(activePageNumber * itemsPerPage, totalRecords)
    return {allItems, firstItem, lastItem};
}

export default ChannelPagination;
 