import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import Chrome from '../../app/layout/Chrome';
import ConnectedBrandStoreBuilder from './state_management/ConnectedBrandStoreBuilder';
import React,{ useEffect } from 'react';
import { clearBrandStore } from './state_management/BrandStoreSlice';
import { useDispatch } from 'react-redux';

export const EditBrandStore = ({ match }) => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearBrandStore());
  }, [])

  return (
    <>
      <Helmet>
        <title>Create Brand Store - {process.env.REACT_APP_NAME}</title>
      </Helmet>

      <Chrome>
        <Container fluid className='pt-4'>
          <Row>
            <Col>
              <ConnectedBrandStoreBuilder />
            </Col>
          </Row>
        </Container>
      </Chrome>
    </>
  );
};
