import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Errors from '../Errors';
import styles from './asset.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { assetsUpdated } from '../../orderFormSlice';
import { Button, Form, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import FileDropzone from '../FileDropzone';
import { selectSession } from '../../../session/sessionSlice';

const AssetChanges = ({
  assetValues,
  readOnly = false,
  accept = [],
  onChange = () => {},
  fields,
  hideButton,
  propertyForOnChange,
}) => {
  const [assetModal, setAssetModal] = useState(false);
  const dispatch = useDispatch();
  const [files, setFiles] = useState(assetValues);

  return (
    <div>
      {!hideButton && (
        <Button
          disabled={readOnly}
          variant='outline-secondary'
          className='w-100'
          style={{ marginTop: '5px' }}
          onClick={() => setAssetModal(true)}
        >
          <div>Upload Media</div>
        </Button>
      )}

      <Modal
        show={assetModal}
        onHide={() => {
          setFiles(assetValues);
          setAssetModal(false);
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Select media to add to the order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FileDropzone
            max={1}
            maxDuration={fields?.max_video_duration}
            onChange={(fs) => setFiles(fs)}
            onDelete={() => {
              setFiles([]);
            }}
            readOnly={readOnly}
            accept={accept}
            value={files}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='primary'
            disabled={files.length === 0}
            onClick={() => {
              if (files && files.length > 0) {
                let filePath = files.map((file) => file[propertyForOnChange]);
                onChange(filePath[0]);

                let assets = [...assetValues];

                files.forEach((file) => {
                  if (!assets.some((asset) => asset.id === file.id)) {
                    assets.push(file);
                  }
                });

                dispatch(assetsUpdated(assets));
              }

              setAssetModal(false);
            }}
          >
            Insert
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const ImgMedia = ({ src, alt }) => (
  <img key={src} className={`w-100 rounded ${styles.media_square}`} alt={alt} src={src} />
);

const VideoMedia = ({ src }) => (
  <>
    <video key={src} controls={true} width='100%' className={`w-100 h-100 rounded ${styles.media_square}`}>
      <source src={src} />
    </video>
  </>
);

const EditableAsset = ({
  controlId,
  readOnly = false,
  required = false,
  value = null,
  files = [],
  onChange = () => {},
  errors = [],
  header,
  field,
  notice,
  info,
  limitPreviewHeight = false,
  hideButtonOnReadOnly = false,
  emptyStatePlaceholder,
  forceHideButton = false,
  propertyForOnChange = 'url',
}) => {
  const session = useSelector(selectSession);

  return (
    <Form.Group controlId={controlId}>
      <Form.Label className='d-flex align-self-center align-items-center font-weight-bold'>
        <span>
          {header} {required && '*'}{' '}
          {info && (
            <OverlayTrigger placement='left' overlay={<Tooltip id={`${controlId}-info-popover`}>{info}</Tooltip>}>
              <FontAwesomeIcon icon={faQuestionCircle} size='sm' />
            </OverlayTrigger>
          )}
        </span>
      </Form.Label>
      {notice && <Form.Control.Feedback className='d-block text-body mb-2'>{notice}</Form.Control.Feedback>}
      <Form.Control type='hidden' />
      <div>
        {!value || value === '' ? (
          <div className='text-center' style={{ lineHeight: '4rem' }}>
            {emptyStatePlaceholder ? emptyStatePlaceholder : 'No ' + field.type + ' assets uploaded.'}
          </div>
        ) : (
          <div>
            {field?.constraints?.max_video_duration && (
              <p>Max video duration (in seconds): {field.constraints.max_video_duration} </p>
            )}
            <div
              className='d-flex flex-wrap'
              style={limitPreviewHeight ? { maxHeight: '200px', overflow: 'hidden' } : {}}
            >
              {field.type === 'image' && <ImgMedia src={value} alt={field.label} />}
              {field.type === 'video' && <VideoMedia src={value} />}
            </div>
          </div>
        )}
        <AssetChanges
          assetValues={files}
          onChange={onChange}
          readOnly={readOnly}
          fields={field}
          accept={
            field.type === 'image'
              ? session.file_formats.images
              : field.type === 'video'
              ? session.file_formats.videos
              : field.type === 'document'
              ? session.file_formats.documents
              : []
          }
          hideButton={forceHideButton || (hideButtonOnReadOnly && readOnly)}
          propertyForOnChange={propertyForOnChange}
        />
      </div>

      <Form.Control.Feedback type='invalid' className={`${errors.length > 0 ? 'd-block' : ''}`}>
        <Errors errors={errors} />
      </Form.Control.Feedback>
    </Form.Group>
  );
};

EditableAsset.propTypes = {
  controlId: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  errors: PropTypes.arrayOf(PropTypes.string),
  header: PropTypes.string,
  value: PropTypes.string,
  files: PropTypes.array,
  max: PropTypes.number,
  field: PropTypes.shape({
    type: PropTypes.oneOf(['image', 'video', 'mixed']).isRequired,
  }).isRequired,
  maxVideoDuration: PropTypes.number,
  notice: PropTypes.string,
};

export default EditableAsset;
