import moment from 'moment';
import 'moment-business-days';
import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-bootstrap';

const DateRangeDescription = ({ start_datetime, stop_datetime }) => {
  if (!start_datetime && !stop_datetime) {
    return (
      <div className='d-flex align-items-center'>
        Dates: <span className='text-primary ml-1'>No ad dates have been selected yet.</span>
      </div>
    );
  }

  const startDate = moment.utc(start_datetime);
  const stopDate = moment.utc(stop_datetime);

  return (
    <div className='d-flex align-items-center'>
      Dates:
      <Form.Group className='mb-0 mx-2'>
        <span className='text-primary cursor-pointer'>
          {start_datetime ? (
            <>
              {startDate.format('YYYY-MM-DD')} <span className='small'>{startDate.format('hh:mm A')}</span>
            </>
          ) : (
            '–'
          )}
        </span>
      </Form.Group>
      to
      <Form.Group className='mb-0 mx-2'>
        <span className='text-primary cursor-pointer'>
          {stop_datetime ? (
            <>
              {stopDate.format('YYYY-MM-DD')} <span className='small'>{stopDate.format('hh:mm A')}</span>
            </>
          ) : (
            '–'
          )}
        </span>
      </Form.Group>
    </div>
  );
};

DateRangeDescription.propTypes = {
  start_datetime: PropTypes.string,
  stop_datetime: PropTypes.string,
};

export default DateRangeDescription;
