import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { Button, Col, Container, Row } from 'react-bootstrap';

import { ScreensAvailabilityModal } from './ScreensAvailabilityModal';

const Summary = styled.div`
  padding-bottom: 24px;
  font-size: 18px;
  font-weight: 300;
`;

const Label = styled.span`
  font-weight: 600;
`;

export const BroadsignScheduleTab = ({ ad, errors, handleChange, order, controlId, readOnly }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const haveWeeksBeenSelected = (ad.schedule_weeks_selected || []).length > 0;

  const { startDateString, endDateString } = useMemo(() => {
    if ((ad.schedule_weeks_selected || []).length > 0) {
      const startDate = new moment(ad.schedule_weeks_selected[0].start_at);
      const endDate = new moment(ad.schedule_weeks_selected.at(-1).stop_at);
      return { startDateString: startDate.format('D MMMM, YYYY'), endDateString: endDate.format('D MMMM, YYYY') };
    }
    return { startDateString: '', endDateString: '' };
  }, [ad.schedule_weeks_selected]);

  return (
    <Container>
      <Row>
        <Col>
          {haveWeeksBeenSelected && (
            <Summary>
              <Label>Selected schedule: </Label>
              <span>
                {startDateString} - {endDateString}
              </span>
            </Summary>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          {!readOnly && (
            <Button onClick={handleOpenModal} type='button' variant='light' className='btn-outline-secondary'>
              {haveWeeksBeenSelected ? 'Edit Schedule' : 'View Availability'}
            </Button>
          )}
        </Col>
      </Row>
      <ScreensAvailabilityModal
        ad={ad}
        isOpen={isModalOpen}
        onChange={handleChange}
        onClose={handleCloseModal}
        ownerId={order.owner_id}
      />
    </Container>
  );
};

BroadsignScheduleTab.propTypes = {
  ad: PropTypes.object.isRequired,
  errors: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
  controlId: PropTypes.string.isRequired,
};
