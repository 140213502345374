import axios from '../../axios';

export async function getBroadsignScreenAvailability(ownerId, startDate, endDate, orientation) {
  return axios.post(
    `/broadsign/screen-availability`,
    {
      owner_id: ownerId,
      start_date: startDate,
      end_date: endDate,
      orientation: orientation,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
}

export async function getBroadsignSettings() {
  return axios.get(`/broadsign/settings`);
};

export async function saveBroadsignSettings(fields) {
  return axios.post(`/broadsign/settings`, fields);
};

export async function getBroadsignRateRulesSettings() {
  return axios.get(`/broadsign/rate-rules`);
};

export async function deleteInStoreRateRulesSettings(id) {
  return axios.delete(`/broadsign/rate-rules/${id}`);
};
