import { Modal, Button } from 'react-bootstrap';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const VetoModal = ({
  show,
  handleClose,
  confirmAction,
  heading,
  messageParagraphs,
  confirmMessage,
  cancelMessage,
}) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title style={{ fontSize: '16px', fontWeight: 'bold' }}>
          <FontAwesomeIcon icon={faCircleExclamation} className='mr-2' />
          {heading ? heading : 'Confirm action'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ justifyContent: 'flex-start', fontSize: '14px' }}>
        {messageParagraphs && messageParagraphs.length > 0
          ? messageParagraphs.map((paragraph, index) => (
              <p key={index} style={{ fontWeight: paragraph.bold ? 'bold' : 'normal' }}>
                {paragraph.text}
              </p>
            ))
          : 'Are you sure you want to continue?'}
        <Button variant='danger' className={'mr-2'} onClick={confirmAction}>
          {confirmMessage || 'Yes'}
        </Button>
        <Button variant='secondary' onClick={handleClose}>
          {cancelMessage || 'No'}
        </Button>
      </Modal.Body>
    </Modal>
  );
};
