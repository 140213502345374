import { uniqBy } from 'lodash'
import { connect } from 'react-redux'
import PackagePane from '../../components/AdSelectionModal/PackagePane'
import { selectAvailablePackages, selectOwnerOrganisation } from '../../orderFormSlice'

const mapStateToProps = state => ({
    owner: selectOwnerOrganisation(state),
    packages: selectAvailablePackages(state),
    groups: uniqBy(
        selectAvailablePackages(state).map(pkg => ({
            id: pkg.package_group?.id ?? 0,
            label: pkg.package_group?.name ?? 'No Group',
        })),
        group => group.id,
    ),

})


const mapDispatchToProps = dispatch => ({
})


export default connect(mapStateToProps, mapDispatchToProps)(PackagePane)
