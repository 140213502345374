import React from 'react';
import { Form, Image } from 'react-bootstrap';
import PropTypes from 'prop-types';

const SelectedBrand = ({ formData }) => {
  return (
    <Form.Group className='mb-3' controlId='supplier'>
      <Form.Label className='font-weight-bold mb-1'>Selected Brand</Form.Label>
      <div className='d-flex'>
        <Image
          src={formData.supplier_logo}
          alt={`${formData.supplier_name} logo`}
          style={{ height: '25px' }}
          className='mt-2'
        />

        <p className='mt-2 ml-3'>{formData.supplier_name}</p>
      </div>
    </Form.Group>
  );
};

SelectedBrand.propTypes = {
  formData: PropTypes.shape({
    supplier_logo: PropTypes.string,
    supplier_name: PropTypes.string,
  }).isRequired,
};

export default SelectedBrand;
