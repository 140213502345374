import { connect } from 'react-redux';
import { selectUser, selectUserOrganisation } from '../../session/sessionSlice';
import { selectBrandStoreOrder, selectErrors, selectRequestErrors } from './BrandStoreSlice';
import { BrandStoreBuilder } from '../BrandStoreBuilder';

const mapStateToProps = (state) => {
  return {
    shownOrganisation: selectUserOrganisation(state),
    brandStore: selectBrandStoreOrder(state),
    user: selectUser(state),
    showOrderStatus: true,
    error: selectRequestErrors(state)?.error,
    formErrors: selectErrors(state),
  };
};

export default connect(mapStateToProps)(BrandStoreBuilder);
