import React, {useState} from "react";
import {Button, Modal} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {videoErrors} from "../../../../../lib/asset_selection";
import {selectSession} from "../../../../session/sessionSlice";
import {assetsUpdated} from "../../../orderFormSlice";
import FileDropzone from "../../FileDropzone";
import {postAsset, postFacebookAsset} from "../../../../../lib/api";
import {PLATFORM_FACEBOOK} from "../../../../../lib/platforms";


const AssetModal = ({
  fieldNames,
  value,
  onChange,
  fieldStructure,
  errors = [],
  ad,
  onHide = () => {},
  show = false,
  readOnly = false,
}) => {
  const dispatch = useDispatch();
  const [files, setFiles] = useState(value);
  const [isUploading, setIsUploading] = useState(false);

  const handleHide = () => {
    setFiles(value)
    onHide();
  }

  const handleInsert = () => {
    if (isUploading) {
      return;
    }

    onChange({
      [fieldNames[0]]: files.map((file) => file.id),
    });

    let assets = [...value];

    files.forEach((file) => {
      if (!assets.some((asset) => asset.id === file.id)) {
        assets.push(file);
      }
    });

    dispatch(assetsUpdated(assets));

    onHide();
  }

  let formats = [];

  const videoFormats = useSelector(state => selectSession(state).file_formats.videos);
  const imageFormats = useSelector(state => selectSession(state).file_formats.images);

  if (fieldStructure.max_images > 0) {
    formats = [...formats, ...imageFormats];
  }
  if (fieldStructure.max_videos > 0) {
    formats = [...formats, ...videoFormats];
  }

  return (
    <div>
      <Modal
        show={show}
        onHide={handleHide}
      >
        <Modal.Header closeButton>
          <Modal.Title>Select media to add to the order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FileDropzone
            ad={ad}
            postRequest={ad.platform === PLATFORM_FACEBOOK ? postFacebookAsset : postAsset}
            max={fieldStructure.max_attachments}
            maxDuration={fieldStructure.max_video_duration}
            value={files}
            onChange={(fs) => setFiles(fs)}
            onDelete={(file) => setFiles(files.filter((f) => f.id !== file.id))}
            accept={formats}
            onUploadStart={(_) => setIsUploading(true)}
            onUploadEnd={(_) => setIsUploading(false)}
            videoErrors={(file) => videoErrors(fieldStructure, file)} 
            readOnly={readOnly}  
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            disabled={isUploading}
            onClick={handleInsert}
          >
            Insert
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AssetModal
