import { Col, Form, Row } from 'react-bootstrap';
import styles from './bespokeFields.module.scss';
import PropTypes from 'prop-types';

const YesNoField = ({ field, index, updateLayoutData, layoutData, idHelper, errors }) => {
  return (
    <Row>
      <Col md={6}>
        <Form.Group className={styles.group_style} controlId={field.type + index + '-Name'}>
          <Form.Label>Name</Form.Label>
          <Form.Control
            type='text'
            value={layoutData[index]?.title ?? ''}
            onChange={(e) => updateLayoutData({ id: idHelper(e.target.value), title: e.target.value }, index)}
            isInvalid={!layoutData[index]?.title && errors.length > 0}
          />
        </Form.Group>
        <Form.Group className={styles.group_style} controlId={field.type + index + '-Info'}>
          <Form.Label>Description</Form.Label>
          <Form.Control
            type='text'
            value={layoutData[index]?.notice ?? ''}
            onChange={(e) => updateLayoutData({ notice: e.target.value }, index)}
          />
        </Form.Group>
      </Col>
      <Col md={6}>
        <Form.Group className={styles.group_style} controlId={field.type + index + '-Default'}>
          <Form.Label>Default</Form.Label>
          <Form.Check
            className={styles.form_check}
            type='radio'
            label='Yes'
            name={field.type + index + '-Default'}
            checked={layoutData[index]?.default === 'yes'}
            onChange={(e) => updateLayoutData({ default: 'yes' }, index)}
          />
          <Form.Check
            className={styles.form_check}
            type='radio'
            label='No'
            name={field.type + index + '-Default'}
            checked={layoutData[index]?.default === 'no'}
            onChange={(e) => updateLayoutData({ default: 'no' }, index)}
          />
        </Form.Group>
      </Col>
    </Row>
  );
};

YesNoField.propTypes = {
  field: PropTypes.object,
  index: PropTypes.number,
  updateLayoutData: PropTypes.func,
  layoutData: PropTypes.func,
  idHelper: PropTypes.func,
  errors: PropTypes.array,
};

export default YesNoField;
