import React, { memo } from 'react';
import { Subheader, SubheaderWrapper, Subsection } from './AdGrid';
import { LoadingCompleteBadge } from './Spinner/LoadingCompleteBadge';

const PreviewSection = memo(
  ({ children, header, maximisePreviewSpace = false, noScroll = false, loaderState = 'none' }) => {
    return (
      <Subsection noScroll={noScroll}>
        <SubheaderWrapper>
          <Subheader>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              Preview
              <LoadingCompleteBadge loadingState={loaderState} />
            </div>
          </Subheader>
          <span className='ml-auto'>{header}</span>
        </SubheaderWrapper>
        {maximisePreviewSpace ? (
          <div style={{ minHeight: '1500px' }}>{children}</div>
        ) : (
          <div className={'p-3 h-100'}>{children}</div>
        )}
      </Subsection>
    );
  }
);

export default PreviewSection;
