import React from 'react'
import PropTypes from 'prop-types'

const Errors = ({ errors = [] }) => errors.map((e, i) => <div key={i}>{e}</div>)

Errors.propTypes = {
    errors: PropTypes.arrayOf(PropTypes.string),
}

export default Errors
