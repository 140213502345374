export const BRAND_STORE_ORDER_STATUS = {
  new: {
    status: 'new',
    label: 'New',
  },
  draft: {
    status: 'draft',
    label: 'Draft',
  },
  pending_approval: {
    status: 'pending_approval',
    label: 'Pending Approval',
  },
  scheduled: {
    status: 'scheduled',
    label: 'Scheduled',
  },
  live: {
    status: 'live',
    label: 'Live',
  },
  live_edits_saved: {
    status: 'live_edits_saved',
    label: 'Live - Edits Saved',
  },
  live_edits_pending_approval: {
    status: 'live_edits_pending_approval',
    label: 'Live - Edits Pending Approval',
  },
  expired: {
    status: 'expired',
    label: 'Expired',
  },
};

export const BRAND_STORE_ORDER_FORM_STATE = {
  VIEW: 'VIEW',
  EDIT: 'EDIT',
  SAVING: 'SAVING',
  APPROVING: 'APPROVING',
  SUBMITTING: 'SUBMITTING',
  PROVIDING_FEEDBACK: 'PROVIDING_FEEDBACK',
};

export const PREVIEW_STATE = {
  INITIAL: 'INITIAL',
  UPDATING: 'UPDATING',
  UPDATED: 'UPDATED',
  ERROR: 'ERROR',
};

export const ORDER_LOADING_STATE = {
  INITIAL: 'INITIAL',
  LOADING: 'LOADING',
  LOADED: 'LOADED',
  ERROR: 'ERROR',
};

export const SAVE_STATE = {
  INITIAL: 'INITIAL',
  SAVING: 'SAVING',
  SAVED: 'SAVED',
  ERROR: 'ERROR',
};

export const SUBMIT_STATE = {
  INITIAL: 'INITIAL',
  SUBMITTING: 'SUBMITTING',
  SUBMITTED: 'SUBMITTED',
  ERROR: 'ERROR',
};

export const APPROVE_STATE = {
  INITIAL: 'INITIAL',
  APPROVING: 'APPROVING',
  APPROVED: 'APPROVED',
  ERROR: 'ERROR',
};

export const PROVIDE_FEEDBACK_STATE = {
  INITIAL: 'INITIAL',
  PROVIDING_FEEDBACK: 'PROVIDING_FEEDBACK',
  PROVIDED_FEEDBACK: 'PROVIDED_FEEDBACK',
  ERROR: 'ERROR',
};

/*
state transitions:
VIEW -> EDIT (via edit button)
VIEW -> SUBMITTING (via submit button)
SUBMITTING -> VIEW (via success/fail response)
SUBMITTING -> EDIT (via error response)
EDIT -> VIEW (via cancel button)
EDIT -> SAVING (via save button)
SAVING -> DIRTY (via fail response)
SAVING -> VIEW (via success response)
 */

export const ACTIONS = {
  REQUEST_APPROVAL: 'request_approval',
  PROVIDE_FEEDBACK: 'provide_feedback',
  APPROVE: 'approve',
};
export const DATE_FORMAT = 'YYYY-MM-DD';
export const STORE_DATE_FORMAT = 'YYYY-MM-DD';
