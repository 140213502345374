import { connect } from 'react-redux'
import Urls from '../components/Urls'
import { selectOwnedSpace, updateOwnedUrls } from '../ownedSpaceFormSlice'

const mapStateToProps = state => ({
    urls: selectOwnedSpace(state).owned_urls,
})


const mapDispatchToProps = dispatch => ({
    onChange: value => dispatch(updateOwnedUrls(value)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Urls)
