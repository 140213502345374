import {Button, Modal} from "react-bootstrap";
import React from "react";

type AdBlockModalProps = {
  show: boolean,
  onAccept: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void,
};


const AdBlockModal = ({show, onAccept}: AdBlockModalProps) =>
  <Modal show={show}>
    <Modal.Header>
      Ad Blocker Detected
    </Modal.Header>
    <Modal.Body>
      Hey there! We've noticed you're using an Adblocker. At Zitcha, we strive to improve your experience with us. 
      To do this, we use analytics tools that help us understand how you use our platform. 
      To ensure our platform continues to improve, we kindly request that you consider turning off your Adblocker while using Zitcha. 
      Thank you for helping us out!
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={onAccept}>
        Accept
      </Button>
    </Modal.Footer>
  </Modal>

export default AdBlockModal;
