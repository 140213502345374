import React, { useCallback, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Chrome from "./layout/Chrome";
import { selectSession } from "../features/session/sessionSlice";
import $styles from "./organisation_channels.module.scss";
import Breadcrumbs from "./components/Breadcrumbs";
import { AdType } from "../lib/enums/ad_types";

const breadcrumbItems = [
  {
    path: '/organisation/channels',
    title: 'Channel Settings',
    active: true,
  },
];

const ChannelItem = ({ logo, title, settingsPath, description }) => {
  const navigate = useNavigate();

  return (
    <div className='d-flex'>
      {/* <Figure>
        <Figure.Image className="img-responsive" src={logo} rounded />
      </Figure> */}
      <div className={$styles.channelItemDiv}>
        <h4 className={$styles.channelItemTitle}>{title}</h4>
        <p className={$styles.channelItemDesc}>{description}</p>
        <button className={$styles.settingsBtn} onClick={() => navigate(settingsPath)}>
          Settings
        </button>
      </div>
    </div>
  );
};

const OrganisationChannels = () => {
  const session = useSelector(selectSession);
  const [organisation ] = useState(session.user.active_organisation);
  const hasBroadsignEnabled = session?.feature_flags?.ad_types?.broadsign_in_store || false;

  const adTypes = useSelector(selectSession).ad_types;

  const getAdTypeLogo = useCallback(
    (type) => {
      const adType = adTypes.find((e) => e.control_id === type);
      return adType ? adType.placeholder_images?.large : null;
    },
    [adTypes]
  );

  return (
    <>
      <Helmet>
        <title>
          Owned Organisation Channels for {organisation.name} - {process.env.REACT_APP_NAME}
        </title>
      </Helmet>
      <Chrome>
        <Container className='pt-4'>
          <Breadcrumbs items={breadcrumbItems} />

          <Form>
            <Row className='mb-3'>
              <Col>
                <div className='d-flex'>
                  <h3 className={$styles.topHeading}>Owned Organisation Channels</h3>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm='6' xs='12'>
                <ChannelItem
                  logo={getAdTypeLogo('web_ads')}
                  title='Web Ads'
                  description='Manage your onsite spaces and templates.'
                  settingsPath='/organisation/websettings'
                />
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col>
                <div>&nbsp;</div>
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col>
                <div>&nbsp;</div>
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col>
                <div className='d-flex'>
                  <h3 className={$styles.topHeading}>Extension Channels</h3>
                </div>
              </Col>
            </Row>
            <Row>
              {hasBroadsignEnabled && (
                <Col sm='6' xs='12'>
                  <ChannelItem
                    logo={getAdTypeLogo(AdType.BROADSIGN)}
                    title='In-Store Screens'
                    description='Manage your In-Store Screens.'
                    settingsPath='/organisation/instorescreens-settings'
                  />
                </Col>
              )}
              <Col sm='6' xs='12'>
                <ChannelItem
                  logo={getAdTypeLogo('bespoke_product')}
                  title='Bespoke Products'
                  description='Manage your physical and digital Bespoke Products.'
                  settingsPath='/organisation/bespokesettings'
                />
              </Col>
            </Row>
          </Form>
        </Container>
      </Chrome>
    </>
  );
};

export default OrganisationChannels;
