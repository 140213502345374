import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useMemo } from 'react';
import { Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { selectAllBrands } from '../../brands/brandsSlice';
import { selectAllRetailers } from '../../retailers/retailersSlice';
import { selectAdTypes, selectOrderStatuses, selectPlatforms, selectTheme } from '../../session/sessionSlice';

interface FiltersI {
  sort_by: string;
  organisation: string;
  platform: string;
  order_status: string;
  format: string;
  text: string;
  page: number;
}
type ChangeFilters = (value: FiltersI) => void;

export default function Filters({ onChange, filterFields }: { onChange: ChangeFilters; filterFields: FiltersI }) {
  const theme = useSelector(selectTheme);
  const retailers = useSelector(selectAllRetailers);
  const suppliers = useSelector(selectAllBrands);
  const adTypes = useSelector(selectAdTypes);
  const platforms = useSelector(selectPlatforms);

  const organisations = useMemo(
    () => [
      ...retailers.map((retailer) => ({ ...retailer, label: `${retailer.name} (Retailer)` })),
      ...suppliers.map((supplier) => ({ ...supplier, label: `${supplier.name} (Supplier)` })),
    ],
    [retailers, suppliers]
  );
  const statuses = useSelector(selectOrderStatuses);
  const sortBy = useMemo(
    () => [
      {
        value: 'status',
        label: 'Status',
      },
      {
        value: 'name',
        label: 'Name',
      },
      {
        value: 'start_date',
        label: 'Start Date',
      },
      {
        value: 'end_date',
        label: 'End Date',
      },
      {
        value: 'created_date',
        label: 'Created Date',
      },
      {
        value: 'last_updated',
        label: 'Last Updated',
      },
      {
        value: 'retailer',
        label: 'Retailer',
      },
      {
        value: 'supplier',
        label: 'Supplier',
      },
    ],
    []
  );
  return (
    <Form>
      <Row className='mb-4'>
        <Col lg='4'>
          <InputGroup style={{ position: 'relative' }}>
            <InputGroup.Prepend>
              <InputGroup.Text style={theme.components?.input_group_text}>Search:</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              className='text-dark'
              value={filterFields.text}
              style={theme.components?.form_control}
              onChange={(event) =>
                onChange({
                  ...filterFields,
                  text: event.target.value,
                })
              }
            />
            {filterFields.text !== '' && (
              <span
                style={{ position: 'absolute', right: '8px', top: '7px', zIndex: 9999, cursor: 'pointer' }}
                onClick={() =>
                  onChange({
                    ...filterFields,
                    text: '',
                  })
                }
              >
                <FontAwesomeIcon icon={faTimesCircle} />
              </span>
            )}
          </InputGroup>
        </Col>
        <Col lg='4'></Col>
        <Col lg='4'>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text style={theme.components?.input_group_text}>Sort:</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              as='select'
              style={theme.components?.form_control}
              onChange={(event) =>
                onChange({
                  ...filterFields,
                  sort_by: event.target.value,
                })
              }
            >
              {sortBy.map(({ label, value }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </Form.Control>
          </InputGroup>
        </Col>
      </Row>
      <Card className='p-4 mb-4' style={theme.components?.filter_card}>
        <h5>Filters</h5>
        <Row>
          <Col>
            <Form.Control
              as='select'
              style={theme.components?.form_control}
              onChange={(event) => {
                onChange({
                  ...filterFields,
                  organisation: event.target.value,
                });
              }}
            >
              <option value=''>All Organisations</option>
              {organisations.map((organisation) => (
                <option key={organisation.id} value={organisation.id}>
                  {organisation.label}
                </option>
              ))}
            </Form.Control>
          </Col>
          <Col>
            <Form.Control
              as='select'
              style={theme.components?.form_control}
              onChange={(event) =>
                onChange({
                  ...filterFields,
                  order_status: event.target.value,
                })
              }
            >
              <option value=''>All Statuses</option>
              {statuses.map((status) => (
                <option key={status.id} value={status.id}>
                  {status.label}
                </option>
              ))}
            </Form.Control>
          </Col>
          <Col>
            <Form.Control
              as='select'
              style={theme.components?.form_control}
              onChange={(event) =>
                onChange({
                  ...filterFields,
                  format: event?.target.value,
                })
              }
            >
              <option value=''>All Formats</option>
              {adTypes.map((adType) => (
                <option key={adType.control_id} value={adType.control_id}>
                  {adType.label}
                </option>
              ))}
            </Form.Control>
          </Col>
          <Col>
            <Form.Control
              as='select'
              style={theme.components?.form_control}
              onChange={(event) =>
                onChange({
                  ...filterFields,
                  platform: event.target.value,
                })
              }
            >
              <option value=''>All Platforms</option>
              {platforms.map((platform) => (
                <option key={platform.id} value={platform.id}>
                  {platform.label}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Row>
      </Card>
    </Form>
  );
}
