import {unwrapResult} from '@reduxjs/toolkit'
import {connect} from 'react-redux'
import {orderUpdated} from '../orders/ordersSlice'
import {selectUser} from '../session/sessionSlice'
import OrderFormChrome from './OrderFormChrome'
import {
    archiveOrder,
    clearLiveOrder,
    clearOrder,
    deleteOrder,
    duplicateOrder,
    rejectOrder,
    selectErrors,
    selectOrder,
    selectOtherOrganisation,
    selectReadOnly,
    selectRequestErrors,
    showBudget
} from './orderFormSlice'

const mapStateToProps = state => ({
    readOnly: selectReadOnly(state),
    shownOrganisation: selectOtherOrganisation(state),
    order: selectOrder(state),
    user: selectUser(state),
    showOrderStatus: true,
    showBudget: showBudget(state),
    error: selectRequestErrors(state)?.error,
    formErrors: selectErrors(state),
})


const mapDispatchToProps = dispatch => ({
    onDeleteDraft: () => {
        dispatch(deleteOrder())
        dispatch(clearOrder())
    },
    onRejectOrder: rejectComments => dispatch(rejectOrder({ comments: rejectComments })).then((action) => {
        dispatch(orderUpdated(action.payload))
        dispatch(clearLiveOrder())
    }),
    onArchiveOrder: () => dispatch(archiveOrder()).then((action) =>
        dispatch(orderUpdated(action.payload))
    ),
    onDuplicateOrder: async () => dispatch(duplicateOrder())
        .then(unwrapResult)
        .then(order => {
            dispatch(orderUpdated(order))
            return order
        }),
})


export default connect(mapStateToProps, mapDispatchToProps)(OrderFormChrome)
