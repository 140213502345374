import React, { useEffect, useState } from 'react';
import { Badge, Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import OwnedSpaceCategory from '../features/owned_space_form/containers/OwnedSpaceCategory';
import OwnedSpaceMonetisation from '../features/owned_space_form/containers/OwnedSpaceMonetisation';
import OwnedSpaceName from '../features/owned_space_form/containers/OwnedSpaceName';
import OwnedSpaceSlots from '../features/owned_space_form/containers/OwnedSpaceSlots';
import OwnedTemplates from '../features/owned_space_form/containers/OwnedTemplates';
import OwnedUrls from '../features/owned_space_form/containers/OwnedUrls';
import OwnedSpaceAllowedOrganisations from '../features/owned_space_form/containers/OwnedSpaceAllowedOrganisations';
import {
  clearOwnedSpace,
  createOwnedSpace,
  ownedSpaceFieldUpdated,
  selectOwnedSpace,
  selectUserOrganisation,
} from '../features/owned_space_form/ownedSpaceFormSlice';
import Chrome from './layout/Chrome';
import Breadcrumbs from './components/Breadcrumbs';
import { trackCreateSpace } from '../helpers/segment';

const breadcrumbItems = [
  {
    path: '/organisation/settings',
    title: 'Settings',
  },
  {
    path: '/organisation/channels',
    title: 'Owned Channels',
  },
  {
    path: '/organisation/websettings',
    title: 'Spaces & Templates',
  },
];

const CreateOwnedSpace = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { placement, format, subformat } = useParams();
  const ownedSpace = useSelector(selectOwnedSpace);
  const userOrganisation = useSelector(selectUserOrganisation);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    dispatch(clearOwnedSpace());
    dispatch(
      ownedSpaceFieldUpdated({
        field: 'format',
        value: format,
      })
    );
    dispatch(
      ownedSpaceFieldUpdated({
        field: 'subformat',
        value: subformat,
      })
    );
    dispatch(
      ownedSpaceFieldUpdated({
        field: 'placement',
        value: placement,
      })
    );
    dispatch(
      ownedSpaceFieldUpdated({
        field: 'organisation_id',
        value: userOrganisation.id,
      })
    );
  }, [dispatch]);

  if (!ownedSpace) {
    return <Navigate to='/organisation/websettings' replace />;
  }

  return (
    <>
      <Helmet>
        <title>Create Virtual Space - {process.env.REACT_APP_NAME}</title>
      </Helmet>

      <Chrome>
        <Container className='pt-4'>
          <Breadcrumbs items={breadcrumbItems} />
          <Row>
            <Col>
              <Form>
                <div className='mb-5 d-flex'>
                  <div>
                    <div className='d-flex align-items-center mb-2'>
                      <h5>{ownedSpace?.name ? ownedSpace.name : 'Untitled'}</h5>{' '}
                      {ownedSpace.placement && (
                        <Badge className='ml-3 text-capitalize' variant='info'>
                          {ownedSpace.placement} Space
                        </Badge>
                      )}{' '}
                    </div>
                    <div>
                      <span>
                        <b>Format:</b> {ownedSpace.format ? ownedSpace.format : '-'}
                      </span>
                      <span className='ml-2'>
                        <b>Subformat:</b> {ownedSpace.subformat ? ownedSpace.subformat : '-'}
                      </span>
                    </div>
                  </div>
                  <div className='ml-auto'>
                    <Button
                      variant='primary'
                      className='ml-3'
                      onClick={(e) => {
                        setIsSaving(true);
                        dispatch(createOwnedSpace()).then((action) => {
                          if (!action.error) {
                            const space = action.payload;
                            trackCreateSpace({ ...space, placement: 'embedded' });
                            navigate(`/organisation/websettings`);
                          } else {
                            setIsSaving(false);
                          }
                        });
                      }}
                      disabled={isSaving}
                    >
                      <span className='small d-flex'>
                        {isSaving && (
                          <Spinner
                            as='span'
                            animation='border'
                            size='sm'
                            role='status'
                            aria-hidden='true'
                            className='mr-2'
                          />
                        )}
                        <span>Create Space</span>
                      </span>
                    </Button>
                  </div>
                </div>
                <div className='mb-5'>
                  <h5>General Settings</h5>
                  <hr />
                  <Container fluid>
                    <Row>
                      <Col>
                        <OwnedSpaceName />
                      </Col>
                      <Col>
                        <OwnedSpaceSlots />
                      </Col>
                      {ownedSpace?.subformat === 'web' && (
                        <Col>
                          <OwnedSpaceCategory />
                        </Col>
                      )}
                    </Row>
                  </Container>
                </div>
                <section>
                  <h5>Space Details</h5>
                  <hr />
                  <Container fluid>
                    <Row>
                      {ownedSpace.placement !== 'embedded' && (
                        <Col>
                          <OwnedUrls />
                        </Col>
                      )}
                      <Col>
                        <OwnedSpaceMonetisation />
                      </Col>
                      <Col>
                        <OwnedSpaceAllowedOrganisations />
                      </Col>
                      <Col>
                        <OwnedTemplates />
                      </Col>
                    </Row>
                  </Container>
                </section>
              </Form>
            </Col>
          </Row>
        </Container>
      </Chrome>
    </>
  );
};

export default CreateOwnedSpace;
