import {OverlayTrigger, Tooltip as RBTooltip} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faQuestionCircle} from "@fortawesome/free-solid-svg-icons";
import React, {ReactElement} from "react";


const icons = {
    info: faQuestionCircle,
}


type TooltipProps = {
    text: string | ReactElement,
    type?: 'info',
    placement?: 'top' | 'right' | 'bottom' | 'left',
};


const Tooltip = (props: TooltipProps) => {
    const id = 1;
    const {
        text,
        type = 'info',
        placement = 'top'
    } = props;

    return <OverlayTrigger
        placement={placement}
        overlay={<RBTooltip id={`tooltip-${id}`} data-testid="tooltip-text">{text}</RBTooltip>}
    >
        <FontAwesomeIcon data-testid="tooltip-icon" icon={icons[type] || icons['info']} size='sm'/>
    </OverlayTrigger>
}

export default Tooltip;
