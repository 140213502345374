import React from 'react';
import styled from 'styled-components';
import { UploadDynamicAsset } from './UploadDynamicAsset';

const ConditionalUploadSectionTopPadding = styled.div`
  margin-top: ${(props) => (props.isFirst ? '0px' : '22px')};
`;

export const AssetCardContent = ({ ad, creativeAssetIndex, handleChange, orderAssets, readOnly }) => {
  const selectedMediaEntry = ad?.broadsign_media?.[creativeAssetIndex] || [];
  const hardcodedAvailableMediaTypesForPortrait = ['image', 'video'];
  const hardcodedAvailableMediaTypesForLandscape = ['video'];

  if (!ad?.screen_types?.length) return <>No Screen Types Selected</>;

  return ad?.screen_types?.map((screenType, index) => (
    <ConditionalUploadSectionTopPadding isFirst={index === 0} key={screenType}>
      <UploadDynamicAsset
        screenType={screenType}
        availableMediaTypes={
          screenType === 'Landscape'
            ? hardcodedAvailableMediaTypesForLandscape
            : hardcodedAvailableMediaTypesForPortrait
        }
        ad={ad}
        creativeAssetIndex={creativeAssetIndex}
        handleChange={handleChange}
        value={selectedMediaEntry.find((v) => v.screen_type === screenType) || {}}
        readOnly={readOnly}
        orderAssets={orderAssets}
      />
    </ConditionalUploadSectionTopPadding>
  ));
};
