import React from 'react';
import { Col, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import propTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import { updateWallet } from '../../lib/api/wallets';
import { toast } from 'react-toastify';
import { getCurrencySymbol } from '../../lib/wallets';
import WalletName from './components/form/WalletName';
import WalletMinBalance from './components/form/WalletMinBalance';
import WalletDateSelector from './components/form/WalletDateSelector';
import WalletRemark from './components/form/WalletRemark';
import WalletOverdraw from './components/form/WalletOverdraw';
import WalletFormActionButton from './components/form/WalletFormActionButton';
import StyledCurrencyBadge from './components/form/StyledCurrencyBadge';

const UpdateWalletDialog = ({
  organisation,
  wallet,
  currency,
  existingWallets = [],
  onHideHandler = () => {},
  onSubmitHandler = () => {},
}) => {
  const methods = useForm({
    defaultValues: {
      name: wallet.name,
      start_date: wallet?.start_date ?? null,
      end_date: wallet?.end_date ?? null,
      min_balance: wallet?.min_balance ?? '',
      remark: wallet?.remark ?? '',
      allow_overdraw: wallet?.allow_overdraw ?? false,
    },
  });

  const { handleSubmit, setError, formState } = methods;
  const { isSubmitting } = formState;

  const onSubmit = async (data) => {
    try {
      const res = await updateWallet(wallet.id, data);
      toast.success(res?.data?.message ?? 'Wallet updated successfully');
      onSubmitHandler();
    } catch (e) {
      const errors = e.response?.data?.errors;
      if (e.response?.status !== 422) {
        toast.error('Something went wrong, please try again');
      } else {
        const keys = Object.keys(errors);
        keys.forEach((errorKey) => {
          setError(errorKey, {
            type: 'manual',
            message: errors[errorKey][0],
          });
        });
      }
    }
  };
  return (
    <>
      <Modal size='md' show={true} enforceFocus={false} onHide={onHideHandler} backdrop='static'>
        <FormProvider {...methods}>
          <Modal.Header closeButton>Update wallet for {organisation?.name}</Modal.Header>
          <Modal.Body>
            <Form>
              <Row>
                <Col>
                  <p>
                    <small className='text-muted'>ID: {wallet.id}</small>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <WalletName existingWallets={existingWallets} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className='position-relative'>
                    <StyledCurrencyBadge currency={currency} />
                    <Form.Label>Current Net Position</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>{getCurrencySymbol(wallet?.currency)}</InputGroup.Text>
                      <Form.Control disabled type='text' value={wallet.balance} />
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <WalletMinBalance currency={currency} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <WalletDateSelector control_name='start_date' label='Start Date' />
                </Col>
                <Col>
                  <WalletDateSelector control_name='end_date' label='End Date' />
                </Col>
              </Row>

              <Row>
                <Col>
                  <WalletRemark />
                </Col>
              </Row>

              <Row>
                <Col>
                  <WalletOverdraw />
                </Col>
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Form.Group>
              <WalletFormActionButton handleSubmit={handleSubmit(onSubmit)} text='Update' loading={isSubmitting} />
            </Form.Group>
          </Modal.Footer>
        </FormProvider>
      </Modal>
    </>
  );
};

UpdateWalletDialog.propTypes = {
  organisation: propTypes.shape({
    id: propTypes.isRequired,
    name: propTypes.string.isRequired,
  }),
  currency: propTypes.string.isRequired,
  existingWallets: propTypes.arrayOf(propTypes.string).isRequired,
  wallet: propTypes.shape({
    id: propTypes.isRequired,
    name: propTypes.string.isRequired,
    currency: propTypes.string.isRequired,
    balance: propTypes.number.isRequired,
    min_balance: propTypes.number.isRequired,
    remark: propTypes.string.isRequired,
  }),
  onHideHandler: propTypes.func.isRequired,
  onSubmitHandler: propTypes.func.isRequired,
};

export default UpdateWalletDialog;
