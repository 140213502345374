import React from 'react'
import { Card } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { selectTheme } from '../session/sessionSlice'
import InviteForm from './InviteForm'

const Invite = () => {
  const theme = useSelector(selectTheme)

  return (
    <Card style={theme.components?.card}>
      <Card.Header style={theme.components?.card_header}>
        Invite Supplier Organisation
      </Card.Header>
      <Card.Body>
        <InviteForm />
      </Card.Body>
    </Card>
  )
}

export default Invite
