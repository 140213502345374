import { faEdit, faMinusSquare, faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import { faPlus, faTrash } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Badge, Button, Form, OverlayTrigger, Popover, ToggleButton, ToggleButtonGroup } from 'react-bootstrap'
import styled from 'styled-components'
import { uniqBy } from 'lodash'
import Errors from '../Errors'

const KeywordRow = styled.div`
    :hover {
        background-color: #f2f2f2;
    }
`

const ButtonGroupContainer = styled.div`
    margin-bottom: 10px;
`

const PopoverContent = styled(Popover.Content)`
    width: 430px;
`

const DetailPopover = styled(Popover)`
    max-width: 430px;
`

const FormCheckGroup = styled.div`
    font-size: 18px;
`

const PlusSquare = styled.span`
    color: #4FAD80;
`

const MinusSquare = styled.span`
    color: #EF767A;
`

const MatchTypeContainer = styled.span`
    min-width: 1rem;
    margin-right: 0.6rem;
`

const MatchFitBadge = styled(Badge)`
    background-color: #B9B9C0;
    width: 48px;
`

const PositiveToggleButton = styled(ToggleButton)`
    background-color: transparent;
    border-color: #87F5C0;
    color: #87F5C0;

    &:not(:disabled):not(.disabled).active,
    &:hover {
        color: #fff;
        background-color: #87F5C0;
        border-color: #87F5C0;
    }
`

const NegativeToggleButton = styled(ToggleButton)`
    background-color: transparent;
    border-color: #EF767A;
    color: #EF767A;

    &:not(:disabled):not(.disabled).active,
    &:hover {
        color: #fff;
        background-color: #EF767A;
        border-color: #EF767A;
    }
`

const DetailPopup = React.forwardRef(({ value = {}, onSubmit = () => { }, edit = false, onCancel = () => { }, popper, ...props }, ref) => {
    const [keyword, setKeyword] = useState(value)
    const [phraseError, setPhraseError] = useState('')
    const handleChange = val => setKeyword({ ...keyword, ...val })
    const handleSubmit = () => {
        if (keyword.phrase.length < 3 || keyword.phrase.length > 50) {
            setPhraseError("Phrase needs to be between 3 and 50 characters.")
            popper.scheduleUpdate()
            return
        }
        onSubmit(keyword)
    }

    if (!keyword.match_type) {
        return handleChange({ match_type: 'positive' })
    }

    if (!keyword.match_fit) {
        return handleChange({ match_fit: 'exact' })
    }

    return <DetailPopover ref={ref} {...props} onKeyDown={(e) => {
        if (e.key === "Enter") {
            handleSubmit()
        }
    }}>
        <PopoverContent>
            <Form.Label className="font-weight-bold">Keyword Type</Form.Label>
            <ButtonGroupContainer>
                <ToggleButtonGroup
                    className="w-100"
                    name="keyword-button-group"
                    type="radio"
                    value={keyword.match_type}
                    onChange={val => handleChange({ match_type: val })}
                >
                    <PositiveToggleButton variant="outline-success" value="positive">Positive</PositiveToggleButton>
                    <NegativeToggleButton variant="outline-danger" value="negative">Negative</NegativeToggleButton>
                </ToggleButtonGroup>
            </ButtonGroupContainer>
            <div className="mb-2">
                <div className="d-flex">
                    <Form.Label className="font-weight-bold">{edit ? 'Enter keyword (e.g. toys)' : 'Enter keywords (e.g. toys, books)'}</Form.Label>
                    <span className="ml-auto text-muted">({keyword.phrase?.length || 0}/50)</span>
                </div>
                <Form.Control
                    type="text"
                    className="mb-1"
                    value={keyword.phrase}
                    isInvalid={phraseError}
                    onChange={e => handleChange({ phrase: e.target.value })}
                    minLength={3}
                    maxLength={50}
                />
                <Form.Control.Feedback type="invalid">
                    {phraseError}
                </Form.Control.Feedback>
            </div>
            <div className="mb-4">
                <Form.Label className="font-weight-bold">The keyword should be an</Form.Label>

                <FormCheckGroup className="d-flex justify-content-around">
                    <Form.Check inline type="radio" label="Exact" id="Exact" checked={keyword.match_fit === 'exact'} onChange={e => e.target.checked && handleChange({ match_fit: 'exact' })} />
                    <Form.Check inline type="radio" label="Broad" id="Broad" checked={keyword.match_fit === 'broad'} onChange={e => e.target.checked && handleChange({ match_fit: 'broad' })} />
                </FormCheckGroup>
            </div>
            <div className="mb-3">
                <div className="d-flex justify-content-between">
                    <Button variant="outline-danger" className="px-5 py-2" onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button variant="outline-primary" className="px-5 py-2" onClick={e => handleSubmit()}>
                        Save
                    </Button>
                </div>
            </div>
        </PopoverContent>
    </DetailPopover>
})

const Keywords = ({ controlId, value = [], readOnly = true, errors = [], onChange = () => { } }) => {
    const [showPopover, setShowPopover] = useState(false)

    const handleAdd = val => {
        let arrVal = [val]

        if (val.phrase.includes(',')) {
            arrVal = val.phrase
                .split(',')
                .map(phrase => ({
                    ...val,
                    phrase: phrase.trim(),
                }))
        }

        let nv = [...value, ...arrVal]
        nv = uniqBy(nv, kw => kw.phrase)
        onChange(nv)
        setShowPopover(false)
    }

    const handleChange = (val, index) => {
        let nv = [...value]
        nv[index] = val
        nv = uniqBy(nv, kw => kw.phrase)
        onChange(nv)
        setShowPopover(false)
    }

    const handleDelete = index => {
        let nv = [...value]
        nv.splice(index, 1)
        onChange(nv)
    }

    return <Form.Group>
        <div className="px-3 mb-2">
            <Form.Control.Feedback
                type="invalid"
                className={errors.length > 0 ? "d-block" : ""}
            >
                <Errors errors={errors} />
            </Form.Control.Feedback>
        </div>
        <div className="d-flex mb-2 position-relative justify-content-center" style={{ position: 'relative' }}>
            {!readOnly &&
                <OverlayTrigger
                    trigger="click"
                    show={showPopover === 'main'}
                    rootClose
                    onToggle={e => setShowPopover(!showPopover ? 'main' : false)}
                    placement="bottom"
                    overlay={<DetailPopup
                        id={`${controlId}-popover`}
                        onSubmit={handleAdd}
                        onCancel={e => setShowPopover(false)}
                    />}
                >
                    <Button variant="outline-primary" className="px-5 py-2">
                        <FontAwesomeIcon icon={faPlus} /> Add
                    </Button>
                </OverlayTrigger>}
        </div>
        <div className="bg-white rounded px-1 py-3">
            {value.length > 0
                ? value.map((keyword, index) =>
                    <KeywordRow key={index} className="d-flex px-2 align-items-center rounded">
                        <MatchTypeContainer>
                            {keyword.match_type === 'positive'
                                ? <PlusSquare>
                                    <FontAwesomeIcon icon={faPlusSquare} size="lg" fixedWidth />
                                </PlusSquare>
                                : <MinusSquare>
                                    <FontAwesomeIcon icon={faMinusSquare} size="lg" fixedWidth />
                                </MinusSquare>}
                        </MatchTypeContainer>
                        {keyword.match_fit === 'broad'
                            ? <MatchFitBadge variant="secondary">Broad</MatchFitBadge>
                            : <MatchFitBadge variant="secondary">Exact</MatchFitBadge>}
                        <span className="ml-2">{keyword.phrase}</span>
                        {!readOnly &&
                            <>
                                <OverlayTrigger
                                    trigger="click"
                                    show={showPopover === index}
                                    rootClose
                                    onToggle={e => setShowPopover(!showPopover ? index : false)}
                                    placement="top"
                                    overlay={<DetailPopup
                                        edit
                                        id={`${controlId}-popover`}
                                        value={keyword}
                                        onSubmit={v => handleChange(v, index)}
                                    />}
                                >
                                    <span className="ml-auto cursor-pointer">
                                        <FontAwesomeIcon icon={faEdit} />
                                    </span>
                                </OverlayTrigger>
                                <span className="ml-2 cursor-pointer" onClick={e => handleDelete(index)}>
                                    <FontAwesomeIcon icon={faTrash} />
                                </span>
                            </>}
                    </KeywordRow>)
                : <div className="w-100 text-center">No keywords added.</div>}
        </div>
    </Form.Group>
}

export default Keywords
