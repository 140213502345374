import React from 'react';
import { Carousel } from 'react-bootstrap';
import PackageCard from '../../features/packages/components/PackageCard';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectUserOrganisation, selectAdTypes, selectSession } from '../../features/session/sessionSlice';
import { v4 as uuidv4 } from 'uuid';
import {
  adInsert,
  selectFacebookProductGroup,
  selectGoogleProductGroup,
  selectImageAssets,
  selectVideoAssets,
  orderFieldUpdated,
  clearOrder,
} from '../../features/order_form/orderFormSlice';
import { STATUS_PENDING_APPROVAL } from '../../lib/orders';
import { PLATFORM_NATIVE } from '../../lib/platforms';

const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
`;

const createStandardAd = (adSelection, facebookProducts, googleProducts, imageAssets, videoAssets, settings) => {
  let ad = {
    type: adSelection.control_id,
    platform: adSelection.platform,
  };

  // Apply checkbox default values
  adSelection.fields
    .filter((field) => field.type === 'select_one_or_many' && field.default?.length > 0)
    .forEach((field) => {
      ad[field.id] = field.default;
    });

  // Perform default feed value
  if (adSelection.fields.some((field) => field.type === 'feed')) {
    const feedField = adSelection.fields.find((field) => field.type === 'feed');
    if (ad.platform === 'google') {
      ad[feedField.id] = googleProducts;
    } else if (ad.platform === 'facebook') {
      ad[feedField.id] = facebookProducts;
    }
  }

  // Set default cta
  if (
    ad.type === 'facebook_online_conversion_dpa' ||
    ad.type === 'facebook_display' ||
    ad.type === 'facebook_carousel'
  ) {
    ad.cta = 'LEARN_MORE';
  }

  return ad;
};

const createOwnedSpaceAd = (adSelection) => {
  let ad = {
    platform: PLATFORM_NATIVE,
    type: adSelection.control_id,
  };

  return ad;
};

const PackageOffers = ({ packages = [] }) => {
  const organisation = useSelector(selectUserOrganisation);
  const facebookProducts = useSelector(selectFacebookProductGroup);
  const googleProducts = useSelector(selectGoogleProductGroup);
  const imageAssets = useSelector(selectImageAssets);
  const videoAssets = useSelector(selectVideoAssets);
  const session = useSelector(selectSession);
  const user = session?.user;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const retailers = session?.retailers;

  let adTypes = useSelector(selectAdTypes);
  const handleInsert = (adFormat, fields = {}) => {
    let ad;
    if (adFormat.platform !== PLATFORM_NATIVE) {
      ad = createStandardAd(
        adFormat,
        facebookProducts,
        googleProducts,
        imageAssets,
        videoAssets,
        organisation.settings
      );
    } else if (adFormat.platform === PLATFORM_NATIVE) {
      ad = createOwnedSpaceAd(adFormat);
    }

    ad = { ...ad, ...fields };

    dispatch(orderFieldUpdated({ field: 'owner_id', value: fields.owner_id }));
    dispatch(orderFieldUpdated({ field: 'supplier_id', value: fields.supplier_id }));
    dispatch(orderFieldUpdated({ field: 'status', value: STATUS_PENDING_APPROVAL }));

    dispatch(adInsert(ad));

    navigate('/order/create');
  };

  const clear = () => {
    dispatch(clearOrder());
  };

  return (
    <section>
      <h5 className='text-capitalize'>Drive Results with these great packages</h5>
      <Carousel>
        {packages.map((pkg) => (
          <Carousel.Item key={pkg.id}>
            <CardContainer>
              <PackageCard
                key={pkg.id}
                image={pkg.image}
                organisation={retailers?.find(org => org.id === pkg.organisation_id)}                header={pkg.name}
                description={pkg.description}
                outcomes={pkg.outcomes}
                ads={pkg.ad_format_templates}
                onAdd={() => {
                  clear();
                  const packageCollectionID = uuidv4();
                  pkg.ad_format_templates.forEach((adTemplate) => {
                    let adFormat = adTypes.find((format) => format.control_id === adTemplate.adformat_control_id);
                    if (adFormat) {
                      handleInsert(adFormat, {
                        owner_id: pkg.organisation_id,
                        supplier_id: user.active_organisation_id,
                        package_id: pkg.id,
                        package_collection_id: packageCollectionID,
                        package_item_id: adTemplate.id,
                      });
                    }
                  });
                }}
              />
            </CardContainer>
          </Carousel.Item>
        ))}
      </Carousel>
    </section>
  );
};

export default PackageOffers;
