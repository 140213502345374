import { connect } from 'react-redux';
import BespokeFormEditor from './BespokeFormEditor';
import { selectBespokeProduct, selectErrors, updateBespokeProduct } from '../bespokeFormSlice';

const mapStateToProps = (state) => ({
  bespoke: selectBespokeProduct(state),
  errors: selectErrors(state),
});

const mapDispatchToProps = (dispatch) => ({
  onChange: (bespoke) => {
    dispatch(updateBespokeProduct(bespoke));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BespokeFormEditor);
