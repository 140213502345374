import { faHandPointRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { Row } from 'react-bootstrap';
import 'react-multi-carousel/lib/styles.css';

const BannerAdsCategory = ({ enablementText = 'test text for enablement'}) => {
  return (
    <div id='ad_sp' style={{ margin: '10px' }}>
      <div
        style={{
          color: '#3A3B4F',
          fontSize: '0.75rem',
          textAlign: 'center',
          width: '100%',
          marginBottom: '20px',
          backgroundColor: '#D4CCFF',
          borderRadius: '24px',
          height: '32px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <FontAwesomeIcon icon={faHandPointRight} style={{ marginRight: '5px' }} /> {enablementText}{' '}
        <FontAwesomeIcon icon={faHandPointRight} style={{ marginLeft: '5px' }} />
      </div>
      <Row style={{ backgroundColor: '#EDEDED', padding: '10px' }}>
        <div style={{ width: '30%', display: 'inline-block', float: 'left' }}>
          <div style={{ width: '150px', height: '150px', backgroundColor: '#C4C4C4' }}></div>
        </div>
        <div style={{ width: '65%', display: 'inline-block', marginLeft: '5px', float: 'left' }}>
          <div style={{ width: '100%', height: '42px', backgroundColor: '#C4C4C4', borderRadius: '4px' }}></div>
          <div
            style={{
              width: '250px',
              height: '42px',
              backgroundColor: '#C4C4C4',
              marginTop: '10px',
              borderRadius: '4px',
            }}
          ></div>
          <div style={{ width: '100%', height: '60px' }}>
            <div style={{ width: '30%', display: 'inline-block', float: 'left' }}>
              <div
                style={{
                  width: '250px',
                  height: '20px',
                  backgroundColor: '#C4C4C4',
                  marginTop: '10px',
                  borderRadius: '4px',
                }}
              ></div>
              <div
                style={{
                  width: '250px',
                  height: '20px',
                  backgroundColor: '#C4C4C4',
                  marginTop: '5px',
                  borderRadius: '4px',
                }}
              ></div>
            </div>
            <div style={{ width: '30%', display: 'inline-block', float: 'right' }}>
              <div
                style={{
                  width: '125px',
                  height: '50px',
                  backgroundColor: '#C4C4C4',
                  marginTop: '5px',
                  borderRadius: '4px',
                }}
              ></div>
            </div>
          </div>
        </div>
      </Row>
    </div>
  );
};

BannerAdsCategory.propTypes = {
  enablementText: PropTypes.string,
};

export default BannerAdsCategory;
