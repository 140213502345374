import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { getSuppliers } from '../../lib/api';

export const fetchBrands = createAsyncThunk('brands/fetchBrands', async (arg, { rejectWithValue }) => {
  try {
    const response = await getSuppliers();
    return response.data.data;
  } catch (err) {
    if (!err.response?.data) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const cachedFetchBrands = createAsyncThunk('brands/cachedFetchBrands', async (arg, { getState, dispatch }) => {
  if (getState().brands.status === 'idle') {
    const action = await dispatch(fetchBrands());
    return action.payload;
  }
});

const brandsAdapter = createEntityAdapter({
  // Sort alphabetically
  sortComparer: (a, b) => (a.name < b.name ? -1 : +(a.name > b.name)),
});

export const {
  selectAll: selectAllBrands,
  selectIds: selectBrandIds,
  selectById: selectBrandById,
} = brandsAdapter.getSelectors((state) => state.brands);

const brandsSlice = createSlice({
  name: 'brands',

  initialState: brandsAdapter.getInitialState({
    brands: [],
    status: 'idle',
  }),

  reducers: {
    brandDeleted: brandsAdapter.removeOne,
    brandsCleared: brandsAdapter.removeAll,
  },

  extraReducers: (builder) => {
    builder.addCase(fetchBrands.pending, (state, action) => {
      state.status = 'loading';
    });

    builder.addCase(fetchBrands.fulfilled, (state, action) => {
      if (state.status === 'loading') {
        brandsAdapter.setAll(state, action.payload);
        state.status = 'succeeded';
      }
    });

    builder.addCase(fetchBrands.rejected, (state, action) => {
      if (state.status === 'loading') {
        state.status = 'failed';
      }
    });
  },
});

export const {} = brandsSlice.actions;

export const selectStatus = (state) => state.brands.status;

export default brandsSlice.reducer;
