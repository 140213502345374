import { connect } from 'react-redux';
import GoogleFeed from '../components/Feed/GoogleFeed';
import { selectOrderFormPermissions, selectReadOnly } from '../orderFormSlice';

const mapStateToProps = (state) => {
  const perms = selectOrderFormPermissions(state);
  const productSets = perms?.product_sets?.filter((set) => set.platform === 'google');
  const productCatalogs = perms?.product_catalogs?.filter((catalog) => catalog.platform === 'google');

  return {
    controlId: 'google-catalogs',
    required: false,
    readOnly: selectReadOnly(state),
    productSets: productSets,
    productCatalogs: productCatalogs,
  };
};

export default connect(mapStateToProps)(GoogleFeed);
