import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { refreshSession, selectSession, selectUserOrganisation } from '../session/sessionSlice';
import { inviteUserOrganisation } from '../../lib/api';
import Alert from 'react-bootstrap/Alert';
import { toast } from 'react-toastify';
import { trackInviteSupplier } from '../../helpers/segment';

const InviteForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const session = useSelector(selectSession);
  const userOrganisation = useSelector(selectUserOrganisation);
  const [form, setForm] = useState({
    email: '',
    organisation_name: '',
    first_name: '',
    last_name: '',
    jobtitle: '',
    selected_org_id: null,
  });
  const [orgs, setOrgs] = useState([]);
  const [formDisabled, setFormDisabled] = useState(false);
  const [info, setInfo] = useState([]);
  const [msg, setMsg] = useState(null);
  const [inviteAsNewSupplier, setInviteAsNewSupplier] = useState(false);
  const [inviteLinkedSupplierAsNew, setInviteLinkedSupplierAsNew] = useState(false);

  return (
    <Form>
      {info.length > 0 && <Alert variant='danger'>{info.join('\n')}</Alert>}

      {msg && !Array.isArray(msg.body) && (
        <Alert className='mt-3' variant={msg.type}>
          {msg.body}
        </Alert>
      )}
      {msg && Array.isArray(msg.body) && (
        <Alert className='mt-3' variant={msg.type}>
          {Object.keys(msg.body[1]).map((key, i) => (
            <li key={key}>
              {msg.body[1][key][0]}
            </li>
          ))}
        </Alert>
      )} 
      <Form.Group controlId='name'>
        <Form.Label>User's first name*</Form.Label>
        <Form.Control type='text' onChange={(e) => setForm({ ...form, first_name: e.target.value })} />
      </Form.Group>
      <Form.Group controlId='name'>
        <Form.Label>User's last name*</Form.Label>
        <Form.Control type='text' onChange={(e) => setForm({ ...form, last_name: e.target.value })} />
      </Form.Group>
      <Form.Group controlId='name'>
        <Form.Label>User's job title*</Form.Label>
        <Form.Control type='text' onChange={(e) => setForm({ ...form, jobtitle: e.target.value })} />
      </Form.Group>
      {!form?.selected_org_id && (
        <Form.Group controlId='organisation_name'>
          <Form.Label>Supplier's organisation name (required if new user)</Form.Label>
          <Form.Control type='text' onChange={(e) => setForm({ ...form, organisation_name: e.target.value })} />
        </Form.Group>
      )}
      <Form.Group controlId='email'>
        <Form.Label>Email*</Form.Label>
        <Form.Control
          type='email'
          onChange={(e) => {
            setForm({ ...form, email: e.target.value, selected_org_id: null });
            setFormDisabled(false);
            setOrgs([]);
            setInviteAsNewSupplier(false);
          }}
        />
      </Form.Group>
      <div style={{ display: inviteLinkedSupplierAsNew ? 'block' : 'none' }}>
        {inviteLinkedSupplierAsNew && (
          <Form.Group controlId='organisation_name'>
            <Form.Label>Organisation name</Form.Label>
            <Form.Control
              type='text'
              value={form?.organisation_name}
              onChange={(e) => setForm({ ...form, organisation_name: e.target.value })}
            />
          </Form.Group>
        )}
      </div>
      {orgs && orgs.length > 0 && (
        <div>
          <div className='alert alert-warning'>
            This user belongs to more than one organisation. Please choose the organisation you wish to invite as
            supplier or invite as a new supplier
          </div>
          <Form.Group controlId='selectInvite'>
            <Form.Check
              inline
              type='radio'
              name={''}
              label={'Invite as a new supplier'}
              checked={inviteAsNewSupplier}
              value={true}
              id={'selectInvite'}
              onChange={(e) => {
                setForm({ ...form, ...{ selected_org_id: 'create_as_new', organisation_name: '' } });
                setInviteAsNewSupplier(true);
              }}
            />
            <Form.Check
              inline
              type='radio'
              name={''}
              label={'Invite as an existing supplier'}
              checked={!inviteAsNewSupplier}
              value={false}
              id={'selectInvite'}
              onChange={(e) => {
                setForm({ ...form, selected_org_id: null });
                setInviteAsNewSupplier(false);
              }}
            />
          </Form.Group>
          <div style={{ display: inviteAsNewSupplier ? 'none' : 'block' }}>
            <Form.Group controlId='selected_org_id'>
              <Form.Label>Select Organisation</Form.Label>
              <Form.Control as='select' onChange={(e) => setForm({ ...form, selected_org_id: e.target.value })}>
                <option></option>
                {orgs
                  .filter((o) => o.id !== userOrganisation.id)
                  .map((org) => (
                    <option key={org.id} value={org.id}>
                      {org.name}
                    </option>
                  ))}
              </Form.Control>
            </Form.Group>
          </div>
          <div style={{ display: inviteAsNewSupplier ? 'block' : 'none' }}>
            {form?.selected_org_id && form?.selected_org_id === 'create_as_new' && (
              <Form.Group controlId='organisation_name'>
                <Form.Label>Supplier's organisation name</Form.Label>
                <Form.Control type='text' onChange={(e) => setForm({ ...form, organisation_name: e.target.value })} />
              </Form.Group>
            )}
          </div>
        </div>
      )}

      <Form.Group>
        <Button
          disabled={(!form.selected_org_id && orgs && !!orgs.length) || formDisabled}
          style={session?.theme?.components?.button}
          onClick={(e) => {
            e.preventDefault();
            setFormDisabled(true);
            setMsg(null);
            setInfo([]);

            if (
              form?.selected_org_id &&
              form.selected_org_id === 'create_as_new' &&
              (form.organisation_name === null || form.organisation_name === '')
            ) {
              setMsg({
                type: 'danger',
                body: 'Please enter a valid organisation name.',
              });
              setFormDisabled(false);
              return;
            }

            setOrgs([]);
            setForm({ ...form, selected_org_id: null });

            inviteUserOrganisation(
              form.first_name,
              form.last_name,
              form.email,
              form.jobtitle,
              form.selected_org_id,
              form.organisation_name
            )
              .then((resp) => {
                const data = resp.data.data;
                setFormDisabled(false);
                if (data?.connected) {
                  if (data?.info) {
                    setInfo([resp.data.data.info + '. Do you like to invite this user as a new supplier instead?']);
                    setForm({ ...form, selected_org_id: 'create_as_new' });
                    setInviteLinkedSupplierAsNew(true);
                  }
                } else if (data?.user_orgs) {
                  setOrgs(data.user_orgs);
                } else {
                  // TODO: check later if this needs to be moved into helper/reduce store so the logic can be centralized in one place
                  toast.success('The invitation has been sent out to the supplier');
                  dispatch(refreshSession());
                  trackInviteSupplier(
                    form.first_name,
                    form.last_name,
                    form.email,
                    form.jobtitle,
                    form.organisation_name
                  );
                  navigate('/');
                }
              })
              .catch((err) => {
                setFormDisabled(false);
                const errors = err?.response?.data;
                const keys = Object.keys(errors || {});
                if (!errors || !keys.length || typeof errors === 'string') {
                  setMsg({
                    type: 'danger',
                    body: 'Could not save the details, please try again.',
                  });
                } else {
                  let tmp = [];
                  keys.forEach((errorKey) => {
                    tmp.push(errors[errorKey]);
                  });
                  setMsg({
                    type: 'danger',
                    body: tmp,
                  });
                }
              });
          }}
        >
          <FontAwesomeIcon icon={faPaperPlane} /> {!inviteLinkedSupplierAsNew && <span>Send Invite</span>}
          {inviteLinkedSupplierAsNew && <span>Send Invite as a new Supplier</span>}
        </Button>
      </Form.Group>
      <small>* required</small>
    </Form>
  );
};

export default InviteForm;
