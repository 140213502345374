import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import AdDateRange from '../components/AdDateRange';

export const ScheduleTab = ({ ad, errors, handleChange, order, controlId, readOnly }) => {
  return (
    <Container>
      <Row>
        <Col>
          <AdDateRange
            controlIdStartAt={`${controlId}.start_at`}
            controlIdStopAt={`${controlId}.stop_at`}
            readOnly={readOnly}
            required
            placeholder={{
              start_datetime: order.start_at,
              stop_datetime: order.stop_at,
            }}
            value={{
              start_datetime: ad.start_at,
              stop_datetime: ad.stop_at,
            }}
            errors={{
              start_datetime: errors.start_at,
              stop_datetime: errors.stop_at,
            }}
            onChange={({ start_datetime, stop_datetime }) =>
              handleChange({
                start_at: start_datetime,
                stop_at: stop_datetime,
              })
            }
            dateTime={ad.platform !== 'google'}
          />
        </Col>
      </Row>
    </Container>
  );
};

ScheduleTab.propTypes = {
  ad: PropTypes.object.isRequired,
  errors: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
  controlId: PropTypes.string.isRequired,
};
