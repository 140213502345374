import { Badge, Form } from 'react-bootstrap'
import styled from 'styled-components'

export const LabelText = styled.span`
  font-size: 14px;
  font-weight: 700;
`

export const DescriptionContainer = styled.div`
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 8px;
`

export const LengthBadge = styled(Badge)`
  background-color: #421CFF;
  padding: 5px 9px 5px 9px;
  margin-left: auto;
  font-size: 12px;

  ${props => props.readOnly && 'filter: grayscale(1);'}
`

export const TextInput = styled(Form.Control)`
  border-color: #B9B9C0;
  border-radius: 4px;
  width: 100%;
`

export const TextAreaInput = styled(TextInput)`
  padding: 6px 12px;
  color: #495057;

  &:focus {
    background-color: #fff;
    border-color: #dcd5ff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(113 85 255 / 25%);
  }

  &:disabled {
    background-color: #eaecf4 !important;
    opacity: 1 !important;
    border: 1px solid #d1d3e2 !important;
  }
`

export const ErrorFeedback = styled(Form.Control.Feedback)`
  display: ${props => props.isInvalid ? 'block' : 'none'};
`
