import { createSlice } from "@reduxjs/toolkit";

const appSlice = createSlice({
  name: "app",
  initialState: {
    dialog: null,
  },
  reducers: {
    showDialog(state, { payload }) {
      state.dialog = payload;
    },
  },
});

export const { showDialog } = appSlice.actions;

export const selectDialog = (state) => state.app.dialog;

export default appSlice.reducer;