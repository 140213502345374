import React from "react";
import styled from "styled-components";


const Container = styled.div`
  display: flex;
  align-items: center;
  ${props => props.width && `width: ${props.width};`}
`;

const Label = styled.span`
  font-weight: 400;
  color: black;
  font-size: 11.11px;
  line-height: 140%;
  display: flex;
  align-items: center;
  margin: 0;
`;

const Select = styled.select`
  color: ${props => props.value ? '#000000' : '#777785'};

  flex: none;
  flex-grow: 0;
  font-weight: 500;
  font-size: 13.33px;
  border-width: 0 0 1px 0 !important;
  border-radius: 0 !important;
  border-color: #777785 !important;
  background-color: transparent !important;
  padding: 6px 28px 6px 12px;
  outline: none;

  &:active {
    border-color: #000000 !important;
  }

  &:focus {
    border-color: #6051fb !important;
  }
`;

type DropDownItem = {
  value: string,
  label: string,
};

type DropdownProps = {
  label: string,
  options: DropDownItem[],
  value: string,
  onChange: (EventTarget) => void,
  width?: string,
  disabled?: boolean,
  readOnly?: boolean,
};


const Dropdown = React.forwardRef(({
  label,
  options,
  value,
  width,
  onChange,
  disabled = false,
  readOnly = false
}: DropdownProps, ref): JSX.Element =>
  <Container width={width}>
      <Label>{label}</Label>
      <Select
          ref={ref}
          onChange={onChange}
          value={value}
          disabled={disabled}
          readOnly={readOnly}
      >
          {options.map(option => (
              <option key={option.value} value={option.value}>
                  {option.label}
              </option>
          ))}
      </Select>
  </Container>
);

export default Dropdown;