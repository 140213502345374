import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  createBespokeProduct as createBespokeProductRequest,
  showBespokeProduct,
  updateBespokeProduct as updateBespokeProductRequest,
} from '../../lib/api';
import { trackCreateBespokeChannel } from '../../helpers/segment';

const initialState = {
  bespoke_product: {
    name: '',
    description: '',
    fields: [],
    availability_start: '',
    availability_end: '',
    placeholder_images: {},
    custom_fields: {
      campaign_start: '',
      campaign_end: '',
      classification: 'digital',
      lead_time: 0,
    },
  },
  status: 'idle',
  request_type: 'none',
  read_only: true,
  errors: {},
};

const bespokeFormSlice = createSlice({
  name: 'bespoke_product_form',
  initialState,
  reducers: {
    updateBespokeProduct(state, action) {
      state.bespoke_product = action.payload;
    },
    updateErrors(state, action) {
      state.errors = action.payload || {};
    },
    clearBespokeProduct: () => initialState,
  },
});

export const selectBespokeProduct = (state) => state.bespoke_product_form.bespoke_product;

export const selectErrors = (state) => state.bespoke_product_form.errors;
export const selectFormErrors = (state) => selectErrors(state)._errors;

export const upsertBespokeProduct = createAsyncThunk(
  'bespoke_form/upsertBespokeProduct',
  async (arg, { getState, dispatch, rejectWithValue }) => {
    try {
      let response;
      if (selectBespokeProduct(getState()).id) {
        response = await updateBespokeProductRequest(
          selectBespokeProduct(getState()).id,
          selectBespokeProduct(getState())
        );
      } else {
        response = await createBespokeProductRequest(selectBespokeProduct(getState()));
        const bespoke = response.data.data;
        trackCreateBespokeChannel(bespoke.control_id, bespoke.label);
      }

      return response.data.data;
    } catch (err) {
      if (!err.response?.data) {
        throw err;
      }

      dispatch(updateErrors(err.response.data.errors));

      throw rejectWithValue(err.response.data);
    }
  }
);

export const fetchBespokeProduct = createAsyncThunk(
  'bespoke_form/fetchBespokeProduct',
  async (arg, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await showBespokeProduct(arg);

      dispatch(updateBespokeProduct(response.data.data));
      return response.data.data;
    } catch (err) {
      if (!err.response?.data) {
        throw err;
      }
      dispatch(updateErrors(err.response.data.errors));

      throw rejectWithValue(err.response.data);
    }
  }
);

export const selectStatus = (state) => state.bespoke_product_form.status;
export const selectRequestType = (state) => state.bespoke_product_form.request_type;

export const { updateBespokeProduct, updateErrors, clearBespokeProduct } = bespokeFormSlice.actions;

export default bespokeFormSlice.reducer;
