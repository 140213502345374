import { connect } from 'react-redux'
import EditableAsset from '../../components/form_elements/Asset'
import { selectPackageImage, selectPackageImageErrors, updateImage } from '../../packageFormSlice'

const mapStateToProps = state => ({
    controlId: "package-image",
    label: "Image",
    description: "Upload package image",
    required: false,
    value: selectPackageImage(state),
    errors: selectPackageImageErrors(state),
})


const mapDispatchToProps = dispatch => ({
    onChange: name => dispatch(updateImage(name)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditableAsset)
