import {Product} from "../types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import styled from "styled-components";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

const Container = styled.div<{ disabled: boolean }>`
  width: 6rem;
  ${({disabled}) => disabled && 'opacity: 0.5;'}
`;

const DeleteButton = styled.button`
  top: 8px;
  right: 8px;
  position: absolute;
  line-height: 0;
  border-radius: 50%;
  background: #fff;
`;

type SmallProductSquareProps = {
    product: Product,
    readOnly?: boolean,
    onDelete: (Product) => void,
};

const SmallProductSquare = (props: SmallProductSquareProps) => {
    let {
        product,
        readOnly = false,
        onDelete,
    } = props;

    const handleClick = e => {
        e.preventDefault();
        onDelete(product);
    }

    return <OverlayTrigger
        placement="top"
        overlay={
            <Tooltip id={`tooltip-${product.id}`}>
                {product.title} {readOnly && '(Removed)'}
            </Tooltip>
        }
    >
        <Container className="m-3 position-relative" disabled={readOnly}>
            <img
                className={`p-1 w-100 h-100 rounded`}
                alt={product.title}
                src={product.image_url}
            />
            {!readOnly && (
                <DeleteButton
                    className="border-0 p-0"
                    onClick={handleClick}
                >
                    <FontAwesomeIcon icon={faTimesCircle} size="1x"/>
                </DeleteButton>
            )}
        </Container>
    </OverlayTrigger>;
}

export default SmallProductSquare;